import React, { Component } from "react";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Galleria } from "primereact/galleria";
import { parseISO } from "date-fns";

import { isUser, isViewerExpert } from "../common/token-check";
import YesNoPending from "../common/ccs_yesNoPending";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SysService from "../services/sys.service";
import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { Divider } from "primereact/divider";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import needRelocateTemporary from "../common/ccs_needRelocateTemporary";
import rentalAgreement from "../common/ccs_rentalAgreement";
import storageOfGoods from "../common/ccs_storageOfGoods";

import { handleDownloadDocument } from "../common/functions";
import DocumentUpload from "../common/documentUpload.jsx";
import PdfViewer from "../common/pdfViewer.jsx";

class MovingAndTemporaryRelocation extends Component {
  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",

      pid: "",
      visibleCustomSidebar: false,
      nonEditAccess: true,
      activeIndex: 0,

      p50Documents: [],
      p50DocumentIndex: -1,
      p50SelectedDocument: null,

      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      addDocumentConfirmDialogVisible: false,
      uploadDocumentCheckConfirmDialog: false,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      s3Response: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      saveButtonStatus: false,

      date: null,

      dateTheHomeownerMovedToTheTemporaryAcommodation: "",
      startDateOfTheRentalAgreement: "",
      endDateOfTheRentalAgreement: "",

      selectedRow: null,
      deleteDocumentConfirmDialogVisible: false,
      signedDateOfRentalAgreementUploadedDialog: false,
      signedDateOfRentalAgreementUploaded: "",

      documents: [],
      selectedDocument: null,

      galleria1: React.createRef(null),
      imageList1: [],
      galleriaIndex: 0,

      pdfVisible: false,
      documentUrl: "",
    };
  }

  /*JS_methods*/

  itemTemplate = (item) => {
    return (
      <div className="custom-galleria-item">
        <img
          src={item?.itemImageSrc}
          alt={item?.alt}
          style={{ width: "100%", display: "block" }}
        />
        <div className="custom-content">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    );
  };

  itemTemplatePdf = () => {
    return (
      <div className="custom-galleria-item">
        <img src={this.state.documentUrl} style={{ width: "100%", display: "block" }} />
      </div>
    );
  };

  thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: "block" }}
      />
    );
  };

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeSignedRentalAgreementUploaded = (e) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project["signedRentalAgreementUploaded"] = val;

    if (val === "1" && !this.state.p50Documents.length > 0) {
      this.setState({
        signedDateOfRentalAgreementUploadedDialog: true,
      });
    }

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeCalendarTemporary = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      dateTheHomeownerMovedToTheTemporaryAcommodation: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeCalendarStart = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;
    this.setState({
      project,
      startDateOfTheRentalAgreement: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeCalendarEnd = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      endDateOfTheRentalAgreement: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocument = { ...this.state.newDocument };
    newDocument[`${name}`] = val;

    this.setState({ newDocument });
  };

  PDFViewer = (rowData) => {
    this.setState({
      pdfVisible: true,
      documentUrl: rowData.documentURL,
    });
  };

  actionTemplate = (data, props) => {
    const { t } = this.props;

    return (
      <div>
        <Button
          icon="pi pi-eye"
          tooltip={t("view")}
          className="p-button-rounded p-button-text"
          onClick={() => this.PDFViewer(data, props)}
        />

        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.setState({
                selectedRow: data,
                deleteDocumentConfirmDialogVisible: true,
              });
            }}
          />
        )}
      </div>
    );
  };

  hideSignedDateOfRentalAgreementUploadedDialog = () => {
    this.setState({
      signedDateOfRentalAgreementUploadedDialog: false,
    });
  };

  acceptDeleteDocument = () => {
    this.handleDeleteDocument(this.state.selectedRow);
  };

  rejectDeleteDocument = () => {};

  handleDeleteDocument = (rowData) => {
    if (this.state.activeIndex === 0) {
      const index = this.state.p50Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p50Documents.splice(index, 1);
      }
    }

    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  handleDocuments = () => {
    this.setState({
      loading: true,
    });

    let documents = [...this.state.documents];

    if (documents) {
      let localArray = [];

      for (let i = 0; i < documents.length; i++) {
        const doc = { ...this.emptyNewDocument };

        doc.documentDate = documents[i].documentDate;
        doc.documentName = documents[i].documentName;
        doc.documentURL = documents[i].documentURL;
        doc.fileDescription = documents[i].description;
        doc.fileName = documents[i].fileName;
        doc.phase = "P5" + this.state.activeIndex;

        localArray.push(doc);
      }

      if (this.state.activeIndex === 0) {
        let localP50Documents = [...this.state.p50Documents];
        localP50Documents = localP50Documents.concat(localArray);
        this.setState({
          loading: false,
          p50Documents: localP50Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      }
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
      });
    }
  };

  handleAddDocument = () => {
    this.setState({
      newDocument: this.emptyNewDocument,
      submitted: false,
      addDocumentDialogVisible: true,
      documents: [],
    });
  };

  hideAddDocumentDialog = () => {
    this.setState({
      submitted: false,
      addDocumentDialogVisible: false,
    });
  };

  acceptAddDocument = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);

      return;
    }

    if (
      this.state.newDocument.fileDescription == null ||
      this.state.newDocument.fileDescription === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDescription" />);

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P5" + this.state.activeIndex,
      this.state.pid
    ).then(
      (response) => {
        this.setState(
          { s3Response: response.data, tbuFile: fileX, saveButtonStatus: true },
          () => {
            this.state.newDocument.uploadURL = this.state.s3Response.uploadURL;
            this.state.newDocument.fileName = this.state.s3Response.fileName;
            this.state.newDocument.documentName =
              this.state.s3Response.documentName;
            this.state.newDocument.documentDate =
              this.state.s3Response.documentDate;
            this.state.newDocument.documentURL =
              this.state.s3Response.documentURL;
          }
        );

        this.put2S3();

        let p50doc = [...this.state.p50Documents];

        if (this.state.activeIndex === 0) {
          p50doc.push(this.state.newDocument);
          this.setState({ p50Documents: p50doc, saveButtonStatus: true });
        }
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  rejectAddDocument = () => {};

  calendarFormatTemplate = (data) => {
    if (data === null) {
      return null;
    } else if (data !== undefined) {
      const formatDate = parseISO(data, 2);
      return formatDate;
    } else {
      return "";
    }
  };

  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let temp50Documents = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P50") {
              temp50Documents.push(response.data.documentList[i]);
            }

            if (
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpg") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".png") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpeg")
            ) {
              let newObj = {};
              newObj.itemImageSrc = response.data.documentList[i].documentURL;
              newObj.thumbnailImageSrc =
                response.data.documentList[i].documentURL;
              newObj.alt = response.data.documentList[i].fileDescription;
              newObj.title = response.data.documentList[i].fileName;
              if (response.data.documentList[i].phase === "P50") {
                this.state.imageList1.push(newObj);
              }
            }
          }
        }

        this.setState({
          project: response.data,
          p50Documents: temp50Documents,

          dateTheHomeownerMovedToTheTemporaryAcommodation:
            this.calendarFormatTemplate(
              response?.data?.dateTheHomeownerMovedToTheTemporaryAcommodation
            ),
          startDateOfTheRentalAgreement: this.calendarFormatTemplate(
            response?.data?.startDateOfTheRentalAgreement
          ),
          endDateOfTheRentalAgreement: this.calendarFormatTemplate(
            response?.data?.endDateOfTheRentalAgreement
          ),

          loading: false,
          blockedPanel: false,
          nonEditAccess:
            response.data.status === 2 && !isViewerExpert() ? false : true,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.initFilters1();
    window.scrollTo(0, 0);
  }

  /**/
  getS3URL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }

    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.getS3URL(
        fileNameX,
        this.state.doc.documentType,
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addDocumentDialogVisible: false,
      });
    }
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (
        this.state.project.signedRentalAgreementUploaded === "1" &&
        !this.state.p50Documents.length > 0
      ) {
        this.setState({ loading: false });
        toast.warning(<Trans i18nKey="warnEmptyDocument" />);
        return;
      }

      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p50Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P5" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              imageList1: [],
            });

            this.componentDidMount();
            window.scrollTo(0, 0);

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }

    window.scrollTo(0, 0);
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  };

  projectIDTemplate(data) {
    let arr = [];

    arr = Array.from(String(data), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }
    let arrRes = arrX.concat(arr);
    let str = "";
    str = str + arrRes.join("");
    return str;
  }

  nextPhase = () => {
    if (!this.state.saveButtonStatus) {
      this.props.history.push({
        pathname: "/financialManagement",
        state: this.state.project,
      });
    } else {
      toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
    }
  };

  acceptUploadDocumentCheck = () => {
    this.setState({ uploadDocumentCheckConfirmDialog: false });
  };

  uploadDocumentCheck = (e) => {
    if (e.target.value === "1" && this.state.p50Documents.length < 1) {
      this.setState({ uploadDocumentCheckConfirmDialog: true });
    } else {
      this.setState({ uploadDocumentCheckConfirmDialog: false });
    }
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  setDocumentLoading = (documentLoading) => {
    this.setState({ documentLoading });
  };

  setDocuments = (data) => {
    this.setState({
      documents: data,
    });
  };

  setSaveButtonVisible = (data) => {
    this.setState({
      saveButtonStatus: data,
    });
  };

  setSelectedDocument = (data) => {
    this.setState({
      selectedDocument: data,
    });
  };

  hideDocumentDialog = () => {
    this.setState({
      addDocumentDialogVisible: false,
      documents: [],
    });
  };

  handleKeyDown = (event, name) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      let project = { ...this.state.project };
      project[`${name}`] = null;
      this.setState({
        project,
        saveButtonStatus: true,
      });
    }
  };

  /**/
  render() {
    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);

    this.stepItems = [
      {
        label: t("Relocation"),
        command: (event) => {},
      },
    ];

    const addDocumentDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddDocumentDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={this.acceptAddDocument}
        />
      </React.Fragment>
    );

    const informDialogHeader = (
      <React.Fragment>
        <div>
          <i
            className="pi pi-exclamation-triangle"
            style={{ fontSize: "1.5rem", color: "#f4c13f" }}
          ></i>{" "}
          {t("inform")}
        </div>
      </React.Fragment>
    );

    const addDocumentFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideDocumentDialog}
          />

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb"
            disabled={[...this.state.documents].length > 0 ? false : true}
            onClick={this.handleDocuments}
          />
        </div>
      </>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <Card
                style={{ height: "3.8em", background: "rgb(230, 240, 247)" }}
              >
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      style={{ fontSize: "18px", fontStyle: "Manrope" }}
                      label={
                        this.state.project.projectName +
                        " (ID: " +
                        this.projectIDTemplate(this.state.project.id) +
                        ")"
                      }
                      className="mb-2 custom-chip-home"
                    />
                  </Link>
                </div>
              </Card>
              <br /> <br />
              <div className={"col-12 "}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      style={{
                        color: "0094A8",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: "lighter",
                      }}
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />
                    <div>
                      <Chip
                        style={{ fontStyle: "Manrope", fontSize: "14px" }}
                        label={<b>{t("phase") + " 5: " + t("phase5Name")}</b>}
                        icon="pi pi-car"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                    />
                  </div>

                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >
                    {/*Relocation*/}
                    <TabPanel header={t("Relocation")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p51Text")}</div>
                      </div>

                      <Divider></Divider>
                      <div className="grid">
                        {/*needRelocateTemporaryHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {" "}
                            {t("needRelocateTemporary")}
                          </label>
                        </div>

                        {/*needRelocateTemporary*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="needRelocateTemporary"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.needRelocateTemporary}
                              options={needRelocateTemporary}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "needRelocateTemporary")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "needRelocateTemporary")
                              }
                            />
                          </div>
                        </div>

                        {/*rentalAgreementHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("rentalAgreement")}
                          </label>
                        </div>

                        {/*rentalAgreement*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="rentalAgreement"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.rentalAgreement}
                              options={rentalAgreement}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "rentalAgreement");
                                this.uploadDocumentCheck(e);
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "rentalAgreement")
                              }
                            />
                          </div>
                        </div>

                        {/*storageOfGoodsHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("storageOfGoods")}
                          </label>
                        </div>

                        {/*storageOfGoods*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="storageOfGoods"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.storageOfGoods}
                              options={storageOfGoods}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "storageOfGoods")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "storageOfGoods")
                              }
                            />
                          </div>
                        </div>

                        {/*Date the homeowner moved to the temporary accommodation Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t(
                              "dateTheHomeownerMovedToTheTemporaryAcommodation"
                            )}
                          </label>
                        </div>

                        {/*Date the homeowner moved to the temporary accommodation*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              id="dateTheHomeownerMovedToTheTemporaryAcommodation"
                              placeholder={t("pleaseEnter")}
                              value={
                                this?.state
                                  ?.dateTheHomeownerMovedToTheTemporaryAcommodation
                              }
                              onChange={(e) => {
                                this.onInputChangeCalendarTemporary(
                                  e,
                                  "dateTheHomeownerMovedToTheTemporaryAcommodation"
                                );
                              }}
                              maxLength={10}
                              readOnlyInput={true}
                              disabled={this.state.nonEditAccess}
                            />
                          </div>
                        </div>

                        {/*Signed rental agreement uploaded Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("signedRentalAgreementUploaded")}
                          </label>
                        </div>

                        {/*Signed rental agreement uploaded*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="signedRentalAgreementUploaded"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.signedRentalAgreementUploaded
                              }
                              options={YesNoPending}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChangeSignedRentalAgreementUploaded(
                                  e
                                );
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "signedRentalAgreementUploaded"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*Start date of the rental agreement Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("startDateOfTheRentalAgreement")}
                          </label>
                        </div>

                        {/*Start date of the rental agreement*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <div className="card flex justify-content-center">
                              <Calendar
                                id="startDateOfTheRentalAgreement"
                                dateFormat="dd/mm/yy"
                                placeholder={t("pleaseEnter")}
                                value={
                                  this?.state?.startDateOfTheRentalAgreement
                                }
                                onChange={(e) =>
                                  this.onInputChangeCalendarStart(
                                    e,
                                    "startDateOfTheRentalAgreement"
                                  )
                                }
                                maxLength={10}
                                readOnlyInput={true}
                                disabled={this.state.nonEditAccess}
                              />
                            </div>
                          </div>
                        </div>

                        {/*End date of the rental agreement Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("endDateOfTheRentalAgreement")}
                          </label>
                        </div>

                        {/*End date of the rental agreement*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <div className="card flex justify-content-center">
                              <Calendar
                                id="endDateOfTheRentalAgreement"
                                dateFormat="dd/mm/yy"
                                placeholder={t("pleaseEnter")}
                                value={this?.state?.endDateOfTheRentalAgreement}
                                onChange={(e) =>
                                  this.onInputChangeCalendarEnd(
                                    e,
                                    "endDateOfTheRentalAgreement"
                                  )
                                }
                                maxLength={10}
                                readOnlyInput={true}
                                disabled={this.state.nonEditAccess}
                              />
                            </div>
                          </div>
                        </div>

                        {/*Duration of the rental agreement Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("durationOfTheRentalAgreement")}
                          </label>
                        </div>

                        {/*Duration of the rental agreement*/}
                        <div className="col-12 md:col-6 lg:col-6 ">
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="durationOfTheRentalAgreement"
                              value={
                                this.state.project.durationOfTheRentalAgreement
                              }
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "durationOfTheRentalAgreement"
                                )
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        <Divider />

                        {/*addDocument*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p50Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable}
                              paginator
                              rows={10}
                              filters={this.state.filters1}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>

                          {this.state.imageList1.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleria1}
                                  value={this.state.imageList1}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageList1 &&
                                    this.state.imageList1.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleria1.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {/* p50Notes */}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="p50Notes"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("notes")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="p50Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.p50Notes}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "p50Notes")
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <br />
                <div
                  class="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div class="btn-group" role="group" aria-label="First group">
                    {!isViewerExpert() && (
                      <Button
                        disabled={!this.state.saveButtonStatus}
                        style={{ fontSize: "14px", fontStyle: "Manrope" }}
                        icon="pi pi-check-circle"
                        label={
                          this.state.activeIndex < this.stepItems.length - 1
                            ? t("saveNext")
                            : t("save")
                        }
                        className="p-button-sm p-button-danger"
                        onClick={() => this.stepSaveAndNext()}
                      />
                    )}
                  </div>
                  <div class="btn-group">
                    <div class="input-group-prepend">
                      <Button
                        style={{
                          fontSize: "14px",
                          fontStyle: "Manrope",
                        }}
                        iconPos="right"
                        icon="pi pi-angle-double-right"
                        label={t("nextPhase")}
                        className="p-button-sm p-button-mb"
                        onClick={() => this.nextPhase()}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {/* addDocument dialogu */}
              <Dialog
                visible={this.state.addDocumentDialogVisible}
                style={{ width: "650px" }}
                header={t("addDocument")}
                modal
                className="p-fluid"
                footer={addDocumentFooter}
                onHide={this.hideDocumentDialog}
              >
                <DocumentUpload
                  loading={this.state.loading}
                  setLoading={this.setLoading}
                  documents={this.state.documents}
                  setDocuments={this.setDocuments}
                  selectedDocument={this.state.selectedDocument}
                  setSelectedDocument={this.setSelectedDocument}
                  setSaveButtonVisible={this.setSaveButtonVisible}
                  uploadType={"D"}
                  isMulti={true}
                  isRequired={this.state.isRequiredDocumentCompliance}
                  phase={"P5" + this.state.activeIndex}
                  pid={this.state.pid}
                />
              </Dialog>
              <Dialog
                visible={this.state.signedDateOfRentalAgreementUploadedDialog}
                style={{ width: "450px" }}
                header={informDialogHeader}
                modal
                className="p-fluid"
                icon="pi pi-exclamation-triangle"
                onHide={this.hideSignedDateOfRentalAgreementUploadedDialog}
              >
                <div className="p-float-label">
                  {/* <label style={{ fontWeight:"bolder" }} htmlFor="ad2">{t("signedRentalAgreementUploadedText")}</label> */}
                  <p style={{ fontWeight: "bolder" }}>
                    <span style={{ color: "#3d7e7a" }}>
                      {" "}
                      {t("signedRentalAgreementUploadedText")}
                    </span>
                  </p>
                </div>
              </Dialog>
              <Dialog
                visible={this.state.pdfVisible}
                style={{ width: "%100", height: "%100" }}
                modal
                className="p-fluid"
                onHide={() => this.setState({ pdfVisible: false })}
              >
                {this.state.documentUrl.endsWith(".png") ||
                this.state.documentUrl.endsWith(".jpeg") ||
                this.state.documentUrl.endsWith(".jpg") ? (
                  <Galleria
                    value={this.state.documentUrl}
                    numVisible={5}
                    circular
                    style={{ maxWidth: "640px", borderRadius: "6px" }}
                    showThumbnails={false}
                    item={this.itemTemplatePdf}
                  />
                ) : this.state.documentUrl.endsWith(".pdf") ? (
                  <PdfViewer pdfUrl={this.state.documentUrl} />
                ) : (
                  <div>No valid document format found</div>
                )}
              </Dialog>
              <ConfirmDialog
                visible={this.state.uploadDocumentCheckConfirmDialog}
                onHide={() => {
                  this.setState({ uploadDocumentCheckConfirmDialog: false });
                }}
                message={t("uploadDocumentCheckText3")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-orange"
                accept={this.acceptUploadDocumentCheck}
                reject={this.acceptUploadDocumentCheck}
                acceptLabel={t("okey")}
                rejectLabel={t("cancel")}
              />
              <ConfirmDialog
                visible={this.state.deleteDocumentConfirmDialogVisible}
                onHide={() => {
                  this.setState({ deleteDocumentConfirmDialogVisible: false });
                }}
                message={t("confirmMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle"
                accept={this.acceptDeleteDocument}
                reject={this.rejectDeleteDocument}
              />
              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />
              <WaitDialog loading={this.state.loading} lang={t} />
              <WaitDialogDocument
                loading={this.state.documentLoading}
                lang={t}
              />
              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(
  connect(mapStateToProps)(MovingAndTemporaryRelocation)
);
