import React, { Component } from "react";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { Galleria } from "primereact/galleria";
import { Divider } from "primereact/divider";

import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { isUser, isViewerExpert } from "../common/token-check";
import { format, parseISO } from "date-fns";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SysService from "../services/sys.service";

import WorksAgreementWithHomeownerSigned from "../common/ccs_worksAgreementWithHomeownerSigned";
import TenderPackPreparedByQuantitySurveyor from "../common/ccs_tenderPackPreparedByQuantitySurveyor";
import TenderPackSentTo3Contractors from "../common/ccs_tenderPackSentTo3Contractors";
import ContractorOffersReceived from "../common/ccs_contractorOffersReceived";
import TenderReport from "../common/ccs_tenderReport";
import ContractorAppointed from "../common/ccs_contractorAppointed";
import WorksStartOnSite from "../common/ccs_worksStartOnSite";

import { handleDownloadDocument } from "../common/functions";
import { Calendar } from "primereact/calendar";
import DocumentUpload from "../common/documentUpload.jsx";
import PdfViewer from "../common/pdfViewer.jsx";

class contractManagement extends Component {
  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",

      pid: "",
      visibleCustomSidebar: false,
      visibleRight: false,

      nonEditAccess: true,
      activeIndex: 0,

      p40Documents: [],
      p41Documents: [],

      p40DocumentIndex: -1,
      p41DocumentIndex: -1,

      p40SelectedDocument: null,
      p41SelectedDocument: null,

      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      addDocumentConfirmDialogVisible: false,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      s3Response: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      filters2: null,
      globalFilterValue2: "",

      saveButtonStatus: false,

      contractEndDate: "",
      plannedDateTheWorksStartOnSite: "",
      actualDateTheWorksStartOnSite: "",

      selectedRow: null,
      deleteDocumentConfirmDialogVisible: false,
      sendApprovalConfirmDialogVisible: false,

      documents: [],
      selectedDocument: null,

      galleriaP40: React.createRef(null),
      galleriaP41: React.createRef(null),
      imageListP40: [],
      imageListP41: [],
      galleriaIndex: 0,
      
      pdfVisible: false,
      documentUrl: "",
    };
  }

  /*JS_methods*/

  itemTemplate = (item) => {
    return (
      <div className="custom-galleria-item">
        <img
          src={item?.itemImageSrc}
          alt={item?.alt}
          style={{ width: "100%", display: "block" }}
        />
        <div className="custom-content">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    );
  };

  itemTemplatePdf = () => {
    return (
      <div className="custom-galleria-item">
        <img src={this.state.documentUrl} style={{ width: "100%", display: "block" }} />
      </div>
    );
  };

  thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: "block" }}
      />
    );
  };
  
  projectIDTemplate(data) {
    let arr = [];
    arr = Array.from(String(data), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }
    let arrRes = arrX.concat(arr);
    let str = "";
    str = str + arrRes.join("");

    return str;
  }

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeContractEndDateCalendar = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;
    this.setState({
      project,
      contractEndDate: val,
      saveButtonStatus: true,
    });
  };

  onInputChangePlannedDateTheWorksStartOnSiteCalendar = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;
    this.setState({
      project,
      plannedDateTheWorksStartOnSite: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeActualDateTheWorksStartOnSiteCalendar = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;
    this.setState({
      project,
      actualDateTheWorksStartOnSite: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocument = { ...this.state.newDocument };
    newDocument[`${name}`] = val;

    this.setState({ newDocument });
  };

  PDFViewer = (rowData) => {
    this.setState({
      pdfVisible: true,
      documentUrl: rowData.documentURL,
    });
  };

  actionTemplate = (data, props) => {
    const { t } = this.props;

    return (
      <div>
        <Button
          icon="pi pi-eye"
          tooltip={t("view")}
          className="p-button-rounded p-button-text"
          onClick={() => this.PDFViewer(data, props)}
        />

        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.setState({
                selectedRow: data,
                deleteDocumentConfirmDialogVisible: true,
              });
            }}
          />
        )}
      </div>
    );
  };

  acceptDeleteDocument = () => {
    this.handleDeleteDocument(this.state.selectedRow);
  };

  rejectDeleteDocument = () => {};

  handleDeleteDocument = (rowData) => {
    if (this.state.activeIndex === 0) {
      const index = this.state.p40Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p40Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 1) {
      const index = this.state.p41Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p41Documents.splice(index, 1);
      }
    }

    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  handleDocuments = () => {
    this.setState({
      loading: true,
    });

    let documents = [...this.state.documents];

    if (documents) {
      let localArray = [];

      for (let i = 0; i < documents.length; i++) {
        const doc = { ...this.emptyNewDocument };

        doc.documentDate = documents[i].documentDate;
        doc.documentName = documents[i].documentName;
        doc.documentURL = documents[i].documentURL;
        doc.fileDescription = documents[i].description;
        doc.fileName = documents[i].fileName;
        doc.phase = "P1" + this.state.activeIndex;

        localArray.push(doc);
      }

      if (this.state.activeIndex === 0) {
        let localP40Documents = [...this.state.p40Documents];
        localP40Documents = localP40Documents.concat(localArray);
        this.setState({
          loading: false,
          p40Documents: localP40Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      } else if (this.state.activeIndex === 1) {
        let localP41Documents = [...this.state.p41Documents];
        localP41Documents = localP41Documents.concat(localArray);
        this.setState({
          loading: false,
          p41Documents: localP41Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      }
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
      });
    }
  };

  handleAddDocument = () => {
    this.setState({
      newDocument: this.emptyNewDocument,
      submitted: false,
      addDocumentDialogVisible: true,
      documents: [],
    });
  };

  hideAddDocumentDialog = () => {
    this.setState({
      submitted: false,
      addDocumentDialogVisible: false,
    });
  };

  acceptAddDocument = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);

      return;
    }

    if (
      this.state.newDocument.fileDescription == null ||
      this.state.newDocument.fileDescription === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDescription" />);

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P1" + this.state.activeIndex,
      this.state.pid
    ).then(
      (response) => {
        this.setState(
          { s3Response: response.data, tbuFile: fileX, saveButtonStatus: true },
          () => {
            this.state.newDocument.uploadURL = this.state.s3Response.uploadURL;
            this.state.newDocument.fileName = this.state.s3Response.fileName;
            this.state.newDocument.documentName =
              this.state.s3Response.documentName;
            this.state.newDocument.documentDate =
              this.state.s3Response.documentDate;
            this.state.newDocument.documentURL =
              this.state.s3Response.documentURL;
          }
        );

        this.put2S3();

        let p40doc = [...this.state.p40Documents];
        let p41doc = [...this.state.p41Documents];

        if (this.state.activeIndex === 0) {
          p40doc.push(this.state.newDocument);
          this.setState({ p40Documents: p40doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 1) {
          p41doc.push(this.state.newDocument);
          this.setState({ p41Documents: p41doc, saveButtonStatus: true });
        }
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  rejectAddDocument = () => {};

  documentDateTemplate(data) {
    return format(new Date(data.documentDate), "dd/MM/yyyy");
  }

  calendarFormatTemplate = (data) => {
    if (data === null) {
      return null;
    } else if (data !== undefined) {
      const formatDate = parseISO(data, 2);
      return formatDate;
    } else {
      return "";
    }
  };

  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let temp40Documents = [];
        let temp41Documents = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {}
        }

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P40") {
              temp40Documents.push(response.data.documentList[i]);
            }
            if (response.data.documentList[i].phase === "P41") {
              temp41Documents.push(response.data.documentList[i]);
            }

            if (
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpg") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".png") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpeg")
            ) {
              let newObj = {};
              newObj.itemImageSrc = response.data.documentList[i].documentURL;
              newObj.thumbnailImageSrc = response.data.documentList[i].documentURL;
              newObj.alt = response.data.documentList[i].fileDescription;
              newObj.title = response.data.documentList[i].fileName;

              if (response.data.documentList[i].phase === "P40") {
                this.state.imageListP40.push(newObj);
              }
              if (response.data.documentList[i].phase === "P41") {
                this.state.imageListP41.push(newObj);
              }
            }
          }
        }

        this.setState({
          project: response.data,
          p40Documents: temp40Documents,
          p41Documents: temp41Documents,
          contractEndDate: this.calendarFormatTemplate(
            response?.data?.contractEndDate
          ),
          plannedDateTheWorksStartOnSite: this.calendarFormatTemplate(
            response?.data?.plannedDateTheWorksStartOnSite
          ),
          actualDateTheWorksStartOnSite: this.calendarFormatTemplate(
            response?.data?.actualDateTheWorksStartOnSite
          ),
          loading: false,
          blockedPanel: false,
          nonEditAccess:
            response.data.status === 2 && !isViewerExpert() ? false : true,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.initFilters1();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  }

  sendApprovalMail = () => {
    this.state.project.p4Request = "waiting";
    this.state.project.projectStatus = "5";

    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (inx === 1) {
        if (!isViewerExpert()) {
          this.setState({
            loading: true,
          });

          this.state.project.projectId = this.state.pid;
          this.state.project.phase = "P4" + inx;

          SysService.updateProject(this.state.project).then(
            (response) => {
              this.setState({
                project: response.data,
                loading: false,
                saveButtonStatus: false,
              });

              toast.success(<Trans i18nKey="sendForApproval" />);
            },
            (error) => {
              this.setState({
                loading: false,
                content:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString(),
              });

              toast.error(<Trans i18nKey={error.response} />);

              if (
                error.response &&
                (error.response.status === 401 ||
                  error.response.status === 403 ||
                  error.response.status === 404)
              ) {
                switch (error.response.status) {
                  case 401:
                    window.location.href = "/401";
                    break;
                  case 403:
                    window.location.href = "/403";
                    break;
                  case 404:
                    window.location.href = "/404";
                    break;
                  default:
                    break;
                }
              }
            }
          );
        }
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx;
        this.setState({
          activeIndex: inx,
        });
      }
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  };

  /**/
  getS3URL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }

    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.getS3URL(
        fileNameX,
        this.state.doc.documentType,
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addDocumentDialogVisible: false,
      });
    }
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0.1);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 5);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p40Documents;
        } else if (inx === 1) {
          this.state.project.documents = this.state.p41Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P4" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              imageListP40:[],
              imageListP41:[],
            });
            this.componentDidMount();

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderHeaderTableTop2(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue2}
            onChange={this.onGlobalFilterChange2}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let filters2 = { ...this.state.filters2 };
    filters2["global"].value = value;

    this.setState({ filters2, globalFilterValue2: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });

    this.setState({
      filters2: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue2: "",
    });
  };

  nextPhase = () => {
    if (!this.state.saveButtonStatus) {
      this.props.history.push({
        pathname: "/movingAndTemporaryRelocation",
        state: this.state.project,
      });
    } else {
      toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
    }
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  setDocumentLoading = (documentLoading) => {
    this.setState({ documentLoading });
  };

  setDocuments = (data) => {
    this.setState({
      documents: data,
    });
  };

  setSaveButtonVisible = (data) => {
    this.setState({
      saveButtonStatus: data,
    });
  };

  setSelectedDocument = (data) => {
    this.setState({
      selectedDocument: data,
    });
  };

  hideDocumentDialog = () => {
    this.setState({
      addDocumentDialogVisible: false,
      documents: [],
    });
  };

  handleKeyDown = (event,name) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      let project= {...this.state.project}
      project[`${name}`]  = null;
      this.setState({
        project,
        saveButtonStatus: true
      })
    }
  };

  /**/
  render() {
    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);
    const headerTable2 = this.renderHeaderTableTop2(t);

    this.stepItems = [
      {
        label: t("worksAgreementWitHomeowner"),
        command: (event) => {},
      },
      {
        label: t("contractor"),
        command: (event) => {},
      },
    ];

    const addDocumentDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddDocumentDialog}
        />
        <Button
          style={{ fontSize: "14px", fontStyle: "Manrope" }}
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={this.acceptAddDocument}
        />
      </React.Fragment>
    );

    const addDocumentFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideDocumentDialog}
          />

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb"
            disabled={[...this.state.documents].length > 0 ? false : true}
            onClick={this.handleDocuments}
          />
        </div>
      </>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <Card
                style={{ height: "3.8em", background: "rgb(230, 240, 247)" }}
              >
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      style={{ fontSize: "18px", fontStyle: "Manrope" }}
                      label={
                        this.state.project.projectName +
                        " (ID: " +
                        this.projectIDTemplate(this.state.project.id) +
                        ")"
                      }
                      className="mb-2 custom-chip-home"
                    />
                  </Link>
                </div>
              </Card>
              <br /> <br />
              <div className={"col-12"}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      style={{
                        color: "0094A8",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: "lighter",
                      }}
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />
                    <div>
                      <Chip
                        style={{
                          fontStyle: "Manrope",
                          fontWeight: "bolder",
                          fontSize: "14px",
                        }}
                        label={t("phase") + " 4: " + t("phase4Name")}
                        icon="pi pi-briefcase"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                      onSelect={(e) => {
                        if (!this.state.saveButtonStatus) {
                          this.setState({ activeIndex: e.index });
                        } else {
                          if (this.state.activeIndex !== e.index) {
                            toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
                          }
                        }
                      }}
                      readOnly={false}
                    />
                  </div>

                  {/*Phase4_Contract/TenderManagement*/}
                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >
                    {/*Phase4_worksAgreementWithHomeowner*/}
                    <TabPanel header={t("worksAgreementWithHomeowner")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p41Text")}</div>
                      </div>

                      <Divider />

                      <div className="grid">
                        {/*nameOfQuantitySurveyor_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("nameOfQuantitySurveyor")}
                          </label>
                        </div>

                        {/*nameOfQuantitySurveyor*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="nameOfQuantitySurveyor"
                              value={this.state.project.nameOfQuantitySurveyor}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "nameOfQuantitySurveyor")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*tenderPackPreparedByQuantitySurveyor_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("tenderPackPreparedByQuantitySurveyor")}
                          </label>
                        </div>

                        {/*tenderPackPreparedByQuantitySurveyor*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="tenderPackPreparedByQuantitySurveyor"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project
                                  .tenderPackPreparedByQuantitySurveyor
                              }
                              options={TenderPackPreparedByQuantitySurveyor}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "tenderPackPreparedByQuantitySurveyor"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'tenderPackPreparedByQuantitySurveyor')}
                            />
                          </div>
                        </div>

                        {/*worksAgreementWithHomeownerSigned_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("worksAgreementWithHomeownerSigned")}
                          </label>
                        </div>

                        {/*worksAgreementWithHomeownerSigned*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="tenderPack"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project
                                  .worksAgreementWithHomeownerSigned
                              }
                              options={WorksAgreementWithHomeownerSigned}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "worksAgreementWithHomeownerSigned"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'worksAgreementWithHomeownerSigned')}

                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocument1*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>

                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p40Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable}
                              paginator
                              rows={10}
                              filters={this.state.filters1}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>

                          {this.state.imageListP40.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleriaP40}
                                  value={this.state.imageListP40}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageListP40 &&
                                    this.state.imageListP40.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleriaP40.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}

                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase4_contractor*/}
                    <TabPanel header={t("contractor")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p42Text")}</div>
                      </div>

                      <div className="grid">
                        {/*tenderPackSentTo3Contractors_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("tenderPackSentTo3Contractors")}
                          </label>
                        </div>

                        {/*tenderPackSentTo3Contractors*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="tenderPackSentTo3Contractors"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.tenderPackSentTo3Contractors
                              }
                              options={TenderPackSentTo3Contractors}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "tenderPackSentTo3Contractors"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'tenderPackSentTo3Contractors')}

                            />
                          </div>
                        </div>

                        {/*contractorOffersReceived _Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("contractorOffersReceived")}
                          </label>
                        </div>

                        {/*contractorOffersReceived*/}
                        <div className="col-12 md:col- lg:col-6">
                          <div>
                            <Dropdown
                              id="contractorOffersReceived"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.contractorOffersReceived
                              }
                              options={ContractorOffersReceived}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "contractorOffersReceived"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'contractorOffersReceived')}

                            />
                          </div>
                        </div>

                        {/*tenderReport_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("tenderReport")}
                          </label>
                        </div>

                        {/*tenderReport*/}
                        <div className="col-12 md:col- lg:col-6">
                          <div>
                            <Dropdown
                              id="tenderReport"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.tenderReport}
                              options={TenderReport}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "tenderReport")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'tenderReport')}

                            />
                          </div>
                        </div>

                        {/*contractorAppointed_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("contractorAppointed")}
                          </label>
                        </div>

                        {/*contractorAppointed*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="contractorAppointed"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.contractorAppointed}
                              options={ContractorAppointed}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "contractorAppointed")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'contractorAppointed')}

                            />
                          </div>
                        </div>

                        {/*Name of the contractor Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("nameOfTheContractor")}
                          </label>
                        </div>

                        {/*Name of the contractor*/}
                        <div className="col-12 md:col-6 lg:col-6 ">
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="nameOfTheContractor"
                              value={this.state.project.nameOfTheContractor}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "nameOfTheContractor")
                              }
                              maxLength={100}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*worksStartOnSite_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("worksStartOnSite")}
                          </label>
                        </div>

                        {/*worksStartOnSite*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="worksStartOnSite"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.worksStartOnSite}
                              options={WorksStartOnSite}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "worksStartOnSite")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'worksStartOnSite')}

                            />
                          </div>
                        </div>

                        {/*plannedDateTheWorksStartOnSite_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("plannedDateTheWorksStartOnSite")}
                          </label>
                        </div>

                        {/*plannedDateTheWorksStartOnSite*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              id="plannedDateTheWorksStartOnSite"
                              placeholder={t("pleaseEnter")}
                              value={
                                this?.state?.plannedDateTheWorksStartOnSite
                              }
                              onChange={(e) => {
                                this.onInputChangePlannedDateTheWorksStartOnSiteCalendar(
                                  e,
                                  "plannedDateTheWorksStartOnSite"
                                );
                              }}
                              maxLength={10}
                              readOnlyInput={true}
                              disabled={this.state.nonEditAccess}
                            />
                          </div>
                        </div>

                        {/*actualDateTheWorksStartOnSite_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("actualDateTheWorksStartOnSite")}
                          </label>
                        </div>

                        {/*actualDateTheWorksStartOnSite*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              id="actualDateTheWorksStartOnSite"
                              placeholder={t("pleaseEnter")}
                              value={this?.state?.actualDateTheWorksStartOnSite}
                              onChange={(e) => {
                                this.onInputChangeActualDateTheWorksStartOnSiteCalendar(
                                  e,
                                  "actualDateTheWorksStartOnSite"
                                );
                              }}
                              maxLength={10}
                              readOnlyInput={true}
                              disabled={this.state.nonEditAccess}
                            />
                          </div>
                        </div>

                        {/*Contract period Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("contractPeriod")}
                          </label>
                        </div>

                        {/*Contract period*/}
                        <div className="col-12 md:col-6 lg:col-6 ">
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="contractPeriod"
                              value={this.state.project.contractPeriod}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "contractPeriod")
                              }
                              maxLength={20}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*Contract end date Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("contractEndDate")}
                          </label>
                        </div>

                        {/*Contract end date*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              id="contractEndDate"
                              placeholder={t("pleaseEnter")}
                              value={this?.state?.contractEndDate}
                              onChange={(e) => {
                                this.onInputChangeContractEndDateCalendar(
                                  e,
                                  "contractEndDate"
                                );
                              }}
                              maxLength={10}
                              readOnlyInput={true}
                              disabled={this.state.nonEditAccess}
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocument*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>

                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p41Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable2}
                              paginator
                              rows={10}
                              filters={this.state.filters2}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>

                          {this.state.imageListP41.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleriaP41}
                                  value={this.state.imageListP41}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageListP41 &&
                                    this.state.imageListP41.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleriaP41.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}

                        </div>

                        {/* p41Notes */}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="p41Notes"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("notes")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="p41Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.p41Notes}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "p41Notes")
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <br />
                <div
                  class="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div class="btn-group" role="group" aria-label="First group">
                    <Button
                      style={{ fontSize: "14px", fontStyle: "Manrope" }}
                      icon="pi pi-chevron-circle-left"
                      disabled={this.state.activeIndex <= 0}
                      label={t("previous")}
                      className="p-button-sm p-button-mb"
                      onClick={() => this.stepPrev()}
                    />
                    &nbsp;
                    <Button
                      icon="pi pi-chevron-circle-right"
                      disabled={
                        this.state.activeIndex >= this.stepItems.length - 1
                      }
                      style={{ fontSize: "14px", fontStyle: "Manrope" }}
                      label={t("next")}
                      className="p-button-sm p-button-mb"
                      onClick={() => {
                        this.stepNext();
                      }}
                    />
                    &nbsp;
                    {!isViewerExpert() && (
                      <>
                        <Button
                          disabled={!this.state.saveButtonStatus}
                          style={{ fontSize: "14px", fontStyle: "Manrope" }}
                          icon="pi pi-check-circle"
                          label={
                            this.state.activeIndex < this.stepItems.length - 1
                              ? t("saveNext")
                              : t("save")
                          }
                          className="p-button-sm p-button-danger"
                          onClick={() => this.stepSaveAndNext()}
                        />
                        &nbsp;
                        {this.state.activeIndex === 1 &&
                          (this.state.project.projectStatus === "1" ||
                            this.state.project.projectStatus === "6" ||
                            this.state.project.projectStatus === null) && (
                            <Button
                              style={{
                                fontSize: "14px",
                                fontStyle: "Manrope",
                                position: "right",
                                color: "004E7A",
                              }}
                              iconPos="right"
                              icon="pi pi-send"
                              label={t("sendForApproval")}
                              className="p-button-sm"
                              disabled={
                                this.state.project.status == 4 ||
                                this.state.project.status == 3
                              }
                              onClick={() =>
                                this.setState({
                                  sendApprovalConfirmDialogVisible: true,
                                })
                              }
                            ></Button>
                          )}
                      </>
                    )}
                  </div>
                  {this.state.activeIndex === this.stepItems.length - 1 && (
                    <div class="btn-group">
                      <div class="input-group-prepend">
                        <Button
                          style={{
                            fontSize: "14px",
                            fontStyle: "Manrope",
                          }}
                          iconPos="right"
                          icon="pi pi-angle-double-right"
                          label={t("nextPhase")}
                          className="p-button-sm p-button-mb"
                          onClick={() => this.nextPhase()}
                        ></Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <br />
              {/* addDocument dialogu */}
              <Dialog
                visible={this.state.addDocumentDialogVisible}
                style={{ width: "650px" }}
                header={t("addDocument")}
                modal
                className="p-fluid"
                footer={addDocumentFooter}
                onHide={this.hideDocumentDialog}
              >
                <DocumentUpload
                  loading={this.state.loading}
                  setLoading={this.setLoading}
                  documents={this.state.documents}
                  setDocuments={this.setDocuments}
                  selectedDocument={this.state.selectedDocument}
                  setSelectedDocument={this.setSelectedDocument}
                  setSaveButtonVisible={this.setSaveButtonVisible}
                  uploadType={"D"}
                  isMulti={true}
                  isRequired={this.state.isRequiredDocumentCompliance}
                  phase={"P4" + this.state.activeIndex}
                  pid={this.state.pid}
                />
              </Dialog>

              <Dialog
                visible={this.state.pdfVisible}
                style={{ width: "%100", height: "%100" }}
                modal
                className="p-fluid"
                onHide={() => this.setState({ pdfVisible: false })}
              >
                {this.state.documentUrl.endsWith(".png") ||
                this.state.documentUrl.endsWith(".jpeg") ||
                this.state.documentUrl.endsWith(".jpg") ? (
                  <Galleria
                    value={this.state.documentUrl}
                    numVisible={5}
                    circular
                    style={{ maxWidth: "640px", borderRadius: "6px" }}
                    showThumbnails={false}
                    item={this.itemTemplatePdf}
                  />
                ) : this.state.documentUrl.endsWith(".pdf") ? (
                  <PdfViewer pdfUrl={this.state.documentUrl} />
                ) : (
                  <div>No valid document format found</div>
                )}
              </Dialog>
              
              <ConfirmDialog
                style={{ fontSize: "16px", fontStyle: "Manrope" }}
                visible={this.state.sendApprovalConfirmDialogVisible}
                onHide={() => {
                  this.setState({ sendApprovalConfirmDialogVisible: false });
                }}
                message={
                  <div>
                    <p>{t("sendApprovalMessage")}</p>
                    <p>
                      <b>{t("confirmMessage")}</b>
                    </p>
                  </div>
                }
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-red"
                acceptLabel={t("Yes")}
                rejectLabel={t("cancel")}
                accept={this.sendApprovalMail}
              />
              <ConfirmDialog
                visible={this.state.deleteDocumentConfirmDialogVisible}
                onHide={() => {
                  this.setState({ deleteDocumentConfirmDialogVisible: false });
                }}
                message={t("confirmMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle"
                accept={this.acceptDeleteDocument}
                reject={this.rejectDeleteDocument}
              />
              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />
              <WaitDialog loading={this.state.loading} lang={t} />
              <WaitDialogDocument
                loading={this.state.documentLoading}
                lang={t}
              />
              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(contractManagement));
