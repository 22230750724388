import React, { Component } from "react";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { InputMask } from "primereact/inputmask";
import { Divider } from "primereact/divider";
import { Calendar } from "primereact/calendar";
import { Galleria } from "primereact/galleria";

import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";

import { isUser, isViewerExpert } from "../common/token-check";
import { handleDownloadDocument } from "../common/functions";
import DocumentUpload from "../common/documentUpload.jsx";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SysService from "../services/sys.service";

import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";
import hearAboutUs from "../common/ccs_hearAboutUs";
import gender from "../common/ccs_gender";
import ethnicOrigin from "../common/ccs_ethnicOrigin";
import maritalStatusTypes from "../common/ccs_maritalStatusTypes";
import title from "../common/ccs_title";
import cities from "../common/ccs_cities";
import propertyType from "../common/ccs_propertyType";
import numberOfBedrooms from "../common/ccs_bedrooms";
import socialWorker from "../common/ccs_socialWorker";
import uploadLRSHomeImprovementReferralForm from "../common/ccs_LRSHomeImprovementReferralForm";
import validationOfBuildingInsurance from "../common/ccs_buildingsInsurance";
import validationOfLandRegistryAddress from "../common/ccs_proofOfAddressStatusOption";
import idTypes from "../common/ccs_idTypes";
import validationOfLandRegistryTitle from "../common/ccs_landRegistryTitleOption";
import validationOfLandRegistryIdentity from "../common/ccs_validationOfLandRegistryIdentity";
import propertyRiskAssessment from "../common/ccs_propertyRiskAssessment";
import referToAdvisor from "../common/css_referToAdvisor.js";
import grantApplied from "../common/ccs_grantApplied";
import loanStatus from "../common/ccs_loanStatus";
import energyEfficiencyGrantApplied from "../common/ccs_energyEfficiencyGrantApplied";
import ongoingMortgageStatus from "../common/ccs_ongoingMortgageStatus";
import energyEfficiencySurveyReport from "../common/ccs_surveyReportReceived";
import energyEfficiencyWholeHouse from "../common/ccs_energyEfficiencyWholeHouse";
import energyBills from "../common/ccs_energyBills";
import energyPerformanceCertificate from "../common/ccs_energyPerformanceCertificate";
import chargeOnProperty from "../common/ccs_chargeOnProperty";
import registeredDisabled from "../common/ccs_registeredDisabled";
import checkWithUnderwriter from "../common/css_checkWithUnderwriter";
import WallInsulation from "../common/ccs_wallInsulation.js";
import EnergyPerformanceClass from "../common/css_energyPerformanceClass.js";
import FloorInsulation from "../common/css_floorInsulation.js";
import Ventilation from "../common/ccs_ventilation.js";
import Solar from "../common/ccs_solar.js";
import RoofInsulation from "../common/ccs_roofInsulation.js";
import ExternalDoorReplacement from "../common/ccs_externalDoorReplacemen.js";
import HeatingSystemUpgrade from "../common/ccs_heatingSystemUpgrade.js";
import YesNo from "../common/ccs_yesNo";
import PdfViewer from "../common/pdfViewer.jsx";
import axios from "axios";

class PI extends Component {
  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: "",
  };

  emptyNewDocumentLink = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    documentDate: "",
    phase: "",
  };
  emptyUpdateStatus = {
    projectId: "",
    projectStatus: "",
    pausedReason: "",
    closedReason: "",
    notes: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",
      pmApprove: "",
      pid: "",
      visibleCustomSidebar: false,
      visibleRight: false,

      uploadDocumentCheckConfirmDialog: false,

      nonEditAccess: true,
      activeIndex: 0,

      p12Documents: [],
      p13Documents: [],
      p14Documents: [],
      p15Documents: [],

      p12DocumentIndex: -1,
      p13DocumentIndex: -1,
      p14DocumentIndex: -1,
      p15DocumentIndex: -1,

      p12SelectedDocument: null,
      p13SelectedDocument: null,
      p14SelectedDocument: null,
      p15SelectedDocument: null,

      newDocument: this.emptyNewDocument,
      newDocumentLink: this.emptyNewDocumentLink,
      addDocumentDialogVisible: false,
      addDocumentConfirmDialogVisible: false,
      deleteDocumentConfirmDialogVisible: false,

      addDocumentLinkDialogVisible: false,
      addDocumentLinkConfirmDialogVisible: false,
      updateDocumentLinkDialogVisible: false,

      sendApprovalConfirmDialogVisible: false,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      documentLinkData: "",

      s3Response: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      filters2: null,
      globalFilterValue2: "",

      filters3: null,
      globalFilterValue3: "",

      filters4: null,
      globalFilterValue4: "",

      filters5: null,
      globalFilterValue5: "",

      saveButtonStatus: false,

      mortgageStatusVisible: true,

      validationOfLandRegistryVisible: true,
      dateOfBirth: null,

      selectedRow: null,
      selectedLinkRow: null,
      annualGasConsumptionDate: "",
      annualElectricityConsumptionDate: "",

      documents: [],
      selectedDocument: null,

      galleriaP12: React.createRef(null),
      galleriaP13: React.createRef(null),
      galleriaP14: React.createRef(null),
      galleriaP15: React.createRef(null),

      imageListP12: [],
      imageListP13: [],
      imageListP14: [],
      imageListP15: [],

      galleriaIndex: 0,

      mandatoryFieldConfirmDailogVisible: false,
      mandatoryField: true,

      pdfVisible: false,
      documentUrl: "",
    };
  }

  /*JS_methods*/

  itemTemplate = (item) => {
    return (
      <div className="custom-galleria-item">
        <img
          src={item?.itemImageSrc}
          alt={item?.alt}
          style={{ width: "100%", display: "block" }}
        />
        <div className="custom-content">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    );
  };

  itemTemplatePdf = () => {
    return (
      <div className="custom-galleria-item">
        <img
          src={this.state.documentUrl}
          style={{ width: "100%", display: "block" }}
        />
      </div>
    );
  };

  thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: "block" }}
      />
    );
  };

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });

    if (project.idType === "5") {
      this.setState({
        validationOfLandRegistryVisible: true,
      });
    } else {
      this.setState({
        validationOfLandRegistryVisible: false,
      });
    }
  };

  onInputChangeDateOfBirthCalendar = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;
    this.setState({
      project,
      dateOfBirth: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocument = { ...this.state.newDocument };
    newDocument[`${name}`] = val;

    this.setState({ newDocument });
  };

  onInputChangeDocLink = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocumentLink = { ...this.state.newDocumentLink };
    newDocumentLink[`${name}`] = val;

    this.setState({ newDocumentLink });
  };

  onInputChangeCalendar1 = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      annualGasConsumptionDate: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeCalendar2 = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      annualElectricityConsumptionDate: val,
      saveButtonStatus: true,
    });
  };

  PDFViewer = (rowData) => {
    this.setState({
      pdfVisible: true,
      documentUrl: rowData.documentURL,
    });
  };

  actionTemplate = (data, props) => {
    const { t } = this.props;

    return (
      <div>
        {/* <img
          className="doc-icons"
          src="/assets/img/passport/viewIcon.png"
          alt=""
          onClick={() => PDFViewer(data, props)}
          title={t("view")}
        /> */}

        <Button
          icon="pi pi-eye"
          tooltip={t("view")}
          className="p-button-rounded p-button-text"
          onClick={() => this.PDFViewer(data, props)}
        />

        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={() =>
              this.setState({
                selectedRow: data,
                deleteDocumentConfirmDialogVisible: true,
              })
            }
          />
        )}
      </div>
    );
  };

  actionTemplate2 = (data, props) => {
    return (
      <div>
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.setState({
                selectedLinkRow: data,
                deleteDocumentLinkConfirmDialogVisible: true,
              });
            }}
          />
        )}
      </div>
    );
  };

  handleKeyDown = (event, name) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      let project = { ...this.state.project };
      project[`${name}`] = null;
      this.setState({
        project,
        saveButtonStatus: true,
      });
    }
  };

  // handleClearDropDown = (name) => {
  //   let project = { ...this.state.project };
  //   project[`${name}`] = null;

  //   this.setState({
  //     project,
  //     saveButtonStatus: true,
  //   });
  // };

  handleDeleteDocument = (rowData) => {
    if (this.state.activeIndex === 2) {
      const index = this.state.p12Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p12Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 3) {
      const index = this.state.p13Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p13Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 4) {
      const index = this.state.p14Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p14Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 5) {
      const index = this.state.p15Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p15Documents.splice(index, 1);
      }
    }

    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  handleDocuments = () => {
    this.setState({
      loading: true,
    });

    let documents = [...this.state.documents];

    if (documents) {
      let localArray = [];

      for (let i = 0; i < documents.length; i++) {
        const doc = { ...this.emptyNewDocument };

        doc.documentDate = documents[i].documentDate;
        doc.documentName = documents[i].documentName;
        doc.documentURL = documents[i].documentURL;
        doc.fileDescription = documents[i].description;
        doc.fileName = documents[i].fileName;
        doc.phase = "P1" + this.state.activeIndex;

        localArray.push(doc);
      }

      if (this.state.activeIndex === 2) {
        let localP12Documents = [...this.state.p12Documents];
        localP12Documents = localP12Documents.concat(localArray);
        this.setState({
          loading: false,
          p12Documents: localP12Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      } else if (this.state.activeIndex === 3) {
        let localP13Documents = [...this.state.p13Documents];
        localP13Documents = localP13Documents.concat(localArray);
        this.setState({
          loading: false,
          p13Documents: localP13Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      } else if (this.state.activeIndex === 4) {
        let localP14Documents = [...this.state.p14Documents];
        localP14Documents = localP14Documents.concat(localArray);
        this.setState({
          loading: false,
          p14Documents: localP14Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      } else if (this.state.activeIndex === 5) {
        let localP15Documents = [...this.state.p15Documents];
        localP15Documents = localP15Documents.concat(localArray);
        this.setState({
          loading: false,
          p15Documents: localP15Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      }
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
      });
    }
  };

  handleAddDocument = () => {
    this.setState({
      newDocument: this.emptyNewDocument,
      submitted: false,
      addDocumentDialogVisible: true,
      documents: [],
    });
  };

  hideAddDocumentDialog = () => {
    this.setState({
      submitted: false,
      addDocumentDialogVisible: false,
    });
  };

  handleAddDocumentLink = () => {
    this.setState({
      newDocumentLink: this.emptyNewDocumentLink,
      submitted: false,
      addDocumentLinkDialogVisible: true,
    });
  };

  hideAddDocumentLinkDialog = () => {
    this.setState({
      submitted: false,
      addDocumentLinkDialogVisible: false,
    });
  };

  acceptAddDocument = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);

      return;
    }

    if (
      this.state.newDocument.fileDescription == null ||
      this.state.newDocument.fileDescription === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDescription" />);

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P1" + this.state.activeIndex,
      this.state.pid
    ).then(
      (response) => {
        this.setState(
          { s3Response: response.data, tbuFile: fileX, saveButtonStatus: true },
          () => {
            this.state.newDocument.uploadURL = this.state.s3Response.uploadURL;
            this.state.newDocument.fileName = this.state.s3Response.fileName;
            this.state.newDocument.documentName =
              this.state.s3Response.documentName;
            this.state.newDocument.documentDate =
              this.state.s3Response.documentDate;
            this.state.newDocument.documentURL =
              this.state.s3Response.documentURL;
          }
        );

        this.put2S3();

        let p12doc = [...this.state.p12Documents];
        let p13doc = [...this.state.p13Documents];
        let p14doc = [...this.state.p14Documents];
        let p15doc = [...this.state.p15Documents];

        if (this.state.activeIndex === 2) {
          p12doc.push(this.state.newDocument);
          this.setState({ p12Documents: p12doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 3) {
          p13doc.push(this.state.newDocument);
          this.setState({ p13Documents: p13doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 4) {
          p14doc.push(this.state.newDocument);
          this.setState({ p14Documents: p14doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 5) {
          p15doc.push(this.state.newDocument);
          this.setState({ p15Documents: p15doc, saveButtonStatus: true });
        }
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  acceptAddDocumentLink = () => {
    this.setState({
      loading: true,
    });

    if (
      this.state.newDocumentLink.documentName == null ||
      this.state.newDocumentLink.documentName.trim() === "" ||
      this.state.newDocumentLink.documentURL == null ||
      this.state.newDocumentLink.documentURL.trim() === "" ||
      this.state.newDocumentLink.fileDescription == null ||
      this.state.newDocumentLink.fileDescription.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    } else {
      this.setState({
        loading: true,
        addDocumentLinkDialogVisible: false,
      });

      SysService.acceptAddDocumentLink(
        this.state.newDocumentLink.fileDescription,
        this.state.newDocumentLink.documentName,
        this.state.newDocumentLink.documentURL,
        "P14",
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            loading: false,
          });

          toast.success(<Trans i18nKey={response.data.message} />);

          this.getDocumentLink();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  };

  acceptDeleteDocument = () => {
    this.handleDeleteDocument(this.state.selectedRow);
  };

  acceptDeleteLinkDocument = () => {
    this.deleteDocumentLink(this.state.selectedLinkRow);
  };

  rejectDeleteDocument = () => {};

  acceptMandatoryField = () => {
    this.setState({
      //mandatoryField:false,
      mandatoryFieldConfirmDailogVisible: false,
    });

    this.state.mandatoryField = false;
    this.stepSaveAndNext();
  };

  rejectMandatoryField = () => {
    this.setState({
      mandatoryFieldConfirmDailogVisible: false,
    });
  };

  updateDocumentLink() {
    if (
      this.state.newDocumentLink.documentName == null ||
      this.state.newDocumentLink.documentName.trim() === "" ||
      this.state.newDocumentLink.documentURL == null ||
      this.state.newDocumentLink.documentURL.trim() === "" ||
      this.state.newDocumentLink.fileDescription == null ||
      this.state.newDocumentLink.fileDescription.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    } else {
      this.setState({
        loading: true,
        updateDocumentLinkDialogVisible: false,
      });
      SysService.updateDocumentLink(
        this.state.newDocumentLink.fileDescription,
        this.state.newDocumentLink.documentName,
        this.state.newDocumentLink.documentURL,
        this.state.newDocumentLink.id.toString()
      ).then(
        (response) => {
          this.setState({
            loading: false,
          });
          toast.success(<Trans i18nKey="164" />);

          this.getDocumentLink();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  }

  getDocumentLink() {
    SysService.getDocumentLink(this.props.location.state.id, "P14").then(
      (response) => {
        this.setState({
          documentLinkData: response.data,
          loading: false,
          blockedPanel: false,
        });
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  }

  deleteDocumentLink(data) {
    this.setState({
      loading: true,
    });

    let newData = data.id.toString();

    SysService.deleteDocumentLink(newData).then(
      (response) => {
        this.setState({
          loading: false,
        });

        toast.success(<Trans i18nKey={response.data.message} />);

        this.getDocumentLink();
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  }

  dateTableTemplate = (data) => {
    return format(new Date(data.logTime), "dd-MM-yyyy");
  };

  documentURLTemplate = (data) => {
    return (
      <div>
        {!isViewerExpert() && (
          <a href={data.documentURL} target="_blank" rel="noopener noreferrer">
            {data.documentURL}
          </a>
        )}
      </div>
    );
  };

  rejectAddDocument = () => {};

  acceptUploadDocumentCheck = () => {
    this.setState({ uploadDocumentCheckConfirmDialog: false });
  };

  uploadDocumentCheck = (e) => {
    if (e.target.value === "1" && this.state.p14Documents.length < 1) {
      this.setState({ uploadDocumentCheckConfirmDialog: true });
    } else {
      this.setState({ uploadDocumentCheckConfirmDialog: false });
    }
  };

  calendarFormatTemplate = (data) => {
    if (data === null) {
      return null;
    } else if (data !== undefined) {
      const formatDate = parseISO(data, 2);
      return formatDate;
    } else {
      return "";
    }
  };

  updateProjectStatus() {
    this.setState({ loading: true });

    let local = this.emptyUpdateStatus;
    local.projectId = this.state.pid;
    local.projectStatus = "2";

    SysService.updateProjectStatus(local).then(
      (response) => {
        this.setState({
          updateProjectStatus: response.data,
          loading: false,
          blockedPanel: false,
        });

        SysService.getProject(this.props.location.state.id).then(
          (response) => {
            this.setState({
              project: response.data,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              lastModificationTime: response.data.lastModificationTime,
              documents: response.data.documentList,
              loading: false,
              blockedPanel: false,
              nonEditAccess: response.data.status === 2 ? false : true,
            });
          },

          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString(),
              blockedPanel: true,
            });

            toast.error(<Trans i18nKey={this.state.content} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;

                case 403:
                  window.location.href = "/403";
                  break;

                case 404:
                  window.location.href = "/404";
                  break;

                default:
                  break;
              }
            }
          }
        );
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);
      }
    );
  }

  sendApprovalMail = () => {
    this.state.project.pmApprove = "waiting";
    this.state.project.projectStatus = "2";

    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (inx === 5) {
        if (!isViewerExpert()) {
          this.setState({
            loading: true,
          });

          this.state.project.projectId = this.state.pid;
          this.state.project.phase = "P1" + inx;

          SysService.updateProject(this.state.project).then(
            (response) => {
              this.setState({
                project: response.data,
                saveButtonStatus: false,
              });
              this.updateProjectStatus();

              toast.success(<Trans i18nKey="sendForApproval" />);
            },
            (error) => {
              this.setState({
                content:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString(),
              });

              toast.error(<Trans i18nKey={error.response} />);

              if (
                error.response &&
                (error.response.status === 401 ||
                  error.response.status === 403 ||
                  error.response.status === 404)
              ) {
                switch (error.response.status) {
                  case 401:
                    window.location.href = "/401";
                    break;
                  case 403:
                    window.location.href = "/403";
                    break;
                  case 404:
                    window.location.href = "/404";
                    break;
                  default:
                    break;
                }
              }
            }
          );
        }
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx;
        this.setState({
          activeIndex: inx,
        });
      }
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  };

  getProject() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    if (!isUser()) {
      SysService.getProject(this.props.location.state.id).then(
        (response) => {
          this.setState({
            project: response.data,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            lastModificationTime: response.data.lastModificationTime,
            documents: response.data.documentList,
            loading: false,
            blockedPanel: false,
            nonEditAccess: response.data.status === 2 ? false : true,
          });
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  }

  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });
    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let temp12Documents = [];
        let temp13Documents = [];
        let temp14Documents = [];
        let temp15Documents = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P12") {
              temp12Documents.push(response.data.documentList[i]);
            }
            if (response.data.documentList[i].phase === "P13") {
              temp13Documents.push(response.data.documentList[i]);
            }
            if (response.data.documentList[i].phase === "P14") {
              temp14Documents.push(response.data.documentList[i]);
            }
            if (response.data.documentList[i].phase === "P15") {
              temp15Documents.push(response.data.documentList[i]);
            }

            if (
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpg") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".png") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpeg")
            ) {
              let newObj = {};
              newObj.itemImageSrc = response.data.documentList[i].documentURL;
              newObj.thumbnailImageSrc =
                response.data.documentList[i].documentURL;
              newObj.alt = response.data.documentList[i].fileDescription;
              newObj.title = response.data.documentList[i].fileName;

              if (response.data.documentList[i].phase === "P12") {
                this.state.imageListP12.push(newObj);
              }
              if (response.data.documentList[i].phase === "P13") {
                this.state.imageListP13.push(newObj);
              }
              if (response.data.documentList[i].phase === "P14") {
                this.state.imageListP14.push(newObj);
              }
              if (response.data.documentList[i].phase === "P15") {
                this.state.imageListP15.push(newObj);
              }
            }
          }
        }

        this.setState({
          project: response.data,
          p12Documents: temp12Documents,
          p13Documents: temp13Documents,
          p14Documents: temp14Documents,
          p15Documents: temp15Documents,
          dateOfBirth: this.calendarFormatTemplate(response?.data?.dateOfBirth),

          loading: false,
          blockedPanel: false,
          nonEditAccess:
            response.data.status === 2 && !isViewerExpert() ? false : true,

          annualGasConsumptionDate: this.calendarFormatTemplate(
            response?.data?.annualGasConsumptionTimePeriodP1
          ),
          annualElectricityConsumptionDate: this.calendarFormatTemplate(
            response?.data?.annualElectricityConsumptionTimePeriodP1
          ),
        });
        this.statusCheck = response.data.status;
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.getDocumentLink();

    this.initFilters1();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  }

  /**/
  getS3URL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }

    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.getS3URL(
        fileNameX,
        this.state.doc.documentType,
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addDocumentDialogVisible: false,
      });
    }
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0.1);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 5);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (inx === 0) {
        if (
          this.state.project.projectName == null ||
          this.state.project.projectName.trim() === ""
        ) {
          this.setState({ loading: false });
          toast.warning(<Trans i18nKey="warnProjectName" />);
          return;
        } else if (
          (this.state.project.lastName == null ||
            this.state.project.lastName.trim() === "" ||
            this.state.project.firstName == null ||
            this.state.project.firstName.trim() === "" ||
            this.state.project.gender == null ||
            this.state.project.gender.trim() === "" ||
            this.state.project.city == null ||
            this.state.project.city.trim() === "" ||
            this.state.project.postcode == null ||
            this.state.project.postcode.trim() === "" ||
            this.state.project.streetName == null ||
            this.state.project.streetName.trim() === "" ||
            this.state.project.houseNumber == null ||
            this.state.project.houseNumber.trim() === "" ||
            this.state.project.phoneNumber == null ||
            this.state.project.phoneNumber.trim() === "" ||
            this.state.project.correspondenceAddress == null ||
            this.state.project.correspondenceAddress.trim() === "" ||
            this.state.project.howdidYouHearAboutUs == null ||
            this.state.project.howdidYouHearAboutUs.trim() === "" ||
            this.state.project.worksRequired == null ||
            this.state.project.worksRequired.trim() === "") &&
          this.state.mandatoryField
        ) {
          this.setState({
            loading: false,
            mandatoryFieldConfirmDailogVisible: true,
          });
          //toast.warning(<Trans i18nKey="warnMandatory" />);
          return;
        }
      } else if (inx === 1) {
        // if (
        //   this.state.project.propertyType == null ||
        //   this.state.project.propertyType.trim() === "" ||
        //   this.state.project.numberOfBedrooms == null ||
        //   this.state.project.numberOfBedrooms.trim() === "" ||
        //   this.state.project.socialWorker == null ||
        //   this.state.project.socialWorker.trim() === "" ||
        //   this.state.project.occupants == null ||
        //   this.state.project.occupants.trim() === ""
        // ) {
        //   this.setState({ loading: false });
        //   toast.warning(<Trans i18nKey="warnMandatory" />);
        //   return;
        // }
        if (
          (this.state.project.propertyType == null ||
            this.state.project.propertyType.trim() === "" ||
            this.state.project.numberOfBedrooms == null ||
            this.state.project.numberOfBedrooms.trim() === "" ||
            this.state.project.socialWorker == null ||
            this.state.project.socialWorker.trim() === "" ||
            this.state.project.occupants == null ||
            this.state.project.occupants.trim() === "") &&
          this.state.mandatoryField
        ) {
          this.setState({
            loading: false,
            mandatoryFieldConfirmDailogVisible: true,
          });

          return;
        }
      }

      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 2) {
          this.state.project.documents = this.state.p12Documents;
        } else if (inx === 3) {
          this.state.project.documents = this.state.p13Documents;
        } else if (inx === 4) {
          this.state.project.documents = this.state.p14Documents;
        } else if (inx === 5) {
          this.state.project.documents = this.state.p15Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P1" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              documents: [],
              imageListP12: [],
              imageListP13: [],
              imageListP14: [],
              imageListP15: [],
              mandatoryField: true,
            });
            this.componentDidMount();

            window.scrollTo(0, 0);

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={this.state.content} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderHeaderTableTop2(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue2}
            onChange={this.onGlobalFilterChange2}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderHeaderTableTop3(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue3}
            onChange={this.onGlobalFilterChange3}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderHeaderTableTop4(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue4}
            onChange={this.onGlobalFilterChange4}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderHeaderTableTop5(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue5}
            onChange={this.onGlobalFilterChange5}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let filters2 = { ...this.state.filters2 };
    filters2["global"].value = value;

    this.setState({ filters2, globalFilterValue2: value });
  };

  onGlobalFilterChange3 = (e) => {
    const value = e.target.value;
    let filters3 = { ...this.state.filters3 };
    filters3["global"].value = value;

    this.setState({ filters3, globalFilterValue3: value });
  };

  onGlobalFilterChange4 = (e) => {
    const value = e.target.value;
    let filters4 = { ...this.state.filters4 };
    filters4["global"].value = value;

    this.setState({ filters4, globalFilterValue4: value });
  };

  onGlobalFilterChange5 = (e) => {
    const value = e.target.value;
    let filters5 = { ...this.state.filters5 };
    filters5["global"].value = value;

    this.setState({ filters5, globalFilterValue5: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
    this.setState({
      filters2: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue2: "",
    });
    this.setState({
      filters3: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue3: "",
    });
    this.setState({
      filters4: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue4: "",
    });
    this.setState({
      filters5: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue5: "",
    });
  };

  projectIDTemplate(data) {
    let arr = [];

    arr = Array.from(String(data), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }
    let arrRes = arrX.concat(arr);
    let str = "";
    str = str + arrRes.join("");
    return str;
  }

  nextPhase = () => {
    if (!this.state.saveButtonStatus) {
      this.props.history.push({
        pathname: "/fs",
        state: this.state.project,
      });
    } else {
      toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
    }
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  setDocumentLoading = (documentLoading) => {
    this.setState({ documentLoading });
  };

  setDocuments = (data) => {
    this.setState({
      documents: data,
    });
  };

  setSaveButtonVisible = (data) => {
    this.setState({
      saveButtonStatus: data,
    });
  };

  setSelectedDocument = (data) => {
    this.setState({
      selectedDocument: data,
    });
  };

  hideDocumentDialog = () => {
    this.setState({
      addDocumentDialogVisible: false,
      documents: [],
    });
  };

  fetchLocation = () => {
    let newResults = { ...this.state.project };

    axios
      .get(`https://api.postcodes.io/postcodes/${this.state.project.postcode}`)
      .then((response) => {
        newResults.city = response.data.result.region;
        newResults.streetName = response.data.result.admin_ward;
        this.setState({
          project: newResults,
        });
        //setLocation(response.data.result); // Assuming response.data.result contains location data
      })
      .catch((error) => {
      });
  };
  /**/
  render() {
    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);
    const headerTable2 = this.renderHeaderTableTop2(t);
    const headerTable3 = this.renderHeaderTableTop3(t);
    const headerTable4 = this.renderHeaderTableTop4(t);
    const headerTable5 = this.renderHeaderTableTop5(t);

    this.stepItems = [
      {
        label: t("homeownerReferral"),
        command: (event) => {},
      },
      {
        label: t("lrsInitialInterview"),
        command: (event) => {},
      },
      {
        label: t("dueDiligence"),
        command: (event) => {},
      },
      {
        label: t("propertyValuations"),
        command: (event) => {},
      },
      {
        label: t("energyEfficiencyPreworks"),
        command: (event) => {},
      },
      {
        label: t("homeownerCommitment"),
        command: (event) => {},
      },
    ];

    const addDocumentDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddDocumentDialog}
        />
        <Button
          style={{ fontSize: "14px", fontStyle: "Manrope" }}
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.acceptAddDocument()}
        />

        <ConfirmDialog
          visible={this.state.addDocumentConfirmDialogVisible}
          onHide={() => {
            this.setState({ addDocumentConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptAddDocument}
          reject={this.rejectAddDocument}
        />
      </React.Fragment>
    );

    const addDocumentLinkDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddDocumentLinkDialog}
        />
        <Button
          style={{ fontSize: "14px", fontStyle: "Manrope" }}
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() =>
            this.setState({ addDocumentLinkConfirmDialogVisible: true })
          }
        />

        <ConfirmDialog
          visible={this.state.addDocumentLinkConfirmDialogVisible}
          onHide={() => {
            this.setState({ addDocumentLinkConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptAddDocumentLink}
          reject={this.rejectAddDocument}
        />
      </React.Fragment>
    );

    const addDocumentFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideDocumentDialog}
          />

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb"
            disabled={[...this.state.documents].length > 0 ? false : true}
            onClick={this.handleDocuments}
          />
        </div>
      </>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <Card
                style={{ height: "3.8em", background: "rgb(230, 240, 247)" }}
              >
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      style={{ fontSize: "18px", fontStyle: "Manrope" }}
                      label={
                        this.state.project.projectName +
                        " (ID: " +
                        this.projectIDTemplate(this.state.project.id) +
                        ")"
                      }
                      className="mb-2 custom-chip-home"
                    />
                  </Link>
                </div>
              </Card>

              <br />

              <br />

              <div className={"col-12"}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      style={{
                        color: "0094A8",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: "lighter",
                      }}
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />
                    <div>
                      <Chip
                        style={{
                          fontStyle: "Manrope",
                          fontWeight: "bolder",
                          fontSize: "14px",
                        }}
                        label={t("phase") + " 1: " + t("phase1Name")}
                        icon="pi pi-id-card"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                      onSelect={(e) => {
                        if (!this.state.saveButtonStatus) {
                          this.setState({ activeIndex: e.index });
                        } else {
                          if (this.state.activeIndex !== e.index) {
                            toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
                          }
                        }
                      }}
                      readOnly={false}
                    />
                  </div>

                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >
                    {/*Phase1_Homeowner_referral*/}
                    <TabPanel header={t("homeownerReferral")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {t("p11Text")}
                      </div>

                      <div className="grid">
                        {/*project Name*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="projectName"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("projectName")} (*)
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="projectName"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.projectName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "projectName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*title*/}
                        <div className="col-12 md:col-6 lg:col-6  ">
                          <div className="mb-1">
                            <label
                              htmlFor="title"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("title")}
                            </label>
                          </div>
                          <div>
                            <Dropdown
                              id="title"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.title}
                              fontSize="16px"
                              options={title}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => this.onInputChange(e, "title")}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, "title")}
                            />
                          </div>
                        </div>

                        {/*lastname*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="lastName"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("lastName")} (!)
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="lastName"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.lastName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "lastName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*firstname*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="firstName"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("firstName")} (!)
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="firstName"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.firstName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "firstName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*middlename*/}
                        <div className="col-12 md:col-12 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="middleName"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("middleName")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="middleName"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.middleName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "middleName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*maritalStatus*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="maritalStatus"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("maritalStatus")}
                            </label>
                          </div>
                          <div>
                            <Dropdown
                              id="maritalStatus"
                              fontSize="14px"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.maritalStatus}
                              options={maritalStatusTypes}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "maritalStatus")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "maritalStatus")
                              }
                            />
                          </div>
                        </div>

                        {/*ethnicOrigin*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="ethnicOrigin"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("ethnicOrigin")}
                            </label>
                          </div>
                          <div>
                            <Dropdown
                              id="ethnicOrigin"
                              fontSize="14px"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.ethnicOrigin}
                              options={ethnicOrigin}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "ethnicOrigin")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "ethnicOrigin")
                              }
                            />
                          </div>
                        </div>

                        {/*age*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="age"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("age")} (!)
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="age"
                              fontSize="14px"
                              value={this.state.project.age}
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              onChange={(e) => this.onInputChange(e, "age")}
                              className="w-full"
                              maxLength={3}
                              keyfilter="pint"
                            ></InputText>
                          </div>
                        </div>

                        {/*dateOfBirth*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="dateOfBirth"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("dateOfBirth")}
                            </label>
                          </div>

                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              id="dateOfBirth"
                              placeholder={t("pleaseEnter")}
                              value={this?.state?.dateOfBirth}
                              onChange={(e) => {
                                this.onInputChangeDateOfBirthCalendar(
                                  e,
                                  "dateOfBirth"
                                );
                              }}
                              maxLength={10}
                              readOnlyInput={true}
                              disabled={this.state.nonEditAccess}
                            />
                          </div>
                        </div>

                        {/*gender*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="gender"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("gender")} (!)
                            </label>
                          </div>
                          <div>
                            <Dropdown
                              id="gender"
                              fontSize="14px"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.gender}
                              options={gender}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => this.onInputChange(e, "gender")}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, "gender")}
                            />
                          </div>
                        </div>

                        {/*Is the Homeowner registeredDisabled?*/}
                        <div className="col-12 md:col-6 lg:col-6  ">
                          <div className="mb-1">
                            <label
                              htmlFor="registeredDisabled"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("registeredDisabled")}
                            </label>
                          </div>
                          <div>
                            <Dropdown
                              id="registeredDisabled"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.registeredDisabled}
                              fontSize="16px"
                              options={registeredDisabled}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "registeredDisabled")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "registeredDisabled")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-6  "></div>
                        <Divider />

                        {/*postcode*/}
                        <div className="col-12 md:col-6 lg:6">
                          <div className="mb-1">
                            <label
                              htmlFor="postcode"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("postcode")} (!)
                            </label>
                          </div>
                          <div className="p-inputgroup">
                            <InputText
                              id="postcode"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.postcode}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "postcode")
                              }
                              maxLength={10}
                              className="w-full"
                            />
                            <i
                              className="pi pi-search p-button p-button-secondary"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              onClick={this.fetchLocation}
                            />
                          </div>
                        </div>

                        {/*borough*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="borough"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("borough")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="borough"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              value={this.state.project.borough}
                              disabled={this.state.nonEditAccess}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) => this.onInputChange(e, "borough")}
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*town*/}
                        {this.state.project.city === "Other" && (
                          <>
                            <div className="col-12 md:col-6 lg:col-6">
                              <div className="mb-1">
                                <label
                                  htmlFor="town"
                                  className="platformColor"
                                  style={{ fontSize: "14px" }}
                                >
                                  {t("town")}
                                </label>
                              </div>
                              <div>
                                <InputText
                                  id="town"
                                  value={this.state.project.town}
                                  onChange={(e) =>
                                    this.onInputChange(e, "town")
                                  }
                                  maxLength={50}
                                  className="w-full"
                                />
                              </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6"></div>
                          </>
                        )}

                        {/*city*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="city"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("city")} (!)
                            </label>
                          </div>
                          <div>
                            <Dropdown
                              id="city"
                              fontSize="14px"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.city}
                              options={cities}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => this.onInputChange(e, "city")}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, "city")}
                            />
                          </div>
                        </div>

                        {/*streetName*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="streetName"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("streetName")} (!)
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="streetName"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.streetName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "streetName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*houseNumber*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="houseNumber"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("houseNumber")} (!)
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="houseNumber"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.houseNumber}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "houseNumber")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        <div className="col-12 md:col-6 lg:col-6"></div>

                        {/*email*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="email"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("email")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="email"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.email}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) => this.onInputChange(e, "email")}
                              maxLength={100}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*phone*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="phoneNumber"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("phone")} (!)
                            </label>
                          </div>
                          <div>
                            <InputMask
                              fontSize="14px"
                              id="phoneNumber"
                              mask="+44-9999-999999"
                              placeholder="+44-XXXXX-XXXXXX"
                              autoClear={false}
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.phoneNumber}
                              onChange={(e) =>
                                this.onInputChange(e, "phoneNumber")
                              }
                              className="w-full"
                            />
                          </div>
                        </div>

                        <Divider />

                        {/*secondTitle*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="secondTitle"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("secondTitle")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="secondTitle"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.secondTitle}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "secondTitle")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*secondLastName*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="secondLastName"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("secondLastName")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="secondLastName"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.secondLastName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "secondLastName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*secondFirstName*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="secondFirstName"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("secondFirstName")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="secondFirstName"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.secondFirstName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "secondFirstName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*secondMiddleName*/}
                        <div className="col-12 md:col-12 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="secondMiddleName"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("secondMiddleName")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="secondMiddleName"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.secondMiddleName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "secondMiddleName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*secondDateOfBirth*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="secondDateOfBirth"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("secondDateOfBirth")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="secondDateOfBirth"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.secondDateOfBirth}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "secondDateOfBirth")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*Is the Second Homeowner registeredDisabled?*/}
                        <div className="col-12 md:col-6 lg:col-6  ">
                          <div className="mb-1">
                            <label
                              htmlFor="secondRegisteredDisabled"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("secondRegisteredDisabled")}
                            </label>
                          </div>
                          <div>
                            <Dropdown
                              id="secondRegisteredDisabled"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.secondRegisteredDisabled
                              }
                              fontSize="16px"
                              options={registeredDisabled}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "secondRegisteredDisabled"
                                )
                              }
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "secondRegisteredDisabled"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                            />
                          </div>
                        </div>
                        <Divider />

                        {/*correspondenceAddress*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="correspondenceAddress"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("correspondenceAddress")} (!)
                            </label>
                          </div>
                          <div>
                            <InputText
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="correspondenceAddress"
                              value={this.state.project.correspondenceAddress}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "correspondenceAddress")
                              }
                              maxLength={100}
                              className="w-full"
                            />
                          </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-6"> </div>

                        {/*hearAboutUs*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="howdidYouHearAboutUs"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("hearAboutUs")} (!)
                            </label>
                          </div>
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="howdidYouHearAboutUs"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.howdidYouHearAboutUs}
                              options={hearAboutUs}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "howdidYouHearAboutUs")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "howdidYouHearAboutUs")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-4 mb-4"></div>

                        {/*worksRequired*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="worksRequired"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("worksRequired")} (!)
                            </label>
                          </div>
                          <div>
                            <InputTextarea
                              id="worksRequired"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.worksRequired}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "worksRequired")
                              }
                              rows={3}
                              cols={20}
                              maxLength={3000}
                              className="w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase1_LRS_Initial_Interview */}
                    <TabPanel header={t("LRSinitialInterview")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p12Text")}</div>
                      </div>

                      <div className="grid">
                        {/*Property Type*/}
                        <div className="col-12 md:col-6 lg:col-6  ">
                          <div className="mb-1">
                            <label
                              htmlFor="propertyType"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("propertyType")} (!)
                            </label>
                          </div>
                          <div>
                            <div className="flex items-center">
                              <Dropdown
                                id="propertyType"
                                style={{ fontSize: "14px" }}
                                disabled={this.state.nonEditAccess}
                                value={this.state.project.propertyType}
                                options={propertyType}
                                placeholder={t("pleaseSelect")}
                                onChange={(e) =>
                                  this.onInputChange(e, "propertyType")
                                }
                                optionLabel="name"
                                optionValue="code"
                                className="w-full"
                                onKeyDown={(e) =>
                                  this.handleKeyDown(e, "propertyType")
                                }
                              />
                              {/* {this.state.project.propertyType !== null && (
                                <>
                                  <Button 
                              disabled={this.state.project.propertyType === null ?true :false}
                              icon="pi pi-replay"
                              className="p-button-rounded p-button-text" 
                              tooltip="Reset"
                              onClick={() => {
                                this.handleClearDropDown("propertyType")
                              }}                             
                             />     
                                   <Button
                                    disabled={
                                      this.state.project.propertyType === null
                                        ? true
                                        : false
                                    }
                                    className="p-button-outlined"
                                    label="Reset"
                                    onClick={() => {
                                      this.handleClearDropDown("propertyType");
                                    }}
                                  /> 
                                </>
                              )} */}
                            </div>
                          </div>
                        </div>

                        {/*numberOfBedrooms*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="numberOfBedrooms"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("numberOfBedrooms")} (!)
                            </label>
                          </div>
                          <div className="flex items-center">
                            <Dropdown
                              id="numberOfBedrooms"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.numberOfBedrooms}
                              options={numberOfBedrooms}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "numberOfBedrooms")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "numberOfBedrooms")
                              }
                            />
                            {/*{this.state.project.numberOfBedrooms !== null && (
                                <>
                                  <Button 
                              disabled={this.state.project.numberOfBedrooms === null ?true :false}
                              icon="pi pi-replay"
                              className="p-button-rounded p-button-text" 
                              tooltip="Reset"
                              onClick={() => {
                                this.handleClearDropDown("numberOfBedrooms")
                              }}                             
                             />      
                                  <Button
                                    disabled={
                                      this.state.project.numberOfBedrooms === null
                                        ? true
                                        : false
                                    }
                                    className="p-button-outlined"
                                    label="Reset"
                                    onClick={() => {
                                      this.handleClearDropDown("numberOfBedrooms");
                                    }}
                                  />
                                </>
                              )}*/}
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*socialWorker*/}
                        <div className="col-12 md:col-12 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="socialWorker"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("socialWorker")} (!)
                            </label>
                          </div>
                          <div className="flex items-center">
                            <Dropdown
                              id="socialWorker"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.socialWorker}
                              style={{ fontSize: "14px" }}
                              options={socialWorker}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "socialWorker")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "socialWorker")
                              }
                            />
                            {/* {this.state.project.socialWorker !== null && (
                                <>
                                  <Button 
                              disabled={this.state.project.socialWorker === null ?true :false}
                              icon="pi pi-replay"
                              className="p-button-rounded p-button-text" 
                              tooltip="Reset"
                              onClick={() => {
                                this.handleClearDropDown("socialWorker")
                              }}                             
                             />      
                                  <Button
                                    disabled={
                                      this.state.project.socialWorker === null
                                        ? true
                                        : false
                                    }
                                    className="p-button-outlined p-button-secondary"
                                    label="Clear"
                                    onClick={() => {
                                      this.handleClearDropDown("socialWorker");
                                    }}
                                  />
                                </>
                              )}*/}
                          </div>
                        </div>

                        {/*socialSupport*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="socialSupportInformation"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("professionalSupport")}{" "}
                            </label>
                          </div>
                          <div>
                            <InputTextarea
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              style={{ fontSize: "14px" }}
                              id="socialSupportInformation"
                              value={
                                this.state.project.socialSupportInformation
                              }
                              placeholder={t("pleaseEnterInformation")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "socialSupportInformation"
                                )
                              }
                              rows={3}
                              cols={20}
                              maxLength={800}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*numberOfOccupants*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="occupants"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("numberOfOccupants")} (!)
                            </label>
                          </div>
                          <div>
                            <InputText
                              keyfilter={"pnum"}
                              readOnly={this.state.nonEditAccess}
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              id="occupants"
                              value={this.state.project.occupants}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "occupants")
                              }
                              maxLength={100}
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "occupants")
                              }
                            />
                          </div>
                        </div>

                        {/*numberOfDependantsUnder18*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="numberOfDependantsUnder18"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("numberOfDependantsUnder18")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              keyfilter={"pnum"}
                              id="numberOfDependantsUnder18"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={
                                this.state.project.numberOfDependantsUnder18
                              }
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "numberOfDependantsUnder18"
                                )
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*dateOfBirthDependantsUnder18*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="dateOfBirthDependantsUnder18"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("dateOfBirthDependantsUnder18")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="dateOfBirthDependantsUnder18"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={
                                this.state.project.dateOfBirthDependantsUnder18
                              }
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "dateOfBirthDependantsUnder18"
                                )
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*numberOfDependantsOver18*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="numberOfDependantsOver18"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("numberOfDependantsOver18")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              keyfilter={"pnum"}
                              id="numberOfDependantsOver18"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.numberOfDependantsOver18
                              }
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "numberOfDependantsOver18"
                                )
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*dateOfBirthDependantsOver18*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="dateOfBirthDependantsOver18"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("dateOfBirthDependantsOver18")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="dateOfBirthDependantsOver18"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.dateOfBirthDependantsOver18
                              }
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "dateOfBirthDependantsOver18"
                                )
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*numberOfOccupantsOther*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="numberOfOccupantsOther"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("numberOfOccupantsOther")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              keyfilter={"pnum"}
                              id="numberOfOccupantsOther"
                              fontSize="14px"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.numberOfOccupantsOther}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "numberOfOccupantsOther")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        <Divider />

                        {/*homeownerRepresentativeName*/}
                        <div className="col-12 md:col-12 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="homeownerRepresentativeName"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("homeownerRepresentativeName")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              id="homeownerRepresentativeName"
                              value={
                                this.state.project.homeownerRepresentativeName
                              }
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "homeownerRepresentativeName"
                                )
                              }
                              maxLength={100}
                              className="w-full"
                            />
                          </div>
                        </div>

                        <div className="col-12 md:col-6 lg:col-6"></div>

                        {/*homeownerRepresentativeEmail*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="homeownerRepresentativeEmail"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("homeownerRepresentativeEmail")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              id="homeownerRepresentativeEmail"
                              value={
                                this.state.project.homeownerRepresentativeEmail
                              }
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "homeownerRepresentativeEmail"
                                )
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*homeownerRepresentativePhone*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="homeownerRepresentativePhone"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("homeownerRepresentativePhone")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              style={{ fontSize: "14px" }}
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="homeownerRepresentativePhone"
                              value={
                                this.state.project.homeownerRepresentativePhone
                              }
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "homeownerRepresentativePhone"
                                )
                              }
                              maxLength={20}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*p11Notes*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="p11Notes"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("notes")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="p11Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.p11Notes}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "p11Notes")
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase1_Due_Diligence */}
                    <TabPanel header={t("dueDiligence")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {t("p13Text")}
                      </div>

                      <Divider />

                      <div className="grid">
                        {/*powerOfAttorneyRequiredHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("powerOfAttorneyRequired")}
                          </label>
                        </div>

                        {/*powerOfAttorneyRequired*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="powerOfAttorneyRequired"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.powerOfAttorneyRequired}
                              options={YesNo}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "powerOfAttorneyRequired")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "powerOfAttorneyRequired")
                              }
                            />
                          </div>

                          <div
                            style={{
                              display:
                                this.state.project.powerOfAttorneyRequired ===
                                "1"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {/*powerOfAttorneyPlace*/}
                            <div>
                              <div className="mb-1">
                                <br />
                                <label
                                  htmlFor="powerOfAttorneyPlace"
                                  className="platformColor"
                                >
                                  {t("powerOfAttorneyPlace")}{" "}
                                </label>
                              </div>
                              <div>
                                <Dropdown
                                  fontSize="14px"
                                  id="powerOfAttorneyPlace"
                                  disabled={this.state.nonEditAccess}
                                  value={
                                    this.state.project.powerOfAttorneyPlace
                                  }
                                  options={YesNo}
                                  placeholder={t("pleaseSelect")}
                                  onChange={(e) =>
                                    this.onInputChange(
                                      e,
                                      "powerOfAttorneyPlace"
                                    )
                                  }
                                  optionLabel="name"
                                  optionValue="code"
                                  className="w-full"
                                  onKeyDown={(e) =>
                                    this.handleKeyDown(
                                      e,
                                      "powerOfAttorneyPlace"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*validationOfLandRegistryTitleHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("validationOfLandRegistryTitle")}
                          </label>
                        </div>

                        {/*validationOfLandRegistryTitle*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="validationOfLandRegistryTitle"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.validationOfLandRegistryTitle
                              }
                              options={validationOfLandRegistryTitle}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "validationOfLandRegistryTitle"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "validationOfLandRegistryTitle"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*ongoingMortgageStatusHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("ongoingMortgageStatus")}
                          </label>
                        </div>

                        {/*ongoingMortgageStatus*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="ongoingMortgageStatus"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.ongoingMortgageStatus}
                              options={ongoingMortgageStatus}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "ongoingMortgageStatus")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "ongoingMortgageStatus")
                              }
                            />
                          </div>
                          <div
                            style={{
                              display:
                                this.state.project.ongoingMortgageStatus === "1"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {/*ongoingMortgageAmount*/}
                            <div>
                              <div className="mb-1">
                                <br />
                                <label
                                  htmlFor="ongoingMortgageAmount"
                                  className="platformColor"
                                >
                                  {t("ongoingMortgageAmount")}{" "}
                                </label>
                              </div>
                              <div>
                                <InputText
                                  readOnly={this.state.nonEditAccess}
                                  disabled={this.state.nonEditAccess}
                                  id="ongoingMortgageAmount"
                                  value={
                                    this.state.project.ongoingMortgageAmount
                                  }
                                  placeholder={t("pleaseEnter")}
                                  onChange={(e) =>
                                    this.onInputChange(
                                      e,
                                      "ongoingMortgageAmount"
                                    )
                                  }
                                  maxLength={50}
                                  className="w-full"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*chargeOnPropertyHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("chargeOnProperty")}
                          </label>
                        </div>

                        {/*chargeOnProperty*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="chargeOnProperty"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.chargeOnProperty}
                              options={chargeOnProperty}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "chargeOnProperty")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "chargeOnProperty")
                              }
                            />
                          </div>
                        </div>

                        {/*validationOfLandRegistryAddressHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("propertyRiskAssessment")}
                          </label>
                        </div>

                        {/*validationOfLandRegistryAddress*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="idType"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.propertyRiskAssessment}
                              options={propertyRiskAssessment}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "propertyRiskAssessment")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "propertyRiskAssessment")
                              }
                            />
                          </div>
                        </div>

                        {/*validationOfLandRegistryIdentityHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("validationOfLandRegistryIdentity")}
                            </label>
                          </div>
                        </div>

                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="grid">
                            {/*idTypes*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <Dropdown
                                  fontSize="14px"
                                  id="idTypes"
                                  disabled={this.state.nonEditAccess}
                                  value={this.state.project.idType}
                                  options={idTypes}
                                  placeholder={t("idTypes")}
                                  onChange={(e) => {
                                    this.onInputChange(e, "idType");
                                  }}
                                  optionLabel="name"
                                  optionValue="code"
                                  className="w-full"
                                  onKeyDown={(e) =>
                                    this.handleKeyDown(e, "idType")
                                  }
                                />
                              </div>
                            </div>

                            {/*validationOfLandRegistryIdentity*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <Dropdown
                                  fontSize="14px"
                                  id="validationOfLandRegistryIdentity"
                                  disabled={
                                    this.state.validationOfLandRegistryVisible
                                  }
                                  value={
                                    this.state.project
                                      .validationOfLandRegistryIdentity
                                  }
                                  options={validationOfLandRegistryIdentity}
                                  placeholder={t("pleaseSelect")}
                                  onChange={(e) =>
                                    this.onInputChange(
                                      e,
                                      "validationOfLandRegistryIdentity"
                                    )
                                  }
                                  optionLabel="name"
                                  optionValue="code"
                                  className="w-full"
                                  onKeyDown={(e) =>
                                    this.handleKeyDown(
                                      e,
                                      "validationOfLandRegistryIdentity"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*validationOfLandRegistryAddressHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("validationOfLandRegistryAddress")}
                          </label>
                        </div>

                        {/*validationOfLandRegistryAddress*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="idType"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project
                                  .validationOfLandRegistryAddress
                              }
                              options={validationOfLandRegistryAddress}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "validationOfLandRegistryAddress"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "validationOfLandRegistryAddress"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*validationOfBuildingInsuranceHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("validationOfBuildingInsurance")}
                          </label>
                        </div>

                        {/*validationOfBuildingInsurance*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="validationOfBuildingInsurance"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.validationOfBuildingInsurance
                              }
                              options={validationOfBuildingInsurance}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "validationOfBuildingInsurance"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "validationOfBuildingInsurance"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*uploadLRSHomeImprovementReferralFormHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("uploadLRSHomeImprovementReferralForm")}
                          </label>
                        </div>

                        {/*uploadLRSHomeImprovementReferralForm*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="uploadLRSHomeImprovementReferralForm"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project
                                  .uploadLRSHomeImprovementReferralForm
                              }
                              options={uploadLRSHomeImprovementReferralForm}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "uploadLRSHomeImprovementReferralForm"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "uploadLRSHomeImprovementReferralForm"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocument*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>

                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p12Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable}
                              paginator
                              rows={10}
                              filters={this.state.filters1}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>

                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>
                          {this.state.imageListP12.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleriaP12}
                                  value={this.state.imageListP12}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageListP12 &&
                                    this.state.imageListP12.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleriaP12.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {/*p12Notes*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="p12Notes"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("notes")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="p12Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.p12Notes}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "p12Notes")
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase1_Property_Valuations*/}
                    <TabPanel header={t("propertyValuations")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p14Text")}</div>
                      </div>

                      <div className="grid">
                        <Divider />

                        {/*currentValuationHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("currentValuation")}
                          </label>
                        </div>

                        {/*currentValuation*/}
                        <div className="col-12 md:col-6 lg:col-6 ">
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="currentValuation"
                              value={this.state.project.currentValuation}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "currentValuation")
                              }
                              maxLength={20}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*postworksEstimationHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("postworksEstimation")}
                          </label>
                        </div>

                        {/*postworksEstimation*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="postworksEstimation"
                              value={this.state.project.postworksEstimation}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "postworksEstimation")
                              }
                              maxLength={20}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*desktopFeasibilityHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("desktopFeasibility")}
                          </label>
                        </div>

                        {/*desktopFeasibility*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="desktopFeasibility"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.desktopFeasibility}
                              options={YesNo}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "desktopFeasibility")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "desktopFeasibility")
                              }
                            />
                          </div>
                        </div>

                        {/*fundingForRepairsHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("fundingForRepairs")}
                          </label>
                        </div>

                        {/*fundingForRepairs*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="fundingForRepairs"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.fundingForRepairs}
                              options={YesNo}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "fundingForRepairs")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "fundingForRepairs")
                              }
                            />
                          </div>
                          <div
                            style={{
                              display:
                                this.state.project.fundingForRepairs === "1"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {/*fundingForRepairsDetails*/}
                            <div>
                              <div className="mb-1">
                                <br />
                                <label
                                  htmlFor="fundingForRepairsDetails"
                                  className="platformColor"
                                >
                                  {t("fundingForRepairsDetails")}{" "}
                                </label>
                              </div>
                              <div>
                                <InputText
                                  readOnly={this.state.nonEditAccess}
                                  disabled={this.state.nonEditAccess}
                                  id="fundingForRepairsDetails"
                                  value={
                                    this.state.project.fundingForRepairsDetails
                                  }
                                  placeholder={t("pleaseEnter")}
                                  onChange={(e) =>
                                    this.onInputChange(
                                      e,
                                      "fundingForRepairsDetails"
                                    )
                                  }
                                  maxLength={100}
                                  className="w-full"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <Divider />

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*grantInformation*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <h4>{t("grantInformation")}</h4>
                        </div>

                        {/*grantAppliedHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("grantApplied")}
                          </label>
                        </div>

                        {/*grantApplied*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="grantApplied"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.grantApplied}
                              options={grantApplied}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "grantApplied")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "grantApplied")
                              }
                            />
                          </div>
                        </div>
                        {this.state.project.grantApplied === "1" && (
                          <>
                            {/*grantSchemeUsedHeader*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("grantSchemeUsed")}
                              </label>
                            </div>

                            {/*grantSchemeUsed*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <InputText
                                  readOnly={this.state.nonEditAccess}
                                  disabled={this.state.nonEditAccess}
                                  id="grantSchemeUsed"
                                  value={this.state.project.grantSchemeUsed}
                                  placeholder={t("pleaseEnter")}
                                  onChange={(e) =>
                                    this.onInputChange(e, "grantSchemeUsed")
                                  }
                                  maxLength={100}
                                  className="w-full"
                                />
                              </div>
                            </div>

                            {/*grantAmountReceivedHeader*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("grantAmountReceived")}
                              </label>
                            </div>

                            {/*grantAmountReceived*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <InputText
                                  id="grantAmountReceived"
                                  readOnly={this.state.nonEditAccess}
                                  disabled={this.state.nonEditAccess}
                                  value={this.state.project.grantAmountReceived}
                                  placeholder={t("pleaseEnter")}
                                  onChange={(e) =>
                                    this.onInputChange(e, "grantAmountReceived")
                                  }
                                  maxLength={50}
                                  className="w-full"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <br />

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*energyEfficiencyGrantInformation*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <h4>{t("energyEfficiencyGrantInformation")}</h4>
                        </div>
                        <div className="col-12 md:col-6 lg:col-6"></div>

                        {/*energyEfficiencyGrantAppliedHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("energyEfficiencyGrantApplied")}
                          </label>
                        </div>

                        {/*energyEfficiencyGrantApplied*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyEfficiencyGrantApplied"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.energyEfficiencyGrantApplied
                              }
                              options={energyEfficiencyGrantApplied}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "energyEfficiencyGrantApplied"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "energyEfficiencyGrantApplied"
                                )
                              }
                            />
                          </div>
                        </div>
                        {this.state.project.energyEfficiencyGrantApplied ===
                          "1" && (
                          <>
                            {/*energyEfficiencyGrantSchemeUsedHeader*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("energyEfficiencyGrantSchemeUsed")}
                              </label>
                            </div>

                            {/*energyEfficiencyGrantSchemeUsed*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <InputText
                                  id="energyEfficiencyGrantSchemeUsed"
                                  readOnly={this.state.nonEditAccess}
                                  disabled={this.state.nonEditAccess}
                                  value={
                                    this.state.project
                                      .energyEfficiencyGrantSchemeUsed
                                  }
                                  placeholder={t("pleaseEnter")}
                                  onChange={(e) =>
                                    this.onInputChange(
                                      e,
                                      "energyEfficiencyGrantSchemeUsed"
                                    )
                                  }
                                  maxLength={50}
                                  className="w-full"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <br />

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*loansInformation*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <h4>{t("loansInformation")}</h4>
                        </div>
                        <div className="col-12 md:col-6 lg:col-6"></div>

                        {/*loanStatusHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("loanStatus")}
                          </label>
                        </div>

                        {/*loanStatus*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="loanStatus"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.loanStatus}
                              options={loanStatus}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "loanStatus")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "loanStatus")
                              }
                            />
                          </div>
                        </div>

                        {this.state.project.loanStatus === "1" && (
                          <>
                            {/*loanProviderHeader*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("loanProvider")}
                              </label>
                            </div>

                            {/*loanProvider*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <label className="platformColor">
                                  {t("ifYesLoanProvider")}{" "}
                                </label>

                                <InputText
                                  id="loanProvider"
                                  readOnly={this.state.nonEditAccess}
                                  disabled={this.state.nonEditAccess}
                                  value={this.state.project.loanProvider}
                                  placeholder={t("pleaseEnter")}
                                  onChange={(e) =>
                                    this.onInputChange(e, "loanProvider")
                                  }
                                  maxLength={50}
                                  className="w-full"
                                />
                              </div>
                            </div>

                            {/*loanAmountHeader*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("loanAmount")}
                              </label>
                            </div>

                            {/*loanAmount*/}
                            <div className="col-12 md:col-6 lg:col-6">
                              <div>
                                <InputText
                                  id="loanAmount"
                                  readOnly={this.state.nonEditAccess}
                                  disabled={this.state.nonEditAccess}
                                  value={this.state.project.loanAmount}
                                  placeholder={t("pleaseEnter")}
                                  onChange={(e) =>
                                    this.onInputChange(e, "loanAmount")
                                  }
                                  maxLength={50}
                                  className="w-full"
                                />
                              </div>
                            </div>
                            <br />
                          </>
                        )}

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocument*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>

                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p13Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable2}
                              paginator
                              rows={10}
                              filters={this.state.filters2}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>

                          {this.state.imageListP13.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleriaP13}
                                  value={this.state.imageListP13}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageListP13 &&
                                    this.state.imageListP13.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleriaP13.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {/*p13Notes*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="p13Notes"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("notes")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="p13Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.p13Notes}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "p13Notes")
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase1_Energy_Efficiency(Pre-works)*/}
                    <TabPanel header={t("energyEfficiency(Pre-works)")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {t("p15Text")}
                      </div>

                      <div className="grid">
                        <Divider />
                        {/*energyEfficiencySurveyReportHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("energyEfficiencySurveyReport")}
                          </label>
                        </div>
                        {/*energyEfficiencySurveyReport*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyEfficiencySurveyReport"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.energyEfficiencySurveyReport
                              }
                              options={energyEfficiencySurveyReport}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "energyEfficiencySurveyReport"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "energyEfficiencySurveyReport"
                                )
                              }
                            />
                          </div>
                        </div>
                        {/*energyEfficiencyWholeHouseHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("energyEfficiencyWholeHouse")}
                          </label>
                        </div>
                        {/*energyEfficiencyWholeHouse*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyEfficiencyWholeHouse"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.energyEfficiencyWholeHouse
                              }
                              options={energyEfficiencyWholeHouse}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "energyEfficiencyWholeHouse"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "energyEfficiencyWholeHouse"
                                )
                              }
                            />
                          </div>
                        </div>
                        {/*energyBillsHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("energyBills")}
                          </label>
                        </div>
                        {/*energyBills*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyBills"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.energyBills}
                              options={energyBills}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "energyBills");
                                this.uploadDocumentCheck(e);
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "energyBills")
                              }
                            />
                          </div>
                        </div>

                        {/*energyPerformanceCertificateHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("energyPerformanceCertificate")}
                          </label>
                        </div>
                        {/*energyPerformanceCertificate*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.energyPerformanceCertificate
                              }
                              options={energyPerformanceCertificate}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(
                                  e,
                                  "energyPerformanceCertificate"
                                );
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "energyPerformanceCertificate"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*energyPerformanceClass Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("energyPerformanceClass")}
                          </label>
                        </div>

                        {/*energyPerformanceClass*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceClass"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.energyPerformanceClassP1
                              }
                              options={EnergyPerformanceClass}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(
                                  e,
                                  "energyPerformanceClassP1"
                                );
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "energyPerformanceClassP1"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*Energy efficiency recommendations*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <h4>{t("energyEfficiencyRecommendations")}</h4>
                        </div>

                        {/*Wall InsulationÂ Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("wallInsulation")}
                          </label>
                        </div>

                        {/*Wall Insulation*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="wallInsulation"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.wallInsulationP1}
                              options={WallInsulation}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "wallInsulationP1");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "wallInsulationP1")
                              }
                            />
                          </div>
                        </div>

                        {/*Floor InsulationÂ Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("floorInsulation")}
                          </label>
                        </div>

                        {/*Floor InsulationÂ */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.floorInsulationP1}
                              options={FloorInsulation}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "floorInsulationP1");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "floorInsulationP1")
                              }
                            />
                          </div>
                        </div>

                        {/*Roof Insulation Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("roofInsulation")}
                          </label>
                        </div>

                        {/*Roof Insulation*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.roofInsulationP1}
                              options={RoofInsulation}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "roofInsulationP1");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "roofInsulationP1")
                              }
                            />
                          </div>
                        </div>

                        {/*Windows UpgradeÂ Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("windowsUpgrade")}
                          </label>
                        </div>

                        {/*Windows UpgradeÂ */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.windowsUpgradeP1}
                              options={YesNo}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "windowsUpgradeP1");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "windowsUpgradeP1")
                              }
                            />
                          </div>
                        </div>

                        {/*External Door ReplacementÂ Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("externalDoorReplacement")}
                          </label>
                        </div>

                        {/*External Door Replacement*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.externalDoorReplacementP1
                              }
                              options={ExternalDoorReplacement}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(
                                  e,
                                  "externalDoorReplacementP1"
                                );
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "externalDoorReplacementP1"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*Heating System UpgradeÂ Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("heatingSystemUpgrade")}
                          </label>
                        </div>

                        {/*Heating System UpgradeÂ */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.heatingSystemUpgradeP1}
                              options={HeatingSystemUpgrade}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "heatingSystemUpgradeP1");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "heatingSystemUpgradeP1")
                              }
                            />
                          </div>
                        </div>

                        {/*SolarÂ Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("solar")}
                          </label>
                        </div>

                        {/*Solar */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.solarP1}
                              options={Solar}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "solarP1");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "solarP1")
                              }
                            />
                          </div>
                        </div>

                        {/* VentilationÂ Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("ventilation")}
                          </label>
                        </div>

                        {/*VentilationÂ */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceClass"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.ventilationP1}
                              options={Ventilation}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "ventilationP1");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "ventilationP1")
                              }
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*Existing Energy Consumption Label*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <h4>{t("existingEnergyConsumptionPre")}</h4>
                        </div>

                        {/* Annual Gas Consumption (kWh)Â Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("annualGasConsumption")}
                          </label>
                        </div>

                        {/*Â Annual Gas Consumption (kWh)*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <InputText
                              id="p14Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.annualGasConsumptionP1}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "annualGasConsumptionP1")
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*  */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {""}
                          </label>
                        </div>

                        {/*Â Annual Gas Consumption Time Period*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              id="annualGasConsumptionTimePeriod"
                              placeholder={t("pleaseEnter")}
                              value={this?.state?.annualGasConsumptionDate}
                              onChange={(e) => {
                                this.onInputChangeCalendar1(
                                  e,
                                  "annualGasConsumptionTimePeriodP1"
                                );
                              }}
                              readOnlyInput={true}
                              disabled={this.state.nonEditAccess}
                            />
                          </div>
                        </div>

                        {/* Annual Electricity Consumption (kWh)Â Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("annualElectricityConsumption")}
                          </label>
                        </div>

                        {/*Â Annual Electricity Consumption (kWh)*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <InputText
                              id="p14Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={
                                this.state.project
                                  .annualElectricityConsumptionP1
                              }
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "annualElectricityConsumptionP1"
                                )
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*  */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {""}
                          </label>
                        </div>

                        {/*Â Annual Electricity Consumption Time Period*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              id="annualElectricityConsumptionTimePeriod"
                              placeholder={t("pleaseEnter")}
                              value={
                                this?.state?.annualElectricityConsumptionDate
                              }
                              annualElectricityConsumptionTimePeriodP1
                              onChange={(e) => {
                                this.onInputChangeCalendar2(
                                  e,
                                  "annualElectricityConsumptionTimePeriodP1"
                                );
                              }}
                              readOnlyInput={true}
                              disabled={this.state.nonEditAccess}
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocument*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p14Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable3}
                              paginator
                              rows={10}
                              filters={this.state.filters3}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>

                          {this.state.imageListP14.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleriaP14}
                                  value={this.state.imageListP14}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageListP14 &&
                                    this.state.imageListP14.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleriaP14.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocumentLink*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocumentLink")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocumentLink}
                            />
                          )}
                        </div>

                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              value={this.state.documentLinkData}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentLinkFound")}
                              header={headerTable4}
                              paginator
                              rows={10}
                              filters={this.state.filters4}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="documentName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                body={this.dateTableTemplate}
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                body={this.documentURLTemplate}
                                header={t("documentURL")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate2}
                              />
                            </DataTable>
                          </div>
                        </div>

                        {/*p14Notes*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="p14Notes"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("notes")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="p14Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.p14Notes}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "p14Notes")
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase1_Homeowner_commitment*/}
                    <TabPanel header={t("homeownerCommitment")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p16Text")}</div>
                      </div>

                      <div className="grid">
                        {/*checkWithUnderwriterHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("checkWithUnderwriter")}
                          </label>
                        </div>

                        {/*checkWithUnderwriter*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="checkWithUnderwriter"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.checkWithUnderwriter}
                              options={checkWithUnderwriter}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "checkWithUnderwriter")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "checkWithUnderwriter")
                              }
                            />
                          </div>
                        </div>

                        {/*responseFromtheUnderwriterHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("responseFromtheUnderwriter")}
                          </label>
                        </div>

                        {/*responseFromtheUnderwriter*/}
                        <div className="col-12 md:col- lg:col-6">
                          <div>
                            <Dropdown
                              id="responseFromtheUnderwriter"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.responseFromtheUnderwriter
                              }
                              options={checkWithUnderwriter}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "responseFromtheUnderwriter"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "responseFromtheUnderwriter"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*referToAdvisorHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("referToAdvisor")}
                          </label>
                        </div>

                        {/*referToAdvisor*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="referToAdvisor"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.referToAdvisor}
                              options={referToAdvisor}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "referToAdvisor")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "referToAdvisor")
                              }
                            />
                          </div>
                        </div>

                        {/*signatureOnExpressionOfInterest*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("signatureOnExpressionOfInterest")}
                          </label>
                        </div>

                        {/*signatureOnExpressionOfInterest*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="signatureOnExpressionOfInterest"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project
                                  .signatureOnExpressionOfInterest
                              }
                              options={referToAdvisor}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "signatureOnExpressionOfInterest"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "signatureOnExpressionOfInterest"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocument*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>

                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p15Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable5}
                              paginator
                              rows={10}
                              filters={this.state.filters5}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>
                          {this.state.imageListP15.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleriaP15}
                                  value={this.state.imageListP15}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageListP15 &&
                                    this.state.imageListP15.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleriaP15.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {/*p15Notes*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label
                              htmlFor="p15Notes"
                              className="platformColor"
                              style={{ fontSize: "14px" }}
                            >
                              {t("notes")}
                            </label>
                          </div>
                          <div>
                            <InputText
                              id="p15Notes"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              fontSize="16px"
                              value={this.state.project.p15Notes}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "p15Notes")
                              }
                              maxLength={1000}
                              className="w-full"
                            />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <br />

                <div
                  class="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div class="btn-group" role="group" aria-label="First group">
                    <Button
                      style={{ fontSize: "14px", fontStyle: "Manrope" }}
                      icon="pi pi-chevron-circle-left"
                      disabled={this.state.activeIndex <= 0}
                      label={t("previous")}
                      className="p-button-sm p-button-mb"
                      onClick={() => this.stepPrev()}
                    />
                    &nbsp;
                    <Button
                      icon="pi pi-chevron-circle-right"
                      disabled={
                        this.state.activeIndex >= this.stepItems.length - 1
                      }
                      style={{ fontSize: "14px", fontStyle: "Manrope" }}
                      label={t("next")}
                      className="p-button-sm p-button-mb"
                      onClick={() => {
                        this.stepNext();
                      }}
                    />
                    &nbsp;
                    {!isViewerExpert() && (
                      <Button
                        disabled={!this.state.saveButtonStatus}
                        style={{ fontSize: "14px", fontStyle: "Manrope" }}
                        icon="pi pi-check-circle"
                        label={
                          this.state.activeIndex < this.stepItems.length - 1
                            ? t("saveNext")
                            : t("save")
                        }
                        className="p-button-sm p-button-danger"
                        onClick={() => this.stepSaveAndNext()}
                      />
                    )}
                    &nbsp;
                    {this.state.activeIndex === 5 &&
                      (this.state.project.projectStatus === "0" ||
                        this.state.project.projectStatus === "3" ||
                        this.state.project.projectStatus === null) && (
                        <Button
                          style={{
                            fontSize: "14px",
                            fontStyle: "Manrope",
                            position: "right",
                            color: "004E7A",
                          }}
                          iconPos="right"
                          icon="pi pi-send"
                          label={t("sendForApproval")}
                          className="p-button-sm"
                          disabled={
                            this.state.project.status == 4 ||
                            this.state.project.status == 3
                          }
                          onClick={() =>
                            this.setState({
                              sendApprovalConfirmDialogVisible: true,
                            })
                          }
                        ></Button>
                      )}
                  </div>
                  {this.state.activeIndex === this.stepItems.length - 1 && (
                    <div class="btn-group">
                      <div class="input-group-prepend">
                        <Button
                          style={{
                            fontSize: "14px",
                            fontStyle: "Manrope",
                          }}
                          iconPos="right"
                          icon="pi pi-angle-double-right"
                          label={t("nextPhase")}
                          className="p-button-sm p-button-mb"
                          onClick={() => this.nextPhase()}
                        ></Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <br />

              {/* addDocument dialogu */}
              <Dialog
                visible={this.state.addDocumentDialogVisible}
                style={{ width: "650px" }}
                header={t("addDocument")}
                modal
                className="p-fluid"
                footer={addDocumentFooter}
                onHide={this.hideDocumentDialog}
              >
                <DocumentUpload
                  loading={this.state.loading}
                  setLoading={this.setLoading}
                  documents={this.state.documents}
                  setDocuments={this.setDocuments}
                  selectedDocument={this.state.selectedDocument}
                  setSelectedDocument={this.setSelectedDocument}
                  setSaveButtonVisible={this.setSaveButtonVisible}
                  uploadType={"D"}
                  isMulti={true}
                  isRequired={this.state.isRequiredDocumentCompliance}
                  phase={"P1" + this.state.activeIndex}
                  pid={this.state.pid}
                />
              </Dialog>

              <ConfirmDialog
                visible={this.state.uploadDocumentCheckConfirmDialog}
                onHide={() => {
                  this.setState({ uploadDocumentCheckConfirmDialog: false });
                }}
                message={t("uploadDocumentCheckText")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-orange"
                accept={this.acceptUploadDocumentCheck}
                reject={this.acceptUploadDocumentCheck}
                acceptLabel={t("okey")}
                rejectLabel={t("cancel")}
              />

              {/* addDocumentLink dialogu */}
              <Dialog
                visible={this.state.addDocumentLinkDialogVisible}
                style={{ width: "450px" }}
                header={t("document")}
                modal
                className="p-fluid"
                footer={addDocumentLinkDialogFooter}
                onHide={this.hideAddDocumentLinkDialog}
              >
                <div className="p-float-label">
                  <InputText
                    id="documentName1"
                    value={this.state.newDocumentLink.documentName}
                    onChange={(e) =>
                      this.onInputChangeDocLink(e, "documentName")
                    }
                    maxLength={50}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="documentName1" className="platformColor">
                    {t("documentName")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="documentURL1"
                    value={this.state.newDocumentLink.documentURL}
                    onChange={(e) =>
                      this.onInputChangeDocLink(e, "documentURL")
                    }
                    maxLength={1000}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="documentURL1" className="platformColor">
                    {t("documentURL")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputTextarea
                    id="ad2"
                    value={this.state.newDocumentLink.fileDescription}
                    onChange={(e) =>
                      this.onInputChangeDocLink(e, "fileDescription")
                    }
                    rows={3}
                    cols={20}
                    maxLength={500}
                    className="w-full"
                  />
                  <label htmlFor="ad2">{t("fileDescription")} (*)</label>
                </div>
              </Dialog>

              <Dialog
                visible={this.state.pdfVisible}
                style={{ width: "%100", height: "%100" }}
                modal
                className="p-fluid"
                onHide={() => this.setState({ pdfVisible: false })}
              >
                {this.state.documentUrl.endsWith(".png") ||
                this.state.documentUrl.endsWith(".jpeg") ||
                this.state.documentUrl.endsWith(".jpg") ? (
                  <Galleria
                    value={this.state.documentUrl}
                    numVisible={5}
                    circular
                    style={{ maxWidth: "640px", borderRadius: "6px" }}
                    showThumbnails={false}
                    item={this.itemTemplatePdf}
                  />
                ) : this.state.documentUrl.endsWith(".pdf") ? (
                  <PdfViewer pdfUrl={this.state.documentUrl} />
                ) : (
                  <div>No valid document format found</div>
                )}
              </Dialog>

              {/* send approval confirm dialogu */}
              <ConfirmDialog
                style={{ fontSize: "16px", fontStyle: "Manrope" }}
                visible={this.state.sendApprovalConfirmDialogVisible}
                onHide={() => {
                  this.setState({ sendApprovalConfirmDialogVisible: false });
                }}
                message={
                  <div>
                    <p>{t("sendApprovalMessage")}</p>
                    <p>
                      <b>{t("confirmMessage")}</b>
                    </p>
                  </div>
                }
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-red"
                acceptLabel={t("Yes")}
                rejectLabel={t("cancel")}
                accept={this.sendApprovalMail}
              />

              <ConfirmDialog
                visible={this.state.deleteDocumentConfirmDialogVisible}
                onHide={() => {
                  this.setState({ deleteDocumentConfirmDialogVisible: false });
                }}
                message={t("confirmMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle"
                accept={this.acceptDeleteDocument}
                reject={this.rejectDeleteDocument}
              />

              {/* zorunlu alan kontrol onay dialogu */}
              <ConfirmDialog
                visible={this.state.mandatoryFieldConfirmDailogVisible}
                onHide={() => {
                  this.setState({
                    mandatoryFieldConfirmDailogVisible: false,
                  });
                }}
                message={t("confirmMessageMandatoryField")}
                header={t("reminder")}
                icon="pi pi-info-circle"
                accept={this.acceptMandatoryField}
                reject={this.rejectMandatoryField}
                acceptLabel="OK"
                rejectLabel="CANCEL"
              />

              {/* send approval confirm dialogu */}
              <ConfirmDialog
                style={{ fontSize: "16px", fontStyle: "Manrope" }}
                visible={this.state.sendApprovalConfirmDialogVisible}
                onHide={() => {
                  this.setState({ sendApprovalConfirmDialogVisible: false });
                }}
                message={
                  <div>
                    <p>{t("sendApprovalMessage")}</p>
                    <p>
                      <b>{t("confirmMessage")}</b>
                    </p>
                  </div>
                }
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-red"
                acceptLabel={t("Yes")}
                rejectLabel={t("cancel")}
                accept={this.sendApprovalMail}
              />

              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />

              <WaitDialog loading={this.state.loading} lang={t} />
              <WaitDialogDocument
                loading={this.state.documentLoading}
                lang={t}
              />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(PI));
