import React, { Component } from "react";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Galleria } from "primereact/galleria";
import { Calendar } from "primereact/calendar";
import { BlockUI } from "primereact/blockui";
import { FilterMatchMode } from "primereact/api";
import { Divider } from "primereact/divider";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from "primereact/image";
import {
  isPlatformManager,
  isSupervisor,
  isTracidTokenValid,
} from "../common/token-check";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Badge } from "primereact/badge";
import { Checkbox } from "primereact/checkbox";

import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { isUser, isViewerExpert } from "../common/token-check";
import { handleDownloadDocument } from "../common/functions";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SysService from "../services/sys.service";
import TracidService from "../services/tracid.service.js";

import lrsAndQSVisit from "../common/ccs_lrsAndQSVisit";
import ContractorCompletesOutstandingWorks from "../common/ccs_contractorCompletesOutstandingWorks";
import lrsAndQSFinalVisit from "../common/ccs_lrsAndQSFinalVisit";
import FinalAccountAgreedWithContractor from "../common/ccs_finalAccountAgreedWithContractor";
import PracticalCompletion from "../common/ccs_practicalCompletion";
import DocumentUpload from "../common/documentUpload.jsx";

import types from "../common/ccs_types.js";
import sections from "../common/ccs_sections.js";
import documentSections from "../common/ccs_documentSections.js";
import indoorSections from "../common/ccs_indoorSections.js";

import PdfViewer from "../common/pdfViewer.jsx"; 
class practicalCompletion extends Component {
  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: "",
  };

  emptyNewDocument2 = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
  };

  emptyEQ = {
    id: "",
    eqRoomName: "",
    eqDeviceName: "",
    eqDeviceType: "",
    eqBrandNameModel: "",
    eqDeviceEUI: "",
    eqDatasheetURL: "",
    section: "",
  };

  emptyNewToken = {
    apiKey: "",
    apiSecret: "",
  };

  emptyNewpassportData = {
    projectId: "",
    projectName: "",
    logbookObject: {},
  };

  emptyNewpassportData2 = {
    passportId: "",
    projectId: "",
    projectName: "",
    logbookObject: {},
  };

  emptylogbookObject = {
    logbookBasicInfo: {
      homeowner: "",
      city: "",
      postcode: "",
      streetName: "",
      houseNumber: "",
      propertyType: "",
      constructionYear: "",
      energyPerformanceClass: "",
      annualGasConsumption: "",
      annualElectricityConsumption: "",
      images: [],
    },

    logbookInformationPanels: [
      {
        panelName: "Contracts & Legal Documents",
        panelType: "document",
        panelIcon: "docIcon",
        documents: [],
        panelPrivacy: "",
      },
      {
        panelName: "Technical & Financial Documents",
        panelType: "document",
        panelIcon: "docIcon",
        documents: [],
        panelPrivacy: "",
      },
      {
        panelName: "Guarantees & Warranties",
        panelType: "document",
        panelIcon: "docIcon",
        documents: [],
        panelPrivacy: "",
      },
      {
        panelName: "Renovations & Repairs",
        panelType: "renovation",
        panelIcon: "renovationIcon",
        renovationsRepairs: [],
        panelPrivacy: "",
      },
      {
        panelName: "Indoor Air Quality Data",
        panelType: "indoorAirQuality",
        panelIcon: "airIcon",
        euis: [],
        panelPrivacy: "",
      },
    ],

    sha256Hash: "",
    passportCode: "",
  };

  emptyNewEvent = {
    eventId: "",
    type: "",
    scope: "",
    description: "",
    eventDate: "",
    documents: [],
  };

  emptyNewEQ = {
    section: "",
    documents: [],
  };

  emptyNewDocumentLogbook = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    //documentDate: "",
    section: "",
    description: "",
    documentGivenName: "",
  };

  emptyNewProjectDocumentTemp = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    //documentDate: "",
    description: "",
    section: "",
  };

  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef(null);

    this.state = {
      project: "",

      pid: "",
      visibleCustomSidebar: false,
      visibleRight: false,

      nonEditAccess: true,
      activeIndex: 0,

      p70Documents: [],
      p70DocumentIndex: -1,
      p70SelectedDocument: null,

      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      addDocumentConfirmDialogVisible: false,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      s3Response: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      saveButtonStatus: false,
      mortgageStatusVisible: true,

      selectedRow: null,
      deleteDocumentConfirmDialogVisible: false,

      dateofPracticalCompletion: "",
      periodofDefectsEndDate: "",
      periodofDefectsStartDate: "",
      dateVisit: "",

      documents: [],
      selectedDocument: null,

      galleria1: React.createRef(null),
      imageList1: [],
      galleriaIndex: 0,

      inputSwitchValue: false,
      inputSwitchValue2: false,
      inputSwitchValue3: false,
      inputSwitchValue4: false,
      inputSwitchValue5: false,
      inputSwitchValue6: false,

      documentDialogVisible: false,
      projectDocumentDialogVisible: false,
      equipmentDialogVisible: false,

      P71FileName: "",
      P71EQFileName: "",
      P9EquipmentList: [],
      equipments: [],
      projectEQList: [],

      selectFromRegisteredEquipmentDialog: false,
      selectEQDialog: false,
      selectedEQData: null,

      addNewVersionDialog: false,

      addNewEventDialog: false,

      selectedImage: null,
      selectedImages: [],
      images: [],

      uploadGalleriaIndex: 0,
      uploadGalleria: null,
      uploadedImageList: [],

      publishDBLDialog: false,
      deleteDBLDialog: false,

      publishData: false,

      tracidToken: null,

      newToken: this.emptyNewToken,

      passportLogbooks: "",
      equipmentsList: [],

      newEvent: this.emptyNewEvent,
      eventsList: [],
      saveEventButtonStatus: false,

      passportId: "",

      logbookObject: this.emptylogbookObject,

      passportData: this.emptyNewpassportData,

      newEQ: this.emptyEQ,
      eqList: [],
      eqFileName: "",

      logbookDocList: [],
      selectedLogbookDoc: "",
      selectedRowData: null,
      editDocumentDialogVisible: false,

      p71Documents: [],
      p71DocumentIndex: -1,
      p71SelectedDocument: null,
      //hiddenFileInput: React.createRef(null),
      inputFile: "",

      newLogbookDoc: this.emptyNewDocumentLogbook,
      saveVersionButton: false,

      selectedLogbookData: null,

      projectDocList: "",
      selectFromProjectDocumentDialog: false,
      selectprojectDocDialog: false,
      selectedProjectDocRowData: null,
      selectedProjectDocList: [],
      selectedProjectDocName: "",
      newProjectDoc: this.emptyNewProjectDocumentTemp,

      sec1Documents: [],
      sec2Documents: [],
      sec3Documents: [],
      sec4Documents: [],

      projectDocListP1: [],
      projectDocListP2: [],
      projectDocListP3: [],
      projectDocListP4: [],
      projectDocListP5: [],
      projectDocListP6: [],
      projectDocListP7: [],
      projectDocListP8: [],
      projectDocListP9: [],

      docChecked: false,
      addButtonStatus: false,

      eventDocuments: [],
      selectedEventDocument: null,
      addEventDocumentDialogVisible: false,
      saveEventDocButtonStatus: false,

      eventDocList: [],
      eqAddButtonStatus: false,
      selectedEvent: null,

      selectedEQRowData: null,
      updateEQDialog: false,

      imageRatio: "",
      imgHeight: "",
      imageString: "",
      newEQList2: [],
      serviceWorked: true,
      isPublish: "false",
      passportDataRowData: null,

      historyData: [],
      historyDialog: false,
      selectedROwPublishData: null,
      verisonId: 0,
      passportLogbooksLength: 0,

      updateVersionDialog: false,
      newPassportData: "",

      selectedDocRowData: "",

      selectedPassportData: null,

      deletedDocuments: "",

      allProjectDocList: "",

      checkedContractsAndLegal: false,
      checkedTechnicalAndFinancial: false,
      checkedGuaranteesAndWarranties: false,
      checkedEQ: false,
      checkedRenovationRepairs: false,

      badge1: 0,
      badge2: 0,
      badge3: 0,
      badge4: 0,
      badge5: 0,
      badge6: 0,
      badge7: 0,
      badge8: 0,

      pdfVisible: false,
      documentUrl: "", 
      //loadingImages: false,

      //selectedLogBookRowData:null
    };

    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 5,
      },
      {
        breakpoint: "768px",
        numVisible: 3,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
      },
    ];
  }

  /*JS_methods*/

  onEventChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let event = { ...this.state.newEvent };
    event[`${name}`] = val;

    this.setState({
      newEvent: event,
      saveEventButtonStatus: true,
    });
  };

  onEventUpdateChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let selectedEvent = { ...this.state.selectedEvent };
    selectedEvent[`${name}`] = val;

    this.setState({
      selectedEvent,
      saveEventButtonStatus: true,
    });
  };

  galleriaItemTemplate = (item) => {
    return (
      <div>
        <img src={item.url} alt={item.file.name} />
        <div className="image-title">{item.file.name}</div>
      </div>
    );
  };

  logBookHistoryLogTimeTemplate(data) {
    return format(new Date(data.logTime), "dd/MM/yyyy");
  }

  itemTemplate = (item) => {
    return (
      <div className="custom-galleria-item">
        <img
          src={item?.itemImageSrc}
          alt={item?.alt}
          style={{ width: "100%", display: "block" }}
        />
        <div className="custom-content">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    );
  };

  itemTemplatePdf = () => {
    return (
      <div className="custom-galleria-item">
        <img src={this.state.documentUrl} style={{ width: "100%", display: "block" }} />
      </div>
    );
  }; 

  itemTemplate2 = (item) => {
    return (
      <img
        src={item.documentURL}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={item.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  thumbnailTemplate2 = (item) => {
    return (
      <img
        src={item.url}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={item.alt}
        style={{ display: "block" }}
      />
    );
  };

  thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: "block" }}
      />
    );
  };

  //   onItemChange = (event) => {
  //     this.setState({ uploadGalleriaIndex: event.index });
  // }

  calendarFormatTemplate = (data) => {
    if (data === null) {
      return "null";
    } else if (data !== undefined) {
      return format(new Date(data), "dd/MM/yyyy");
    } else {
      return "";
    }
  };

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeEQ = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newEQ = { ...this.state.newEQ };

    newEQ[`${name}`] = val;

    this.setState({
      newEQ,
      saveVersionButton: true,
      //eqAddButtonStatus: true,
    });
  };

  onInputChangeEQUpdate = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let selectedEQRowData = { ...this.state.selectedEQRowData };

    selectedEQRowData[`${name}`] = val;

    this.setState({
      selectedEQRowData,
      saveVersionButton: true,
      eqAddButtonStatus: true,
    });
  };

  onInputChangeLogbookDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newLogbookDoc = { ...this.state.newLogbookDoc };
    newLogbookDoc[`${name}`] = val;

    this.setState({
      newLogbookDoc,
      saveVersionButton: true,
    });
  };

  onInputChangeSelectedProjectSection = (e, fileName, phase) => {
    const section = (e.target && e.target.value) || "";

    const newData = this.state[`projectDocList${phase}`].map((doc) => {
      if (doc.fileName === fileName) {
        return { ...doc, section: section };
      }
      return doc;
    });

    this.setState({
      [`projectDocList${phase}`]: newData,
      addButtonStatus: true,
    });
  };

  onInputChangeProjectDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newProjectDoc = { ...this.state.newProjectDoc };
    newProjectDoc[`${name}`] = val;

    this.setState({
      newProjectDoc,
      saveVersionButton: true,
    });
  };

  onInputChangeDateVisit = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      dateVisit: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocument = { ...this.state.newDocument };
    newDocument[`${name}`] = val;

    this.setState({ newDocument });
  };

  onInputChangeDateofPracticalCompletionCalendar = (e) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${"dateofPracticalCompletion"}`] = val;

    this.setState({
      project,
      dateofPracticalCompletion: val,
      saveButtonStatus: true,
    });
  };

  onInputChangeEventCalendar = (e) => {
    const val = (e.target && e.target.value) || "";

    let newEvent = { ...this.state.newEvent };
    newEvent[`${"eventDate"}`] = val;

    this.setState({
      newEvent,
      saveEventButtonStatus: true,
    });
  };

  onInputChangeEventUpdateCalendar = (e) => {
    const val = (e.target && e.target.value) || "";

    let selectedEvent = { ...this.state.selectedEvent };
    selectedEvent[`${"eventDate"}`] = new Date(val);

    this.setState({
      selectedEvent,
      //dateofPracticalCompletion: val,
      saveEventButtonStatus: true,
    });
  };

  eventCalendarFormat = (data) => {
    if (data === null) {
      return null;
    } else if (data !== undefined) {
      const formatDate = parseISO(data, 2);
      return formatDate;
    } else {
      return "";
    }
  };

  calendarFormatTemplateRow = (rowData) => {
    if (rowData === null) {
      return "null";
    } else if (rowData !== undefined) {
      return format(new Date(rowData), "dd/MM/yyyy");
    } else {
      return "";
    }
  };

  onInputChangePeriodofDefectsStartDateCalendar = (e) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${"periodofDefectsStartDate"}`] = val;

    let newEndDate = new Date(val)
    newEndDate.setMonth(newEndDate.getMonth() + 6);
    project["periodofDefectsEndDate"] = newEndDate;

    this.setState({
      project,
      periodofDefectsStartDate: val,
      periodofDefectsEndDate: newEndDate,
      saveButtonStatus: true,
    });
  };

  onInputChangePeriodofDefectsEndDateCalendar = (e) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${"periodofDefectsEndDate"}`] = val;

    this.setState({
      project,
      periodofDefectsEndDate: val,
      saveButtonStatus: true,
    });
  };

  handleDelete = (data) => {
    let id = data.id;
    let equipmentsList = [...this.state.equipmentsList];
    const newData = equipmentsList.filter((item) => item.id !== id);

    for (let i = 0; i < newData.length; i++) {
      newData[i].id = i + 1;
    }

    this.setState({
      equipmentsList: newData,
    });

    const equipments = [...this.state.equipments];
    equipments.push(data);

    this.setState({
      equipments: equipments,
      saveVersionButton: true,
    });
  };

  handleDeleteLogbookDocument = (rowData) => {
    if (this.state.activeIndex === 1) {
      const index = this.state.p71Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p71Documents.splice(index, 1);
      }
    }

    let allDocs = [...this.state.allProjectDocList];

    let localIndex = 0;
    let phase = "";

    const docObj = {
      documentName: "",
      documentURL: "",
      fileDescription: "",
      fileName: "",
      phase: "",
      checked: false,
      section: "",
    };

    for (let i = 0; i < allDocs.length; i++) {
      if (
        //rowData.documentDate === allDocs[i].documentDate&&
        rowData.documentName === allDocs[i].documentName &&
        rowData.documentURL === allDocs[i].documentURL &&
        (rowData.fileDescription === allDocs[i].fileDescription ||
          rowData.description === allDocs[i].fileDescription) &&
        rowData.fileName === allDocs[i].fileName
      ) {
        localIndex = i;
        phase = allDocs[i].phase;
        break;
      }
    }

    if (phase.startsWith("P1")) {
      docObj.documentName = rowData.documentName;
      docObj.documentURL = rowData.documentURL;
      docObj.fileDescription = rowData.description;
      docObj.fileName = rowData.fileName;
      docObj.phase = "P1";

      this.state.projectDocListP1.push(docObj);
    } else if (phase.startsWith("P2")) {
      docObj.documentName = rowData.documentName;
      docObj.documentURL = rowData.documentURL;
      docObj.fileDescription = rowData.description;
      docObj.fileName = rowData.fileName;
      docObj.phase = "P2";

      this.state.projectDocListP2.push(rowData);
    } else if (phase.startsWith("P3")) {
      docObj.documentName = rowData.documentName;
      docObj.documentURL = rowData.documentURL;
      docObj.fileDescription = rowData.description;
      docObj.fileName = rowData.fileName;
      docObj.phase = "P3";

      this.state.projectDocListP3.push(rowData);
    } else if (phase.startsWith("P4")) {
      docObj.documentName = rowData.documentName;
      docObj.documentURL = rowData.documentURL;
      docObj.fileDescription = rowData.description;
      docObj.fileName = rowData.fileName;
      docObj.phase = "P4";

      this.state.projectDocListP4.push(rowData);
    } else if (phase.startsWith("P5")) {
      docObj.documentName = rowData.documentName;
      docObj.documentURL = rowData.documentURL;
      docObj.fileDescription = rowData.description;
      docObj.fileName = rowData.fileName;
      docObj.phase = "P5";

      this.state.projectDocListP5.push(rowData);
    } else if (phase.startsWith("P6")) {
      docObj.documentName = rowData.documentName;
      docObj.documentURL = rowData.documentURL;
      docObj.fileDescription = rowData.description;
      docObj.fileName = rowData.fileName;
      docObj.phase = "P6";

      this.state.projectDocListP6.push(rowData);
    } else if (phase.startsWith("P7")) {
      docObj.documentName = rowData.documentName;
      docObj.documentURL = rowData.documentURL;
      docObj.fileDescription = rowData.description;
      docObj.fileName = rowData.fileName;
      docObj.phase = "P7";

      this.state.projectDocListP7.push(rowData);
    } else if (phase.startsWith("P8")) {
      docObj.documentName = rowData.documentName;
      docObj.documentURL = rowData.documentURL;
      docObj.fileDescription = rowData.description;
      docObj.fileName = rowData.fileName;
      docObj.phase = "P8";

      this.state.projectDocListP8.push(rowData);
    }

    this.setState({
      saveVersionButton: true,
    });
  };

  handleDeleteEventDocument = (data) => {
    let id = data.id;
    let eventsList = [...this.state.newEvent.documents];
    const newData = eventsList.filter((item) => item.eventId !== id);
    for (let i = 0; i < newData.length; i++) {
      newData[i].eventId = i + 1;
    }

    let newEvent = { ...this.emptyNewEvent };
    newEvent.documents = newData;
    newEvent.type = this.state.newEvent.type;
    newEvent.scope = this.state.newEvent.scope;
    newEvent.description = this.state.newEvent.description;
    newEvent.eventDate = this.state.newEvent.eventDate;
    newEvent.eventId = this.state.newEvent.eventId;

    this.setState({
      newEvent: newEvent,
    });
  };

  handleDeleteEvent = (rowData) => {
    let eventsListLocal = [...this.state.eventsList];

    const index = eventsListLocal.indexOf(rowData);
    if (index > -1) {
      eventsListLocal.splice(index, 1);
    }

    this.setState({
      eventsList: eventsListLocal,
      saveVersionButton: true,
    });
  };

  handleEQRowData = (data) => {
    this.setState({
      submitted: false,
      updateEQDialog: true,
      selectedEQRowData: data,
    });
  };

  handleClick1 = (event) => {
    this.hiddenFileInput.current.click();
    //this.hiddenFileInput.current.choose();
  };

  handleClickSelectEQ = (event) => {
    this.setState({
      //selectEQDialog: true,
    });
  };

  handleClickSelectProjectDoc = (event) => {
    this.setState({
      selectprojectDocDialog: true,
    });
  };

  hideSelectEQ = () => {
    this.setState({
      submitted: false,
      //selectEQDialog: false,
      selectedEQData: null,
      newEQ: this.emptyEQ,
    });
  };

  handleChange1 = (event) => {
    const fileUploaded = event.target.files[0];

    if (fileUploaded) {
      this.setState({
        P71FileName: fileUploaded.name,
        //inputFile: filesX,
      });
    }
  };

  handleChange2 = (event) => {
    //let doc = {...this.emptyNewDocument}
    let newEQ = { ...this.state.newEQ };
    newEQ.eqDatasheetURL = event.target.files[0];

    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      this.setState({
        eqFileName: fileUploaded.name,
        newEQ,
      });

      //this.props.handleFile(fileUploaded);
    }
  };

  handleChange3 = (event) => {
    //let doc = {...this.emptyNewDocument}
    // let newDoc = {...this.state.newDoc}
    //newDoc.documentURL = event.target.files[0];

    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      this.setState({
        selectedProjectDocName: fileUploaded.name,
        //newDoc,
      });
    }
  };

  createOTPCode = (event) => {
    this.setOTPCode();
  };

  PDFViewer = (rowData) => {
    this.setState({
      pdfVisible: true,
      documentUrl: rowData.documentURL,
    });
  };


  actionTemplate = (data, props) => {
    const { t } = this.props; 
    return (
      <div>
        <Button
          icon="pi pi-eye"
          tooltip={t("view")}
          className="p-button-rounded p-button-text"
          onClick={() => this.PDFViewer(data, props)}
        /> 
        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.setState({
                selectedRow: data,
                deleteDocumentConfirmDialogVisible: true,
              });
            }}
          />
        )}
      </div>
    );
  };

  actionTemplate4 = (data, props) => {
    return (
      <div>
        {!isViewerExpert() && (
          <>
            <Button
              icon="pi pi-download"
              href
              tooltip="Download"
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                handleDownloadDocument(data, props);
              }}
            />
            <Button
              icon="pi pi-trash"
              tooltip="Delete"
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.handleDeleteEventDocument(data, props);
              }}
            />
          </>
        )}
      </div>
    );
  };

  historyTemplate = (data, props) => {
    return (
      <div>
        {!isViewerExpert() && (
          <>
            <Button
              icon="pi pi-clock"
              href
              tooltip="History"
              className="p-button-rounded p-button-text"
              deleteDBLDialog
              onClick={(e) => {
                this.passportLogbookHistoryById(data);
                // this.setState({
                //   passportDataRowData:data
                // })
              }}
            />
          </>
        )}
      </div>
    );
  };

  publishTemplate = (data, props) => {
    if (data.passportCode) {
      return (
        <div>
          <Trans i18nKey={"published"} />
        </div>
      );
    } else {
      return (
        <div>
          <Trans i18nKey={"waiting"} />
        </div>
      );
    }
  };

  bdlVersionTemplate = (rowData, rowIndex) => {
    const verisonIdLocal = rowIndex.rowIndex + 1;
    const verisonIdLocal2 = this.state.passportLogbooks.length - verisonIdLocal;

    if (rowData.passportCode) {
      return (
        <div className={"mr-2 mb-2 p-float-label-blue2"}>
          <a
            href={"/passport/" + rowData.passportCode}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#0094A8",
              textDecoration: "none",
              fontStyle: "Roboto",
            }}
          >
            <Trans i18nKey={"version"} /> {verisonIdLocal2 + 1}
          </a>{" "}
        </div>
      );
    } else {
      return (
        <div>
          <Trans i18nKey={"version"} /> {verisonIdLocal2 + 1}
        </div>
      );
    }
  };

  actionTemplateVersion = (data, props) => {
    let icon = "pi pi-book";
    let passportCodeValid = false;

    if (data.passportCode) {
      passportCodeValid = true;
    }

    return (
      <div>
        {!isViewerExpert() && (
          <>
            {!passportCodeValid && (
              <Button
                icon={icon}
                href
                tooltip="Publish"
                className="p-button-rounded p-button-text"
                onClick={(e) => {
                  // handleDownloadDocument(data, props);

                  this.setState({
                    publishDBLDialog: true,
                    selectedROwPublishData: data,
                  });
                }}
              />
            )}

            <Button
              icon="pi pi-pencil"
              href
              tooltip="Update"
              className="p-button-rounded p-button-text"
              deleteDBLDialog
              onClick={(e) => {
                this.handleUpdateLogBookData(data, props);
                this.setState({
                  //  updateVersionDialog: true,
                  //selectedLogbookData: data,
                });
              }}
            />

            <Button
              icon="pi pi-trash"
              tooltip="Delete"
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.setState({
                  deleteDBLDialog: true,
                  selectedLogbookData: data,
                });
              }}
            />
          </>
        )}
      </div>
    );
  };

  actionTemplateEvent = (data, props) => {
    return (
      <div>
        {!isViewerExpert() && (
          <Button
            icon="pi pi-pencil"
            href
            tooltip="Update"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.handleUpdateEventDialog(data, props);
            }}
          />
        )}

        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.handleDeleteEvent(data, props);
            }}
          />
        )}
      </div>
    );
  };

  actionTemplate2 = (data, props) => {
    return (
      <div>
        {/* {!isViewerExpert() && (
          <Button
            icon="pi pi-pencil"
            href
            tooltip="Update"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.setState({
                selectedDocRowData: data,
              });
              this.handleEditLogbookDocument(data, props);
            }}
          />
        )} */}

        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />

        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.setState({
                selectedDocRowData: data,
              });

              this.handleDeleteLogbookDocument(data, props);
            }}
          />
        )}
      </div>
    );
  };

  actionTemplate3 = (data, props) => {
    return (
      <div>
        {!isViewerExpert() && (
          <>
            {/* <Button
              icon="pi pi-pencil"
              href
              tooltip="Update"
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.handleEQRowData(data, props);
              }}
            /> */}
            <Button
              icon="pi pi-trash"
              tooltip="Delete"
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.handleDelete(data, props);
              }}
            />
          </>
        )}
      </div>
    );
  };

  actionTemplate5 = (data, props) => {
    const { t } = this.props;

    return (
      <div>
        {!isViewerExpert() && (
          <>
            <Button
              //icon="pi pi-pencil"
              //style={{ width: "50%"}}
              label={t("btnSelect")}
              className="p-button-mb"
              onClick={(e) => {
                // this.setState({
                //   selectedEQRowData2:data
                // })
                this.handleSelectedEQRowData(data, props);
              }}
            />
          </>
        )}
      </div>
    );
  };

  actionTemplate6 = (data, props) => {
    const { t } = this.props;

    return (
      <div>
        {!isViewerExpert() && (
          <>
            <Button
              //icon="pi pi-pencil"
              //style={{ width: "50%"}}
              label={t("btnSelect")}
              className="p-button-mb"
              onClick={(e) => {
                this.handleSelectedRowProjectDocData(data, props);
              }}
            />
          </>
        )}
      </div>
    );
  };

  selectedDocumentSectionTemplate = (data, props) => {
    const { t } = this.props;
    let fileName = data.fileName;
    let phase = data.phase;
    return (
      <div>
        {!isViewerExpert() && (
          <>
            <Dropdown
              editable={false}
              id="section"
              style={{ fontSize: "14px" }}
              disabled={this.state.nonEditAccess || !data.checked}
              value={data.section}
              options={documentSections}
              placeholder={t("pleaseSelect")}
              onChange={(e) =>
                this.onInputChangeSelectedProjectSection(e, fileName, phase)
              }
              optionLabel="name"
              optionValue="code"
              className="w-full"
            />
          </>
        )}
      </div>
    );
  };

  eqSectionTemplate = (data, props) => {
    let section = data.section;
    if (section === "1") {
      return <Trans i18nKey={"indoorAirQualityData"} />;
    } else if (section === "2") {
      return <Trans i18nKey={"energyConsumptionData"} />;
    }
  };

  selectedFileNameTemplate = (data) => {
    const { t } = this.props;

    let fileName = data?.fileName;
    let checked = data?.checked;
    let phase = data?.phase;
    let documentURL = data?.documentURL;

    return (
      <div>
        {!isViewerExpert() && (
          <>
            <div className="field-checkbox">
              <Checkbox
                inputId="binary"
                checked={checked}
                onChange={(e) =>
                  this.onCheckedChange(e, fileName, documentURL, phase)
                }
              />{" "}
              <label htmlFor="binary">{fileName}</label>
            </div>
          </>
        )}
      </div>
    );
  };

  handleSelectedEQRowData = (rowData) => {
    let newEQ = { ...this.state.newEQ };

    newEQ[`${"eqBrandNameModel"}`] = rowData[`${"eqBrandNameModel"}`];
    newEQ[`${"eqDatasheetURL"}`] = rowData[`${"eqDatasheetURL"}`];
    newEQ[`${"eqDeviceEUI"}`] = rowData[`${"eqDeviceEUI"}`];
    newEQ[`${"eqDeviceName"}`] = rowData[`${"eqDeviceName"}`];
    newEQ[`${"eqDeviceType"}`] = rowData[`${"eqDeviceType"}`];
    newEQ[`${"id"}`] = rowData[`${"id"}`];
    newEQ[`${"eqRoomName"}`] = rowData[`${"eqRoomName"}`];

    let eqId = this.state.equipmentsList.length + 1;
    newEQ.id = eqId;

    this.state.equipmentsList.push(newEQ);

    this.setState({
      equipments: this.state.newEQList2,
      submitted: false,
      selectFromRegisteredEquipmentDialog: false,
      saveVersionButton: true,
      selectedEQData: null,
      newEQ: this.emptyEQ,
    });

    if (rowData) {
      const newEqList = this.state.equipments.filter(
        (item) => item.id !== rowData.id
      );
      this.setState({
        equipments: newEqList,
      });
    }
  };

  handleSelectedRowProjectDocData = (data) => {
    this.state.selectedProjectDocList.push(data);

    this.setState({
      selectedProjectDocRowData: data,
      selectedProjectDocName: data.documentName,
      submitted: false,
      selectprojectDocDialog: false,
      eqAddButtonStatus: true,
    });
  };

  handleUpdateEQ = (data) => {
    if (
      this.state.selectedEQRowData.section === "" ||
      this.state.selectedEQRowData.section === null
    ) {
      this.setState({
        loading: false,
      });
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    let selectedEvent = { ...this.state.selectedEQRowData };

    let eqList = [...this.state.equipmentsList];

    for (let i = 0; i < eqList.length; i++) {
      if (eqList[i].id === selectedEvent.id) {
        eqList[i].section = selectedEvent.section;
        break;
      }
    }

    this.setState({
      submitted: false,
      updateEQDialog: false,
      saveVersionButton: true,
      selectedEQData: null,
      newEQ: this.emptyEQ,
      eqAddButtonStatus: false,
      selectedEQRowData: null,
      equipmentsList: eqList,
    });
  };

  acceptDeleteDocument = () => {
    this.handleDeleteDocument(this.state.selectedRow);
  };

  filterDocsForSectionVal(array) {
    return array.filter(function (eleman) {
      return ![1, 2, 3, 4].includes(Number(eleman.section));
    });
  }

  logbookStatusTemplate(data) {
    let status = "";

    if (data.logType === 70) {
      return <Trans i18nKey={"addLogbook"} />;
    } else if (data.logType === 71) {
      return <Trans i18nKey={"deleteLogbook"} />;
    } else if (data.logType === 72) {
      return <Trans i18nKey={"updateLogbook"} />;
    } else if (data.logType === 76) {
      status = <Trans i18nKey={"publish"} />;
      return <div className={"mr-2 mb-2 p-float-label-blue"}>{status}</div>;
    }
  }

  rejectDeleteDocument = () => {};

  handleDeleteDocument = (rowData) => {
    if (this.state.activeIndex === 0) {
      const index = this.state.p70Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p70Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 1) {
      const index = this.state.p71Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p71Documents.splice(index, 1);
      }
    }

    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  handleEvents = () => {
    this.setState({
      loading: true,
    });

    if (
      this.state.newEvent.type === "" ||
      this.state.newEvent.type === null ||
      this.state.newEvent.scope === "" ||
      this.state.newEvent.scope === null ||
      this.state.newEvent.description.trim() === "" ||
      this.state.newEvent.description === null ||
      this.state.newEvent.eventDate === undefined ||
      this.state.newEvent.eventDate === null
    ) {
      this.setState({
        loading: false,
      });
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    let localEvent = {
      eventId: "",
      type: "",
      scope: "",
      section: "",
      description: "",
      eventDate: "",
      documents: [],
    };

    const localEventList = [...this.state.eventsList];

    let len = localEventList.length;
    let index = len + 1;

    localEvent.eventId = index;
    localEvent.type = this.state.newEvent.type;
    localEvent.scope = this.state.newEvent.scope;
    localEvent.description = this.state.newEvent.description;
    localEvent.eventDate = this.state.newEvent.eventDate;
    localEvent.documents = this.state.newEvent.documents;

    localEventList.push(localEvent);

    this.setState({
      eventsList: localEventList,
      submitted: false,
      addNewEventDialog: false,
      saveVersionButton: true,
      loading: false,
    });
  };

  handleUpdateEvent = () => {
    this.setState({
      loading: true,
    });

    if (
      this.state.selectedEvent.type === "" ||
      this.state.selectedEvent.type === null ||
      this.state.selectedEvent.scope === "" ||
      this.state.selectedEvent.scope === null ||
      this.state.selectedEvent.description.trim() === "" ||
      this.state.selectedEvent.description === null ||
      this.state.selectedEvent.eventDate === undefined ||
      this.state.selectedEvent.eventDate === null
    ) {
      this.setState({
        loading: false,
      });
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    let eventsList = [...this.state.eventsList];

    let selectedEvent = { ...this.state.selectedEvent };

    for (let i = 0; i < eventsList.length; i++) {
      if (eventsList[i].eventId === selectedEvent.eventId) {
        eventsList[i].type = selectedEvent.type;
        eventsList[i].scope = selectedEvent.scope;
        eventsList[i].description = selectedEvent.description;
        eventsList[i].eventDate = selectedEvent.eventDate;
      }
    }

    this.setState({
      eventsList,
      selectedEvent: null,
      submitted: false,
      updateEventDialog: false,
      saveVersionButton: true,
      loading: false,
    });
  };

  handleDocuments2 = () => {
    this.setState({
      loading: true,
    });

    let newLogbookDoc = [...this.state.newLogbookDoc];

    if (newLogbookDoc) {
      let localArray = [];

      for (let i = 0; i < newLogbookDoc.length; i++) {
        const doc = { ...this.emptyNewDocumentLogbook };

        //doc.documentDate = newLogbookDoc[i].documentDate;
        doc.documentName = newLogbookDoc[i].documentName;
        doc.documentURL = newLogbookDoc[i].documentURL;
        doc.fileDescription = newLogbookDoc[i].fileDescription;
        doc.fileName = newLogbookDoc[i].fileName;
        doc.phase = "P7" + this.state.activeIndex;

        doc.description = newLogbookDoc[i]?.description;
        doc.section = newLogbookDoc[i]?.section;
        doc.documentGivenName = newLogbookDoc[i]?.documentGivenName;

        localArray.push(doc);
      }

      if (this.state.activeIndex === 1) {
        let localP71Documents = [...this.state.p71Documents];
        localP71Documents = localP71Documents.concat(localArray);
        this.setState({
          loading: false,
          p71Documents: localP71Documents,
          documentDialogVisible: false,
          saveVersionButton: true,
        });
      }
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
      });
    }
  };

  handleShowLogBookData = (rowData) => {
    window.open(`/passport/${rowData.passportCode}`, "_blank");
  };

  handleUpdateLogBookData = (rowData, props) => {
    this.setState({
      //loading: true,
    });

    this.getPassportLogbookById(rowData);
    this.setState({ selectedPassportData: rowData });
  };

  handleDocuments = () => {
    this.setState({
      loading: true,
    });

    let documents = [...this.state.documents];

    if (documents) {
      let localArray = [];

      for (let i = 0; i < documents.length; i++) {
        const doc = { ...this.emptyNewDocument };

        doc.documentDate = documents[i].documentDate;
        doc.documentName = documents[i].documentName;
        doc.documentURL = documents[i].documentURL;
        doc.fileDescription = documents[i].description;
        doc.fileName = documents[i].fileName;
        doc.phase = "P7" + this.state.activeIndex;

        localArray.push(doc);
      }

      if (this.state.activeIndex === 0) {
        let localP70Documents = [...this.state.p70Documents];
        localP70Documents = localP70Documents.concat(localArray);

        this.setState({
          loading: false,
          p70Documents: localP70Documents,
          addDocumentDialogVisible: false,
          saveButtonStatus: true,
        });
      }
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
      });
    }
  };

  handleEventDocuments = () => {
    this.setState({
      loading: true,
    });

    let eventDocuments = [...this.state.eventDocuments];

    if (eventDocuments) {
      let localArray = [];

      for (let i = 0; i < eventDocuments.length; i++) {
        const doc = { ...this.emptyNewDocument2 };

        let date1 = new Date(eventDocuments[i].documentDate);
        let date2 = new Date(eventDocuments[i].documentDate.toString());
        let date3 = Date.parse(eventDocuments[i].documentDate);
        let date4 = Date.parse(eventDocuments[i].documentDate.toString());
        let date5 = parseISO(eventDocuments[i].documentDate);

        //doc.documentDate = eventDocuments[i].documentDate;
        doc.documentName = eventDocuments[i].documentName;
        doc.documentURL = eventDocuments[i].documentURL;
        doc.fileDescription = eventDocuments[i].description;
        doc.fileName = eventDocuments[i].fileName;

        localArray.push(doc);
      }

      let newEvent = { ...this.state.newEvent };
      let evLength = newEvent.documents.length;

      if (evLength > 0) {
        for (let index = 0; index < localArray.length; index++) {
          newEvent.documents.push(localArray[index]);
        }
      } else {
        newEvent[`${"documents"}`] = localArray;
      }

      this.setState({
        loading: false,
        newEvent,
        //eventDocuments: localArray,
        editDocumentDialogVisible: false,
        saveEventDocButtonStatus: true,
        eventDocuments: [],
      });
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
      });
    }
  };

  handleNewVersion = () => {
    let documents1 = this.state.p71Documents.filter(
      (doc) => doc.section === "1"
    );
    let documents2 = this.state.p71Documents.filter(
      (doc) => doc.section === "2"
    );
    let documents3 = this.state.p71Documents.filter(
      (doc) => doc.section === "3"
    );

    let logbookObject = { ...this.state.logbookObject };

    let images = [...this.state.images];
    let imageUrlArray = [];
    for (let i = 0; i < images.length; i++) {
      imageUrlArray.push(images[i].documentURL);
    }
    let imagesAray = logbookObject[`${"logbookBasicInfo"}`];

    imagesAray.images = imageUrlArray;

    let localArray = logbookObject[`${"logbookInformationPanels"}`];

    if (
      localArray.findIndex((obj) => obj.panelName === "Indoor Air Quality Data")
    ) {
      const index = localArray.findIndex(
        (obj) => obj.panelName === "Indoor Air Quality Data"
      );

      if (this.state.equipmentsList.length > 0) {
        let equipmentsList = [...this.state.equipmentsList];

        for (let i = 0; i < equipmentsList.length; i++) {
          localArray[index].euis.push(equipmentsList[i].eqDeviceEUI);
        }
      }
      localArray[index].panelPrivacy = this.state.checkedEQ
        ? "private"
        : "public";
    }
    // }

    if (
      localArray.findIndex((obj) => obj.panelName === "Renovations & Repairs")
    ) {
      const index = localArray.findIndex(
        (obj) => obj.panelName === "Renovations & Repairs"
      );

      for (let i = 0; i < this.state.eventsList.length; i++) {
        localArray[index].renovationsRepairs.push(this.state.eventsList[i]);
      }
      // let renovationsRepairs = localArray[`${"renovationsRepairs"}`];

      // if(eq1.length > 0){
      // localArray[index].documents = eq1;
      //}
      localArray[index].panelPrivacy = this.state.checkedRenovationRepairs
        ? "private"
        : "public";
    }

    if (documents1) {
      const index = localArray
        .findIndex((obj) => obj.panelName === "Contracts & Legal Documents")
        .toString();
      if (index) {
        let index2 = parseInt(index);

        if (documents1.length > 0) {
          localArray[index2].documents = [...documents1];
        }
        localArray[index2].panelPrivacy = this.state.checkedContractsAndLegal
          ? "private"
          : "public";
      }
    }

    if (documents2) {
      const index = localArray
        .findIndex((obj) => obj.panelName === "Technical & Financial Documents")
        .toString();

      if (index) {
        let index2 = parseInt(index);

        if (documents2.length > 0) {
          localArray[index2].documents = [...documents2];
        }
        localArray[index2].panelPrivacy = this.state
          .checkedTechnicalAndFinancial
          ? "private"
          : "public";
      }
    }

    if (documents3) {
      const index = localArray
        .findIndex((obj) => obj.panelName === "Guarantees & Warranties")
        .toString();

      if (index) {
        let index2 = parseInt(index);
        if (documents3.length > 0) {
          localArray[index2].documents = [...documents3];
        }
        localArray[index2].panelPrivacy = this.state
          .checkedGuaranteesAndWarranties
          ? "private"
          : "public";
      }
    }

    logbookObject[`${"logbookInformationPanels"}`] = localArray;

    let passportDataL = { ...this.state.passportData };
    passportDataL.projectId = this.state.pid;
    passportDataL.projectName = this.state.project.projectName;

    passportDataL.logbookObject = logbookObject;

    this.setState({
      passportData: passportDataL,
    });

    this.addNewPassportLogbook(passportDataL);
  };

  handleUpdateVersion = () => {
    // this.setState({
    //   loading: true,
    // });

    // let eq1 = this.state.equipmentsList.filter((eq) => eq.section === "1");
    // let eq2 = this.state.equipmentsList.filter((eq) => eq.section === "2");

    let documents1 = this.state.p71Documents.filter(
      (doc) => doc.section === "1"
    );
    let documents2 = this.state.p71Documents.filter(
      (doc) => doc.section === "2"
    );
    let documents3 = this.state.p71Documents.filter(
      (doc) => doc.section === "3"
    );

    let logbookObject = { ...this.emptylogbookObject };

    let images = [...this.state.images];
    let imageUrlArray = [];
    for (let i = 0; i < images.length; i++) {
      imageUrlArray.push(images[i].documentURL);
    }
    let imagesAray = logbookObject[`${"logbookBasicInfo"}`];

    imagesAray.images = imageUrlArray;

    let localArray = logbookObject[`${"logbookInformationPanels"}`];

    if (
      localArray.findIndex((obj) => obj.panelName === "Indoor Air Quality Data")
    ) {
      const index = localArray.findIndex(
        (obj) => obj.panelName === "Indoor Air Quality Data"
      );
      let eqArray = [];

      if (this.state.equipmentsList.length > 0) {
        for (let i = 0; i < this.state.equipmentsList.length; i++) {
          eqArray.push(this.state.equipmentsList[i].eqDeviceEUI);
        }
        localArray[index].euis = eqArray;
      }
      localArray[index].panelPrivacy = this.state.checkedEQ
        ? "private"
        : "public";
    }

    if (
      localArray.findIndex((obj) => obj.panelName === "Renovations & Repairs")
    ) {
      const index = localArray.findIndex(
        (obj) => obj.panelName === "Renovations & Repairs"
      );

      for (let i = 0; i < this.state.eventsList.length; i++) {
        localArray[index].renovationsRepairs.push(this.state.eventsList[i]);
      }
      localArray[index].renovationsRepairs = this.state.eventsList;

      localArray[index].panelPrivacy = this.state.checkedRenovationRepairs
        ? "private"
        : "public";
    }

    if (documents1) {
      const index = localArray
        .findIndex((obj) => obj.panelName === "Contracts & Legal Documents")
        .toString();

      if (documents1.length > 0) {
        localArray[index].documents = [...documents1];
      } else {
        localArray[index].documents = [];
      }
      localArray[index].panelPrivacy = this.state.checkedContractsAndLegal
        ? "private"
        : "public";
    }

    if (documents2) {
      const index = localArray
        .findIndex((obj) => obj.panelName === "Technical & Financial Documents")
        .toString();

      if (documents2.length > 0) {
        localArray[index].documents = [...documents2];
      } else {
        localArray[index].documents = [];
      }

      localArray[index].panelPrivacy = this.state.checkedTechnicalAndFinancial
        ? "private"
        : "public";
    }

    if (documents3) {
      const index = localArray
        .findIndex((obj) => obj.panelName === "Guarantees & Warranties")
        .toString();

      if (documents3.length > 0) {
        localArray[index].documents = [...documents3];
      } else {
        localArray[index].documents = [];
      }
      localArray[index].panelPrivacy = this.state.checkedGuaranteesAndWarranties
        ? "private"
        : "public";
    }

    logbookObject[`${"logbookInformationPanels"}`] = localArray;

    let passportDataL = { ...this.emptyNewpassportData2 };
    passportDataL.projectId = this.state.pid;
    passportDataL.projectName = this.state.project.projectName;

    passportDataL.passportId = this.state.selectedPassportData.id;

    passportDataL.logbookObject = logbookObject;

    this.setState({
      passportData: passportDataL,
    });

    this.updatePassportLogbook(passportDataL);
  };

  handleAddDocument = () => {
    this.setState({
      newDocument: this.emptyNewDocument,
      submitted: false,
      addDocumentDialogVisible: true,
      documents: [],
    });
  };

  hideAddDocumentDialog = () => {
    this.setState({
      submitted: false,
      addDocumentDialogVisible: false,
    });
  };

  handleSelectRegisteredEquipmentDialog = () => {
    this.setState({
      submitted: false,
      selectFromRegisteredEquipmentDialog: true,
    });
  };

  handleSelectFromProjectDocumentDialog = () => {
    this.setState({
      submitted: false,
      selectFromProjectDocumentDialog: true,
    });
  };

  handleAddNewEventDialog = () => {
    this.setState({
      newEvent: this.emptyNewEvent,
      submitted: false,
      addNewEventDialog: true,
    });
  };

  handleUpdateEventDialog = (data) => {
    this.setState({
      submitted: false,
      updateEventDialog: true,
      selectedEvent: data,
    });
  };

  hideSelectFromRegisteredEquipmentDialog = () => {
    this.setState({
      submitted: false,
      selectFromRegisteredEquipmentDialog: false,
      selectedEQData: null,
      newEQ: this.emptyEQ,
      eqAddButtonStatus: false,
    });
  };

  hideSelectFromProjectDocumentDialog = () => {
    this.setState({
      submitted: false,
      selectFromProjectDocumentDialog: false,
    });
  };

  hideNewEventDialog = () => {
    this.setState({
      submitted: false,
      addNewEventDialog: false,
    });
  };

  hidePublishDBLDialog = () => {
    this.setState({
      submitted: false,
      publishDBLDialog: false,
    });
  };

  hideDeleteDBLDialog = () => {
    this.setState({
      submitted: false,
      deleteDBLDialog: false,
    });
  };

  handleImageChange = (e) => {
    this.setState({
      loading: true,
    });

    e.persist();

    const selectedImages = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];

      if (file.type.startsWith("image/")) {
        this.setState({
          loading: true,
        });

        SysService.getS3URL(
          file.name,
          "P7" + this.state.activeIndex,
          this.state.pid,
          0
        ).then(
          (response) => {
            const s3Response = response.data;
            const tbuFile = file;

            this.setState({
              loading: true,
            });

            SysService.putFile2S3URL(s3Response.uploadURL, tbuFile).then(
              (response) => {
                const newImgObj = {
                  uploadURL: s3Response.uploadURL,
                  fileName: s3Response.fileName,
                  documentName: s3Response.documentName,
                  documentURL: s3Response.documentURL,
                };

                selectedImages.push(newImgObj);

                if (selectedImages.length === e.target.files.length) {
                  this.setState((prevState) => ({
                    images: [...prevState.images, ...selectedImages],
                    loading: false,
                  }));
                }
                this.setState({
                  saveVersionButton: true,
                });
              },
              (error) => {
                this.setState({
                  loading: false,
                  content:
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString(),
                });
              }
            );
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });
          }
        );
      }
    }
  };

  handleImageDelete = (index) => {
    const updatedImages = this.state.images.filter((image, i) => i !== index);
    this.setState({
      images: updatedImages,
      saveVersionButton: true,
    });
  };

  acceptAddDocument = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);

      return;
    }

    if (
      this.state.newDocument.fileDescription == null ||
      this.state.newDocument.fileDescription === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDescription" />);

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P70" + this.state.activeIndex,
      this.state.pid
    ).then(
      (response) => {
        this.setState(
          { s3Response: response.data, tbuFile: fileX, saveButtonStatus: true },
          () => {
            this.state.newDocument.uploadURL = this.state.s3Response.uploadURL;
            this.state.newDocument.fileName = this.state.s3Response.fileName;
            this.state.newDocument.documentName =
              this.state.s3Response.documentName;
            this.state.newDocument.documentDate =
              this.state.s3Response.documentDate;
            this.state.newDocument.documentURL =
              this.state.s3Response.documentURL;
          }
        );

        this.put2S3();

        let p70doc = [...this.state.p70Documents];

        if (this.state.activeIndex === 0) {
          p70doc.push(this.state.newDocument);
          this.setState({ p70Documents: p70doc, saveButtonStatus: true });
        }
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  handleAddNewLogbookDoc = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);

      return;
    }

    if (
      this.state.newLogbookDoc.documentGivenName == null ||
      this.state.newLogbookDoc.documentGivenName.trim() === "" ||
      this.state.newLogbookDoc.section == null ||
      this.state.newLogbookDoc.section === ""
      // this.state.newLogbookDoc.description == null ||
      // this.state.newLogbookDoc.description.trim() === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P7" + this.state.activeIndex,
      this.state.pid,
      0
    ).then(
      (response) => {
        this.setState(
          {
            s3Response: response.data,
            tbuFile: fileX,
            //saveButtonStatus: true
          },
          () => {
            this.state.newLogbookDoc.uploadURL =
              this.state.s3Response.uploadURL;
            this.state.newLogbookDoc.fileName = this.state.s3Response.fileName;
            this.state.newLogbookDoc.documentName =
              this.state.s3Response.documentName;
            this.state.newLogbookDoc.documentDate =
              this.state.s3Response.documentDate;
            this.state.newLogbookDoc.documentURL =
              this.state.s3Response.documentURL;
          }
        );

        this.putS3LogbookDoc();
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  handleAddSelectedProjectDoc = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);

      return;
    }

    if (
      this.state.newProjectDoc.section == null ||
      this.state.newProjectDoc.section === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P7" + this.state.activeIndex,
      this.state.pid,
      0
    ).then(
      (response) => {
        this.setState(
          {
            s3Response: response.data,
            tbuFile: fileX,
            //saveButtonStatus: true
          },
          () => {
            this.state.newProjectDoc.uploadURL =
              this.state.s3Response.uploadURL;
            this.state.newProjectDoc.fileName = this.state.s3Response.fileName;
            this.state.newProjectDoc.documentName =
              this.state.s3Response.documentName;
            this.state.newProjectDoc.documentDate =
              this.state.s3Response.documentDate;
            this.state.newProjectDoc.documentURL =
              this.state.s3Response.documentURL;
          }
        );

        this.putS3SelectedProjectDoc();
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  saveSelectedDocFiles = () => {
    let selectedDocs = [];

    let p1Doclist = this.state.projectDocListP1;
    let p2Doclist = this.state.projectDocListP2;
    let p3Doclist = this.state.projectDocListP3;
    let p4Doclist = this.state.projectDocListP4;
    let p5Doclist = this.state.projectDocListP5;
    let p6Doclist = this.state.projectDocListP6;
    let p7Doclist = this.state.projectDocListP7;
    let p8Doclist = this.state.projectDocListP8;

    if (p1Doclist.length > 0) {
      for (let i = 0; i < p1Doclist.length; i++) {
        if (
          p1Doclist[i].section === "1" ||
          p1Doclist[i].section === "2" ||
          p1Doclist[i].section === "3" ||
          p1Doclist[i].section === "4"
        ) {
          let newDocObj = {
            //documentDate: p1Doclist[i].documentDate,
            fileName: p1Doclist[i].fileName,
            documentName: p1Doclist[i].documentName,
            documentURL: p1Doclist[i].documentURL,
            description: p1Doclist[i].fileDescription,
            section: p1Doclist[i].section,
          };
          selectedDocs.push(newDocObj);
        }
      }
    }

    if (p2Doclist.length > 0) {
      for (let i = 0; i < p2Doclist.length; i++) {
        if (
          p2Doclist[i].section === "1" ||
          p2Doclist[i].section === "2" ||
          p2Doclist[i].section === "3" ||
          p2Doclist[i].section === "4"
        ) {
          let newDocObj = {
            //documentDate: p2Doclist[i].documentDate,
            fileName: p2Doclist[i].fileName,
            documentName: p2Doclist[i].documentName,
            documentURL: p2Doclist[i].documentURL,
            description: p2Doclist[i].fileDescription,
            section: p2Doclist[i].section,
          };
          selectedDocs.push(newDocObj);
        }
      }
    }

    if (p3Doclist.length > 0) {
      for (let i = 0; i < p3Doclist.length; i++) {
        if (
          p3Doclist[i].section === "1" ||
          p3Doclist[i].section === "2" ||
          p3Doclist[i].section === "3" ||
          p3Doclist[i].section === "4"
        ) {
          let newDocObj = {
            //documentDate: p3Doclist[i].documentDate,
            fileName: p3Doclist[i].fileName,
            documentName: p3Doclist[i].documentName,
            documentURL: p3Doclist[i].documentURL,
            description: p3Doclist[i].fileDescription,
            section: p3Doclist[i].section,
          };
          selectedDocs.push(newDocObj);
        }
      }
    }

    if (p4Doclist.length > 0) {
      for (let i = 0; i < p4Doclist.length; i++) {
        if (
          p4Doclist[i].section === "1" ||
          p4Doclist[i].section === "2" ||
          p4Doclist[i].section === "3" ||
          p4Doclist[i].section === "4"
        ) {
          let newDocObj = {
            //documentDate: p4Doclist[i].documentDate,
            fileName: p4Doclist[i].fileName,
            documentName: p4Doclist[i].documentName,
            documentURL: p4Doclist[i].documentURL,
            description: p4Doclist[i].fileDescription,
            section: p4Doclist[i].section,
          };
          selectedDocs.push(newDocObj);
        }
      }
    }

    if (p5Doclist.length > 0) {
      for (let i = 0; i < p5Doclist.length; i++) {
        if (
          p5Doclist[i].section === "1" ||
          p5Doclist[i].section === "2" ||
          p5Doclist[i].section === "3" ||
          p5Doclist[i].section === "4"
        ) {
          let newDocObj = {
            //: p5Doclist[i].documentDate,
            fileName: p5Doclist[i].fileName,
            documentName: p5Doclist[i].documentName,
            documentURL: p5Doclist[i].documentURL,
            description: p5Doclist[i].fileDescription,
            section: p5Doclist[i].section,
          };
          selectedDocs.push(newDocObj);
        }
      }
    }

    if (p6Doclist.length > 0) {
      for (let i = 0; i < p6Doclist.length; i++) {
        if (
          p6Doclist[i].section === "1" ||
          p6Doclist[i].section === "2" ||
          p6Doclist[i].section === "3" ||
          p6Doclist[i].section === "4"
        ) {
          let newDocObj = {
            //documentDate: p6Doclist[i].documentDate,
            fileName: p6Doclist[i].fileName,
            documentName: p6Doclist[i].documentName,
            documentURL: p6Doclist[i].documentURL,
            description: p6Doclist[i].fileDescription,
            section: p6Doclist[i].section,
          };
          selectedDocs.push(newDocObj);
        }
      }
    }

    if (p7Doclist.length > 0) {
      for (let i = 0; i < p7Doclist.length; i++) {
        if (
          p7Doclist[i].section === "1" ||
          p7Doclist[i].section === "2" ||
          p7Doclist[i].section === "3" ||
          p7Doclist[i].section === "4"
        ) {
          let newDocObj = {
            //documentDate: p7Doclist[i].documentDate,
            fileName: p7Doclist[i].fileName,
            documentName: p7Doclist[i].documentName,
            documentURL: p7Doclist[i].documentURL,
            description: p7Doclist[i].fileDescription,
            section: p7Doclist[i].section,
          };
          selectedDocs.push(newDocObj);
        }
      }
    }

    if (p8Doclist.length > 0) {
      for (let i = 0; i < p8Doclist.length; i++) {
        if (
          p8Doclist[i].section === "1" ||
          p8Doclist[i].section === "2" ||
          p8Doclist[i].section === "3" ||
          p8Doclist[i].section === "4"
        ) {
          let newDocObj = {
            //documentDate: p8Doclist[i].documentDate,
            fileName: p8Doclist[i].fileName,
            documentName: p8Doclist[i].documentName,
            documentURL: p8Doclist[i].documentURL,
            description: p8Doclist[i].fileDescription,
            section: p8Doclist[i].section,
          };
          selectedDocs.push(newDocObj);
        }
      }
    }

    this.handleAddSelectedProjectDoc2(selectedDocs);
  };

  handleAddSelectedProjectDoc2 = (data) => {
    this.setState({
      loading: true,
    });

    let p71Documents = [...this.state.p71Documents];

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let doc = { ...this.emptyNewDocumentLogbook };
        //doc.documentDate = data[i].documentDate;
        doc.documentName = data[i].documentName;
        doc.documentURL = data[i].documentURL;
        doc.fileDescription = data[i].fileDescription;
        doc.fileName = data[i].fileName;
        doc.phase = "P7" + this.state.activeIndex;

        doc.description = data[i]?.description;
        doc.section = data[i]?.section;
        doc.documentGivenName = data[i]?.documentGivenName;

        p71Documents.push(doc);
      }
    }

    this.setState({
      loading: false,
      p71Documents,
      selectFromProjectDocumentDialog: false,
      saveVersionButton: true,
      addButtonStatus: false,
    });

    let p1Doclist = [...this.state.projectDocListP1];
    let p2Doclist = [...this.state.projectDocListP2];
    let p3Doclist = [...this.state.projectDocListP3];
    let p4Doclist = [...this.state.projectDocListP4];
    let p5Doclist = [...this.state.projectDocListP5];
    let p6Doclist = [...this.state.projectDocListP6];
    let p7Doclist = [...this.state.projectDocListP7];
    let p8Doclist = [...this.state.projectDocListP8];

    p1Doclist = this.filterDocsForSectionVal(p1Doclist);
    p2Doclist = this.filterDocsForSectionVal(p2Doclist);
    p3Doclist = this.filterDocsForSectionVal(p3Doclist);
    p4Doclist = this.filterDocsForSectionVal(p4Doclist);
    p5Doclist = this.filterDocsForSectionVal(p5Doclist);
    p6Doclist = this.filterDocsForSectionVal(p6Doclist);
    p7Doclist = this.filterDocsForSectionVal(p7Doclist);
    p8Doclist = this.filterDocsForSectionVal(p8Doclist);

    this.setState({
      projectDocListP1: p1Doclist,
      projectDocListP2: p2Doclist,
      projectDocListP3: p3Doclist,
      projectDocListP4: p4Doclist,
      projectDocListP5: p5Doclist,
      projectDocListP6: p6Doclist,
      projectDocListP7: p7Doclist,
      projectDocListP8: p8Doclist,
      badge1: 0,
      badge2: 0,
      badge3: 0,
      badge4: 0,
      badge5: 0,
      badge6: 0,
      badge7: 0,
      badge8: 0,
    });
  };

  rejectAddDocument = () => {};

  handleEditLogbookDocument = (data, props) => {
    this.setState({
      selectedRowData: data,
      submitted: false,
      documentDialogVisible: true,
    });
  };

  handleAddDocument2 = () => {
    this.setState({
      submitted: false,
      documentDialogVisible: true,
    });
  };

  hideAddDocument2 = () => {
    this.setState({
      submitted: false,
      documentDialogVisible: false,
      P71FileName: "",
      newLogbookDoc: this.emptyNewDocumentLogbook,

      // saveVersionButton:false
    });
  };

  handleShowProjectDocuments = () => {
    this.setState({
      submitted: false,
      projectDocumentDialogVisible: true,
    });
  };

  hideShowProjectDocuments = () => {
    this.setState({
      submitted: false,
      projectDocumentDialogVisible: false,
    });
  };

  handleSelectRegisteredEquipment = () => {
    this.setState({
      submitted: false,
      equipmentDialogVisible: true,
    });
  };

  hideSelectRegisteredEquipment = () => {
    this.setState({
      submitted: false,
      equipmentDialogVisible: false,
    });
  };

  eventScopeTemplate = (data) => {
    if (data.scope === "1") {
      return <Trans i18nKey={"Electrical&LegalDocuments"} />;
    } else if (data.scope === "2") {
      return <Trans i18nKey={"Plumbing"} />;
    } else if (data.scope === "3") {
      return (
        <Trans i18nKey={"HVAC (Heating, Ventilation, and Air Conditioning)"} />
      );
    } else if (data.scope === "4") {
      return <Trans i18nKey={"Roofing"} />;
    } else if (data.scope === "5") {
      return <Trans i18nKey={"Carpentry and Woodworking"} />;
    } else if (data.scope === "6") {
      return <Trans i18nKey={"Painting and finishing"} />;
    } else if (data.scope === "7") {
      return <Trans i18nKey={"Flooring"} />;
    } else if (data.scope === "8") {
      return <Trans i18nKey={"Masonry and Concrete"} />;
    } else if (data.scope === "9") {
      return <Trans i18nKey={"Structural"} />;
    } else if (data.scope === "10") {
      return <Trans i18nKey={"Appliance"} />;
    } else if (data.scope === "11") {
      return <Trans i18nKey={"Insulation and Weatherproofing"} />;
    } else if (data.scope === "12") {
      return <Trans i18nKey={"General Maintenance and Handyman Services"} />;
    }
  };

  eventTypeTemplate = (data) => {
    if (data.type === "1") {
      return <Trans i18nKey={"Repair"} />;
    } else if (data.type === "2") {
      return <Trans i18nKey={"Maintenance"} />;
    } else if (data.type === "3") {
      return <Trans i18nKey={"Major alteration"} />;
    } else if (data.type === "4") {
      return <Trans i18nKey={"Replacement"} />;
    }
  };

  eventCalendarTemp = (data) => {
    if (data) {
      if (
        data.eventDate !== undefined &&
        data.eventDate !== "" &&
        data.eventDate !== null
      ) {
        return format(new Date(data.eventDate), "dd/MM/yyyy");
      }
    } else {
      return "";
    }
  };

  dateFormatForCalendar = (date) => {
    if (date !== undefined && date !== "" && date !== null) {
      return new Date(date);
    } else {
      return "";
    }
  };

  sectionDataTableTemplate = (data) => {
    if (data.section === "1") {
      return <Trans i18nKey={"contracts&LegalDocuments"} />;
    } else if (data.section === "2") {
      return <Trans i18nKey={"technical&FinancialDocuments"} />;
    } else if (data.section === "3") {
      return <Trans i18nKey={"guarantees&Warranties"} />;
    } else if (data.section === "4") {
      return <Trans i18nKey={"renovations&RepairsSec"} />;
    }
  };

  docNameTemp = (data) => {
    if (
      data.documentGivenName !== null &&
      data.documentGivenName !== "" &&
      data.documentGivenName !== undefined
    ) {
      return data.documentGivenName;
    } else {
      return data.fileName;
    }
  };
  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    let localProjectData;
    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let allDocList = response.data.documentList;
        let P1DocList = [];
        let P2DocList = [];
        let P3DocList = [];
        let P4DocList = [];
        let P5DocList = [];
        let P6DocList = [];
        let P7DocList = [];
        let P8DocList = [];

        for (let i = 0; i < allDocList.length; i++) {
          let docObj = {
            fileDescription: "",
            documentName: "",
            documentURL: "",
            fileName: "",
            //documentDate: "",
            phase: "",
            checked: false,
            section: "",
          };

          if (allDocList[i].phase.startsWith("P1")) {
            docObj.fileDescription = allDocList[i].fileDescription;
            docObj.documentName = allDocList[i].documentName;
            docObj.documentURL = allDocList[i].documentURL;
            docObj.fileName = allDocList[i].fileName;
            //docObj.documentDate = allDocList[i].documentDate;
            docObj.phase = "P1";

            P1DocList.push(docObj);
          }
          if (allDocList[i].phase.startsWith("P2")) {
            docObj.fileDescription = allDocList[i].fileDescription;
            docObj.documentName = allDocList[i].documentName;
            docObj.documentURL = allDocList[i].documentURL;
            docObj.fileName = allDocList[i].fileName;
            //docObj.documentDate = allDocList[i].documentDate;
            docObj.phase = "P2";

            P2DocList.push(docObj);
          }
          if (allDocList[i].phase.startsWith("P3")) {
            docObj.fileDescription = allDocList[i].fileDescription;
            docObj.documentName = allDocList[i].documentName;
            docObj.documentURL = allDocList[i].documentURL;
            docObj.fileName = allDocList[i].fileName;
            //docObj.documentDate = allDocList[i].documentDate;
            docObj.phase = "P3";

            P3DocList.push(docObj);
          }
          if (allDocList[i].phase.startsWith("P4")) {
            docObj.fileDescription = allDocList[i].fileDescription;
            docObj.documentName = allDocList[i].documentName;
            docObj.documentURL = allDocList[i].documentURL;
            docObj.fileName = allDocList[i].fileName;
            //docObj.documentDate = allDocList[i].documentDate;
            docObj.phase = "P4";

            P4DocList.push(docObj);
          }
          if (allDocList[i].phase.startsWith("P5")) {
            docObj.fileDescription = allDocList[i].fileDescription;
            docObj.documentName = allDocList[i].documentName;
            docObj.documentURL = allDocList[i].documentURL;
            docObj.fileName = allDocList[i].fileName;
            //docObj.documentDate = allDocList[i].documentDate;
            docObj.phase = "P5";

            P5DocList.push(docObj);
          }
          if (allDocList[i].phase.startsWith("P6")) {
            docObj.fileDescription = allDocList[i].fileDescription;
            docObj.documentName = allDocList[i].documentName;
            docObj.documentURL = allDocList[i].documentURL;
            docObj.fileName = allDocList[i].fileName;
            //docObj.documentDate = allDocList[i].documentDate;
            docObj.phase = "P6";

            P6DocList.push(docObj);
          }
          if (allDocList[i].phase.startsWith("P7")) {
            docObj.fileDescription = allDocList[i].fileDescription;
            docObj.documentName = allDocList[i].documentName;
            docObj.documentURL = allDocList[i].documentURL;
            docObj.fileName = allDocList[i].fileName;
            //docObj.documentDate = allDocList[i].documentDate;
            docObj.phase = "P7";

            P7DocList.push(docObj);
          }

          if (allDocList[i].phase.startsWith("P8")) {
            docObj.fileDescription = allDocList[i].fileDescription;
            docObj.documentName = allDocList[i].documentName;
            docObj.documentURL = allDocList[i].documentURL;
            docObj.fileName = allDocList[i].fileName;
            //docObj.documentDate = allDocList[i].documentDate;
            docObj.phase = "P8";

            P8DocList.push(docObj);
          }
        }

        this.setState({
          projectDocListP1: P1DocList,
          projectDocListP2: P2DocList,
          projectDocListP3: P3DocList,
          projectDocListP4: P4DocList,
          projectDocListP5: P5DocList,
          projectDocListP6: P6DocList,
          projectDocListP7: P7DocList,
          projectDocListP8: P8DocList,
          allProjectDocList: response.data.documentList,
        });

        localProjectData = response.data;
        this.setProjectDataToNewLogbook(localProjectData);

        let temp70Documents = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P70") {
              temp70Documents.push(response.data.documentList[i]);
            }

            if (
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpg") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".png") ||
              response.data.documentList[i].documentURL
                .toLowerCase()
                ?.endsWith(".jpeg")
            ) {
              let newObj = {};
              newObj.itemImageSrc = response.data.documentList[i].documentURL;
              newObj.thumbnailImageSrc =
                response.data.documentList[i].documentURL;
              newObj.alt = response.data.documentList[i].fileDescription;
              newObj.title = response.data.documentList[i].fileName;
              if (response.data.documentList[i].phase === "P70") {
                this.state.imageList1.push(newObj);
              }
            }
          }
        }

        let equipmentList = [];
        for (let i = 0; i < response.data.equipmentList.length; i++) {
          equipmentList[i] = response.data.equipmentList[i].eqDeviceName;
        }

        const equipmentListOptions = [];
        for (let j = 0; j < equipmentList.length; j++) {
          equipmentListOptions.push({
            name: equipmentList[j],
            code: j.toString(),
          });
        }
        this.setState({
          P9EquipmentList: equipmentListOptions,
          equipments: response.data.equipmentList,
          projectEQList: response.data.equipmentList,

          project: response.data,
          p70Documents: temp70Documents,

          dateofPracticalCompletion: this.calendarFormatTemplate(
            response?.data?.dateofPracticalCompletion
          ),
          periodofDefectsEndDate: this.calendarFormatTemplate(
            response?.data?.periodofDefectsEndDate
          ),
          periodofDefectsStartDate: this.calendarFormatTemplate(
            response?.data?.periodofDefectsStartDate
          ),
          dateOfVisit: this.calendarFormatTemplate(response?.data?.dateOfVisit),

          loading: false,
          blockedPanel: false,
          nonEditAccess:
            response.data.status === 2 && !isViewerExpert() ? false : true,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    const ttoken = JSON.parse(localStorage.getItem("tracid_token"));
    if (ttoken === null || ttoken === undefined) {
      this.getNewToken();
    } else {
      if (!isTracidTokenValid()) {
        this.getNewToken();
      }
    }

    this.initFilters1();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.activeIndex === this.stepItems.length - 1 &&
      this.state.serviceWorked
    ) {
      this.callService();
    }
  }

  callService = () => {
    this.getPassportLogbooksByProjectId();
    this.setState({
      serviceWorked: false,
    });
  };

  /**/
  setOTPCode() {
    this.setState({
      loading: true,
    });

    let email = this.state.project.email;

    let newProject = { ...this.state.project };
    newProject.projectId = this.state.pid;

    if (this.state.project.email === null) {
      toast.warning(<Trans i18nKey="plaseEnterEmailFirst" />);
      this.setState({
        loading: false,
      });
    } else {
      SysService.createNewOTPCode(newProject).then(
        (response) => {
          this.setState({
            loading: false,
          });
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  }

  getNewToken() {
    TracidService.getNewToken(
      process.env.REACT_APP_TRACID_APIKEY,
      process.env.REACT_APP_TRACID_APISECRET
    ).then(
      (response) => {},
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  passportLogbookHistoryById(data) {
    let passportId = data.id;

    TracidService.passportLogbookHistoryById(passportId).then(
      (response) => {
        this.setState({
          historyData: response.data,
          historyDialog: true,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  publishPassportLogbook(data) {
    this.setState({
      loading: true,
    });

    let passportData = {
      passportId: this.state.selectedROwPublishData.id,
    };

    TracidService.publishPassportLogbook(passportData).then(
      (response) => {
        if (response.data.message) {
          toast.success(<Trans i18nKey={response.data.message} />);
        }

        this.setState({
          loading: false,
        });
        this.getPassportLogbooksByProjectId();
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  getPassportLogbooksByProjectId() {
    let projectId = this.props.location.state.id;

    TracidService.getPassportLogbooksByProjectId(projectId).then(
      (response) => {
        this.setState({
          passportLogbooks: response.data,
          passportLogbooksLength: response.data.length,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  getPassportLogbookById(rowData) {
    let passportId = rowData.id;

    TracidService.getPassportLogbookById(passportId).then(
      (response) => {
        this.setState({
          newPassportData: response.data,
        });
        let localDocuments = [];

        let localArray =
          response.data.passport.logbookObject.logbookInformationPanels;
        let localArray2 = response.data.passport.logbookObject.logbookBasicInfo;

        if (localArray2.images.length > 0) {
          const imagesLocal = localArray2.images;
          let localImgList = [];

          for (let i = 0; i < imagesLocal.length; i++) {
            const imgObj = {
              uploadURL: "",
              fileName: "",
              documentName: "",
              documentURL: "",
            };
            if (imagesLocal[i]) {
              imgObj.documentURL = imagesLocal[i];
              localImgList.push(imgObj);
            }
          }

          this.setState({
            images: localImgList,
          });
        }

        for (let i = 0; i < localArray.length; i++) {
          if (localArray[i]?.panelType === "document") {
            if (localArray[i].documents && localArray[i].documents.length > 0) {
              for (let j = 0; j < localArray[i].documents.length; j++) {
                let section = "";

                if (
                  localArray[i]?.panelName === "Contracts & Legal Documents"
                ) {
                  section = "1";
                  if (localArray[i]?.panelPrivacy === "public") {
                    this.setState({
                      checkedContractsAndLegal: false,
                    });
                  } else if (localArray[i]?.panelPrivacy === "private") {
                    this.setState({
                      checkedContractsAndLegal: true,
                    });
                  }
                } else if (
                  localArray[i]?.panelName === "Technical & Financial Documents"
                ) {
                  section = "2";
                  if (localArray[i]?.panelPrivacy === "public") {
                    this.setState({
                      checkedTechnicalAndFinancial: false,
                    });
                  } else if (localArray[i]?.panelPrivacy === "private") {
                    this.setState({
                      checkedTechnicalAndFinancial: true,
                    });
                  }
                } else if (
                  localArray[i]?.panelName === "Guarantees & Warranties"
                ) {
                  section = "3";
                  if (localArray[i]?.panelPrivacy === "public") {
                    this.setState({
                      checkedGuaranteesAndWarranties: false,
                    });
                  } else if (localArray[i]?.panelPrivacy === "private") {
                    this.setState({
                      checkedGuaranteesAndWarranties: true,
                    });
                  }
                }

                const newDocObj = {
                  documentGivenName: "",
                  documentName: "",
                  documentURL: "",
                  fileName: "",
                  description: "",
                  section: "",
                };
                newDocObj.documentGivenName =
                  localArray[i].documents[j]?.documentGivenName;
                newDocObj.documentName =
                  localArray[i].documents[j]?.documentName;
                newDocObj.documentURL = localArray[i].documents[j]?.documentURL;
                newDocObj.fileName = localArray[i].documents[j]?.fileName;
                newDocObj.description = localArray[i].documents[j]?.description;
                newDocObj.section = section;

                localDocuments.push(newDocObj);
              }
            }
          }
        }

        this.setState({
          p71Documents: localDocuments,
        });

        let eventListLocal = [];

        const index2 = localArray.findIndex(
          (obj) => obj?.panelName === "Renovations & Repairs"
        );
        if (localArray[index2]?.panelName === "Renovations & Repairs") {
          if (localArray[index2]?.panelPrivacy === "public") {
            this.setState({
              checkedRenovationRepairs: false,
            });
          } else if (localArray[index2]?.panelPrivacy === "private") {
            this.setState({
              checkedRenovationRepairs: true,
            });
          }

          if (
            localArray[index2].renovationsRepairs &&
            localArray[index2].renovationsRepairs.length > 0
          ) {
            for (
              let i = 0;
              i < localArray[index2].renovationsRepairs.length;
              i++
            ) {
              let newEvent = { ...this.emptyNewEvent };
              newEvent.eventId =
                localArray[index2].renovationsRepairs[i]?.eventId;
              newEvent.description =
                localArray[index2].renovationsRepairs[i]?.description;
              newEvent.scope = localArray[index2].renovationsRepairs[i]?.scope;
              newEvent.type = localArray[index2].renovationsRepairs[i]?.type;
              newEvent.documents =
                localArray[index2].renovationsRepairs[i]?.documents;
              newEvent.eventDate =
                localArray[index2].renovationsRepairs[i].eventDate;
              eventListLocal.push(newEvent);
            }
            this.setState({
              eventsList: eventListLocal,
            });
          }
        }

        let matchedEQList = [];
        let unmatchedEQList = [...this.state.projectEQList];

        const index3 = localArray.findIndex(
          (obj) => obj?.panelName === "Indoor Air Quality Data"
        );
        if (localArray[index3]?.panelName === "Indoor Air Quality Data") {
          if (localArray[index3]?.panelPrivacy === "public") {
            this.setState({
              checkedEQ: false,
            });
          } else if (localArray[index3]?.panelPrivacy === "private") {
            this.setState({
              checkedEQ: true,
            });
          }

          if (localArray[index3].euis && localArray[index3].euis.length > 0) {
            for (let i = 0; i < localArray[index3].euis.length; i++) {
              let eqDeviceEUI = localArray[index3].euis[i];

              let indexInProjectEQList = unmatchedEQList.findIndex(
                (eq) => eq.eqDeviceEUI === eqDeviceEUI
              );

              if (indexInProjectEQList !== -1) {
                matchedEQList.push(unmatchedEQList[indexInProjectEQList]);
                unmatchedEQList.splice(indexInProjectEQList, 1);
              }
            }

            this.setState({
              equipments: unmatchedEQList,
              equipmentsList: matchedEQList,
            });
          }
        }

        this.setState({
          newPassportData: response.data,
          updateVersionDialog: true,
          saveVersionButton: false,
        });

        let docObj = {
          checked: false,
          documentNmae: "",
          documentURL: "",
          fileDescription: "",
          fileName: "",
          phase: "",
          section: "",
        };
        let phase = "";

        let allDocs = [...this.state.allProjectDocList];

        for (let i = 0; i < allDocs.length; i++) {
          for (let j = 0; j < localDocuments.length; j++) {
            if (
              //rowData.documentDate === allDocs[i].documentDate&&
              localDocuments[j]?.documentName === allDocs[i]?.documentName &&
              localDocuments[j].documentURL === allDocs[i].documentURL &&
              (localDocuments[j]?.fileDescription ===
                allDocs[i].fileDescription ||
                localDocuments[j]?.description ===
                  allDocs[i].fileDescription) &&
              localDocuments[j].fileName === allDocs[i].fileName
            ) {
              allDocs.splice(i, 1);
            }
          }
        }
        let docList1 = [];
        let docList2 = [];
        let docList3 = [];
        let docList4 = [];
        let docList5 = [];
        let docList6 = [];
        let docList7 = [];
        let docList8 = [];

        for (let index = 0; index < allDocs.length; index++) {
          let docObj = {
            documentName: "",
            documentURL: "",
            fileDescription: "",
            fileName: "",
            phase: "",
            checked: false,
            section: "",
          };
          if (allDocs[index].phase.startsWith("P1")) {
            docObj.documentName = allDocs[index].documentName;
            docObj.documentURL = allDocs[index].documentURL;
            docObj.fileDescription = allDocs[index].fileDescription;
            docObj.fileName = allDocs[index].fileName;
            docObj.phase = "P1";

            docList1.push(docObj);
          }
          if (allDocs[index].phase.startsWith("P2")) {
            docObj.documentName = allDocs[index].documentName;
            docObj.documentURL = allDocs[index].documentURL;
            docObj.fileDescription = allDocs[index].fileDescription;
            docObj.fileName = allDocs[index].fileName;
            docObj.phase = "P2";
            docList2.push(docObj);
          }
          if (allDocs[index].phase.startsWith("P3")) {
            docObj.documentName = allDocs[index].documentName;
            docObj.documentURL = allDocs[index].documentURL;
            docObj.fileDescription = allDocs[index].fileDescription;
            docObj.fileName = allDocs[index].fileName;
            docObj.phase = "P3";
            docList3.push(docObj);
          }
          if (allDocs[index].phase.startsWith("P4")) {
            docObj.documentName = allDocs[index].documentName;
            docObj.documentURL = allDocs[index].documentURL;
            docObj.fileDescription = allDocs[index].fileDescription;
            docObj.fileName = allDocs[index].fileName;
            docObj.phase = "P4";
            docList4.push(docObj);
          }
          if (allDocs[index].phase.startsWith("P5")) {
            docObj.documentName = allDocs[index].documentName;
            docObj.documentURL = allDocs[index].documentURL;
            docObj.fileDescription = allDocs[index].fileDescription;
            docObj.fileName = allDocs[index].fileName;
            docObj.phase = "P5";
            docList5.push(docObj);
          }
          if (allDocs[index].phase.startsWith("P6")) {
            docObj.documentName = allDocs[index].documentName;
            docObj.documentURL = allDocs[index].documentURL;
            docObj.fileDescription = allDocs[index].fileDescription;
            docObj.fileName = allDocs[index].fileName;
            docObj.phase = "P6";
            docList6.push(docObj);
          }
          if (allDocs[index].phase.startsWith("P7")) {
            docObj.documentName = allDocs[index].documentName;
            docObj.documentURL = allDocs[index].documentURL;
            docObj.fileDescription = allDocs[index].fileDescription;
            docObj.fileName = allDocs[index].fileName;
            docObj.phase = "P7";
            docList7.push(docObj);
          }
          if (allDocs[index].phase.startsWith("P8")) {
            docObj.documentName = allDocs[index].documentName;
            docObj.documentURL = allDocs[index].documentURL;
            docObj.fileDescription = allDocs[index].fileDescription;
            docObj.fileName = allDocs[index].fileName;
            docObj.phase = "P8";
            docList8.push(docObj);
          }
        }
        this.setState({
          projectDocListP1: docList1,
          projectDocListP2: docList2,
          projectDocListP3: docList3,
          projectDocListP4: docList4,
          projectDocListP5: docList5,
          projectDocListP6: docList6,
          projectDocListP7: docList7,
          projectDocListP8: docList8,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  addNewPassportLogbook(passportData) {
    this.setState({
      loading: true,
    });

    TracidService.addNewPassportLogbook(passportData).then(
      (response) => {
        this.setState({
          loading: false,
          addNewVersionDialog: false,
          newEvent: this.emptyNewEvent,
          p71Documents: [],
          images: [],
          sec1Documents: [],
          sec2Documents: [],
          sec3Documents: [],
          sec4Documents: [],
          passportData: this.emptyNewpassportData,
          eventsList: [],
          equipmentList: [],
          equipments: this.state.projectEQList,
          equipmentsList: [],
          checkedContractsAndLegal: false,
          checkedTechnicalAndFinancial: false,
          checkedGuaranteesAndWarranties: false,
          checkedEQ: false,
          checkedRenovationRepairs: false,
        });

        this.componentDidMount();

        this.getPassportLogbooksByProjectId();

        toast.success(<Trans i18nKey={response.data.message} />);
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  updatePassportLogbook(passportData) {
    this.setState({
      loading: true,
    });
    TracidService.updatePassportLogbook(passportData).then(
      (response) => {
        this.setState({
          loading: false,
          updateVersionDialog: false,

          p71Documents: [],
          images: [],
          sec1Documents: [],
          sec2Documents: [],
          sec3Documents: [],
          sec4Documents: [],
          passportData: this.emptyNewpassportData,
          eventsList: [],
          newEvent: this.emptyNewEvent,
          equipmentList: [],
          equipments: this.state.projectEQList,
          equipmentsList: [],
          checkedContractsAndLegal: false,
          checkedTechnicalAndFinancial: false,
          checkedGuaranteesAndWarranties: false,
          checkedEQ: false,
          checkedRenovationRepairs: false,
        });
        this.componentDidMount();
        this.getPassportLogbooksByProjectId();
        toast.success(<Trans i18nKey={response.data.message} />);
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  deletePassportLogbook() {
    this.setState({
      loading: true,
    });

    let passportData = {
      passportId: this.state.selectedLogbookData.id,
    };

    TracidService.deletePassportLogbook(passportData).then(
      (response) => {
        this.getPassportLogbooksByProjectId();

        this.setState({
          loading: false,
          deleteDBLDialog: false,
        });

        toast.success(<Trans i18nKey={response.data.message} />);
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  getS3URL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }

    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.getS3URL(
        fileNameX,
        this.state.doc.documentType,
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addDocumentDialogVisible: false,
      });
    }
  }

  put2S3Img = () => {
    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          const newImgObj = {
            uploadURL: this.state.s3Response.uploadURL,
            fileName: this.state.s3Response.fileName,
            documentName: this.state.s3Response.documentName,
            documentURL: this.state.s3Response.documentURL,
          };

          this.setState((prevState) => ({
            images: [...prevState.images, newImgObj],
            loading: false,
            //loadingImages:false
          }));
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              error.response?.data?.message ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addDocumentDialogVisible: false,
      });
    }
  };

  putS3LogbookDoc() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          const newDocObj = {
            //documentDate: this.state.s3Response.documentDate,
            uploadURL: this.state.s3Response.uploadURL,
            fileName: this.state.s3Response.fileName,
            documentName: this.state.s3Response.documentName,
            documentURL: this.state.s3Response.documentURL,
            fileDescription: this.state.newLogbookDoc.fileDescription,

            section: this.state.newLogbookDoc.section,
            documentGivenName: this.state.newLogbookDoc.documentGivenName,
            description: this.state.newLogbookDoc.description,
          };

          this.setState({
            newLogbookDoc: this.emptyNewDocumentLogbook,
            P71FileName: "",
          });

          let newDocuments = [];
          newDocuments = [...this.state.p71Documents, newDocObj];

          this.setState({
            p71Documents: newDocuments,
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        documentDialogVisible: false,
      });
    }
  }

  putS3SelectedProjectDoc() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          const newDocObj = {
            //documentDate: this.state.s3Response.documentDate,
            uploadURL: this.state.s3Response.uploadURL,
            fileName: this.state.s3Response.fileName,
            documentName: this.state.s3Response.documentName,
            documentURL: this.state.s3Response.documentURL,
            fileDescription: this.state.newProjectDoc.fileDescription,
            description: this.state.newProjectDoc.description,
            section: this.state.newProjectDoc.section,
          };

          this.setState({
            newProjectDoc: this.emptyNewProjectDocumentTemp,
            selectedProjectDocName: "",
          });

          let newDocuments = [];
          newDocuments = [...this.state.p71Documents, newDocObj];

          this.setState({
            p71Documents: newDocuments,
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        documentDialogVisible: false,
      });
    }
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;

    if (inx >= this.stepItems.length - 1) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p70Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P7" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              imageList1: [],
            });

            this.componentDidMount();
            // window.scrollTo(0, 0);

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }

    window.scrollTo(0, 0);
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderVersionHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("versions")}</span>
      </div>
    );
  }

  setProjectDataToNewLogbook = (data) => {
    let logbookObject = { ...this.state.logbookObject };
    logbookObject[`${"logbookBasicInfo"}`].homeowner =
      data.lastName + " " + data.firstName;
    logbookObject[`${"logbookBasicInfo"}`].city = data.city;
    logbookObject[`${"logbookBasicInfo"}`].postcode = data.postcode;
    logbookObject[`${"logbookBasicInfo"}`].streetName = data.streetName;
    logbookObject[`${"logbookBasicInfo"}`].houseNumber = data.houseNumber;
    logbookObject[`${"logbookBasicInfo"}`].propertyType = data.propertyType;
    logbookObject[`${"logbookBasicInfo"}`].constructionYear =
      data.constructionYear;
    logbookObject[`${"logbookBasicInfo"}`].energyPerformanceClass =
      data.energyPerformanceClassP1;
    logbookObject[`${"logbookBasicInfo"}`].annualGasConsumption =
      data.annualGasConsumptionP1;
    logbookObject[`${"logbookBasicInfo"}`].annualElectricityConsumption =
      data.annualElectricityConsumptionP1;

    this.setState({
      logbookObject,
    });
  };

  onCheckedChange = (e, fileName, documentURL, phase) => {
    const checked = e.checked;

    const newData = this.state[`projectDocList${phase}`].map((doc) => {
      if (doc.fileName === fileName && doc.documentURL === documentURL) {
        return { ...doc, checked: checked };
      }
      return doc;
    });

    this.setState({
      [`projectDocList${phase}`]: newData,
      //addButtonStatus:true
    });

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].phase === "P1") {
        let count = 0;
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].checked != false) {
            count++;
          }
        }
        this.setState({
          badge1: count,
        });
      } else if (phase === "P2") {
        let count = 0;
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].checked) {
            count++;
          }
        }
        this.setState({
          badge2: count,
        });
      } else if (phase === "P3") {
        let count = 0;
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].checked) {
            count++;
          }
        }
        this.setState({
          badge3: count,
        });
      } else if (phase === "P4") {
        let count = 0;
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].checked) {
            count++;
          }
        }
        this.setState({
          badge4: count,
        });
      } else if (phase === "P5") {
        let count = 0;
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].checked) {
            count++;
          }
        }
        this.setState({
          badge5: count,
        });
      } else if (phase === "P6") {
        let count = 0;
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].checked) {
            count++;
          }
        }
        this.setState({
          badge6: count,
        });
      } else if (phase === "P7") {
        let count = 0;
        for (let i = 0; i < newData.length; i++) {
          if (newData[i].checked) {
            count++;
          }
        }
        this.setState({
          badge7: count,
        });
      } else if (phase === "P8") {
        let count = 0;
        for (let i = 0; i < newData.length; i++) {
          if (newData.checked) {
            count++;
          }
        }
        this.setState({
          badge8: count,
        });
      }
    }
  };

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  };

  nextPhase = () => {
    if (!this.state.saveButtonStatus) {
      this.props.history.push({
        pathname: "/postCompletion",
        state: this.state.project,
      });
    } else {
      toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
    }
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  setDocumentLoading = (documentLoading) => {
    this.setState({ documentLoading });
  };

  setDocuments = (data) => {
    this.setState({
      documents: data,
    });
  };

  setEventDoc = (data) => {
    this.setState({
      eventDocuments: data,
    });
  };

  setSaveButtonVisible = (data) => {
    this.setState({
      saveButtonStatus: data,
    });
  };

  setSelectedDocument = (data) => {
    this.setState({
      selectedDocument: data,
    });
  };

  setSelectedEventDocument = (data) => {
    this.setState({
      selectedEventDocument: data,
      saveEventDocButtonStatus: true,
    });
  };

  hideDocumentDialog = () => {
    this.setState({
      addDocumentDialogVisible: false,
      documents: [],
    });
  };

  hideEditDocumentDialog = () => {
    this.setState({
      editDocumentDialogVisible: false,
      eventDocuments: [],
      selectedRowData: null,
    });
  };

  hideNewVersionDialog = () => {
    this.setState({
      addNewVersionDialog: false,
      images: [],
      p71Documents: [],
      selectedProjectDocList: [],
      eventsList: [],
      equipmentList: [],
      equipments: this.state.projectEQList,
      passportData: this.emptyNewpassportData,

      badge1: 0,
      badge2: 0,
      badge3: 0,
      badge4: 0,
      badge5: 0,
      badge6: 0,
      badge7: 0,
      badge8: 0,
    });

    this.checkedClear();
  };

  checkedClear = () => {
    let docList = [...this.state.p71Documents];
    let allDocs = [...this.state.allProjectDocList];
    let newDocList = [];

    for (let i = 0; i < allDocs.length; i++) {
      for (let j = 0; j < docList.length; j++) {
        if (
          docList[j].documentName === allDocs[i].documentName &&
          docList[j].documentURL === allDocs[i].documentURL &&
          docList[j].description === allDocs[i].fileDescription &&
          docList[j].fileName === allDocs[i].fileName
        ) {
          allDocs.splice(i, 1);
        }
      }
    }
    let docList1 = [];
    let docList2 = [];
    let docList3 = [];
    let docList4 = [];
    let docList5 = [];
    let docList6 = [];
    let docList7 = [];
    let docList8 = [];

    for (let index = 0; index < allDocs.length; index++) {
      let docObj = {
        documentName: "",
        documentURL: "",
        fileDescription: "",
        fileName: "",
        phase: "",
        checked: false,
        section: "",
      };
      if (allDocs[index].phase.startsWith("P1")) {
        docObj.documentName = allDocs[index].documentName;
        docObj.documentURL = allDocs[index].documentURL;
        docObj.fileDescription = allDocs[index].fileDescription;
        docObj.fileName = allDocs[index].fileName;
        docObj.phase = "P1";

        docList1.push(docObj);
      }
      if (allDocs[index].phase.startsWith("P2")) {
        docObj.documentName = allDocs[index].documentName;
        docObj.documentURL = allDocs[index].documentURL;
        docObj.fileDescription = allDocs[index].fileDescription;
        docObj.fileName = allDocs[index].fileName;
        docObj.phase = "P2";
        docList2.push(docObj);
      }
      if (allDocs[index].phase.startsWith("P3")) {
        docObj.documentName = allDocs[index].documentName;
        docObj.documentURL = allDocs[index].documentURL;
        docObj.fileDescription = allDocs[index].fileDescription;
        docObj.fileName = allDocs[index].fileName;
        docObj.phase = "P3";
        docList3.push(docObj);
      }
      if (allDocs[index].phase.startsWith("P4")) {
        docObj.documentName = allDocs[index].documentName;
        docObj.documentURL = allDocs[index].documentURL;
        docObj.fileDescription = allDocs[index].fileDescription;
        docObj.fileName = allDocs[index].fileName;
        docObj.phase = "P4";
        docList4.push(docObj);
      }
      if (allDocs[index].phase.startsWith("P5")) {
        docObj.documentName = allDocs[index].documentName;
        docObj.documentURL = allDocs[index].documentURL;
        docObj.fileDescription = allDocs[index].fileDescription;
        docObj.fileName = allDocs[index].fileName;
        docObj.phase = "P5";
        docList5.push(docObj);
      }
      if (allDocs[index].phase.startsWith("P6")) {
        docObj.documentName = allDocs[index].documentName;
        docObj.documentURL = allDocs[index].documentURL;
        docObj.fileDescription = allDocs[index].fileDescription;
        docObj.fileName = allDocs[index].fileName;
        docObj.phase = "P6";
        docList6.push(docObj);
      }
      if (allDocs[index].phase.startsWith("P7")) {
        docObj.documentName = allDocs[index].documentName;
        docObj.documentURL = allDocs[index].documentURL;
        docObj.fileDescription = allDocs[index].fileDescription;
        docObj.fileName = allDocs[index].fileName;
        docObj.phase = "P7";
        docList7.push(docObj);
      }
      if (allDocs[index].phase.startsWith("P8")) {
        docObj.documentName = allDocs[index].documentName;
        docObj.documentURL = allDocs[index].documentURL;
        docObj.fileDescription = allDocs[index].fileDescription;
        docObj.fileName = allDocs[index].fileName;
        docObj.phase = "P8";
        docList8.push(docObj);
      }
    }
    this.setState({
      projectDocListP1: docList1,
      projectDocListP2: docList2,
      projectDocListP3: docList3,
      projectDocListP4: docList4,
      projectDocListP5: docList5,
      projectDocListP6: docList6,
      projectDocListP7: docList7,
      projectDocListP8: docList8,
    });
  };

  handleKeyDown = (event,name) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      let project= {...this.state.project}
      project[`${name}`]  = null;
      this.setState({
        project,
        saveButtonStatus: true
      })
    }
  };

  /**/
  render() {
    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);
    const headerVersionTable = this.renderVersionHeaderTableTop(t);

    this.stepItems = [
      {
        label: t("practicalCompletion"),
        command: (event) => {},
      },
      {
        label: t("buildingDigitalLogbook"),
        command: (event) => {},
      },
    ];

    const addDocumentDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddDocumentDialog}
        />
        <Button
          style={{ fontSize: "14px", fontStyle: "Manrope" }}
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={this.acceptAddDocument}
        />
      </React.Fragment>
    );

    const addDocumentFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideDocumentDialog}
          />

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb"
            disabled={[...this.state.documents].length > 0 ? false : true}
            onClick={this.handleDocuments}
          />
        </div>
      </>
    );

    const addEventDocumentFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideEditDocumentDialog}
          />

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb"
            disabled={[...this.state.eventDocuments].length > 0 ? false : true}
            onClick={this.handleEventDocuments}
          />
        </div>
      </>
    );

    const addNewVersionFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          {/* <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideNewVersionDialog}
          /> */}

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb-2"
            //disabled={[...this.state.documents].length > 0 ? false : true}
            onClick={this.handleNewVersion}
            disabled={!this.state.saveVersionButton}
          />
        </div>
      </>
    );

    const updateVersionFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          {/* <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideNewVersionDialog}
          /> */}

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb-2"
            //disabled={[...this.state.documents].length > 0 ? false : true}
            onClick={this.handleUpdateVersion}
            disabled={!this.state.saveVersionButton}
          />
        </div>
      </>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <Card
                style={{ height: "3.8em", background: "rgb(230, 240, 247)" }}
              >
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      style={{ fontSize: "18px", fontStyle: "Manrope" }}
                      label={
                        this.state.project.projectName +
                        " (ID: " +
                        this.state.project.pruid +
                        ")"
                      }
                      className="mb-2 custom-chip-home"
                    />
                  </Link>
                </div>
              </Card>

              <br />
              <br />

              <div className={"col-12 "}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      style={{
                        color: "0094A8",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: "lighter",
                      }}
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />
                    <div>
                      <Chip
                        style={{
                          fontStyle: "Manrope",
                          fontWeight: "bolder",
                          fontSize: "14px",
                        }}
                        label={t("phase") + " 7: " + t("practicalCompletion")}
                        icon="pi pi-folder-open"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                      onSelect={(e) => {
                        if (!this.state.saveButtonStatus) {
                          this.setState({ activeIndex: e.index });
                        } else {
                          if (this.state.activeIndex !== e.index) {
                            toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
                          }
                        }
                      }}
                      readOnly={false}
                    />
                  </div>

                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >
                    {/*Phase7_*/}
                    {/*Phase71*/}
                    <TabPanel header={t("practicalCompletion")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p71Text")}</div>
                      </div>

                      <Divider />

                      <div className="grid">
                        {/*lrsAndQSVisit_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("LRSAndQSVisit")}
                          </label>
                        </div>

                        {/*lrsAndQSVisit*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="lrsAndQSVisit"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.lrsAndQSVisit}
                              options={lrsAndQSVisit}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "lrsAndQSVisit")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'lrsAndQSVisit')}
                            />
                          </div>
                        </div>

                        {/*Date of Visit */}
                        {this.state.project.lrsAndQSVisit === "1" && (
                          <>
                            <div className="col-12 md:col-6 lg:col-6">
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "Roboto",
                                }}
                              >
                                {t("dateOfVisit")}
                              </label>
                            </div>

                            <div className="col-12 md:col-6 lg:col-6">
                              <div className="card flex justify-content-center">
                                <Calendar
                                  dateFormat="dd/mm/yy"
                                  id="dateOfVisit"
                                  placeholder={t("pleaseEnter")}
                                  value={this.dateFormatForCalendar(
                                    this?.state?.project?.dateOfVisit
                                  )}
                                  onChange={(e) => {
                                    this.onInputChangeDateVisit(
                                      e,
                                      "dateOfVisit"
                                    );
                                  }}
                                  readOnlyInput={true}
                                  disabled={this.state.nonEditAccess}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {/*contractorCompletesOutstandingWorks_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("contractorCompletesOutstandingWorks")}
                          </label>
                        </div>

                        {/*contractorCompletesOutstandingWorks*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="contractorCompletesOutstandingWorks"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project
                                  .contractorCompletesOutstandingWorks
                              }
                              options={ContractorCompletesOutstandingWorks}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "contractorCompletesOutstandingWorks"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'contractorCompletesOutstandingWorks')}
                            />
                          </div>
                        </div>

                        {/*lrsAndQSFinalVisit_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("LRSAndQSFinalVisit")}
                          </label>
                        </div>

                        {/*lrsAndQSFinalVisit*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="idType"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.lrsAndQSFinalVisit}
                              options={lrsAndQSFinalVisit}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "lrsAndQSFinalVisit")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'lrsAndQSFinalVisit')}
                            />
                          </div>
                        </div>

                        {/*practicalCompletion_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("practicalCompletion")}
                          </label>
                        </div>

                        {/*practicalCompletion*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="practicalCompletion"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.practicalCompletion}
                              options={PracticalCompletion}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "practicalCompletion")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'practicalCompletion')}
                            />
                          </div>
                        </div>

                        {/*dateofPracticalCompletion_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("dateofPracticalCompletion")}
                          </label>
                        </div>

                        {/*dateofPracticalCompletion*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              id="dateofPracticalCompletion"
                              placeholder={t("pleaseEnter")}
                              value={this.dateFormatForCalendar(
                                this?.state?.project?.dateofPracticalCompletion
                              )}
                              onChange={(e) => {
                                this.onInputChangeDateofPracticalCompletionCalendar(
                                  e
                                );
                              }}
                              maxLength={10}
                              readOnlyInput={true}
                              disabled={this.state.nonEditAccess}
                            />
                          </div>
                        </div>

                        {/*finalAccountAgreedWithContractor_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("finalAccountAgreedWithContractor")}
                          </label>
                        </div>

                        {/*finalAccountAgreedWithContractor*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              fontSize="14px"
                              id="finalAccountAgreedWithContractor"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project
                                  .finalAccountAgreedWithContractor
                              }
                              options={FinalAccountAgreedWithContractor}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "finalAccountAgreedWithContractor"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) => this.handleKeyDown(e, 'finalAccountAgreedWithContractor')}
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*periodofDefects*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <h4>{t("periodofDefects")}</h4>
                        </div>

                        {/*periodofDefectsStartDate_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("periodofDefectsStartDate")}
                          </label>
                        </div>

                        {/*periodofDefectsStartDate*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              id="periodofDefectsStartDate"
                              disabled={this.state.nonEditAccess}
                              placeholder={t("pleaseEnter")}
                              value={this.dateFormatForCalendar(
                                this?.state?.project?.periodofDefectsStartDate
                              )}
                              onChange={(e) => {
                                this.onInputChangePeriodofDefectsStartDateCalendar(
                                  e
                                );
                              }}
                              maxLength={10}
                              readOnlyInput={true}
                            />
                          </div>
                        </div>

                        {/*periodofDefectsEndDate_Header*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("periodofDefectsEndDate")}
                          </label>
                        </div>

                        {/*periodofDefectsEndDate*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="card flex justify-content-center">
                            <Calendar
                              dateFormat="dd/mm/yy"
                              disabled={this.state.nonEditAccess}
                              id="periodofDefectsEndDate"
                              placeholder={t("pleaseEnter")}
                              value={this.dateFormatForCalendar(
                                this?.state?.project?.periodofDefectsEndDate
                              )}
                              onChange={(e) => {
                                this.onInputChangePeriodofDefectsEndDateCalendar(
                                  e
                                );
                              }}
                              maxLength={10}
                              readOnlyInput={true}
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocument*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div>

                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p70Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable}
                              paginator
                              rows={10}
                              filters={this.state.filters1}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>

                          {this.state.imageList1.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleria1}
                                  value={this.state.imageList1}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageList1 &&
                                    this.state.imageList1.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleria1.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase72*/}
                    <TabPanel header={t("buildingDigitalLogbook")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p72Text")}</div>
                      </div>

                      <div className="grid">
                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addNewVersion*/}
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddNewVersion")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={(e) => {
                                this.setState({
                                  addNewVersionDialog: true,
                                });
                              }}
                            />
                          )}
                        </div>

                        {/*Version data table*/}
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              //selectionMode="single"
                              value={this.state.passportLogbooks}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noVersionFound")}
                              header={headerVersionTable}
                              paginator
                              rows={10}
                              filterDisplay="menu"
                              loading={this.state.loading}
                            >
                              <Column
                                body={(rowData, rowIndex) =>
                                  this.bdlVersionTemplate(rowData, rowIndex)
                                }
                                header={t("bdlVersion")}
                              ></Column>
                              <Column
                                style={{
                                  textAlign: "center",
                                  width: "18em",
                                  headerStyle: {
                                    position: "absolute",
                                    bottom: 20,
                                  },
                                }}
                                body={this.publishTemplate}
                                header={t("publish")}
                              ></Column>
                              <Column
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.historyTemplate}
                                header={t("history")}
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "right", width: "12em" }}
                                body={this.actionTemplateVersion}
                              />
                            </DataTable>
                          </div>
                        </div>

                        <br />

                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="flex justify-content-between">
                            <div>
                              {(isPlatformManager() || isSupervisor()) && (
                                <Button
                                  className="p-button-mb-yellow"
                                  label={t("createOTPCode")}
                                  onClick={this.createOTPCode}
                                  disabled={
                                    this.state.project.projectStatus === "11"
                                  }
                                />
                              )}
                            </div>
                            <div>
                              <Button
                                visible={false}
                                className="p-button-mb-gray3"
                                label={t("copyLink")}
                              />
                              &nbsp; &nbsp;
                              <Button
                                visible={false}
                                className="p-button-mb-gray3"
                                label={t("printQRCode")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <br />
                <div
                  class="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div class="btn-group" role="group" aria-label="First group">
                    {!isViewerExpert() && (
                      <Button
                        disabled={!this.state.saveButtonStatus}
                        style={{ fontSize: "14px", fontStyle: "Manrope" }}
                        icon="pi pi-check-circle"
                        label={
                          this.state.activeIndex < this.stepItems.length - 1
                            ? t("saveNext")
                            : t("save")
                        }
                        className="p-button-sm p-button-danger"
                        onClick={() => this.stepSaveAndNext()}
                      />
                    )}
                  </div>
                  {this.state.activeIndex === this.stepItems.length - 1 && (
                    <div class="btn-group">
                      <div class="input-group-prepend">
                        <Button
                          style={{
                            fontSize: "14px",
                            fontStyle: "Manrope",
                          }}
                          iconPos="right"
                          icon="pi pi-angle-double-right"
                          label={t("nextPhase")}
                          className="p-button-sm p-button-mb"
                          onClick={() => this.nextPhase()}
                        ></Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <br />

              {/*add logbook document dialog*/}
              <Dialog
                visible={this.state.documentDialogVisible}
                style={{ width: "450px" }}
                header={t("addADocument")}
                className="p-fluid"
                onHide={this.hideAddDocument2}
              >
                <div className="p-float-label">
                  <Button className="button-upload" onClick={this.handleClick1}>
                    {t("selectAFile")}
                  </Button>
                  <input
                    id="fupload"
                    type="file"
                    accept=".xlsx, .xls, .csv, .doc, .docx, .pdf, .jpg, .png, .jpeg, .gif|image/*"
                    style={{ display: "none" }}
                    ref={this.hiddenFileInput}
                    onChange={this.handleChange1}
                  ></input>{" "}
                  {this.state.P71FileName ? (
                    <font color="black">{" " + this.state.P71FileName} </font>
                  ) : (
                    <font color="#0094A8">{t("fileNotSelected")} </font>
                  )}
                </div>
                <div className="p-float-label">
                  <InputText
                    id="documentName"
                    value={this.state.newLogbookDoc.documentGivenName}
                    onChange={(e) =>
                      this.onInputChangeLogbookDoc(e, "documentGivenName")
                    }
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="documentName">{t("documentName")} (*)</label>
                </div>
                <div className="p-float-label">
                  <Dropdown
                    id="section"
                    style={{ fontSize: "14px" }}
                    disabled={this.state.nonEditAccess}
                    value={this.state.newLogbookDoc.section}
                    options={documentSections}
                    placeholder={t("pleaseSelect")}
                    onChange={(e) => this.onInputChangeLogbookDoc(e, "section")}
                    optionLabel="name"
                    optionValue="code"
                    className="w-full"
                  />
                  <label htmlFor="section">{t("section")} (*)</label>
                </div>
                <div className="p-float-label">
                  <InputTextarea
                    id="description"
                    fontSize="14px"
                    readOnly={this.state.nonEditAccess}
                    disabled={this.state.nonEditAccess}
                    value={this.state.newLogbookDoc.description}
                    placeholder={t("pleaseEnter")}
                    onChange={(e) =>
                      this.onInputChangeLogbookDoc(e, "description")
                    }
                    rows={3}
                    cols={20}
                    maxLength={3000}
                    className="w-full"
                  />
                  <label htmlFor="description">{t("description")}</label>
                </div>
                <br /> <br />
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "20%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({
                        P71FileName: "",
                        newLogbookDoc: this.emptyNewDocumentLogbook,
                        documentDialogVisible: false,
                      });
                    }}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    label={t("ADD")}
                    style={{ width: "20%" }}
                    className="p-button-mb"
                    onClick={(e) => {
                      this.handleAddNewLogbookDoc();
                    }}
                  />
                </div>
              </Dialog>

              {/*select from registered equipment dialog*/}
              <Dialog
                visible={this.state.selectFromRegisteredEquipmentDialog}
                style={{ width: "900px" }}
                header={t("selectFromRegisteredEquipment")}
                className="p-fluid"
                onHide={this.hideSelectFromRegisteredEquipmentDialog}
              >
                <div className="card">
                  <DataTable
                    value={this.state.equipments}
                    responsiveLayout="scroll"
                  >
                    <Column
                      field="eqRoomName"
                      header={t("roomName")}
                      sortable
                    ></Column>
                    <Column
                      field="eqDeviceName"
                      header={t("deviceName")}
                      sortable
                    ></Column>
                    <Column
                      field="eqDeviceType"
                      header={t("deviceType")}
                      sortable
                    ></Column>
                    <Column
                      field="eqDeviceEUI"
                      header={t("deviceEUI")}
                      sortable
                    ></Column>
                    <Column
                      field="eqBrandNameModel"
                      header={t("brandNameModel")}
                      sortable
                    ></Column>
                    <Column
                      header={t("action")}
                      style={{ textAlign: "center", width: "8em" }}
                      body={this.actionTemplate5}
                    />
                  </DataTable>
                </div>

                <br />
              </Dialog>

              {/*update equipment dialog*/}
              <Dialog
                visible={this.state.updateEQDialog}
                style={{ width: "450px" }}
                header={t("updateEQ")}
                className="p-fluid"
                onHide={() => {
                  this.setState({ updateEQDialog: false });
                }}
              >
                <div className="p-float-label">
                  <Dropdown
                    id="section"
                    style={{ fontSize: "14px" }}
                    disabled={this.state.nonEditAccess}
                    value={this.state?.selectedEQRowData?.section}
                    // options={this.state.P9EquipmentList}
                    options={indoorSections}
                    placeholder={t("pleaseSelect")}
                    onChange={(e) => this.onInputChangeEQUpdate(e, "section")}
                    optionLabel="name"
                    optionValue="code"
                    className="w-full"
                  />
                  <label htmlFor="section">{t("section")}</label>
                </div>
                <br /> <br />
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "20%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({
                        updateEQDialog: false,
                        selectedEQRowData: null,
                        newEQ: this.emptyEQ,
                        eqAddButtonStatus: false,
                      });
                    }}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    label={t("btnUpdate")}
                    style={{ width: "20%" }}
                    className="p-button-mb"
                    disabled={!this.state.eqAddButtonStatus}
                    onClick={(e) => {
                      this.handleUpdateEQ();
                    }}
                  />
                </div>
              </Dialog>

              {/*select from project documents dialog*/}
              <Dialog
                visible={this.state.selectFromProjectDocumentDialog}
                style={{ width: "850px" }}
                header={t("selectFromProjectDocuments")}
                className="p-fluid"
                onHide={this.hideSelectFromProjectDocumentDialog}
              >
                <div className="accordion-demo">
                  {/* <div className="card"> */}
                  <Accordion>
                    {this.state.projectDocListP1.length > 0 && (
                      <AccordionTab
                        header={
                          <span className="flex align-items-center gap-2 w-full">
                            <span className="font-bold white-space-nowrap">
                              {t("phase1")}{" "}
                            </span>
                            {this.state.badge1 > 0 && (
                              <Badge
                                value={this.state.badge1}
                                className="ml-auto"
                              />
                            )}
                          </span>
                        }
                      >
                        <div>
                          <DataTable
                            className="green-table"
                            value={this.state.projectDocListP1}
                            responsiveLayout="scroll"
                          >
                            <Column
                              header={t("documentName")}
                              body={this.selectedFileNameTemplate}
                            ></Column>

                            <Column
                              style={{ width: "25em" }}
                              header={t("section")}
                              body={this.selectedDocumentSectionTemplate}
                            />
                          </DataTable>
                        </div>
                      </AccordionTab>
                    )}

                    {this.state.projectDocListP2.length > 0 && (
                      <AccordionTab
                        header={
                          <span className="flex align-items-center gap-2 w-full">
                            <span className="font-bold white-space-nowrap">
                              {t("phase2")}{" "}
                            </span>
                            {this.state.badge2 > 0 && (
                              <Badge
                                value={this.state.badge2}
                                className="ml-auto"
                              />
                            )}
                          </span>
                        }
                      >
                        <div className="card">
                          <DataTable
                            className="green-table"
                            value={this.state.projectDocListP2}
                            responsiveLayout="scroll"
                          >
                            <Column
                              body={this.selectedFileNameTemplate}
                              header={t("documentName")}
                            ></Column>

                            <Column
                              style={{ width: "25em" }}
                              header={t("section")}
                              body={this.selectedDocumentSectionTemplate}
                            />
                          </DataTable>
                        </div>
                      </AccordionTab>
                    )}

                    {this.state.projectDocListP3.length > 0 && (
                      <AccordionTab
                        header={
                          <span className="flex align-items-center gap-2 w-full">
                            <span className="font-bold white-space-nowrap">
                              {t("phase3")}{" "}
                            </span>
                            {this.state.badge3 > 0 && (
                              <Badge
                                value={this.state.badge3}
                                className="ml-auto"
                              />
                            )}
                          </span>
                        }
                      >
                        <div className="card">
                          <DataTable
                            DataTable
                            className="green-table"
                            value={this.state.projectDocListP3}
                            responsiveLayout="scroll"
                          >
                            <Column
                              body={this.selectedFileNameTemplate}
                              header={t("documentName")}
                            ></Column>

                            <Column
                              style={{ width: "25em" }}
                              header={t("section")}
                              body={this.selectedDocumentSectionTemplate}
                            />
                          </DataTable>
                        </div>
                      </AccordionTab>
                    )}

                    {this.state.projectDocListP4.length > 0 && (
                      <AccordionTab
                        header={
                          <span className="flex align-items-center gap-2 w-full">
                            <span className="font-bold white-space-nowrap">
                              {t("phase4")}{" "}
                            </span>
                            {this.state.badge4 > 0 && (
                              <Badge
                                value={this.state.badge4}
                                className="ml-auto"
                              />
                            )}
                          </span>
                        }
                      >
                        <div className="card">
                          <DataTable
                            className="green-table"
                            value={this.state.projectDocListP4}
                            responsiveLayout="scroll"
                          >
                            <Column
                              body={this.selectedFileNameTemplate}
                              header={t("documentName")}
                            ></Column>

                            <Column
                              style={{ width: "25em" }}
                              header={t("section")}
                              body={this.selectedDocumentSectionTemplate}
                            />
                          </DataTable>
                        </div>
                      </AccordionTab>
                    )}

                    {this.state.projectDocListP5.length > 0 && (
                      <AccordionTab
                        header={
                          <span className="flex align-items-center gap-2 w-full">
                            <span className="font-bold white-space-nowrap">
                              {t("phase5")}{" "}
                            </span>
                            {this.state.badge5 > 0 && (
                              <Badge
                                value={this.state.badge5}
                                className="ml-auto"
                              />
                            )}
                          </span>
                        }
                      >
                        <div className="card">
                          <DataTable
                            className="green-table"
                            value={this.state.projectDocListP5}
                            responsiveLayout="scroll"
                          >
                            <Column
                              body={this.selectedFileNameTemplate}
                              header={t("documentName")}
                            ></Column>

                            <Column
                              style={{ width: "25em" }}
                              header={t("section")}
                              body={this.selectedDocumentSectionTemplate}
                            />
                          </DataTable>
                        </div>
                      </AccordionTab>
                    )}

                    {this.state.projectDocListP6.length > 0 && (
                      <AccordionTab
                        header={
                          <span className="flex align-items-center gap-2 w-full">
                            <span className="font-bold white-space-nowrap">
                              {t("phase6")}{" "}
                            </span>
                            {this.state.badge6 > 0 && (
                              <Badge
                                value={this.state.badge6}
                                className="ml-auto"
                              />
                            )}
                          </span>
                        }
                      >
                        <div className="card">
                          <DataTable
                            className="green-table"
                            value={this.state.projectDocListP6}
                            responsiveLayout="scroll"
                          >
                            <Column
                              body={this.selectedFileNameTemplate}
                              header={t("documentName")}
                            ></Column>

                            <Column
                              style={{ width: "25em" }}
                              header={t("section")}
                              body={this.selectedDocumentSectionTemplate}
                            />
                          </DataTable>
                        </div>
                      </AccordionTab>
                    )}

                    {this.state.projectDocListP7.length > 0 && (
                      <AccordionTab
                        header={
                          <span className="flex align-items-center gap-2 w-full">
                            <span className="font-bold white-space-nowrap">
                              {t("phase7")}{" "}
                            </span>
                            {this.state.badge7 > 0 && (
                              <Badge
                                value={this.state.badge7}
                                className="ml-auto"
                              />
                            )}
                          </span>
                        }
                      >
                        <div className="card">
                          <DataTable
                            className="green-table"
                            value={this.state.projectDocListP7}
                            responsiveLayout="scroll"
                          >
                            <Column
                              body={this.selectedFileNameTemplate}
                              header={t("documentName")}
                            ></Column>

                            <Column
                              style={{ width: "25em" }}
                              header={t("section")}
                              body={this.selectedDocumentSectionTemplate}
                            />
                          </DataTable>
                        </div>
                      </AccordionTab>
                    )}

                    {this.state.projectDocListP8.length > 0 && (
                      <AccordionTab
                        header={
                          <span className="flex align-items-center gap-2 w-full">
                            <span className="font-bold white-space-nowrap">
                              {t("phase8")}{" "}
                            </span>
                            {this.state.badge8 > 0 && (
                              <Badge
                                value={this.state.badge8}
                                className="ml-auto"
                              />
                            )}
                          </span>
                        }
                      >
                        <div className="card">
                          <DataTable
                            className="green-table"
                            value={this.state.projectDocListP8}
                            responsiveLayout="scroll"
                          >
                            <Column
                              body={this.selectedFileNameTemplate}
                              header={t("documentName")}
                            ></Column>

                            <Column
                              style={{ width: "25em" }}
                              header={t("section")}
                              body={this.selectedDocumentSectionTemplate}
                            />
                          </DataTable>
                        </div>
                      </AccordionTab>
                    )}
                  </Accordion>
                </div>
                <br /> <br />
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "20%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({
                        selectFromProjectDocumentDialog: false,
                        selectedProjectDocRowData: null,
                        badge1: 0,
                        badge2: 0,
                        badge3: 0,
                        badge4: 0,
                        badge5: 0,
                        badge6: 0,
                        badge7: 0,
                        badge8: 0,
                      });
                      this.checkedClear();
                    }}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    label={t("ADD")}
                    style={{ width: "20%" }}
                    className="p-button-mb"
                    disabled={!this.state.addButtonStatus}
                    onClick={(e) => {
                      this.saveSelectedDocFiles();
                    }}
                  />
                </div>
              </Dialog>

              {/*select equipment dialog*/}
              <Dialog
                visible={this.state.selectEQDialog}
                style={{ width: "850px" }}
                header={t("selectEquipment")}
                className="p-fluid"
                onHide={this.hideSelectEQ}
              ></Dialog>

              {/*select proeject document dialog*/}
              <Dialog
                visible={this.state.selectprojectDocDialog}
                style={{ width: "850px" }}
                header={t("selectADocument")}
                className="p-fluid"
                //footer={addDialogFooter}
                onHide={() => {
                  this.setState({ selectprojectDocDialog: false });
                }}
              >
                <div className="card">
                  <DataTable
                    value={this.state.projectDocList}
                    responsiveLayout="scroll"
                  >
                    <Column
                      field="documentName"
                      header={t("documentName")}
                      sortable
                    ></Column>
                    <Column
                      field="desctiption"
                      header={t("desctiption")}
                      sortable
                    ></Column>
                    <Column
                      field="documentDate"
                      header={t("documentDate")}
                      sortable
                    ></Column>
                    <Column field="phase" header={t("phase")} sortable></Column>
                    <Column
                      header={t("action")}
                      style={{ textAlign: "center", width: "8em" }}
                      body={this.actionTemplate6}
                    />
                  </DataTable>
                </div>
              </Dialog>

              {/*add new logbook dialog*/}
              <Dialog
                visible={this.state.addNewVersionDialog}
                style={{ width: "1000px" }}
                header={t("addNewVersion")}
                className="p-fluid"
                footer={addNewVersionFooter}
                onHide={this.hideNewVersionDialog}
              >
                <br />
                <br />
                <div className="grid">
                  {/*add images haeader*/}
                  <div className="col-12">
                    <label
                      style={{
                        fontSize: "16px",
                        fontStyle: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      {t("addImages")}
                    </label>
                  </div>

                  {/*upload image */}
                  <div className="col-12" style={{ width: "15%" }}>
                    <label
                      htmlFor="fileInput"
                      className="custom-file-upload2 mb-2"
                    >
                      <div>
                        <i
                          className="pi pi-plus-circle"
                          style={{ fontSize: "24px", floodColor: "#D9D9D9" }}
                        ></i>
                      </div>
                      <div className="select-image-label">
                        <label
                          style={{
                            fontFamily: "Manrope",
                            fontSize: "14px",
                            color: "#79747E",
                            fontWeight: "bold",
                          }}
                        >
                          {t("selectImages")}
                        </label>
                      </div>
                    </label>

                    <input
                      type="file"
                      accept="image/*"
                      id="fileInput"
                      multiple
                      onChange={this.handleImageChange}
                      style={{ display: "none" }}
                    />

                    {this.state.images.length > 0 && (
                      <>
                        <div
                          className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                          style={{ width: "950px" }}
                        >
                          <Galleria
                            ref={(el) => (this.galleria12 = el)}
                            value={this.state.images}
                            numVisible={7}
                            style={{ maxWidth: "850px" }}
                            //responsiveOptions={this.responsiveOptions}
                            activeIndex={this.state.uploadGalleriaIndex}
                            onItemChange={(e) =>
                              this.setState({ uploadGalleriaIndex: e.index })
                            }
                            circular
                            fullScreen
                            showItemNavigators
                            showThumbnails={false}
                            item={this.itemTemplate2}
                            thumbnail={this.thumbnailTemplate2}
                            //itemStyle={{ maxWidth: "100%" }}
                          />

                          <div className="grid">
                            {this.state.images &&
                              this.state.images.map((image, index) => {
                                let imgEl = (
                                  <div>
                                    <Image
                                      src={image.documentURL}
                                      zoomSrc={image.documentURL}
                                      alt={image.fileName}
                                      width="80px"
                                      height="80px"
                                      //preview
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.setState(
                                          { uploadGalleriaIndex: index },
                                          () => this.galleria12.show()
                                        );
                                      }}
                                    />
                                    <Button
                                      className="p-button-rounded p-button-secondary p-button-outlined"
                                      style={{ height: "20px", width: "20px" }}
                                      icon="pi pi-minus"
                                      onClick={() =>
                                        this.handleImageDelete(index)
                                      }
                                    />
                                  </div>
                                );

                                return (
                                  <div className="col-2" key={index}>
                                    {imgEl}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/*homeowner's pack*/}
                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        fontStyle: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      {t("homeownersPack")}
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("contracts&LegalDocuments")}:
                      </label>

                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      {/* contracts&LegalDocuments inputSwitch */}
                      <InputSwitch
                        checked={this.state.checkedContractsAndLegal}
                        onChange={(e) =>
                          this.setState({
                            checkedContractsAndLegal: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("technical&FinancialDocuments")}:
                      </label>

                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      {/* technical&FinancialDocuments inputSwitch */}

                      <InputSwitch
                        checked={this.state.checkedTechnicalAndFinancial}
                        onChange={(e) =>
                          this.setState({
                            checkedTechnicalAndFinancial: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("guarantees&Warranties")}:
                      </label>

                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      {/* guarantees&Warranties inputSwitch */}

                      <InputSwitch
                        checked={this.state.checkedGuaranteesAndWarranties}
                        onChange={(e) =>
                          this.setState({
                            checkedGuaranteesAndWarranties: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  {/*add document && select from project document*/}
                  <div className="col-12">
                    {!isViewerExpert() && (
                      <>
                        <Button
                          style={{
                            color: "0094A8",
                            fontSize: "12px",
                            fontStyle: "Manrope",
                            width: "20%",
                          }}
                          disabled={this.state.nonEditAccess}
                          label={t("btnAddDocument")}
                          className="p-button p-component p-button-outlined p-button-sm"
                          onClick={this.handleAddDocument2}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          style={{
                            color: "0094A8",
                            fontSize: "12px",
                            fontStyle: "Manrope",
                            width: "30%",
                          }}
                          disabled={this.state.nonEditAccess}
                          label={t("btnSelectFromProjectDocuments")}
                          className="p-button p-component p-button-outlined p-button-sm"
                          onClick={this.handleSelectFromProjectDocumentDialog}
                        />
                      </>
                    )}
                  </div>

                  {/*homeowner's pack datatable*/}
                  <div className="col-12">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                      <DataTable
                        name="dt"
                        size="small"
                        showGridlines
                        selectionMode="single"
                        value={this.state.p71Documents}
                        dataKey="id"
                        stripedRows
                        responsiveLayout="scroll"
                        emptyMessage={t("noDocumentFound")}
                        //header={headerTable}
                        paginator
                        rows={10}
                        filters={this.state.filters1}
                        filterDisplay="menu"
                        loading={this.state.loading}
                        globalFilterFields={[
                          "documentName",
                          "description",
                          "section",
                        ]}
                      >
                        <Column
                          //field="documentName"
                          //field="documentGivenName"
                          body={this.docNameTemp}
                          header={t("documentName")}
                          sortable
                        ></Column>

                        <Column
                          //field="section"
                          body={this.sectionDataTableTemplate}
                          header={t("section")}
                          sortable
                        ></Column>

                        <Column
                          field="description"
                          header={t("fileDescription")}
                          sortable
                        ></Column>

                        <Column
                          header={t("action")}
                          style={{ textAlign: "center", width: "12em" }}
                          body={this.actionTemplate2}
                        />
                      </DataTable>
                    </div>
                  </div>

                  {/*dividerLine*/}
                  <div className="col-12">
                    <hr />
                  </div>

                  {/*smart home sensor and meters*/}
                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        fontStyle: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      {t("smartHomeSensorsAndMeters")}
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      <InputSwitch
                        checked={this.state.checkedEQ}
                        onChange={(e) =>
                          this.setState({
                            checkedEQ: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  {/*select from registered equipment*/}
                  <div div className="col-12">
                    {!isViewerExpert() && (
                      <Button
                        style={{
                          color: "0094A8",
                          fontSize: "12px",
                          fontStyle: "Manrope",
                          width: "30%",
                        }}
                        disabled={this.state.nonEditAccess}
                        label={t("btnSelectFromRegisteredEquipment")}
                        className="p-button p-component p-button-outlined p-button-sm"
                        onClick={this.handleSelectRegisteredEquipmentDialog}
                      />
                    )}
                  </div>

                  {/*smart home sensor and meters datatable*/}
                  <div className="col-12">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                      <DataTable
                        name="dt"
                        size="small"
                        showGridlines
                        selectionMode="single"
                        value={this.state.equipmentsList}
                        dataKey="id"
                        stripedRows
                        responsiveLayout="scroll"
                        emptyMessage={t("noEquipmentFound")}
                        //header={headerTable}
                        paginator
                        rows={10}
                        filters={this.state.filters1}
                        filterDisplay="menu"
                        loading={this.state.loading}
                        globalFilterFields={[
                          "eqRoomName",
                          "eqDeviceName",
                          "eqDeviceType",
                          //"section",
                        ]}
                      >
                        <Column
                          field="eqRoomName"
                          header={t("roomName")}
                          sortable
                        ></Column>

                        <Column
                          field="eqDeviceType"
                          header={t("deviceType")}
                          sortable
                        ></Column>
                        {/* <Column
                          body={this.eqSectionTemplate}
                          header={t("section")}
                          sortable
                        ></Column> */}

                        <Column
                          header={t("action")}
                          style={{ textAlign: "center", width: "8em" }}
                          body={this.actionTemplate3}
                        />
                      </DataTable>
                    </div>
                  </div>

                  {/*dividerLine*/}
                  <div className="col-12">
                    <hr />
                  </div>

                  {/*Renovations & repairs*/}
                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        fontStyle: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      {t("renovations&Repairs")}
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      <InputSwitch
                        checked={this.state.checkedRenovationRepairs}
                        onChange={(e) =>
                          this.setState({
                            checkedRenovationRepairs: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  {/*Renovations & repairs*/}
                  <div className="col-12">
                    {!isViewerExpert() && (
                      <Button
                        style={{
                          color: "0094A8",
                          fontSize: "12px",
                          fontStyle: "Manrope",
                          width: "30%",
                        }}
                        disabled={this.state.nonEditAccess}
                        label={t("btnAddNewEvent")}
                        className="p-button p-component p-button-outlined p-button-sm"
                        onClick={this.handleAddNewEventDialog}
                      />
                    )}
                  </div>

                  {/*Renovations & repairs datatable*/}
                  <div className="col-12">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                      <DataTable
                        name="dt"
                        size="small"
                        showGridlines
                        selectionMode="single"
                        value={this.state?.eventsList}
                        dataKey="id"
                        stripedRows
                        responsiveLayout="scroll"
                        emptyMessage={t("noEventFound")}
                        //header={headerTable}
                        paginator
                        rows={10}
                        filters={this.state.filters1}
                        filterDisplay="menu"
                        loading={this.state.loading}
                        globalFilterFields={[
                          "type",
                          "scope",
                          "description",
                          "date",
                        ]}
                      >
                        <Column
                          body={this.eventTypeTemplate}
                          header={t("type")}
                          sortable
                        ></Column>
                        <Column
                          body={this.eventScopeTemplate}
                          header={t("scope")}
                          sortable
                        ></Column>
                        <Column
                          field="description"
                          header={t("description")}
                          sortable
                        ></Column>

                        <Column
                          body={this.eventCalendarTemp}
                          header={t("date")}
                          sortable
                        ></Column>

                        <Column
                          header={t("action")}
                          style={{ textAlign: "center", width: "12em" }}
                          body={this.actionTemplateEvent}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              </Dialog>

              {/*update logbook dialog*/}
              <Dialog
                visible={this.state.updateVersionDialog}
                style={{ width: "1000px" }}
                header={t("updateVersion")}
                className="p-fluid"
                footer={updateVersionFooter}
                onHide={() => {
                  this.setState({
                    updateVersionDialog: false,
                    p71Documents: [],
                    images: [],
                    sec1Documents: [],
                    sec2Documents: [],
                    sec3Documents: [],
                    sec4Documents: [],
                    passportData: this.emptyNewpassportData,
                    eventsList: [],
                    equipmentList: [],
                    equipmentsList: [],
                    badge1: 0,
                    badge2: 0,
                    badge3: 0,
                    badge4: 0,
                    badge5: 0,
                    badge6: 0,
                    badge7: 0,
                    badge8: 0,
                    equipments: this.state.projectEQList,
                  });
                  this.componentDidMount();
                }}
              >
                <br />
                <br />
                <div className="grid">
                  {/*add images haeader*/}
                  <div className="col-12">
                    <label
                      style={{
                        fontSize: "16px",
                        fontStyle: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      {t("addImages")}
                    </label>
                  </div>

                  {/*upload image */}
                  <div className="col-12" style={{ width: "15%" }}>
                    <label
                      htmlFor="fileInput"
                      className="custom-file-upload2 mb-2"
                    >
                      <div>
                        <i
                          className="pi pi-plus-circle"
                          style={{ fontSize: "24px", floodColor: "#D9D9D9" }}
                        ></i>
                      </div>
                      <div className="select-image-label">
                        <label
                          style={{
                            fontFamily: "Manrope",
                            fontSize: "14px",
                            color: "#79747E",
                            fontWeight: "bold",
                          }}
                        >
                          {t("selectImages")}
                        </label>
                      </div>
                    </label>

                    <input
                      type="file"
                      accept="image/*"
                      id="fileInput"
                      multiple
                      onChange={this.handleImageChange}
                      style={{ display: "none" }}
                    />

                    {this.state.images.length > 0 && (
                      <>
                        <div
                          className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                          style={{ width: "950px" }}
                        >
                          <Galleria
                            ref={(el) => (this.galleria13 = el)}
                            value={this.state.images}
                            numVisible={7}
                            style={{ maxWidth: "850px" }}
                            //responsiveOptions={this.responsiveOptions}
                            activeIndex={this.state.uploadGalleriaIndex}
                            onItemChange={(e) =>
                              this.setState({ uploadGalleriaIndex: e.index })
                            }
                            circular
                            fullScreen
                            showItemNavigators
                            showThumbnails={false}
                            item={this.itemTemplate2}
                            thumbnail={this.thumbnailTemplate2}
                          />

                          <div className="grid">
                            {this.state.images &&
                              this.state.images.map((image, index) => {
                                let imgEl = (
                                  <div>
                                    <Image
                                      src={image?.documentURL}
                                      zoomSrc={image?.documentURL}
                                      alt={image?.fileName}
                                      width="80px"
                                      height="80px"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        this.setState(
                                          { uploadGalleriaIndex: index },
                                          () => this.galleria13.show()
                                        );
                                      }}
                                    />
                                    <Button
                                      className="p-button-rounded p-button-secondary p-button-outlined"
                                      style={{ height: "20px", width: "20px" }}
                                      icon="pi pi-minus"
                                      onClick={() =>
                                        this.handleImageDelete(index)
                                      }
                                    />
                                  </div>
                                );
                                return (
                                  <div className="col-2" key={index}>
                                    {imgEl}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/*homeowner's pack*/}
                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        fontStyle: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      {t("homeownersPack")}
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("contracts&LegalDocuments")}:
                      </label>

                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      {/* contracts&LegalDocuments inputSwitch */}
                      <InputSwitch
                        checked={this.state.checkedContractsAndLegal}
                        onChange={(e) =>
                          this.setState({
                            checkedContractsAndLegal: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("technical&FinancialDocuments")}:
                      </label>

                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      {/* technical&FinancialDocuments inputSwitch */}

                      <InputSwitch
                        checked={this.state.checkedTechnicalAndFinancial}
                        onChange={(e) =>
                          this.setState({
                            checkedTechnicalAndFinancial: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("guarantees&Warranties")}:
                      </label>

                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      {/* guarantees&Warranties inputSwitch */}

                      <InputSwitch
                        checked={this.state.checkedGuaranteesAndWarranties}
                        onChange={(e) =>
                          this.setState({
                            checkedGuaranteesAndWarranties: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  {/*add document && select from project document*/}
                  <div className="col-12">
                    {!isViewerExpert() && (
                      <>
                        <Button
                          style={{
                            color: "0094A8",
                            fontSize: "12px",
                            fontStyle: "Manrope",
                            width: "20%",
                          }}
                          disabled={this.state.nonEditAccess}
                          label={t("btnAddDocument")}
                          className="p-button p-component p-button-outlined p-button-sm"
                          onClick={this.handleAddDocument2}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          style={{
                            color: "0094A8",
                            fontSize: "12px",
                            fontStyle: "Manrope",
                            width: "30%",
                          }}
                          disabled={this.state.nonEditAccess}
                          label={t("btnSelectFromProjectDocuments")}
                          className="p-button p-component p-button-outlined p-button-sm"
                          onClick={this.handleSelectFromProjectDocumentDialog}
                        />
                      </>
                    )}
                  </div>

                  {/*homeowner's pack datatable*/}
                  <div className="col-12">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                      <DataTable
                        name="dt"
                        size="small"
                        showGridlines
                        selectionMode="single"
                        value={this.state.p71Documents}
                        dataKey="id"
                        stripedRows
                        responsiveLayout="scroll"
                        emptyMessage={t("noDocumentFound")}
                        //header={headerTable}
                        paginator
                        rows={10}
                        filters={this.state.filters1}
                        filterDisplay="menu"
                        loading={this.state.loading}
                        globalFilterFields={[
                          "documentName",
                          "description",
                          "section",
                        ]}
                      >
                        <Column
                          body={this.docNameTemp}
                          header={t("documentName")}
                          sortable
                        ></Column>

                        <Column
                          //field="section"
                          body={this.sectionDataTableTemplate}
                          header={t("section")}
                          sortable
                        ></Column>

                        <Column
                          field="description"
                          header={t("fileDescription")}
                          sortable
                        ></Column>

                        <Column
                          header={t("action")}
                          style={{ textAlign: "center", width: "12em" }}
                          body={this.actionTemplate2}
                        />
                      </DataTable>
                    </div>
                  </div>

                  {/*dividerLine*/}
                  <div className="col-12">
                    <hr />
                  </div>

                  {/*smart home sensor and meters*/}
                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        fontStyle: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      {t("smartHomeSensorsAndMeters")}
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      <InputSwitch
                        checked={this.state.checkedEQ}
                        onChange={(e) =>
                          this.setState({
                            checkedEQ: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  {/*select from registered equipment*/}
                  <div div className="col-12">
                    {!isViewerExpert() && (
                      <Button
                        style={{
                          color: "0094A8",
                          fontSize: "12px",
                          fontStyle: "Manrope",
                          width: "30%",
                        }}
                        disabled={this.state.nonEditAccess}
                        label={t("btnSelectFromRegisteredEquipment")}
                        className="p-button p-component p-button-outlined p-button-sm"
                        onClick={this.handleSelectRegisteredEquipmentDialog}
                      />
                    )}
                  </div>

                  {/*smart home sensor and meters datatable*/}
                  <div className="col-12">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                      <DataTable
                        name="dt"
                        size="small"
                        showGridlines
                        selectionMode="single"
                        value={this.state.equipmentsList}
                        dataKey="id"
                        stripedRows
                        responsiveLayout="scroll"
                        emptyMessage={t("noEquipmentFound")}
                        //header={headerTable}
                        paginator
                        rows={10}
                        filters={this.state.filters1}
                        filterDisplay="menu"
                        loading={this.state.loading}
                        globalFilterFields={[
                          "eqRoomName",
                          "eqDeviceName",
                          "eqDeviceType",
                          //"section",
                        ]}
                      >
                        <Column
                          field="eqRoomName"
                          header={t("roomName")}
                          sortable
                        ></Column>

                        <Column
                          field="eqDeviceType"
                          header={t("deviceType")}
                          sortable
                        ></Column>
                        {/* <Column
                          body={this.eqSectionTemplate}
                          header={t("section")}
                          sortable
                        ></Column> */}

                        <Column
                          header={t("action")}
                          style={{ textAlign: "center", width: "8em" }}
                          body={this.actionTemplate3}
                        />
                      </DataTable>
                    </div>
                  </div>

                  {/*dividerLine*/}
                  <div className="col-12">
                    <hr />
                  </div>

                  {/*Renovations & repairs*/}
                  <div
                    className="col-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        fontStyle: "Roboto",
                        fontWeight: "bold",
                      }}
                    >
                      {t("renovations&Repairs")}
                    </label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        {t("public")}
                      </label>
                      <InputSwitch
                        checked={this.state.checkedRenovationRepairs}
                        onChange={(e) =>
                          this.setState({
                            checkedRenovationRepairs: e.value,
                            saveVersionButton: true,
                          })
                        }
                      />
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        {t("private")}
                      </label>
                    </div>
                  </div>

                  {/*ADD new event*/}
                  <div className="col-12">
                    {!isViewerExpert() && (
                      <Button
                        style={{
                          color: "0094A8",
                          fontSize: "12px",
                          fontStyle: "Manrope",
                          width: "30%",
                        }}
                        disabled={this.state.nonEditAccess}
                        label={t("btnAddNewEvent")}
                        className="p-button p-component p-button-outlined p-button-sm"
                        onClick={this.handleAddNewEventDialog}
                      />
                    )}
                  </div>

                  {/*Renovations & repairs datatable*/}
                  <div className="col-12">
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                      <DataTable
                        name="dt"
                        size="small"
                        showGridlines
                        selectionMode="single"
                        value={this.state.eventsList}
                        dataKey="id"
                        stripedRows
                        responsiveLayout="scroll"
                        emptyMessage={t("noEventFound")}
                        //header={headerTable}
                        paginator
                        rows={10}
                        filters={this.state.filters1}
                        filterDisplay="menu"
                        loading={this.state.loading}
                        globalFilterFields={[
                          "type",
                          "scope",
                          "description",
                          "date",
                        ]}
                      >
                        <Column
                          body={this.eventTypeTemplate}
                          header={t("type")}
                          sortable
                        ></Column>
                        <Column
                          body={this.eventScopeTemplate}
                          header={t("scope")}
                          sortable
                        ></Column>
                        <Column
                          field="description"
                          header={t("description")}
                          sortable
                        ></Column>

                        <Column
                          body={this.eventCalendarTemp}
                          header={t("date")}
                          sortable
                        ></Column>

                        <Column
                          header={t("action")}
                          style={{ textAlign: "center", width: "12em" }}
                          body={this.actionTemplateEvent}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              </Dialog>

              {/*add new event dialog*/}
              <Dialog
                visible={this.state.addNewEventDialog}
                style={{ width: "750px" }}
                header={t("addNewEvent")}
                className="p-fluid"
                //footer={addDialogFooter}
                onHide={this.hideNewEventDialog}
              >
                {/*add event document dialog */}
                <div className="p-float-label">
                  <Button
                    style={{ width: "30%" }}
                    disabled={this.state.nonEditAccess}
                    label={t("addDocument")}
                    className="p-button-mb"
                    onClick={(e) => {
                      this.setState({
                        editDocumentDialogVisible: true,
                      });
                    }}
                  />
                </div>
                {/*event doc table*/}
                <div className="p-float-label">
                  <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                    <DataTable
                      name="dt"
                      size="small"
                      showGridlines
                      selectionMode="single"
                      value={this.state.newEvent.documents}
                      dataKey="id"
                      stripedRows
                      responsiveLayout="scroll"
                      emptyMessage={t("noDocumentFound")}
                      paginator
                      rows={10}
                      filterDisplay="menu"
                      loading={this.state.loading}
                      globalFilterFields={["fileName", "fileDescription"]}
                    >
                      <Column
                        field="fileName"
                        header={t("documentName")}
                      ></Column>
                      <Column
                        field="fileDescription"
                        header={t("fileDescription")}
                      ></Column>
                      <Column
                        header={t("action")}
                        style={{ textAlign: "center", width: "8em" }}
                        body={this.actionTemplate4}
                      />
                    </DataTable>
                  </div>
                </div>
                {/*type*/}
                <div className="p-float-label">
                  <Dropdown
                    id="section"
                    style={{ fontSize: "14px" }}
                    disabled={this.state.nonEditAccess}
                    value={this.state.newEvent.type}
                    options={types}
                    placeholder={t("pleaseSelect")}
                    onChange={(e) => this.onEventChange(e, "type")}
                    optionLabel="name"
                    optionValue="code"
                    className="w-full"
                  />
                  <label htmlFor="type">
                    {t("type")}
                    {" (*)"}
                  </label>
                </div>
                {/*section*/}
                <div className="p-float-label">
                  <Dropdown
                    id="section"
                    style={{ fontSize: "14px" }}
                    disabled={this.state.nonEditAccess}
                    value={this.state.newEvent.scope}
                    options={sections}
                    placeholder={t("pleaseSelect")}
                    onChange={(e) => this.onEventChange(e, "scope")}
                    optionLabel="name"
                    optionValue="code"
                    className="w-full"
                  />
                  <label htmlFor="scope">
                    {t("scope")}
                    {" (*)"}
                  </label>
                </div>
                {/*description*/}
                <div className="p-float-label">
                  <InputTextarea
                    id="description"
                    fontSize="14px"
                    readOnly={this.state.nonEditAccess}
                    disabled={this.state.nonEditAccess}
                    value={this.state.newEvent.description}
                    placeholder={t("pleaseEnter")}
                    onChange={(e) => this.onEventChange(e, "description")}
                    rows={3}
                    cols={20}
                    maxLength={3000}
                    className="w-full"
                  />
                  <label htmlFor="description">
                    {t("description")}
                    {" (*)"}{" "}
                  </label>
                </div>
                {/*date*/}
                <div className="p-float-label">
                  <Calendar
                    dateFormat="dd/mm/yy"
                    id="dateofPracticalCompletion"
                    //placeholder={t("pleaseEnter")}
                    //  value={this.dateFormatForCalendar(
                    //     this?.state?.project?.dateofPracticalCompletion
                    //     )}
                    value={this.dateFormatForCalendar(
                      this.state.newEvent.eventDate
                    )}
                    onChange={(e) => {
                      this.onInputChangeEventCalendar(e);
                    }}
                    maxLength={10}
                    readOnlyInput={true}
                    disabled={this.state.nonEditAccess}
                  />
                  <label htmlFor="dateLabel">
                    {t("dateLabel")}
                    {" (*)"}
                  </label>
                </div>
                <br /> <br />
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "20%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({
                        addNewEventDialog: false,
                      });
                    }}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    label={t("SAVE")}
                    style={{ width: "20%" }}
                    className="p-button-mb"
                    disabled={!this.state.saveEventButtonStatus}
                    onClick={(e) => {
                      this.handleEvents();
                    }}
                  />
                </div>
              </Dialog>

              {/* update event dialog*/}
              <Dialog
                visible={this.state.updateEventDialog}
                style={{ width: "500px" }}
                header={t("updateEvent")}
                className="p-fluid"
                //footer={addDialogFooter}
                onHide={() => {
                  this.setState({ updateEventDialog: false });
                }}
              >
                {/*type*/}
                <div className="p-float-label">
                  <Dropdown
                    id="section"
                    style={{ fontSize: "14px" }}
                    disabled={this.state.nonEditAccess}
                    value={this.state.selectedEvent?.type}
                    options={types}
                    placeholder={t("pleaseSelect")}
                    onChange={(e) => this.onEventUpdateChange(e, "type")}
                    optionLabel="name"
                    optionValue="code"
                    className="w-full"
                  />
                  <label htmlFor="type">
                    {t("type")}
                    {" (*)"}
                  </label>
                </div>
                {/*section*/}
                <div className="p-float-label">
                  <Dropdown
                    id="section"
                    style={{ fontSize: "14px" }}
                    disabled={this.state.nonEditAccess}
                    value={this.state.selectedEvent?.scope}
                    options={sections}
                    placeholder={t("pleaseSelect")}
                    onChange={(e) => this.onEventUpdateChange(e, "scope")}
                    optionLabel="name"
                    optionValue="code"
                    className="w-full"
                  />
                  <label htmlFor="scope">
                    {t("scope")}
                    {" (*)"}
                  </label>
                </div>
                {/*description*/}
                <div className="p-float-label">
                  <InputTextarea
                    id="description"
                    fontSize="14px"
                    readOnly={this.state.nonEditAccess}
                    disabled={this.state.nonEditAccess}
                    value={this.state.selectedEvent?.description}
                    placeholder={t("pleaseEnter")}
                    onChange={(e) => this.onEventUpdateChange(e, "description")}
                    rows={3}
                    cols={20}
                    maxLength={3000}
                    className="w-full"
                  />
                  <label htmlFor="description">
                    {t("description")}
                    {" (*)"}{" "}
                  </label>
                </div>
                {/*date*/}
                <div className="p-float-label">
                  <Calendar
                    dateFormat="dd/mm/yy"
                    id="dateofPracticalCompletion"
                    value={this.dateFormatForCalendar(
                      this.state?.selectedEvent?.eventDate
                    )}
                    onChange={(e) => {
                      this.onInputChangeEventUpdateCalendar(e);
                    }}
                    maxLength={10}
                    readOnlyInput={true}
                    disabled={this.state.nonEditAccess}
                  />
                  <label htmlFor="dateLabel">
                    {t("dateLabel")}
                    {" (*)"}
                  </label>
                </div>
                <br /> <br />
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "20%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({
                        addNewEventDialog: false,
                      });
                    }}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    label={t("SAVE")}
                    style={{ width: "20%" }}
                    className="p-button-mb"
                    disabled={!this.state.saveEventButtonStatus}
                    onClick={(e) => {
                      this.handleUpdateEvent();
                    }}
                  />
                </div>
              </Dialog>

              {/*publish DBL dialog*/}
              <Dialog
                visible={this.state.publishDBLDialog}
                style={{ width: "500px" }}
                header={t("publishDBL")}
                className="p-fluid"
                //footer={addDialogFooter}
                onHide={this.hidePublishDBLDialog}
              >
                {/*text*/}
                <div className="col-12">
                  <label
                    htmlFor="text1"
                    style={{ fontFamily: "Manrope", fontWeight: "bolder" }}
                  >
                    {t("publishText1")}
                  </label>
                  <br />
                </div>
                <br /> <br />
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "20%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({
                        publishDBLDialog: false,
                        publishData: false,
                      });
                    }}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    label={t("btnPublish")}
                    style={{ width: "20%" }}
                    className="p-button-mb"
                    onClick={(e) => {
                      this.setState({
                        publishData: true,
                        publishDBLDialog: false,
                        isPublish: "true",
                        //this.updateProjectStatus();
                      });
                      this.publishPassportLogbook();
                    }}
                  />
                </div>
              </Dialog>

              {/*delete DBL dialog*/}
              <Dialog
                visible={this.state.deleteDBLDialog}
                style={{ width: "500px" }}
                header={t("deleteDBL")}
                className="p-fluid"
                //footer={addDialogFooter}
                onHide={this.hideDeleteDBLDialog}
              >
                {/*text*/}
                <div className="col-12">
                  <label
                    htmlFor="text1"
                    style={{ fontFamily: "Manrope", fontWeight: "bolder" }}
                  >
                    {t("publishText2")}
                  </label>
                  <br />
                  <label
                    htmlFor="text2"
                    style={{
                      color: "#0094A8",
                      fontWeight: "bold",
                      fontFamily: "Manrope",
                    }}
                  >
                    {t("publishTextWarn1")}
                  </label>{" "}
                  <label
                    htmlFor="text3"
                    style={{ color: "#0094A8", fontFamily: "Manrope" }}
                  >
                    {t("publishTextWarn2")}
                  </label>
                </div>
                <br /> <br />
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "20%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({
                        deleteDBLDialog: false,
                      });
                    }}
                  />
                  &nbsp; &nbsp; &nbsp;
                  <Button
                    label={t("btnDelete")}
                    style={{ width: "20%" }}
                    className="p-button-mb"
                    onClick={(e) => {
                      this.deletePassportLogbook();
                    }}
                  />
                </div>
              </Dialog>

              {/* addDocument dialogu */}
              <Dialog
                visible={this.state.addDocumentDialogVisible}
                style={{ width: "650px" }}
                header={t("addDocument")}
                modal
                className="p-fluid"
                footer={addDocumentFooter}
                onHide={this.hideDocumentDialog}
              >
                <DocumentUpload
                  loading={this.state.loading}
                  setLoading={this.setLoading}
                  documents={this.state.documents}
                  setDocuments={this.setDocuments}
                  selectedDocument={this.state.selectedDocument}
                  setSelectedDocument={this.setSelectedDocument}
                  setSaveButtonVisible={this.setSaveButtonVisible}
                  uploadType={"D"}
                  isMulti={true}
                  isRequired={this.state.isRequiredDocumentCompliance}
                  phase={"P7" + this.state.activeIndex}
                  pid={this.state.pid}
                />
              </Dialog>

              {/* add event dialogu */}
              <Dialog
                visible={this.state.addEventDocumentDialogVisible}
                style={{ width: "650px" }}
                header={t("addDocument")}
                modal
                className="p-fluid"
                footer={addDocumentFooter}
                onHide={this.hideDocumentDialog}
              ></Dialog>

              {/* add new event document dialogu */}
              <Dialog
                visible={this.state.editDocumentDialogVisible}
                style={{ width: "650px" }}
                header={t("addDocument")}
                modal
                className="p-fluid"
                footer={addEventDocumentFooter}
                onHide={this.hideEditDocumentDialog}
              >
                <DocumentUpload
                  loading={this.state.loading}
                  setLoading={this.setLoading}
                  documents={this.state.eventDocuments}
                  setDocuments={this.setEventDoc}
                  selectedDocument={this.state.selectedEventDocument}
                  setSelectedDocument={this.setSelectedEventDocument}
                  setSaveButtonVisible={this.saveEventDocButtonStatus}
                  uploadType={"D"}
                  isMulti={true}
                  //phase={"P7" + this.state.activeIndex}
                  pid={this.state.pid}
                />
              </Dialog>

              {/*history Dialog */}
              <Dialog
                visible={this.state.historyDialog}
                style={{ width: "400px" }}
                modal
                className="p-fluid"
                onHide={() => {
                  this.setState({ historyDialog: false });
                }}
                header={t("historyDialog")}
              >
                <DataTable
                  // header={"verisonX"}
                  name="dt"
                  size="small"
                  showGridlines
                  selectionMode="single"
                  value={this.state.historyData}
                  dataKey="id"
                  stripedRows
                  responsiveLayout="scroll"
                  emptyMessage={t("noLogFound")}
                  paginator
                  rows={10}
                  filters={this.state.filters1}
                  filterDisplay="menu"
                  loading={this.state.loading}
                  globalFilterFields={["status", "dataUpdated"]}
                >
                  <Column
                    style={{ textAlign: "center", width: "8em" }}
                    body={this.logBookHistoryLogTimeTemplate}
                    header={t("dataUpdated")}
                  ></Column>

                  <Column
                    style={{ textAlign: "center", width: "8em" }}
                    body={this.logbookStatusTemplate}
                    header={t("status")}
                  ></Column>
                </DataTable>
                <br /> <br />
                <div
                  className="flex justify-content-end px-2"
                  id="dialogButton"
                >
                  <Button
                    className="p-button-mb-gray"
                    style={{ backgroundColor: "#414140", width: "25%" }}
                    label={t("CANCEL")}
                    onClick={() => {
                      this.setState({
                        historyDialog: false,
                      });
                    }}
                  />
                </div>
              </Dialog>

              <Dialog
                visible={this.state.pdfVisible}
                style={{ width: "%100", height: "%100" }}
                modal
                className="p-fluid"
                onHide={() => this.setState({ pdfVisible: false })}
              >
                {this.state.documentUrl.endsWith(".png") ||
                this.state.documentUrl.endsWith(".jpeg") ||
                this.state.documentUrl.endsWith(".jpg") ? (
                  <Galleria
                    value={this.state.documentUrl}
                    numVisible={5}
                    circular
                    style={{ maxWidth: "640px", borderRadius: "6px" }}
                    showThumbnails={false}
                    item={this.itemTemplatePdf}
                  />
                ) : this.state.documentUrl.endsWith(".pdf") ? (
                  <PdfViewer pdfUrl={this.state.documentUrl} />
                ) : (
                  <div>No valid document format found</div>
                )}
              </Dialog> 

              <ConfirmDialog
                visible={this.state.deleteDocumentConfirmDialogVisible}
                onHide={() => {
                  this.setState({ deleteDocumentConfirmDialogVisible: false });
                }}
                message={t("confirmMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle"
                accept={this.acceptDeleteDocument}
                reject={this.rejectDeleteDocument}
              />

              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />

              <WaitDialog loading={this.state.loading} lang={t} />
              <WaitDialogDocument
                loading={this.state.documentLoading}
                lang={t}
              />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(practicalCompletion));
