import React, { Component } from "react";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";
import { Divider } from "primereact/divider";
import { withTranslation, Trans, useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { isUser, isViewerExpert } from "../common/token-check";

import { Calendar } from "primereact/calendar";
import { format, parseISO } from "date-fns";
import { Panel } from "primereact/panel";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SysService from "../services/sys.service";
import { handleDownloadDocument } from "../common/functions";
import CostClaimPaymentOptions from "../common/ccs_costClaimAndPayment";

class FinancialManagement extends Component {
  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: "",
  };

  emptyNewBudget = {
    advocacy: "",
    structuralSurvey: "",
    survey: "",
    energyEfficiencySurvey: "",
    furniture: "",
    lrsFee: "",
    valuation: "",
    qsAndSurveyor: "",
    lendersArrangementFee: "",
    workCosts: "",
    relocationCosts: "",
    independentFinancialAdvisorFee: "",
    lendersLegalFee: "",
    fundsTransferFee: "",
    legals: "",
    clientLegals: "",
    projectManager: "",
    drainInspection: "",
    buildingControl: "",
    planningFee: "",
    other: "",
    vat: "",
  };

  emptyNewBudgetBreakdown = {
    advocacyCost: "0",
    advocacyVat: "0",
    structuralSurveyCost: "0",
    structuralSurveyVat: "0",
    surveyCost: "0",
    surveyVat: "0",
    energyEfficiencySurveyCost: "0",
    energyEfficiencySurveyVat: "0",
    furnitureCost: "0",
    furnitureVat: "0",
    lrsFeeCost: "0",
    lrsFeeVat: "0",
    valuationCost: "0",
    valuationVat: "0",
    qsAndSurveyorCost: "0",
    qsAndSurveyorVat: "0",
    lendersArrangementFeeCost: "0",
    lendersArrangementFeeVat: "0",
    workCostsCost: "0",
    workCostsVat: "0",
    relocationCostsCost: "0",
    relocationCostsVat: "0",
    independentFinancialAdvisorFeeCost: "0",
    independentFinancialAdvisorFeeVat: "0",
    lendersLegalFeeCost: "0",
    lendersLegalFeeVat: "0",
    fundsTransferFeeCost: "0",
    fundsTransferFeeVat: "0",
    legalsCost: "0",
    legalsVat: "0",
    clientLegalsCost: "0",
    clientLegalsVat: "0",
    projectManagerCost: "0",
    projectManagerVat: "0",
    drainInspectionCost: "0",
    drainInspectionVat: "0",
    buildingControlCost: "0",
    buildingControlVat: "0",
    planningFeeCost: "0",
    planningFeeVat: "0",
    otherCost: "0",
    otherVat: "0",
    vatCost: "0",
    vatVat: "0",
  };

  emptynewCostClaimPayments = {
    claimStatus: 1,
    amountClaimed: "",
    totaltoDate: "",
    note: "",
    bbid: "",
  };

  constructor(props) {
    super(props);

    this.formatDateToStringYearValue.bind(this);
    this.budgetHeader = this.budgetHeader.bind(this);
    this.budgetBreakdownHeader = this.budgetBreakdownHeader.bind(this);

    this.state = {
      project: "",
      budget: "",
      budgetBreakdown: "",

      pid: "",
      bid: "",
      bbid: "",

      budgetBreakdowns: [],

      newBudget: this.emptyNewBudget,
      newBudgetBreakdown: this.emptyNewBudgetBreakdown,

      visibleCustomSidebar: false,
      visibleRight: false,

      nonEditAccess: true,
      activeIndex: 0,

      p61Documents: [],
      p61DocumentIndex: -1,
      p61SelectedDocument: null,

      newDocument: this.emptyNewDocument,
      newCostClaimPayments: this.emptynewCostClaimPayments,

      addDocumentDialogVisible: false,
      addDocumentConfirmDialogVisible: false,
      addMonthYearConfirmDialogVisible: false,
      addDrawdownDialogVisible: false,
      updateDrawdownDialogVisible: false,
      addNewDocumentVisible: false,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      bbDocumentResponse: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      saveButtonStatus: false,

      checked: false,
      amountClaimedValue: "",

      updateCostClaimDialogVisible: false,

      total1: 0,
      other: "",

      selectedRow: null,
      selectedDocumentRow: null,
      selectedCostClaimRow: null,
      deleteDocumentConfirmDialogVisible: false,
      deleteBudgetBreakdownConfirmDialogVisible: false,

      costClaimYearVal: "",
      costClaimMonthVal: "",
      budgetBreakdownColorList: [],

      disableDrawdownCell: false,
      disableCostClaimCell: false,
      disableMonthYearCell: false,

      month: "",
      year: "",

      selectedDataTableRow: null,
      actionOrderId: null,
    };
  }

  /*JS_methods*/
  budgetHeader = (options) => {
    const { t } = useTranslation();
    return (
      <>
        <div className="grid" style={{ height: "20%" }}>
          <div className="col-12 md:col-6 lg:col-6">
            <div className="p-3 flex justify-content-md-center">
              <label
                style={{
                  fontStyle: "Roboto",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                {t("budgetBreakdown")}
              </label>
            </div>
          </div>

          <div className="col-12 md:col-6 lg:col-6">
            <div className="p-3 flex justify-content-md-center">
              <label
                style={{
                  fontStyle: "Roboto",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                {t("budget")}
              </label>
            </div>
          </div>
        </div>
      </>
    );
  };

  budgetBreakdownHeader = (options) => {
    const { t } = useTranslation();
    return (
      <>
        <div className="container">
          <div className="row" style={{ height: "60px" }}>
            <div className="col">
              <div className="p-3 flex justify-content-md-center">
                <label
                  style={{
                    fontStyle: "Roboto",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {t("budgetBreakdown")}
                </label>
              </div>
            </div>

            <div className="col">
              <div className="p-3 flex justify-content-md-center">
                <label
                  style={{
                    fontStyle: "Roboto",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {t("budget")}
                </label>
              </div>
            </div>

            <div className="col">
              <div className="p-3 flex justify-content-md-center">
                <label
                  style={{
                    fontStyle: "Roboto",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {t("budgetCost")}
                </label>
              </div>
            </div>

            <div className="col">
              <div className="p-3 flex justify-content-md-center">
                <label
                  style={{
                    fontStyle: "Roboto",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {t("vat")}
                </label>
              </div>
            </div>

            <div className="col">
              <div className="p-3 flex justify-content-md-center">
                <label
                  style={{
                    fontStyle: "Roboto",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {t("totalCost")}
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* <div  className="grid" style={{height:"60px"}}>
              <div className="col-12 md:col-2 lg:col-2">

                 <div className="p-3 flex justify-content-md-center">
                 <label style={{fontStyle:"Roboto" , fontSize:"15px", fontWeight:"bold"}}>{t("budgetBreakdown")}</label>

                 </div>

              </div>

              <div className="col-12 md:col-2 lg:col-2">
                  <div className="p-3 flex justify-content-md-center">
                      <label style={{fontStyle:"Roboto" , fontSize:"15px", fontWeight:"bold"}}>{t("budget")}</label>
                  </div>
               </div>

               <div className="col-12 md:col-2 lg:col-2">
                  <div className="p-3 flex justify-content-md-center">
                      <label style={{fontStyle:"Roboto" , fontSize:"15px", fontWeight:"bold"}}>{t("budgetCost")}</label>
                  </div>
               </div>

               <div className="col-12 md:col-2 lg:col-2">
                  <div className="p-3 flex justify-content-md-center">
                      <label style={{fontStyle:"Roboto" , fontSize:"15px", fontWeight:"bold"}}>{t("vat")}</label>
                  </div>
               </div>

               <div className="col-12 md:col-2 lg:col-2">
                  <div className="p-3 flex justify-content-md-center">
                      <label style={{fontStyle:"Roboto" , fontSize:"15px", fontWeight:"bold"}}>{t("totalCost")}</label>
                  </div>
               </div>

               
        </div> */}

        {/* <div className="d-flex ">
          <div className="p-5 flex-fill">
            <b>{t("budgetBreakdown")}</b>
          </div>
          <div className="p-5 flex-fill">
            <b>{t("budget")}</b>
          </div>
          <div className="p-5 flex-fill">
            <b>{t("budgetCost")}</b>
          </div>
          <div className="p-5 flex-fill">
            <b>{t("vat")}</b>
          </div>
          <div className="p-5 flex-fill">
            <b>{t("totalCost")}</b>
          </div>
        </div> */}
      </>
    );
  };

  getBudget() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getBudget(this.props.location.state.id.toString()).then(
      (response) => {
        this.setState({
          budget: response.data,
          bid: response.data.id,
          loading: false,
        });
        if (response.data === null || response.data === "") {
          this.updateEmptyBudget();
        }
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });
        toast.error(<Trans i18nKey={this.state.content} />);
      }
    );
  }

  getBudgetBreakdowns() {
    if (this.state.budget.id !== null) {
      SysService.getBudgetBreakdowns(this.state.budget.id).then(
        (response) => {
          this.setState({
            budgetBreakdowns: response.data,
            loading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });
          toast.error(<Trans i18nKey={this.state.content} />);
        }
      );
    }
  }

  updateEmptyBudget() {
    this.setState({
      budget: this.state.newBudget,
    });
  }

  getCostClaim() {
    let bbid = this.state.selectedRow.id.toString();
    SysService.getCostClaim(bbid).then(
      (response) => {
        this.setState({
          newCostClaimPayments: response.data,
          loading: false,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });
        toast.error(<Trans i18nKey={this.state.content} />);
      }
    );
  }

  deleteBudgetBreakdown() {
    let bblength = this.state.budgetBreakdowns.length;
    this.setState({
      loading: true,
      addDrawdownDialogVisible: false,
    });

    if (this.state.budgetBreakdowns.length === this.state.selectedRow.orderId) {
      SysService.deleteBudgetBreakdown(
        this.state.bbid,
        bblength,
        this.state.selectedRow.orderId
      ).then(
        (response) => {
          this.setState({
            loading: false,
          });

          toast.success(<Trans i18nKey={response.data.message} />);

          setTimeout(() => {
            this.getBudgetBreakdowns();
          }, 500);
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);
        }
      );
    } else {
      toast.error(<Trans i18nKey={"deleteBudgetBreakdownMessage"} />);
      this.setState({
        loading: false,
      });
    }
  }

  updateCostClaim() {
    if (
      this.state.newCostClaimPayments.claimStatus == null ||
      this.state.newCostClaimPayments.claimStatus === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    } else {
      this.setState({
        loading: true,
      });

      SysService.updateCostClaim(
        this.state?.newCostClaimPayments?.claimStatus,
        this.state.newCostClaimPayments.amountClaimed,
        this.state.newCostClaimPayments.totaltoDate,
        this.state.newCostClaimPayments.note,
        this.state.selectedRow.id
      ).then(
        (response) => {
          this.setState({
            loading: false,
            updateCostClaimDialogVisible: false,
          });
          toast.success(<Trans i18nKey={response.data.message} />);
          this.getCostClaim();
          this.getBudgetBreakdowns();
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );
    }
  }

  createCostClaim(bbid) {
    SysService.updateCostClaim(
      "1",
      this.state.newCostClaimPayments.amountClaimed,
      this.state.newCostClaimPayments.totaltoDate,
      this.state.newCostClaimPayments.note,
      bbid
    ).then(
      (response) => {
        this.setState({
          loading: false,
          updateCostClaimDialogVisible: false,
        });
        SysService.getCostClaim(bbid).then(
          (response) => {
            this.setState({
              newCostClaimPayments: response.data,
              loading: false,
            });
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString(),
              blockedPanel: true,
            });
            toast.error(<Trans i18nKey={this.state.content} />);
          }
        );
        this.getBudgetBreakdowns();
      },

      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;

            case 403:
              window.location.href = "/403";
              break;

            case 404:
              window.location.href = "/404";
              break;

            default:
              break;
          }
        }
      }
    );
  }

  getBudgetBreakdownByMonthAndYear() {
    let result = format(new Date(this.state.year), "yyyy");

    if (
      result !== null &&
      result !== "" &&
      result !== undefined &&
      this.state.year !== null &&
      this.state.year !== "" &&
      this.state.year !== undefined
    ) {
      this.setState({
        disableDrawdownCell: false,
        loading: true,
      });

      SysService.getBudgetBreakdownByMonthAndYear(
        this.state.bid,
        this.state.month,
        result
      ).then(
        (response) => {
          this.setState({
            budgetBreakdown: response.data,
            bbid: response.data.id,
            loading: false,
          });

          if (response.data === null || response.data === "") {
            this.updateEmptyBudgetBreakdown();
          }
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });
          toast.error(<Trans i18nKey={this.state.content} />);
        }
      );
    }
  }

  updateBudgetBreakdownDatatable(rowData) {
    this.setState({
      selectedDataTableRow: rowData,
    });

    if (rowData?.costClaim?.claimStatus === "3") {
      this.setState({
        disableDrawdownCell: true,
        disableMonthYearCell: true,
      });
    } else {
      this.setState({
        disableDrawdownCell: false,
        disableMonthYearCell: false,
      });
    }

    if (isViewerExpert()) {
      this.setState({
        disableDrawdownCell: true,
        disableMonthYearCell: true,
      });
    }

    this.setState({
      updateDrawdownDialogVisible: true,
      selectedRow: rowData,
      bbid: rowData.id,
      loading: false,
    });
    this.state.month = rowData.month;
    this.state.year = rowData.year;
    if (
      this.state.year !== null &&
      this.state.year !== "" &&
      this.state.year !== undefined
    ) {
      this.setState({
        loading: true,
      });

      SysService.getBudgetBreakdownByMonthAndYear(
        this.state.bid,
        this.state.month,
        this.state.year
      ).then(
        (response) => {
          this.setState({
            budgetBreakdown: response.data,
            bbid: response.data.id,
            loading: false,
          });

          if (response.data === null || response.data === "") {
            this.updateEmptyBudgetBreakdown();
          }
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });
          toast.error(<Trans i18nKey={this.state.content} />);
        }
      );
    }
  }

  updateEmptyBudgetBreakdown() {
    this.setState({
      budgetBreakdown: this.state.newBudgetBreakdown,
    });
  }

  formatDateToStringYearValue(data) {
    return format(new Date(data), "yyyy");
  }

  formatStringYearValuetoDate = (data) => {
    if (data !== undefined) {
      const formatDate = parseISO(data, 2);
      return formatDate;
    } else {
      return "";
    }
  };

  stringToBoolean = (value) => {
    if (value === "true") {
      return true;
    } else if (value === "false") {
      return false;
    }
  };

  formatCurrency(data) {
    let euro = Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });

    let localData = euro.format(data).toString();
    localData = localData.substring(1);
    return localData;
  }

  onInputChangeClaimCost = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newCostClaimPayments = { ...this.state.newCostClaimPayments };
    newCostClaimPayments[`${name}`] = val;

    this.setState({
      newCostClaimPayments,
    });
  };

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let budget = { ...this.state.budget };
    budget[`${name}`] = val;

    this.setState({
      budget,
      saveButtonStatus: true,
    });
  };

  onInputChangeProject = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;
    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeBudgetBreakdown = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let budgetBreakdown = { ...this.state.budgetBreakdown };
    budgetBreakdown[`${name}`] = val;

    this.setState({
      budgetBreakdown,
      saveButtonStatus: true,
    });
  };

  onInputChangeDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocument = { ...this.state.newDocument };
    newDocument[`${name}`] = val;

    this.setState({ newDocument });
  };

  onInputChangeClaimCostForChecked = (e, name) => {
    const val = e.checked || "";
    let newCostClaimPayments = { ...this.state.newCostClaimPayments };
    newCostClaimPayments[`${name}`] = val.toString();

    this.setState({
      newCostClaimPayments,
    });
  };

  totalFooter = () => {
    let total = 0;
    for (let i = 0; i < this.state.budgetBreakdowns.length; i++) {
      total =
        total +
        +this.state.budgetBreakdowns[i].advocacyCost +
        +this.state.budgetBreakdowns[i].advocacyVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].structuralSurveyCost +
        +this.state.budgetBreakdowns[i].structuralSurveyVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].surveyCost +
        +this.state.budgetBreakdowns[i].surveyVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].energyEfficiencySurveyCost +
        +this.state.budgetBreakdowns[i].energyEfficiencySurveyVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].furnitureCost +
        +this.state.budgetBreakdowns[i].furnitureVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].lrsFeeCost +
        +this.state.budgetBreakdowns[i].lrsFeeVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].valuationCost +
        +this.state.budgetBreakdowns[i].valuationVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].qsAndSurveyorCost +
        +this.state.budgetBreakdowns[i].qsAndSurveyorVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].lendersArrangementFeeCost +
        +this.state.budgetBreakdowns[i].lendersArrangementFeeVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].workCostsCost +
        +this.state.budgetBreakdowns[i].workCostsVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].relocationCostsCost +
        +this.state.budgetBreakdowns[i].relocationCostsVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].independentFinancialAdvisorFeeCost +
        +this.state.budgetBreakdowns[i].independentFinancialAdvisorFeeVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].lendersLegalFeeCost +
        +this.state.budgetBreakdowns[i].lendersLegalFeeVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].fundsTransferFeeCost +
        +this.state.budgetBreakdowns[i].fundsTransferFeeVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].legalsCost +
        +this.state.budgetBreakdowns[i].legalsVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].clientLegalsCost +
        +this.state.budgetBreakdowns[i].clientLegalsVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].projectManagerCost +
        +this.state.budgetBreakdowns[i].projectManagerVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].drainInspectionCost +
        +this.state.budgetBreakdowns[i].drainInspectionVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].otherCost +
        +this.state.budgetBreakdowns[i].otherVat;
      total =
        total +
        +this.state.budgetBreakdowns[i].vatCost +
        +this.state.budgetBreakdowns[i].vatVat;
    }

    return total;
  };

  projectIDTemplate(data) {
    let arr = [];
    arr = Array.from(String(data), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }
    let arrRes = arrX.concat(arr);
    let str = "";
    str = str + arrRes.join("");

    return str;
  }

  calculateDifference = (rowData) => {
    let totalVat = this.calculateBudgetBreakdownVATSummationForFinancalReport();
    if ((rowData.id = "VAT")) {
      return this.moneyMask(
        (
          parseFloat(this.removeAllSpecialCharacters(rowData.columnBudget)) -
          totalVat
        ).toString()
      );
    } else {
      return this.moneyMask(
        (
          parseFloat(this.removeAllSpecialCharacters(rowData.columnBudget)) -
          rowData.columnCost
        ).toString()
      );
    }
  };

  actionTemplate = (data, props) => {
    const { t } = useTranslation();

    return (
      <div>
        <Button
          icon="pi pi-download"
          href
          tooltip={t("download")}
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip={t("delete")}
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.setState({
                selectedDocumentRow: data,
                deleteDocumentConfirmDialogVisible: true,
              });
            }}
          />
        )}
      </div>
    );
  };

  actionTemplate3 = (data, props) => {
    const { t } = useTranslation();

    return (
      <div>
        {!isViewerExpert() && (
          <>
            <Button
              icon="pi pi-file"
              tooltip={t("documents")}
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.documentVisible(data, props);
                this.setState({
                  actionOrderId: data.orderId,
                });
              }}
              id="buttonFontWeight"
            />
          </>
        )}
      </div>
    );
  };

  acceptDeleteBudgetBreakdown = () => {
    this.deleteBudgetBreakdown(this.state.selectedRow);
  };

  acceptDeleteDocument = () => {
    this.handleDeleteDocument(this.state.selectedDocumentRow);
  };

  rejectDeleteDocument = () => {};

  handleDeleteDocument = (rowData) => {
    const index = this.state.p61Documents.indexOf(rowData);
    if (index > -1) {
      this.state.p61Documents.splice(index, 1);
    }
    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  handleAddDocument = () => {
    this.setState({
      newDocument: this.emptyNewDocument,
      submitted: false,
      addDocumentDialogVisible: true,
    });
  };

  hideAddDocumentDialog = () => {
    this.setState({
      submitted: false,
      addDocumentDialogVisible: false,
    });
  };

  handleAddDrawdown = () => {
    this.setState({
      addDrawdownDialogVisible: true,
      disableDrawdownCell: true,
      disableMonthYearCell: false,
    });
  };

  hideAddDrawdownDialog = () => {
    this.setState({
      addDrawdownDialogVisible: false,
      selectedDataTableRow: null,
      month: "",
      year: "",
    });
  };

  hideUpdateDrawdownDialog = () => {
    this.setState({
      updateDrawdownDialogVisible: false,
      selectedDataTableRow: null,
      month: "",
      year: "",
    });
  };

  acceptAddDocument = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseSelectFile" />);

      return;
    }

    if (
      this.state.newDocument.fileDescription == null ||
      this.state.newDocument.fileDescription === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDescription" />);

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getBudgetBreakdownFileUploadURL(
      fileX.name,
      this.state.bbid
    ).then(
      (response) => {
        this.setState(
          {
            bbDocumentResponse: response.data,
            tbuFile: fileX,
            saveButtonStatus: true,
          },
          () => {
            this.state.newDocument.uploadURL =
              this.state.bbDocumentResponse.uploadURL;
            this.state.newDocument.fileName =
              this.state.bbDocumentResponse.fileName;
            this.state.newDocument.documentName =
              this.state.bbDocumentResponse.documentName;
            this.state.newDocument.documentDate =
              this.state.bbDocumentResponse.documentDate;
            this.state.newDocument.documentURL =
              this.state.bbDocumentResponse.documentURL;
          }
        );

        this.put2S3();

        let p61doc = [...this.state.p61Documents];

        p61doc.push(this.state.newDocument);
        this.setState({ p61Documents: p61doc, saveButtonStatus: true });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  };

  rejectAddDocument = () => {};

  handleUpdateCostClaim = (rowData) => {
    let newCostClaimPayments = { ...rowData };
    this.setState({
      newCostClaimPayments: newCostClaimPayments,
      updateCostClaimDialogVisible: true,
    });
  };

  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let temp61Documents = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "p61") {
              temp61Documents.push(response.data.documentList[i]);
            }
          }
        }

        this.setState({
          project: response.data,
          p61Documents: temp61Documents,
          pid: response.data.id,
          loading: false,
          blockedPanel: false,
          nonEditAccess:
            response.data.status === 2 && !isViewerExpert() ? false : true,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.getBudget();

    setTimeout(() => {
      this.getBudgetBreakdowns();
    }, 2000);

    this.initFilters1();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1);
  }

  /**/
  getBudgetBreakdownFileUploadURL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.getBudgetBreakdownFileUploadURL(
        fileNameX,
        this.state.doc.documentType,
        this.state.bbid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.bbDocumentResponse.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addDocumentDialogVisible: false,
      });
    }
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0.1);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 5);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;

    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });
        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P6" + inx;

        if (inx === 0) {
          this.state.project.budget = this.state.budget;
          SysService.updateProject(this.state.project).then(
            (response) => {
              this.setState({
                project: response.data,
                loading: false,
                saveButtonStatus: false,
              });

              window.scrollTo(0, 0);

              toast.success(<Trans i18nKey="saved" />);

              this.getBudget();
            },
            (error) => {
              this.setState({
                loading: false,
                content:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString(),
              });

              window.scrollTo(0, 0);

              toast.error(<Trans i18nKey={error.response} />);

              if (
                error.response &&
                (error.response.status === 401 ||
                  error.response.status === 403 ||
                  error.response.status === 404)
              ) {
                switch (error.response.status) {
                  case 401:
                    window.location.href = "/401";
                    break;
                  case 403:
                    window.location.href = "/403";
                    break;
                  case 404:
                    window.location.href = "/404";
                    break;
                  default:
                    break;
                }
              }
            }
          );

          if (inx < this.stepItems.length - 1) {
            inx = inx + 1;
            this.setState({
              activeIndex: inx,
            });
          }
        } else if (inx === 1) {
          this.state.budgetBreakdown = { ...this.state.selectedRow };
          this.state.budgetBreakdown.documentList = this.state.p61Documents;

          this.state.budgetBreakdown.bbid =
            this.state.selectedRow.id.toString();

          SysService.addBudgetBreakdownDocument(
            this.state.budgetBreakdown
          ).then(
            (response) => {
              this.setState({
                budgetBreakdown: response.data,
                loading: false,
                saveButtonStatus: false,
              });
              window.scrollTo(0, 0);

              toast.success(<Trans i18nKey="saved" />);
              this.getBudgetBreakdowns();
            },
            (error) => {
              this.setState({
                loading: false,
                content:
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString(),
              });
              window.scrollTo(0, 0);

              toast.error(<Trans i18nKey={error.response} />);

              if (
                error.response &&
                (error.response.status === 401 ||
                  error.response.status === 403 ||
                  error.response.status === 404)
              ) {
                switch (error.response.status) {
                  case 401:
                    window.location.href = "/401";
                    break;
                  case 403:
                    window.location.href = "/403";
                    break;
                  case 404:
                    window.location.href = "/404";
                    break;
                  default:
                    break;
                }
              }
            }
          );
        }
      }
    }
  }

  monthlyReportSave() {
    if (!isViewerExpert()) {
      this.setState({
        loading: true,
      });
      this.state.project.budget = this.state.budget;
      this.state.budgetBreakdown = this.state.budgetBreakdown;
      this.state.budgetBreakdown.month = this.state.month;
      this.state.budgetBreakdown.year = this.formatDateToStringYearValue(
        this.state.year
      );

      this.state.budgetBreakdown.documents = this.state.p61Documents;

      this.state.budgetBreakdown.bid = this.state.bid;
      this.state.project.projectId = this.state.pid;
      this.state.budgetBreakdown.bbid = this.state.bbid;

      SysService.updateBudgetBreakdown(this.state.budgetBreakdown).then(
        (response) => {
          this.createCostClaim(response.data.id);
          this.setState({
            budgetBreakdown: response.data,
            loading: false,
            saveButtonStatus: false,
          });

          toast.success(<Trans i18nKey="saved" />);
          this.getBudgetBreakdowns();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          toast.error(<Trans i18nKey={error.response} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
    this.setState({
      updateDrawdownDialogVisible: false,
    });
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>
          {t("drawdown")} {this?.state?.selectedRow?.orderId} {t("documents")}
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  amountClaimedBody(value) {
    return (
      <InputText
        id="username"
        value={value}
        onChange={(e) => this.setState({ amountClaimedValue: e.target.value })}
        placeholder="(£)"
        maxLength={50}
        keyfilter="num"
      />
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  };

  nextPhase = () => {
    this.props.history.push({
      pathname: "/practicalCompletion",
      state: this.state.project,
    });
  };

  hideCostClaimDialog = () => {
    this.setState({
      submitted: false,
      updateCostClaimDialogVisible: false,
      selectedDataTableRow: null,
    });
  };

  calculateTotalAdvocacyCost = (name) => {
    let total = 0;
    for (let i = 0; i < this.state.budgetBreakdowns.length; i++) {
      total += +this.state.budgetBreakdowns[i][`${name}`];
    }
    return total;
  };

  calculateTotalAdvocacyCost2 = (name) => {
    let total = parseFloat("0");

    for (let i = 0; i < this.state?.budgetBreakdowns?.length; i++) {
      total += parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdowns[i][`${name}`].toString()
        )
      );
    }

    return total;
  };
  
  moneyMask = (value) => {
    if (value === "" || value === undefined || value === null) {
      value = "0";
    }
    if (value === "-") {
      return "-";
    }
    const isNegative = value.startsWith("-");
    value = value.replace(".", "").replace(",", "").replace(/\D/g, "");

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat("en-GB", options).format(
      parseFloat(value) / 100
    );

    if (isNegative) {
      return "-£" + result;
    } else {
      return "£" + result;
    }
  };

  // removeAllSpecialCharacters = (str) => {
  //   let result = "0";

  //   if (str) {
  //     result = str.replace(/\D/g, "");
  //   }

  //   return result;
  // };

  removeAllSpecialCharacters = (str) => {
    let result = "0";
  
    if (str) {
      // Eğer metin içinde - işareti varsa ve - işareti metnin başında değilse, negatif işaret olarak kabul et
      const isNegative = str.includes("-") && str.indexOf("-") > 0;
  
      // Metinden tüm özel karakterleri kaldır
      result = str.replace(/[^\d-]/g, "");
  
      // Eğer negatif işaret varsa ve - işareti metnin başında değilse, bu durumu bir negatif sayı olarak kabul et
      if (isNegative) {
        result = "-" + result;
      }
    }
  
    return result;
  };
  

  calculateBudgetSummationMinusVAT = () => {
    let result =
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budget?.advocacy)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budget?.structuralSurvey)
      ) +
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.survey)) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budget?.energyEfficiencySurvey
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.furniture)
      ) +
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.lrsFee)) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.valuation)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.qsAndSurveyor)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budget?.lendersArrangementFee
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.workCosts)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.relocationCosts)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budget?.independentFinancialAdvisorFee
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.lendersLegalFee)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.fundsTransferFee)
      ) +
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.legals)) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.clientLegals)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.projectManager)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.drainInspection)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.planningFee)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.buildingControl)
      ) +
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.other));
    return result;
  };

  calculateBudgetBreakdownCostSummation = () => {
    let result =
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.advocacyCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.structuralSurveyCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.surveyCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.energyEfficiencySurveyCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.furnitureCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.lrsFeeCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.valuationCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.qsAndSurveyorCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.lendersArrangementFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.workCostsCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.relocationCostsCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.independentFinancialAdvisorFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.lendersLegalFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.fundsTransferFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.legalsCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.clientLegalsCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.projectManagerCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.drainInspectionCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.buildingControlCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.planningFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.otherCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.vatCost)
      );
    return result;
  };

  calculateBudgetBreakdownVATSummation = () => {
    let result =
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.advocacyVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.structuralSurveyVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.surveyVat)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.energyEfficiencySurveyVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.furnitureVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.lrsFeeVat)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.valuationVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.qsAndSurveyorVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.lendersArrangementFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.workCostsVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.relocationCostsVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.independentFinancialAdvisorFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.lendersLegalFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.fundsTransferFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.legalsVat)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.clientLegalsVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.projectManagerVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.drainInspectionVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.buildingControlVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.planningFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.otherVat)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.vatVat)
      );
    return result;
  };

  calculateBudgetBreakdowntTotalSummation = () => {
    let result =
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.advocacyCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.advocacyVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.structuralSurveyCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.structuralSurveyVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budgetBreakdown?.surveyCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.surveyVat)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.energyEfficiencySurveyCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.energyEfficiencySurveyVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.furnitureCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.furnitureVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budgetBreakdown?.lrsFeeCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.lrsFeeVat)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.valuationCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.valuationVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.qsAndSurveyorCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.qsAndSurveyorVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.lendersArrangementFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.lendersArrangementFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.workCostsCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.workCostsVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.relocationCostsCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.relocationCostsVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.independentFinancialAdvisorFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.independentFinancialAdvisorFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.lendersLegalFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.lendersLegalFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.fundsTransferFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.fundsTransferFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budgetBreakdown?.legalsCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.legalsVat)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.clientLegalsCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.clientLegalsVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.projectManagerCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.projectManagerVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.drainInspectionCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.drainInspectionVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.buildingControlCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.buildingControlVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budgetBreakdown?.planningFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state?.budgetBreakdown?.planningFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budgetBreakdown?.otherCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.otherVat)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budgetBreakdown?.vatCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budgetBreakdown?.vatVat)
      );

    return result;
  };

  calculateBudgetBreakdownCostSummationForFinancalReport = () => {
    let result =
      this.calculateTotalAdvocacyCost2("advocacyCost") +
      this.calculateTotalAdvocacyCost2("structuralSurveyCost") +
      this.calculateTotalAdvocacyCost2("surveyCost") +
      this.calculateTotalAdvocacyCost2("energyEfficiencySurveyCost") +
      this.calculateTotalAdvocacyCost2("furnitureCost") +
      this.calculateTotalAdvocacyCost2("lrsFeeCost") +
      this.calculateTotalAdvocacyCost2("valuationCost") +
      this.calculateTotalAdvocacyCost2("qsAndSurveyorCost") +
      this.calculateTotalAdvocacyCost2("lendersArrangementFeeCost") +
      this.calculateTotalAdvocacyCost2("workCostsCost") +
      this.calculateTotalAdvocacyCost2("relocationCostsCost") +
      this.calculateTotalAdvocacyCost2("independentFinancialAdvisorFeeCost") +
      this.calculateTotalAdvocacyCost2("lendersLegalFeeCost") +
      this.calculateTotalAdvocacyCost2("fundsTransferFeeCost") +
      this.calculateTotalAdvocacyCost2("legalsCost") +
      this.calculateTotalAdvocacyCost2("clientLegalsCost") +
      this.calculateTotalAdvocacyCost2("projectManagerCost") +
      this.calculateTotalAdvocacyCost2("drainInspectionCost") +
      this.calculateTotalAdvocacyCost2("buildingControlCost") +
      this.calculateTotalAdvocacyCost2("planningFeeCost") +
      this.calculateTotalAdvocacyCost2("otherCost") +
      this.calculateTotalAdvocacyCost2("vatCost");

    return result;
  };

  calculateBudgetBreakdownVATSummationForFinancalReport = () => {
    let result =
      this.calculateTotalAdvocacyCost2("advocacyVat") +
      this.calculateTotalAdvocacyCost2("structuralSurveyVat") +
      this.calculateTotalAdvocacyCost2("surveyVat") +
      this.calculateTotalAdvocacyCost2("energyEfficiencySurveyVat") +
      this.calculateTotalAdvocacyCost2("furnitureVat") +
      this.calculateTotalAdvocacyCost2("lrsFeeVat") +
      this.calculateTotalAdvocacyCost2("valuationVat") +
      this.calculateTotalAdvocacyCost2("qsAndSurveyorVat") +
      this.calculateTotalAdvocacyCost2("lendersArrangementFeeVat") +
      this.calculateTotalAdvocacyCost2("workCostsVat") +
      this.calculateTotalAdvocacyCost2("relocationCostsVat") +
      this.calculateTotalAdvocacyCost2("independentFinancialAdvisorFeeVat") +
      this.calculateTotalAdvocacyCost2("lendersLegalFeeVat") +
      this.calculateTotalAdvocacyCost2("fundsTransferFeeVat") +
      this.calculateTotalAdvocacyCost2("legalsVat") +
      this.calculateTotalAdvocacyCost2("clientLegalsVat") +
      this.calculateTotalAdvocacyCost2("projectManagerVat") +
      this.calculateTotalAdvocacyCost2("drainInspectionVat") +
      this.calculateTotalAdvocacyCost2("buildingControlVat") +
      this.calculateTotalAdvocacyCost2("planningFeeVat") +
      this.calculateTotalAdvocacyCost2("otherVat") +
      this.calculateTotalAdvocacyCost2("vatVat");

    return result;
  };

  calculateBudgetBreakdownVATSummationForFinancalReportFR = () => {
    let result =
      this.calculateTotalAdvocacyCost2("advocacyVat") +
      this.calculateTotalAdvocacyCost2("structuralSurveyVat") +
      this.calculateTotalAdvocacyCost2("surveyVat") +
      this.calculateTotalAdvocacyCost2("energyEfficiencySurveyVat") +
      this.calculateTotalAdvocacyCost2("furnitureVat") +
      this.calculateTotalAdvocacyCost2("lrsFeeVat") +
      this.calculateTotalAdvocacyCost2("valuationVat") +
      this.calculateTotalAdvocacyCost2("qsAndSurveyorVat") +
      this.calculateTotalAdvocacyCost2("lendersArrangementFeeVat") +
      this.calculateTotalAdvocacyCost2("workCostsVat") +
      this.calculateTotalAdvocacyCost2("relocationCostsVat") +
      this.calculateTotalAdvocacyCost2("independentFinancialAdvisorFeeVat") +
      this.calculateTotalAdvocacyCost2("lendersLegalFeeVat") +
      this.calculateTotalAdvocacyCost2("fundsTransferFeeVat") +
      this.calculateTotalAdvocacyCost2("legalsVat") +
      this.calculateTotalAdvocacyCost2("clientLegalsVat") +
      this.calculateTotalAdvocacyCost2("projectManagerVat") +
      this.calculateTotalAdvocacyCost2("drainInspectionVat") +
      this.calculateTotalAdvocacyCost2("buildingControlVat") +
      this.calculateTotalAdvocacyCost2("planningFeeVat") +
      this.calculateTotalAdvocacyCost2("otherVat");

    return result;
  };

  calculateBudgetBreakdownCostSummationForFinancalReportFR = () => {
    let result =
      this.calculateTotalAdvocacyCost2("advocacyCost") +
      this.calculateTotalAdvocacyCost2("structuralSurveyCost") +
      this.calculateTotalAdvocacyCost2("surveyCost") +
      this.calculateTotalAdvocacyCost2("energyEfficiencySurveyCost") +
      this.calculateTotalAdvocacyCost2("furnitureCost") +
      this.calculateTotalAdvocacyCost2("lrsFeeCost") +
      this.calculateTotalAdvocacyCost2("valuationCost") +
      this.calculateTotalAdvocacyCost2("qsAndSurveyorCost") +
      this.calculateTotalAdvocacyCost2("lendersArrangementFeeCost") +
      this.calculateTotalAdvocacyCost2("workCostsCost") +
      this.calculateTotalAdvocacyCost2("relocationCostsCost") +
      this.calculateTotalAdvocacyCost2("independentFinancialAdvisorFeeCost") +
      this.calculateTotalAdvocacyCost2("lendersLegalFeeCost") +
      this.calculateTotalAdvocacyCost2("fundsTransferFeeCost") +
      this.calculateTotalAdvocacyCost2("legalsCost") +
      this.calculateTotalAdvocacyCost2("clientLegalsCost") +
      this.calculateTotalAdvocacyCost2("projectManagerCost") +
      this.calculateTotalAdvocacyCost2("drainInspectionCost") +
      this.calculateTotalAdvocacyCost2("buildingControlCost") +
      this.calculateTotalAdvocacyCost2("planningFeeCost") +
      this.calculateTotalAdvocacyCost2("otherCost");

    return result;
  };

  calculateBudgetBreakdownTotalSummationForFinancalReport = () => {
    let result =
      this.calculateBudgetBreakdownCostSummationForFinancalReport() +
      this.calculateBudgetBreakdownVATSummationForFinancalReport();

    return result;
  };

  calculateBudgetBreakdownTotalSummationForFinancalReportFR = () => {
    let result =
      this.calculateBudgetBreakdownCostSummationForFinancalReportFR() +
      this.calculateBudgetBreakdownVATSummationForFinancalReportFR();

    return result;
  };

  calculateBudgetBreakdownDiffrenceSummationForFinancalReport = () => {
    let result =
      this.calculateTotalAdvocacyCost2("advocacyCost") +
      this.calculateTotalAdvocacyCost2("structuralSurveyCost") +
      this.calculateTotalAdvocacyCost2("surveyCost") +
      this.calculateTotalAdvocacyCost2("energyEfficiencySurveyCost") +
      this.calculateTotalAdvocacyCost2("furnitureCost") +
      this.calculateTotalAdvocacyCost2("lrsFeeCost") +
      this.calculateTotalAdvocacyCost2("valuationCost") +
      this.calculateTotalAdvocacyCost2("qsAndSurveyorCost") +
      this.calculateTotalAdvocacyCost2("lendersArrangementFeeCost") +
      this.calculateTotalAdvocacyCost2("workCostsCost") +
      this.calculateTotalAdvocacyCost2("relocationCostsCost") +
      this.calculateTotalAdvocacyCost2("independentFinancialAdvisorFeeCost") +
      this.calculateTotalAdvocacyCost2("lendersLegalFeeCost") +
      this.calculateTotalAdvocacyCost2("fundsTransferFeeCost") +
      this.calculateTotalAdvocacyCost2("legalsCost") +
      this.calculateTotalAdvocacyCost2("clientLegalsCost") +
      this.calculateTotalAdvocacyCost2("projectManagerCost") +
      this.calculateTotalAdvocacyCost2("drainInspectionCost") +
      this.calculateTotalAdvocacyCost2("buildingControlCost") +
      this.calculateTotalAdvocacyCost2("planningFeeCost") +
      this.calculateTotalAdvocacyCost2("otherCost") +
      this.calculateTotalAdvocacyCost2("vatCost");

    return result;
  };

  onColumnClick = (rowData) => {
    this.setState({
      selectedDataTableRow: rowData,
    });

    if (rowData.costClaim) {
      if (rowData.costClaim.claimStatus === "3") {
        this.setState({
          disableCostClaimCell: true,
        });
      } else {
        this.setState({
          disableCostClaimCell: false,
        });
      }
    }

    if (isViewerExpert()) {
      this.setState({
        disableCostClaimCell: true,
      });
    }

    let newCostClaimPayments = { ...this.state.newCostClaimPayments };
    if (rowData.costClaim === null) {
      newCostClaimPayments.claimStatus = null;
    } else {
      newCostClaimPayments.claimStatus = parseInt(
        rowData?.costClaim?.claimStatus
      );
    }
    newCostClaimPayments.amountClaimed = rowData?.costClaim?.amountClaimed;
    newCostClaimPayments.totaltoDate = rowData?.costClaim?.totaltoDate;
    newCostClaimPayments.note = rowData?.costClaim?.note;
    newCostClaimPayments.bbid = rowData?.id.toString();
    this.setState({
      selectedRow: rowData,
      newCostClaimPayments,
      updateCostClaimDialogVisible: true,
    });
  };

  drawdownTemplate = (rowData) => {
    const { t } = useTranslation();

    if (rowData?.costClaim?.claimStatus == 1 || rowData?.costClaim == null) {
      return (
        <Button
          className="p-button-rounded p-button-text"
          label={t("drawdown") + " " + rowData.orderId}
          style={{ textDecorationLine: "underline" }}
        />
      );
    }
    if (rowData?.costClaim?.claimStatus == 2) {
      return (
        <Button
          className="p-button-rounded p-button-text"
          label={t("drawdown") + " " + rowData.orderId}
          style={{ textDecorationLine: "underline" }}
        />
      );
    }

    if (rowData?.costClaim?.claimStatus == 3) {
      return (
        <Button
          className="p-button-rounded p-button-text"
          label={t("drawdown") + " " + rowData.orderId}
          style={{ textDecorationLine: "underline", color: "#414140" }}
        />
      );
    }
  };

  documentVisible = (rowData) => {
    if (this.state.actionOrderId === rowData.orderId) {
      this.setState({
        addNewDocumentVisible: !this.state.addNewDocumentVisible,
      });
    } else {
      this.setState({
        addNewDocumentVisible: true,
      });
    }

    this.setState({
      selectedRow: rowData,
      selectedDataTableRow: rowData,
      bbid: rowData.id,
      p61Documents: rowData.documentList,
    });
  };

  calculateNetSummation = (rowdata) => {
    let result =
      parseFloat(this.removeAllSpecialCharacters(rowdata?.advocacyCost)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.structuralSurveyCost)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.surveyCost)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.energyEfficiencySurveyCost)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.furnitureCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.lrsFeeCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.valuationCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.qsAndSurveyorCost)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.lendersArrangementFeeCost)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.workCostsCost)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.relocationCostsCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          rowdata?.independentFinancialAdvisorFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.lendersLegalFeeCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.fundsTransferFeeCost)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.legalsCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.clientLegalsCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.projectManagerCost)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.drainInspectionCost)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.otherCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.vatCost));
    return result;
  };

  calculateVATSummation = (rowdata) => {
    let result =
      parseFloat(this.removeAllSpecialCharacters(rowdata?.advocacyVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.structuralSurveyVat)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.surveyVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.energyEfficiencySurveyVat)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.furnitureVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.lrsFeeVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.valuationVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.qsAndSurveyorVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.lendersArrangementFeeVat)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.workCostsVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.relocationCostsVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(
          rowdata?.independentFinancialAdvisorFeeVat
        )
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.lendersLegalFeeVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.fundsTransferFeeVat)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.legalsVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.clientLegalsVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.projectManagerVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.drainInspectionVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.otherVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.vatVat));
    return result;
  };

  calculateTotalSummation = (rowdata) => {
    let result =
      parseFloat(this.removeAllSpecialCharacters(rowdata?.advocacyCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.advocacyVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.structuralSurveyCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.structuralSurveyVat)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.surveyCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.surveyVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.energyEfficiencySurveyCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.energyEfficiencySurveyVat)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.furnitureCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.furnitureVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.lrsFeeCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.lrsFeeVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.valuationCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.valuationVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.qsAndSurveyorCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.qsAndSurveyorVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.lendersArrangementFeeCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.lendersArrangementFeeVat)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.workCostsCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.workCostsVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.relocationCostsCost)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.relocationCostsVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(
          rowdata?.independentFinancialAdvisorFeeCost
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          rowdata?.independentFinancialAdvisorFeeVat
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.lendersLegalFeeCost)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.lendersLegalFeeVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.fundsTransferFeeCost)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.fundsTransferFeeVat)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.legalsCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.legalsVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.clientLegalsCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.clientLegalsVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.projectManagerCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.projectManagerVat)) +
      parseFloat(
        this.removeAllSpecialCharacters(rowdata?.drainInspectionCost)
      ) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.drainInspectionVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.otherCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.otherVat)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.vatCost)) +
      parseFloat(this.removeAllSpecialCharacters(rowdata?.vatVat));

    return result;
  };

  calculateDataTableNetSummation = () => {
    let sum = 0;

    for (let i = 0; i < this.state.budgetBreakdowns.length; i++) {
      sum += this.calculateNetSummation(this.state.budgetBreakdowns[i]);
    }
    return sum;
  };

  calculateDataTableVatSummation = () => {
    let sum = 0;

    for (let i = 0; i < this.state.budgetBreakdowns.length; i++) {
      sum += this.calculateVATSummation(this.state.budgetBreakdowns[i]);
    }
    return sum;
  };

  calculateDataTableTotalSummation = () => {
    return (
      this.calculateDataTableVatSummation() +
      this.calculateDataTableNetSummation()
    );
  };

  calculateDataTableVatRemaining = () => {
    return (
      parseFloat(this.removeAllSpecialCharacters(this?.state?.budget?.vat)) -
      this.calculateDataTableVatSummation()
    );
  };

  calculateBudgetSummation = () => {
    let result =
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budget?.advocacy)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state?.budget?.structuralSurvey)
      ) +
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.survey)) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budget?.energyEfficiencySurvey
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.furniture)
      ) +
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.lrsFee)) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.valuation)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.qsAndSurveyor)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budget?.lendersArrangementFee
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.workCosts)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.relocationCosts)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budget?.independentFinancialAdvisorFee
        )
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.lendersLegalFee)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.fundsTransferFee)
      ) +
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.legals)) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.clientLegals)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.projectManager)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.drainInspection)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.buildingControl)
      ) +
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.planningFee)
      ) +
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.other)) +
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.vat));
    return result;
  };

  costClaimTemplate = (rowData) => {
    const { t } = useTranslation();

    if (rowData?.costClaim?.claimStatus == 1 || rowData?.costClaim == null) {
      return (
        <Button
          className="p-button-rounded p-button-text"
          label={t("open")}
          style={{ textDecorationLine: "underline" }}
        />
      );
    }
    if (rowData?.costClaim?.claimStatus == 2) {
      return (
        <Button
          className="p-button-rounded p-button-text"
          label={t("initialized")}
          style={{ textDecorationLine: "underline" }}
        />
      );
    }

    if (rowData?.costClaim?.claimStatus == 3) {
      return (
        <Button
          className="p-button-rounded p-button-text"
          label={t("completed")}
          style={{ textDecorationLine: "underline", color: "#414140" }}
        />
      );
    }
  };

  selectInputText = (event) => {
    event.target.select();
  };

  /*render*/
  render() {
    const { t } = this.props;
    const headerTable = this.renderHeaderTableTop(t);

    const remainingBudgetAdvocacy =
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.advocacy)) -
      (this.calculateTotalAdvocacyCost2("advocacyCost") +
        this.calculateTotalAdvocacyCost2("advocacyVat"));

    const remainingBudgetStructuralSurvey =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.structuralSurvey)
      ) -
      (this.calculateTotalAdvocacyCost2("structuralSurveyCost") +
        this.calculateTotalAdvocacyCost2("structuralSurveyVat"));
    const remainingBudgetSurvey =
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.survey)) -
      (this.calculateTotalAdvocacyCost2("surveyCost") +
        this.calculateTotalAdvocacyCost2("surveyVat"));

    const remainingBudgetEnergyEfficiencySurvey =
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budget?.energyEfficiencySurvey
        )
      ) -
      (this.calculateTotalAdvocacyCost2("energyEfficiencySurveyCost") +
        this.calculateTotalAdvocacyCost2("energyEfficiencySurveyVat"));

    const remainingBudgetFurniture =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.furniture)
      ) -
      (this.calculateTotalAdvocacyCost2("furnitureCost") +
        this.calculateTotalAdvocacyCost2("furnitureVat"));
    const remainingBudgetLrsFee =
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.lrsFee)) -
      (this.calculateTotalAdvocacyCost2("lrsFeeCost") +
        this.calculateTotalAdvocacyCost2("lrsFeeVat"));

    const remainingBudgetValuation =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.valuation)
      ) -
      (this.calculateTotalAdvocacyCost2("valuationCost") +
        this.calculateTotalAdvocacyCost2("valuationVat"));

    const remainingBudgetQsAndSurveyor =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.qsAndSurveyor)
      ) -
      (this.calculateTotalAdvocacyCost2("qsAndSurveyorCost") +
        this.calculateTotalAdvocacyCost2("qsAndSurveyorVat"));

    const remainingBudgetLendersArrangementFee =
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budget?.lendersArrangementFee
        )
      ) -
      (this.calculateTotalAdvocacyCost2("lendersArrangementFeeCost") +
        this.calculateTotalAdvocacyCost2("lendersArrangementFeeVat"));

    const remainingBudgetWorkCosts =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.workCosts)
      ) -
      (this.calculateTotalAdvocacyCost2("workCostsCost") +
        this.calculateTotalAdvocacyCost2("workCostsVat"));

    const remainingBudgetRelocationCosts =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.relocationCosts)
      ) -
      (this.calculateTotalAdvocacyCost2("relocationCostsCost") +
        this.calculateTotalAdvocacyCost2("relocationCostsVat"));
    const remainingBudgetIndependentFinancialAdvisorFee =
      parseFloat(
        this.removeAllSpecialCharacters(
          this.state.budget?.independentFinancialAdvisorFee
        )
      ) -
      (this.calculateTotalAdvocacyCost2("independentFinancialAdvisorFeeCost") +
        this.calculateTotalAdvocacyCost2("independentFinancialAdvisorFeeVat"));
    const remainingBudgetLendersLegalFee =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.lendersLegalFee)
      ) -
      (this.calculateTotalAdvocacyCost2("lendersLegalFeeCost") +
        this.calculateTotalAdvocacyCost2("lendersLegalFeeVat"));
    const remainingBudgetFundsTransferFee =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.fundsTransferFee)
      ) -
      (this.calculateTotalAdvocacyCost2("fundsTransferFeeCost") +
        this.calculateTotalAdvocacyCost2("fundsTransferFeeVat"));
    const remainingBudgetLegals =
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.legals)) -
      (this.calculateTotalAdvocacyCost2("legalsCost") +
        this.calculateTotalAdvocacyCost2("legalsVat"));
    const remainingBudgetClientLegals =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.clientLegals)
      ) -
      (this.calculateTotalAdvocacyCost2("clientLegalsCost") +
        this.calculateTotalAdvocacyCost2("clientLegalsVat"));
    const remainingBudgetProjectManager =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.projectManager)
      ) -
      (this.calculateTotalAdvocacyCost2("projectManagerCost") +
        this.calculateTotalAdvocacyCost2("projectManagerVat"));
    const remainingBudgetDrainInspection =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.drainInspection)
      ) -
      (this.calculateTotalAdvocacyCost2("drainInspectionCost") +
        this.calculateTotalAdvocacyCost2("drainInspectionVat"));

    const remainingBudgetPlanningFee =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.planningFee)
      ) -
      (this.calculateTotalAdvocacyCost2("planningFeeCost") +
        this.calculateTotalAdvocacyCost2("planningFeeVat"));

    const remainingBudgetBuildingControl =
      parseFloat(
        this.removeAllSpecialCharacters(this.state.budget?.buildingControl)
      ) -
      (this.calculateTotalAdvocacyCost2("buildingControlCost") +
        this.calculateTotalAdvocacyCost2("buildingControlVat"));

    const remainingBudgetOther =
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.other)) -
      (this.calculateTotalAdvocacyCost2("otherCost") +
        this.calculateTotalAdvocacyCost2("otherVat"));

    const remainingBudgetVat =
      parseFloat(this.removeAllSpecialCharacters(this.state.budget?.vat)) -
      (this.calculateTotalAdvocacyCost2("vatCost") +
        this.calculateTotalAdvocacyCost2("vatVat"));

    const remainingTotalBudget =
      remainingBudgetAdvocacy +
      remainingBudgetStructuralSurvey +
      remainingBudgetSurvey +
      remainingBudgetEnergyEfficiencySurvey +
      remainingBudgetFurniture +
      remainingBudgetLrsFee +
      remainingBudgetValuation +
      remainingBudgetQsAndSurveyor +
      remainingBudgetLendersArrangementFee +
      remainingBudgetWorkCosts +
      remainingBudgetRelocationCosts +
      remainingBudgetIndependentFinancialAdvisorFee +
      remainingBudgetLendersLegalFee +
      remainingBudgetFundsTransferFee +
      remainingBudgetLegals +
      remainingBudgetClientLegals +
      remainingBudgetProjectManager +
      remainingBudgetDrainInspection +
      remainingBudgetBuildingControl +
      remainingBudgetPlanningFee +
      remainingBudgetOther +
      remainingBudgetVat;

    let data = [
      {
        id: t("advocacy"),
        columnBudget: this.moneyMask(this.state.budget.advocacy),
        columnCost: this.calculateTotalAdvocacyCost2("advocacyCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("advocacyVat"),
        total: "",
      },
      {
        id: t("structuralSurvey"),
        columnBudget: this.moneyMask(this.state.budget.structuralSurvey),
        columnCost: this.calculateTotalAdvocacyCost2("structuralSurveyCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("structuralSurveyVat"),
        total: "",
      },
      {
        id: t("survey"),
        columnBudget: this.moneyMask(this.state.budget.survey),
        columnCost: this.calculateTotalAdvocacyCost2("surveyCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("surveyVat"),
        total: "",
      },
      {
        id: t("energyEfficiencySurvey"),
        columnBudget: this.moneyMask(this.state.budget.energyEfficiencySurvey),
        columnCost: this.calculateTotalAdvocacyCost2(
          "energyEfficiencySurveyCost"
        ),
        columnVAT: this.calculateTotalAdvocacyCost2(
          "energyEfficiencySurveyVat"
        ),
        total: "",
      },
      {
        id: t("furniture"),
        columnBudget: this.moneyMask(this.state.budget.furniture),
        columnCost: this.calculateTotalAdvocacyCost2("furnitureCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("furnitureVat"),
        total: "",
      },
      {
        id: t("LRSFee"),
        columnBudget: this.moneyMask(this.state.budget.lrsFee),
        columnCost: this.calculateTotalAdvocacyCost2("lrsFeeCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("lrsFeeVat"),
        total: "",
      },
      {
        id: t("valuation"),
        columnBudget: this.moneyMask(this.state.budget.valuation),
        columnCost: this.calculateTotalAdvocacyCost2("valuationCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("valuationVat"),
        total: "",
      },
      {
        id: t("qsAndSurveyor"),
        columnBudget: this.moneyMask(this.state.budget.qsAndSurveyor),
        columnCost: this.calculateTotalAdvocacyCost2("qsAndSurveyorCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("qsAndSurveyorVat"),
        total: "",
      },
      {
        id: t("lendersArrangementFee"),
        columnBudget: this.moneyMask(this.state.budget.lendersArrangementFee),
        columnCost: this.calculateTotalAdvocacyCost2(
          "lendersArrangementFeeCost"
        ),
        columnVAT: this.calculateTotalAdvocacyCost2("lendersArrangementFeeVat"),
        total: "",
      },
      {
        id: t("workCosts"),
        columnBudget: this.moneyMask(this.state.budget.workCosts),
        columnCost: this.calculateTotalAdvocacyCost2("workCostsCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("workCostsVat"),
        total: "",
      },
      {
        id: t("relocationCosts"),
        columnBudget: this.moneyMask(this.state.budget.relocationCosts),
        columnCost: this.calculateTotalAdvocacyCost2("relocationCostsCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("relocationCostsVat"),
        total: "",
      },
      {
        id: t("independentFinancialAdvisorFee"),
        columnBudget: this.moneyMask(
          this.state.budget.independentFinancialAdvisorFee
        ),
        columnCost: this.calculateTotalAdvocacyCost2(
          "independentFinancialAdvisorFeeCost"
        ),
        columnVAT: this.calculateTotalAdvocacyCost2(
          "independentFinancialAdvisorFeeVat"
        ),
        total: "",
      },
      {
        id: t("lendersLegalFee"),
        columnBudget: this.moneyMask(this.state.budget.lendersLegalFee),
        columnCost: this.calculateTotalAdvocacyCost2("lendersLegalFeeCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("lendersLegalFeeVat"),
        total: "",
      },
      {
        id: t("fundsTransferFee"),
        columnBudget: this.moneyMask(this.state.budget.fundsTransferFee),
        columnCost: this.calculateTotalAdvocacyCost2("fundsTransferFeeCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("fundsTransferFeeVat"),
        total: "",
      },
      {
        id: t("legals"),
        columnBudget: this.moneyMask(this.state.budget.legals),
        columnCost: this.calculateTotalAdvocacyCost2("legalsCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("legalsVat"),
        total: "",
      },
      {
        id: t("clientLegals"),
        columnBudget: this.moneyMask(this.state.budget.clientLegals),
        columnCost: this.calculateTotalAdvocacyCost2("clientLegalsCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("clientLegalsVat"),
        total: "",
      },
      {
        id: t("projectManager"),
        columnBudget: this.moneyMask(this.state.budget.projectManager),
        columnCost: this.calculateTotalAdvocacyCost2("projectManagerCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("projectManagerVat"),
        total: "",
      },
      {
        id: t("drainInspection"),
        columnBudget: this.moneyMask(this.state.budget.drainInspection),
        columnCost: this.calculateTotalAdvocacyCost2("drainInspectionCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("drainInspectionVat"),
        total: "",
      },
      {
        id: t("buildingControl"),
        columnBudget: this.moneyMask(this.state.budget.buildingControl),
        columnCost: this.calculateTotalAdvocacyCost2("buildingControlCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("buildingControlVat"),
        total: "",
      },
      {
        id: t("planningFee"),
        columnBudget: this.moneyMask(this.state.budget.planningFee),
        columnCost: this.calculateTotalAdvocacyCost2("planningFeeCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("planningFeeVat"),
        total: "",
      },
      {
        id: this.state.budget.otherName,
        columnBudget: this.moneyMask(this.state.budget.other),
        columnCost: this.calculateTotalAdvocacyCost2("otherCost"),
        columnVAT: this.calculateTotalAdvocacyCost2("otherVat"),
        total: "",
      },
      {
        id: t("vat"),
        columnBudget: this.moneyMask(this.state.budget.vat),
        columnCost: "",
        columnVAT: "",
        total: "",
      },
    ];

    let monthsAbbreviations = [
      {
        name: "JAN",
        code: "01",
        className: this.state.budgetBreakdownColorList.includes("01")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "FEB",
        code: "02",
        className: this.state.budgetBreakdownColorList.includes("02")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "MAR",
        code: "03",
        className: this.state.budgetBreakdownColorList.includes("03")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "APR",
        code: "04",
        className: this.state.budgetBreakdownColorList.includes("04")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "MAY",
        code: "05",
        className: this.state.budgetBreakdownColorList.includes("05")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "JUNE",
        code: "06",
        className: this.state.budgetBreakdownColorList.includes("06")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "JULY",
        code: "07",
        className: this.state.budgetBreakdownColorList.includes("07")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "AUG",
        code: "08",
        className: this.state.budgetBreakdownColorList.includes("08")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "SEPT",
        code: "09",
        className: this.state.budgetBreakdownColorList.includes("09")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "OCT",
        code: "10",
        className: this.state.budgetBreakdownColorList.includes("10")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "NOV",
        code: "11",
        className: this.state.budgetBreakdownColorList.includes("11")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
      {
        name: "DEC",
        code: "12",
        className: this.state.budgetBreakdownColorList.includes("12")
          ? "custom-option1-text"
          : "custom-option3-text",
      },
    ];

    this.stepItems = [
      {
        label: t("budgetBreakdown"),
        command: (event) => {},
      },
      {
        label: t("monthlyReport"),
        command: (event) => {},
      },
      {
        label: t("finalFinancialReport"),
        command: (event) => {},
      },
    ];

    const addDocumentDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddDocumentDialog}
        />
        <Button
          style={{ fontSize: "14px", fontStyle: "Manrope" }}
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={this.acceptAddDocument}
        />
      </React.Fragment>
    );

    const updateCostClaimFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideCostClaimDialog}
        />
        <Button
          style={{ fontSize: "14px", fontStyle: "Manrope" }}
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => {
            this.updateCostClaim();
          }}
        />

        {/*addMonthYearConfirmDialog*/}
        <ConfirmDialog
          visible={this.state.addMonthYearConfirmDialogVisible}
          onHide={() => {
            this.setState({ addMonthYearConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptAddDocumentLink}
          reject={this.rejectAddDocument}
        />
      </React.Fragment>
    );

    let headerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            alignHeader={"center"}
            header="Budget breakdown"
            rowSpan={3}
          />
          <Column
            alignHeader={"center"}
            header="Budget"
            rowSpan={3}
            sortable
            field="columnBudget"
          />
        </Row>
        <Row>
          <Column alignHeader={"center"} header="Actuals" colSpan={3} />
          <Column alignHeader={"center"} header="Difference" rowSpan={3} />
        </Row>
        <Row>
          <Column
            alignHeader={"center"}
            header="Net"
            sortable
            field="columnCost"
          />
          <Column
            alignHeader={"center"}
            header="VAT"
            sortable
            field="columnVAT"
          />
          <Column
            alignHeader={"center"}
            header="Total"
            sortable
            field="total"
          />
        </Row>
      </ColumnGroup>
    );

    let footerGroup = (
      <ColumnGroup>
        <Row>
          <Column footer={t("total") + " (£)"} rowSpan={3} />
          <Column
            style={{ textAlign: "right" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(this.calculateBudgetSummation().toString())}
                </b>
              </label>
            }
            rowSpan={3}
          />
        </Row>
        <Row>
          <Column
            style={{ textAlign: "right" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    this.calculateBudgetBreakdownCostSummationForFinancalReportFR().toString()
                  )}
                </b>
              </label>
            }
            rowSpan={3}
          />
        </Row>
        <Row>
          <Column
            style={{ textAlign: "right" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    this.calculateBudgetBreakdownVATSummationForFinancalReportFR().toString()
                  )}
                </b>
              </label>
            }
          />
          <Column
            style={{ textAlign: "right" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    this.calculateBudgetBreakdownTotalSummationForFinancalReportFR().toString()
                  )}
                </b>
              </label>
            }
          />
          <Column
            style={{ textAlign: "right" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    (
                      this.calculateBudgetSummation() -
                      this.calculateBudgetBreakdownTotalSummationForFinancalReportFR()
                    ).toString()
                  )}
                </b>
              </label>
            }
          />
        </Row>
      </ColumnGroup>
    );

    let footerMonthlyReportGroup = (
      <ColumnGroup>
        <Row>
          <Column
            style={{ textAlign: "left", backgroundColor: "#D9D9D980" }}
            footer={t("totalDrawdown")}
            rowSpan={3}
          />
          <Column
            style={{ textAlign: "right", backgroundColor: "#D9D9D980" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    this.calculateDataTableNetSummation().toString()
                  )}
                </b>
              </label>
            }
            rowSpan={3}
          />
        </Row>
        <Row>
          <Column
            style={{ textAlign: "right", backgroundColor: "#D9D9D980" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    this.calculateDataTableVatSummation().toString()
                  )}
                </b>
              </label>
            }
            rowSpan={3}
          />
        </Row>
        <Row>
          <Column
            style={{ textAlign: "right", backgroundColor: "#D9D9D980" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    this.calculateDataTableTotalSummation().toString()
                  )}
                </b>
              </label>
            }
          />
          <Column style={{ backgroundColor: "#D9D9D980" }} />
          <Column style={{ backgroundColor: "#D9D9D980" }} />
        </Row>

        <Row>
          <Column
            style={{ textAlign: "left", backgroundColor: "#D9D9D980" }}
            footer={t("totalLeft")}
            rowSpan={3}
          />
          <Column
            style={{ textAlign: "right", backgroundColor: "#D9D9D980" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    (
                      this.calculateBudgetSummationMinusVAT() -
                      this.calculateDataTableNetSummation()
                    ).toString()
                  )}
                </b>
              </label>
            }
            rowSpan={3}
          />
        </Row>
        <Row>
          <Column
            style={{ textAlign: "right", backgroundColor: "#D9D9D980" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    this.calculateDataTableVatRemaining().toString()
                  )}
                </b>
              </label>
            }
            rowSpan={3}
          />
        </Row>
        <Row>
          <Column
            style={{ textAlign: "right", backgroundColor: "#D9D9D980" }}
            footer={
              <label>
                <b>
                  {this.moneyMask(
                    (
                      this.calculateBudgetSummationMinusVAT() -
                      this.calculateDataTableNetSummation() +
                      this.calculateDataTableVatRemaining()
                    ).toString()
                  )}
                </b>
              </label>
            }
          />
          <Column style={{ backgroundColor: "#D9D9D980" }} />
          <Column style={{ backgroundColor: "#D9D9D980" }} />
        </Row>
      </ColumnGroup>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <Card
                style={{ height: "3.8em", background: "rgb(230, 240, 247)" }}
              >
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      style={{ fontSize: "18px", fontStyle: "Manrope" }}
                      label={
                        this.state.project.projectName +
                        " (ID: " +
                        this.projectIDTemplate(this.state.project.id) +
                        ")"
                      }
                      className="mb-2 custom-chip-home"
                    />
                  </Link>
                </div>
              </Card>
              <br /> <br />
              <div className={"col-12"}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      style={{
                        color: "0094A8",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: "lighter",
                      }}
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />
                    <div>
                      <Chip
                        style={{
                          fontStyle: "Manrope",
                          fontWeight: "bolder",
                          fontSize: "14px",
                        }}
                        label={t("phase") + " 6: " + t("phase6Name")}
                        icon="pi pi-money-bill"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-10 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                      onSelect={(e) => {
                        if (!this.state.saveButtonStatus) {
                          this.setState({ activeIndex: e.index });
                        } else {
                          if (this.state.activeIndex !== e.index) {
                            toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
                          }
                        }
                      }}
                      readOnly={false}
                    />
                  </div>

                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >
                    {/*Phase6_0*/}
                    <TabPanel header={t("budgetBreakdown")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p60Text")}</div>
                      </div>

                      <Divider />
                      <Panel
                        headerTemplate={this.budgetHeader}
                        style={{ backgroundColor: "#0094A833" }}
                      >
                        <div className="grid">
                          {/*advocacyHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("advocacy")}
                            </label>
                          </div>

                          {/*advocacy*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="advocacy"
                                value={this.moneyMask(
                                  this.state.budget.advocacy
                                )}
                                valueString
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "advocacy")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*structuralSurveyHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("structuralSurvey")}
                            </label>
                          </div>

                          {/*structuralSurvey*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="structuralSurvey"
                                value={this.moneyMask(
                                  this.state.budget.structuralSurvey
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "structuralSurvey")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*surveyHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("survey")}
                            </label>
                          </div>

                          {/*survey*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="survey"
                                value={this.moneyMask(this.state.budget.survey)}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "survey")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*energyEfficiencySurveyHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("energyEfficiencySurvey")}
                            </label>
                          </div>

                          {/*energyEfficiencySurvey*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="energyEfficiencySurvey"
                                value={this.moneyMask(
                                  this.state.budget.energyEfficiencySurvey
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(
                                    e,
                                    "energyEfficiencySurvey"
                                  )
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*furnitureHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("furniture")}
                            </label>
                          </div>

                          {/*furniture*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="furniture"
                                value={this.moneyMask(
                                  this.state.budget.furniture
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "furniture")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*LRSFeeHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("LRSFee")}
                            </label>
                          </div>

                          {/*LRSFee*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="LRSFee"
                                value={this.moneyMask(this.state.budget.lrsFee)}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "lrsFee")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*valuationHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("valuation")}
                            </label>
                          </div>

                          {/*valuation*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="valuation"
                                value={this.moneyMask(
                                  this.state.budget.valuation
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "valuation")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*qsAndSurveyorHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("qsAndSurveyor")}
                            </label>
                          </div>

                          {/*qsAndSurveyor*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="qsAndSurveyor"
                                value={this.moneyMask(
                                  this.state.budget.qsAndSurveyor
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "qsAndSurveyor")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*lendersArrangementFeeHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("lendersArrangementFee")}
                            </label>
                          </div>

                          {/*lendersArrangementFee*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="lendersArrangementFee"
                                value={this.moneyMask(
                                  this.state.budget.lendersArrangementFee
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "lendersArrangementFee")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*workCostsHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("workCosts")}
                            </label>
                          </div>

                          {/*workCosts*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="workCosts"
                                value={this.moneyMask(
                                  this.state.budget.workCosts
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "workCosts")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*relocationCostsHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("relocationCosts")}
                            </label>
                          </div>

                          {/*relocationCosts*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="relocationCosts"
                                value={this.moneyMask(
                                  this.state.budget.relocationCosts
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "relocationCosts")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*independentFinancialAdvisorFeeHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("independentFinancialAdvisorFee")}
                            </label>
                          </div>

                          {/*independentFinancialAdvisorFee*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="independentFinancialAdvisorFee"
                                value={this.moneyMask(
                                  this.state.budget
                                    .independentFinancialAdvisorFee
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(
                                    e,
                                    "independentFinancialAdvisorFee"
                                  )
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*lendersLegalFeeHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("lendersLegalFee")}
                            </label>
                          </div>

                          {/*lendersLegalFee*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="lendersLegalFee"
                                value={this.moneyMask(
                                  this.state.budget.lendersLegalFee
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "lendersLegalFee")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*fundsTransferFeeHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("fundsTransferFee")}
                            </label>
                          </div>

                          {/*fundsTransferFee*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="fundsTransferFee"
                                value={this.moneyMask(
                                  this.state.budget.fundsTransferFee
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "fundsTransferFee")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*legalsHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("legals")}
                            </label>
                          </div>

                          {/*legals*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="legals"
                                value={this.moneyMask(this.state.budget.legals)}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "legals")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*clientLegalsHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("clientLegals")}
                            </label>
                          </div>

                          {/*clientLegals*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="clientLegals"
                                value={this.moneyMask(
                                  this.state.budget.clientLegals
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "clientLegals")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*projectManagerHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("projectManager")}
                            </label>
                          </div>

                          {/*projectManager*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="projectManager"
                                value={this.moneyMask(
                                  this.state.budget.projectManager
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "projectManager")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*drainInspectionHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("drainInspection")}
                            </label>
                          </div>

                          {/*drainInspection*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="drainInspection"
                                value={this.moneyMask(
                                  this.state.budget.drainInspection
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "drainInspection")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/* Building control Header*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("buildingControl")}
                            </label>
                          </div>

                          {/*Building control*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="other"
                                value={this.moneyMask(
                                  this.state.budget.buildingControl
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "buildingControl")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*Planning fee Header*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("planningFee")}
                            </label>
                          </div>

                          {/*Planning fee*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="other"
                                value={this.moneyMask(
                                  this.state.budget.planningFee
                                )}
                                placeholder={t("0")}
                                onChange={(e) =>
                                  this.onInputChange(e, "planningFee")
                                }
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*otherHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <InputText
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="otherName"
                              value={this.state.budget.otherName}
                              placeholder={t("Other (Please specify: ....)")}
                              onChange={(e) =>
                                this.onInputChange(e, "otherName")
                              }
                              maxLength={50}
                              className="w-full"
                            />
                          </div>

                          {/*other*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="other"
                                value={this.moneyMask(this.state.budget.other)}
                                placeholder={t("0")}
                                onChange={(e) => this.onInputChange(e, "other")}
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*vatHeader*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <label
                              style={{ fontSize: "14px", fontStyle: "Roboto" }}
                            >
                              {t("vat")}
                            </label>
                          </div>

                          {/*vat*/}
                          <div className="col-12 md:col-6 lg:col-6">
                            <div>
                              <InputText
                                onClick={this.selectInputText}
                                style={{ textAlign: "right" }}
                                readOnly={this.state.nonEditAccess}
                                disabled={this.state.nonEditAccess}
                                id="vat"
                                value={this.moneyMask(this.state.budget.vat)}
                                placeholder={t("0")}
                                onChange={(e) => this.onInputChange(e, "vat")}
                                maxLength={20}
                                className="w-full"
                              />
                            </div>
                          </div>

                          {/*budget total header*/}
                          <div
                            className="col-12 md:col-6 lg:col-6"
                            style={{ backgroundColor: "#D9D9D980" }}
                          >
                            <div className="d-flex ">
                              <div className="p-2 flex-fill">
                                <b>{t("totalBudget")}</b>
                              </div>
                            </div>
                          </div>

                          {/*budget total*/}
                          <div
                            className="col-12 md:col-6 lg:col-6"
                            style={{ backgroundColor: "#D9D9D980" }}
                          >
                            <div className="d-flex ">
                              <div
                                className="p-2 flex-fill"
                                style={{ textAlign: "right" }}
                              >
                                <b>
                                  {this.moneyMask(
                                    this.calculateBudgetSummation().toString()
                                  )}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Panel>

                      <Divider />

                      {/*p60Notes*/}
                      <div className="col-12 md:col-12 lg:col-12">
                        <div className="mb-1">
                          <label
                            htmlFor="p60Notes"
                            className="platformColor"
                            style={{ fontSize: "14px" }}
                          >
                            {t("notes")}
                          </label>
                        </div>
                        <div>
                          <InputText
                            id="p60Notes"
                            readOnly={this.state.nonEditAccess}
                            disabled={this.state.nonEditAccess}
                            fontSize="16px"
                            value={this.state.project.p60Notes}
                            placeholder={t("pleaseEnterNote")}
                            onChange={(e) =>
                              this.onInputChangeProject(e, "p60Notes")
                            }
                            maxLength={1000}
                            className="w-full"
                          />
                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase6_1*/}
                    <TabPanel header={t("monthlyReport")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p61Text")}</div>
                      </div>
                      <div className="grid">
                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={
                                this.state.budget.id === undefined ||
                                this.state.budget.id === null
                                  ? true
                                  : false
                              }
                              label={t("btnAddDrawdown")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={() => {
                                this.setState({
                                  budgetBreakdown: this.emptyNewBudgetBreakdown,
                                  year: "",
                                  month: "",
                                });
                                this.handleAddDrawdown();
                              }}
                            />
                          )}
                        </div>
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              value={this.state.budgetBreakdowns}
                              dataKey="id"
                              footerColumnGroup={footerMonthlyReportGroup}
                              responsiveLayout="scroll"
                              emptyMessage={t("noDrawdownFound")}
                              rows={10}
                              alignHeader="center"
                              filters={this.state.filters1}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              selectionMode="single"
                              selection={this.state.selectedDataTableRow}
                              globalFilterFields={[
                                "id",
                                "drawdownNo",
                                "net",
                                "vat",
                                "total",
                                "costClaimAndPayment",
                                "streetName",
                                "documents",
                              ]}
                            >
                              <Column
                                alignHeader="center"
                                bodyStyle={{ textAlign: "center" }}
                                field="drawdownNo"
                                body={(rowData) => (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.updateBudgetBreakdownDatatable(
                                        rowData
                                      )
                                    }
                                  >
                                    {this.drawdownTemplate(rowData)}
                                  </div>
                                )}
                                header={t("drawdownNo")}
                              />

                              <Column
                                alignHeader="center"
                                style={{ textAlign: "right" }}
                                header={t("net")}
                                body={(rowData) =>
                                  this.moneyMask(
                                    this.calculateNetSummation(
                                      rowData
                                    ).toString()
                                  )
                                }
                                sortField="projectStatus"
                              ></Column>

                              <Column
                                alignHeader="center"
                                style={{ textAlign: "right" }}
                                header={t("vat")}
                                body={(rowData) =>
                                  this.moneyMask(
                                    this.calculateVATSummation(
                                      rowData
                                    ).toString()
                                  )
                                }
                              ></Column>

                              <Column
                                alignHeader="center"
                                style={{ textAlign: "right" }}
                                header={t("total")}
                                body={(rowData) =>
                                  this.moneyMask(
                                    this.calculateTotalSummation(
                                      rowData
                                    ).toString()
                                  )
                                }
                              ></Column>

                              <Column
                                alignHeader="center"
                                bodyStyle={{ textAlign: "center" }}
                                body={(rowData) => (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.onColumnClick(rowData)}
                                  >
                                    {this.costClaimTemplate(rowData)}
                                  </div>
                                )}
                                header={t("costClaimAndPayment")}
                              ></Column>

                              {!isViewerExpert() && (
                                <Column
                                  alignHeader="center"
                                  header={t("documents")}
                                  style={{ textAlign: "center", width: "8em" }}
                                  body={this.actionTemplate3}
                                />
                              )}
                            </DataTable>
                          </div>
                        </div>
                      </div>
                      <Divider />

                      {this.state.addNewDocumentVisible === true && (
                        <>
                          <div className="field col-12 mb-0">
                            {!isViewerExpert() && (
                              <Button
                                style={{
                                  color: "0094A8",
                                  fontSize: "12px",
                                  fontStyle: "Manrope",
                                }}
                                disabled={this.state.nonEditAccess}
                                label={t("btnAddDocument")}
                                className="p-button p-component p-button-outlined p-button-sm"
                                onClick={this.handleAddDocument}
                              />
                            )}
                          </div>

                          <div className="col-12">
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                              <DataTable
                                name="dt"
                                size="small"
                                showGridlines
                                selectionMode="single"
                                value={this.state.p61Documents}
                                dataKey="id"
                                stripedRows
                                responsiveLayout="scroll"
                                emptyMessage={t("noDocumentFound")}
                                header={headerTable}
                                paginator
                                rows={10}
                                filters={this.state.filters1}
                                filterDisplay="menu"
                                loading={this.state.loading}
                                globalFilterFields={[
                                  "fileName",
                                  "fileDescription",
                                ]}
                              >
                                <Column
                                  field="fileName"
                                  header={t("documentName")}
                                  sortable
                                ></Column>
                                <Column
                                  field="fileDescription"
                                  header={t("fileDescription")}
                                  sortable
                                ></Column>

                                <Column
                                  header={t("action")}
                                  style={{ textAlign: "center", width: "8em" }}
                                  body={this.actionTemplate}
                                />
                              </DataTable>
                            </div>
                          </div>
                        </>
                      )}
                    </TabPanel>

                    {/*Phase6_2*/}
                    <TabPanel header={t("finalFinancialReport")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p63Text")}</div>
                      </div>

                      {/************************** financial report *********************************** */}

                      {/*Phase6_2*/}
                      {/*Final Financial Report dataTable*/}
                      <div>
                        <DataTable
                          value={data}
                          headerColumnGroup={headerGroup}
                          footerColumnGroup={footerGroup}
                          responsiveLayout="scroll"
                        >
                          <Column field="id" />
                          <Column
                            style={{ textAlign: "right" }}
                            field="columnBudget"
                          />

                          <Column
                            style={{ textAlign: "right" }}
                            field="columnCost"
                            body={(rowData) =>
                              this.moneyMask(
                                rowData.id === "VAT"
                                  ? "-"
                                  : this.moneyMask(
                                      rowData.columnCost.toString()
                                    )
                              )
                            }
                          />
                          <Column
                            style={{ textAlign: "right" }}
                            field="columnVAT"
                            body={(rowData) =>
                              this.moneyMask(
                                rowData.id === "VAT"
                                  ? "-"
                                  : this.moneyMask(rowData.columnVAT.toString())
                              )
                            }
                          />
                          <Column
                            style={{ textAlign: "right" }}
                            field="total"
                            body={(rowData) =>
                              this.moneyMask(
                                (rowData.id === "VAT"
                                  ? "-"
                                  : rowData.columnCost + rowData.columnVAT
                                ).toString()
                              )
                            }
                          />

                          <Column
                            style={{ textAlign: "right" }}
                            field="difference"
                            body={(rowData) =>
                              this.moneyMask(
                                (
                                  parseFloat(
                                    this.removeAllSpecialCharacters(
                                      rowData.columnBudget
                                    )
                                  ) -
                                  (rowData.id === "VAT"
                                    ? this.calculateBudgetBreakdownVATSummationForFinancalReportFR()
                                    : rowData.columnCost)
                                ).toString()
                              )
                            }
                          />
                        </DataTable>
                      </div>
                      <Divider />
                    </TabPanel>
                  </TabView>
                </div>
                <br />
                <div
                  class="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div class="btn-group" role="group" aria-label="First group">
                    <Button
                      style={{ fontSize: "14px", fontStyle: "Manrope" }}
                      icon="pi pi-chevron-circle-left"
                      disabled={this.state.activeIndex <= 0}
                      label={t("previous")}
                      className="p-button-sm p-button-mb"
                      onClick={() => this.stepPrev()}
                    />
                    &nbsp;
                    <Button
                      icon="pi pi-chevron-circle-right"
                      disabled={
                        this.state.activeIndex >= this.stepItems.length - 1
                      }
                      style={{ fontSize: "14px", fontStyle: "Manrope" }}
                      label={t("next")}
                      className="p-button-sm p-button-mb"
                      onClick={() => {
                        this.stepNext();
                      }}
                    />
                    &nbsp;
                    {!isViewerExpert() && (
                      <Button
                        disabled={!this.state.saveButtonStatus}
                        style={{ fontSize: "14px", fontStyle: "Manrope" }}
                        icon="pi pi-check-circle"
                        label={
                          this.state.activeIndex < this.stepItems.length - 1 &&
                          this.state.activeIndex != 1
                            ? t("saveNext")
                            : t("save")
                        }
                        className="p-button-sm p-button-danger"
                        onClick={() => this.stepSaveAndNext()}
                      />
                    )}
                  </div>
                  {this.state.activeIndex === this.stepItems.length - 1 && (
                    <div class="btn-group">
                      <div class="input-group-prepend">
                        <Button
                          style={{
                            fontSize: "14px",
                            fontStyle: "Manrope",
                          }}
                          iconPos="right"
                          icon="pi pi-angle-double-right"
                          label={t("nextPhase")}
                          className="p-button-sm p-button-mb"
                          onClick={() => this.nextPhase()}
                        ></Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <br />
              {/*add Document dialog */}
              <Dialog
                visible={this.state.addDocumentDialogVisible}
                style={{ width: "450px" }}
                header={t("document")}
                modal
                className="p-fluid"
                footer={addDocumentDialogFooter}
                onHide={this.hideAddDocumentDialog}
              >
                <div className="p-float-label">
                  <input
                    id="fupload"
                    type="file"
                    accept=".xlsx, .xls, .csv, .doc, .docx, .pdf, .jpg, .png, .jpeg, .gif|image/*"
                  ></input>
                </div>
                <div className="p-float-label">
                  <InputTextarea
                    id="ad2"
                    value={this.state.newDocument.fileDescription}
                    onChange={(e) =>
                      this.onInputChangeDoc(e, "fileDescription")
                    }
                    rows={3}
                    cols={20}
                    maxLength={100}
                    className="w-full"
                  />
                  <label htmlFor="ad2">{t("fileDescription")} (*)</label>
                </div>
              </Dialog>
              {/*add Drawdown dialog */}
              <Dialog
                visible={this.state.addDrawdownDialogVisible}
                style={{ width: "1000px" }}
                header={t("drawdown")}
                modal
                className="p-fluid"
                onHide={this.hideAddDrawdownDialog}
              >
                <div className="grid">
                  <>
                    {/*Select start date of the site works Header*/}
                    <div className="col-12 md:col-6 lg:col-4">
                      <br />
                      <div className="mb-2">
                        <br />
                        <label
                          style={{
                            fontSize: "14px",
                            fontStyle: "Roboto",
                          }}
                        >
                          {t("selectStartDateOfTheSiteWorks")}
                        </label>
                      </div>
                    </div>

                    {/*Select start date of the site works Dropdowns*/}
                    <div className="col-12 md:col-6 lg:col-8">
                      <div className="grid">
                        {/*Select start date of the site works (Dropdown Month)*/}
                        <div className="col-12 md:col-6 lg:col-4">
                          <div className="row ">
                            <div class="col-12">
                              <br />
                              <Calendar
                                id="yearpicker"
                                placeholder={t("pleaseSelectYear")}
                                value={new Date(this.state.year)}
                                disabled={this.state.disableMonthYearCell}
                                onChange={(e) => {
                                  this.setState({
                                    year: e.target.value,
                                  });
                                }}
                                view="year"
                                dateFormat="yy"
                                className="w-full"
                              />
                            </div>
                          </div>
                        </div>

                        {/*Select start date of the site works (Dropdown Year)*/}
                        <div className="col-12 md:col-6 lg:col-8">
                          <div className="row">
                            <div class="col-6">
                              <br />
                              <Dropdown
                                id="secondRegisteredDisabled"
                                value={this.state.month}
                                options={monthsAbbreviations}
                                placeholder={t("pleaseSelectMonth")}
                                disabled={this.state.disableMonthYearCell}
                                onChange={(e) => {
                                  this.setState({
                                    month: e.target.value,
                                  });
                                }}
                                optionLabel="name"
                                optionValue="code"
                                className="w-full"
                              />
                            </div>

                            {/*update button*/}
                            <div class="col-4">
                              <br />
                              <Button
                                label={t("update")}
                                className="p-button-mb"
                                disabled={this.state.disableMonthYearCell}
                                onClick={() => {
                                  this.getBudgetBreakdownByMonthAndYear();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                  </>
                </div>

                {/******************************** budget breakdown ************************ */}

                <Panel
                  headerTemplate={this.budgetBreakdownHeader}
                  style={{ backgroundColor: "#0094A833" }}
                >
                  {/*row-1 */}
                  <div class="flex">
                    {/*advocacy header*/}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("advocacy")}
                      </label>
                    </div>

                    {/*budget advocacy*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetAdvocacy.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown advocacyCost*/}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.advocacyCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "advocacyCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown advocacyVat*/}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.advocacyVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "advocacyVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*total advocacy*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.advocacyCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.advocacyVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-2 */}
                  <div class="flex">
                    {/*structuralSurvey header*/}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("structuralSurvey")}
                      </label>
                    </div>

                    {/*budget structuralSurvey*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetStructuralSurvey.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown structuralSurveyCost*/}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="structuralSurveyCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.structuralSurveyCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "structuralSurveyCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown structuralSurveyVat*/}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="structuralSurveyVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.structuralSurveyVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "structuralSurveyVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*total structuralSurvey*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.structuralSurveyCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.structuralSurveyVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-3 */}
                  <div class="flex">
                    {/*survey header*/}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("survey")}
                      </label>
                    </div>

                    {/*budget survey */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetSurvey.toString())}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown surveyCost */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="surveyCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.surveyCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "surveyCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown surveyVat */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="surveyVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.surveyVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "surveyVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*total survey*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this?.state?.budgetBreakdown?.surveyCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this?.state?.budgetBreakdown?.surveyVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-4 */}
                  <div class="flex">
                    {/*energyEfficiencySurvey header*/}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("energyEfficiencySurvey")}
                      </label>
                    </div>

                    {/*budget energyEfficiencySurvey */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetEnergyEfficiencySurvey.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown energyEfficiencySurveyCost */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="energyEfficiencySurveyCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.energyEfficiencySurveyCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "energyEfficiencySurveyCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown energyEfficiencySurveyVat */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="energyEfficiencySurveyVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.energyEfficiencySurveyVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "energyEfficiencySurveyVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*total  energyEfficiencySurvey*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.energyEfficiencySurveyCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.energyEfficiencySurveyVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-5 */}
                  <div class="flex">
                    {/*furniture header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("furniture")}
                      </label>
                    </div>

                    {/*furniture budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetFurniture.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*furnitureCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="furnitureCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.furnitureCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "furnitureCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*furnitureCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="furnitureVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.furnitureVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "furnitureVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*furniture total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.furnitureCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.furnitureVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-6 */}
                  <div class="flex">
                    {/*lrsFee header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("LRSFee")}
                      </label>
                    </div>

                    {/*lrsFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetLrsFee.toString())}
                        className="w-full"
                      />
                    </div>

                    {/*lrsFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lrsFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lrsFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "lrsFeeCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lrsFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lrsFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lrsFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "lrsFeeVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lrsFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.lrsFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.lrsFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-7 */}
                  <div class="flex">
                    {/*valuation header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("valuation")}
                      </label>
                    </div>

                    {/*valuation budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetValuation.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*valuationCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="valuationCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.valuationCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "valuationCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*valuationVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="valuationVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.valuationVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "valuationVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*valuation total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.valuationCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.valuationVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-8 */}
                  <div class="flex">
                    {/*qsAndSurveyor header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("qsAndSurveyor")}
                      </label>
                    </div>

                    {/*qsAndSurveyor budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetQsAndSurveyor.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*qsAndSurveyorCost */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="qsAndSurveyorCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.qsAndSurveyorCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "qsAndSurveyorCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*qsAndSurveyorVat */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="qsAndSurveyorVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.qsAndSurveyorVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "qsAndSurveyorVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*qsAndSurveyor total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.qsAndSurveyorCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.qsAndSurveyorVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-9 */}
                  <div class="flex">
                    {/*lendersArrangementFee header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("lendersArrangementFee")}
                      </label>
                    </div>

                    {/*lendersArrangementFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetLendersArrangementFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*lendersArrangementFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lendersArrangementFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lendersArrangementFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "lendersArrangementFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lendersArrangementFeeVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lendersArrangementFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lendersArrangementFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "lendersArrangementFeeVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lendersArrangementFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.lendersArrangementFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.lendersArrangementFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-10 */}
                  <div class="flex">
                    {/*workCosts header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("workCosts")}
                      </label>
                    </div>

                    {/*workCosts budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetWorkCosts.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*workCostsCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="workCostsCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.workCostsCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "workCostsCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*workCostsVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="workCostsVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.workCostsVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "workCostsVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*workCosts total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.workCostsCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.workCostsVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-11*/}
                  <div class="flex">
                    {/*relocationCosts header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("relocationCosts")}
                      </label>
                    </div>

                    {/*relocationCosts budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetRelocationCosts.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*relocationCostsCost budgetBreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="relocationCostsCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.relocationCostsCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "relocationCostsCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*relocationCostsVat budgetBreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="relocationCostsVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.relocationCostsVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "relocationCostsVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*relocationCosts total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.relocationCostsCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.relocationCostsVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-12 */}
                  <div class="flex">
                    {/*relocationCostsVat header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("independentFinancialAdvisorFee")}
                      </label>
                    </div>

                    {/*independentFinancialAdvisorFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetIndependentFinancialAdvisorFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*independentFinancialAdvisorFeeCost budgetBreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="independentFinancialAdvisorFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown
                            .independentFinancialAdvisorFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "independentFinancialAdvisorFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*independentFinancialAdvisorFeeVat budgetBreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="independentFinancialAdvisorFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown
                            .independentFinancialAdvisorFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "independentFinancialAdvisorFeeVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*independentFinancialAdvisorFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.independentFinancialAdvisorFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.independentFinancialAdvisorFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-13 */}
                  <div class="flex">
                    {/*lendersLegalFee header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("lendersLegalFee")}
                      </label>
                    </div>

                    {/*lendersLegalFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetLendersLegalFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*lendersLegalFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lendersLegalFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lendersLegalFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "lendersLegalFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lendersLegalFeeVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lendersLegalFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lendersLegalFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "lendersLegalFeeVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lendersLegalFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.lendersLegalFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.lendersLegalFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-14 */}
                  <div class="flex">
                    {/*fundsTransferFeeC header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("fundsTransferFee")}
                      </label>
                    </div>

                    {/*lendersLegalFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetFundsTransferFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*fundsTransferFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="fundsTransferFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.fundsTransferFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "fundsTransferFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*fundsTransferFeeVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="fundsTransferFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.fundsTransferFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "fundsTransferFeeVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*fundsTransferFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.fundsTransferFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.fundsTransferFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-15 */}
                  <div class="flex">
                    {" "}
                    {/*legals header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("legals")}
                      </label>
                    </div>
                    {/*legals budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetLegals.toString())}
                        className="w-full"
                      />
                    </div>
                    {/*legalsCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="legalsCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.legalsCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "legalsCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                    {/*legalsVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="legalsVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.legalsVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "legalsVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                    {/*legals total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.legalsCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.legalsVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-16 */}
                  <div class="flex">
                    {/*clientLegals header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("clientLegals")}
                      </label>
                    </div>

                    {/*clientlegals budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetClientLegals.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*clientLegalsCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="clientLegalsCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.clientLegalsCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "clientLegalsCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*clientLegalsVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="clientLegalsVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.clientLegalsVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "clientLegalsVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*clientLegals total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.clientLegalsCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.clientLegalsVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-17 */}
                  <div class="flex">
                    {/*projectManager header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("projectManager")}
                      </label>
                    </div>

                    {/*projectManager budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetProjectManager.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*projectManagerCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="projectManagerCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.projectManagerCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "projectManagerCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*projectManagerVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="projectManagerVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.projectManagerVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "projectManagerVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*projectManager total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.projectManagerCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.projectManagerVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-18 */}
                  <div class="flex">
                    {/*drainInspection header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("drainInspection")}
                      </label>
                    </div>

                    {/*drainInspection budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetDrainInspection.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*drainInspectionCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="drainInspectionCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.drainInspectionCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "drainInspectionCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*drainInspectionVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="drainInspectionVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.drainInspectionVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "drainInspectionVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*drainInspection total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.drainInspectionCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.drainInspectionVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-19*/}
                  <div class="flex">
                    {/*buildingControl header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("buildingControl")}
                      </label>
                    </div>

                    {/*buildingControl budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetBuildingControl.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*buildingControlCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="buildingControlCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.buildingControlCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "buildingControlCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*buildingControlVAT budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="buildingControlVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.buildingControlVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "buildingControlVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*buildingControl total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="buildingControlTotal"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.buildingControlCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.buildingControlVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-20 */}
                  <div class="flex">
                    {/*planningFee  header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("planningFee")}
                      </label>
                    </div>

                    {/*planningFeeCost budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetPlanningFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*planningFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="planningFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.planningFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "planningFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*planningFeeVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="planningFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.planningFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "planningFeeVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*drainInspection total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="planningFeeTotal"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.planningFeeVat
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.planningFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-21 */}
                  <div class="flex">
                    {/*other header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {this.state.budget.otherName}
                      </label>
                    </div>

                    {/*other budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetOther.toString())}
                        className="w-full"
                      />
                    </div>

                    {/*otherCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="otherCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.otherCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "otherCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*otherVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="otherVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.otherVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "otherVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*other total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.otherCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.otherVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-22 */}
                  <div class="flex">
                    {/*vat header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("vat")}
                      </label>
                    </div>

                    {/*vat budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetVat.toString())}
                        className="w-full"
                      />
                    </div>

                    {/*vatCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="vatCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.vatCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "vatCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*vatVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="vatVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.vatVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "vatVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*vat total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="vat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.vatCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.vatVat
                              )
                            )
                          ).toString()
                        )}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-21 */}
                  <div class="flex" style={{ backgroundColor: "#D9D9D980" }}>
                    {/*totalBudget header */}
                    <div class="flex-1 p-4">
                      <div className="p-1 flex-fill">
                        <b>{t("totalBudget")}</b>
                      </div>
                    </div>

                    {/* totalBudget */}
                    <div class="flex-1 p-4">
                      <div className="d-flex ">
                        <div
                          className="p-1 flex-fill"
                          style={{ textAlign: "right" }}
                        >
                          <b>
                            {" "}
                            {this.moneyMask(remainingTotalBudget.toString())}
                          </b>
                        </div>
                      </div>
                    </div>

                    {/*costTotal BudgetBreakdown */}
                    <div class="flex-1 p-4">
                      <div
                        className="p-1 flex-fill"
                        style={{ textAlign: "right" }}
                      >
                        <b>
                          {this.moneyMask(
                            this.calculateBudgetBreakdownCostSummation().toString()
                          )}
                        </b>
                      </div>
                    </div>

                    {/*vatTotal BudgetBreakdown */}
                    <div class="flex-1 p-4">
                      <div
                        className="p-1 flex-fill"
                        style={{ textAlign: "right" }}
                      >
                        <b>
                          {this.moneyMask(
                            this.calculateBudgetBreakdownVATSummation().toString()
                          )}
                        </b>
                      </div>
                    </div>

                    {/*totalCost BudgetBreakdown */}
                    <div class="flex-1 p-4">
                      <div
                        className="p-1 flex-fill"
                        style={{ textAlign: "right" }}
                      >
                        <b>
                          {this.moneyMask(
                            this.calculateBudgetBreakdowntTotalSummation().toString()
                          )}
                        </b>
                      </div>
                    </div>
                  </div>

                  <Divider />

                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <div
                      className="flex justify-content-end"
                      id="dialogButtonCancel"
                    >
                      <div>
                        <Button
                          label={t("CANCEL")}
                          className="p-button-mb-gray"
                          onClick={() =>
                            this.setState({ addDrawdownDialogVisible: false })
                          }
                        />
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div>
                        <Button
                          disabled={!this.state.saveButtonStatus}
                          label={t("SAVE")}
                          className="p-button-mb"
                          onClick={() => this.monthlyReportSave()}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
              </Dialog>
              {/*update Drawdown dialog */}
              <Dialog
                visible={this.state.updateDrawdownDialogVisible}
                style={{ width: "1000px" }}
                header={t("drawdown")}
                modal
                className="p-fluid"
                onHide={this.hideUpdateDrawdownDialog}
              >
                <div className="grid">
                  <>
                    {/*Select start date of the site works Header*/}
                    <div className="col-12 md:col-6 lg:col-4">
                      <br />
                      <div className="mb-2">
                        <br />
                        <label
                          style={{
                            fontSize: "14px",
                            fontStyle: "Roboto",
                          }}
                        >
                          {t("selectStartDateOfTheSiteWorks")}
                        </label>
                      </div>
                    </div>

                    {/*Select start date of the site works Dropdowns*/}
                    <div className="col-12 md:col-6 lg:col-8">
                      <div className="grid">
                        {/*Select start date of the site works (Dropdown Month)*/}
                        <div className="col-12 md:col-6 lg:col-4">
                          <div className="row ">
                            <div class="col-12">
                              <br />
                              <Calendar
                                id="yearpicker"
                                placeholder={t("pleaseSelectYear")}
                                value={new Date(this.state.year)}
                                disabled={this.state.disableMonthYearCell}
                                onChange={(e) => {
                                  this.setState({
                                    year: e.target.value,
                                  });
                                }}
                                view="year"
                                dateFormat="yy"
                                className="w-full"
                              />
                            </div>
                          </div>
                        </div>

                        {/*Select start date of the site works (Dropdown Year)*/}
                        <div className="col-12 md:col-6 lg:col-8">
                          <div className="row">
                            <div class="col-6">
                              <br />
                              <Dropdown
                                id="secondRegisteredDisabled"
                                value={this.state.month}
                                options={monthsAbbreviations}
                                placeholder={t("pleaseSelectMonth")}
                                disabled={this.state.disableMonthYearCell}
                                onChange={(e) => {
                                  this.setState({
                                    month: e.target.value,
                                  });
                                }}
                                optionLabel="name"
                                optionValue="code"
                                className="w-full"
                              />
                            </div>

                            {/*update button*/}
                            <div class="col-4">
                              <br />
                              <Button
                                label={t("update")}
                                className="p-button-mb"
                                disabled={this.state.disableMonthYearCell}
                                onClick={() => {
                                  this.getBudgetBreakdownByMonthAndYear();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                    <br />
                  </>
                </div>

                {/******************************** budget breakdown ************************ */}

                <Panel
                  headerTemplate={this.budgetBreakdownHeader}
                  style={{ backgroundColor: "#0094A833" }}
                >
                  {/*row-1 */}
                  <div class="flex">
                    {/*advocacy header*/}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("advocacy")}
                      </label>
                    </div>

                    {/*budget advocacy*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetAdvocacy.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown advocacyCost*/}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.advocacyCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "advocacyCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown advocacyVat*/}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.advocacyVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "advocacyVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*total advocacy*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.advocacyCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.advocacyVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-2 */}
                  <div class="flex">
                    {/*structuralSurvey header*/}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("structuralSurvey")}
                      </label>
                    </div>

                    {/*budget structuralSurvey*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetStructuralSurvey.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown structuralSurveyCost*/}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="structuralSurveyCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.structuralSurveyCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "structuralSurveyCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown structuralSurveyVat*/}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="structuralSurveyVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.structuralSurveyVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "structuralSurveyVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*total structuralSurvey*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.structuralSurveyCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.structuralSurveyVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-3 */}
                  <div class="flex">
                    {/*survey header*/}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("survey")}
                      </label>
                    </div>

                    {/*budget survey */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetSurvey.toString())}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown surveyCost */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="surveyCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.surveyCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "surveyCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown surveyVat */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="surveyVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.surveyVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "surveyVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*total survey*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this?.state?.budgetBreakdown?.surveyCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this?.state?.budgetBreakdown?.surveyVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-4 */}
                  <div class="flex">
                    {/*energyEfficiencySurvey header*/}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("energyEfficiencySurvey")}
                      </label>
                    </div>

                    {/*budget energyEfficiencySurvey */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetEnergyEfficiencySurvey.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown energyEfficiencySurveyCost */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="energyEfficiencySurveyCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.energyEfficiencySurveyCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "energyEfficiencySurveyCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*budgetbreakdown energyEfficiencySurveyVat */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="energyEfficiencySurveyVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.energyEfficiencySurveyVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "energyEfficiencySurveyVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*total  energyEfficiencySurvey*/}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.energyEfficiencySurveyCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.energyEfficiencySurveyVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-5 */}
                  <div class="flex">
                    {/*furniture header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("furniture")}
                      </label>
                    </div>

                    {/*furniture budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetFurniture.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*furnitureCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="furnitureCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.furnitureCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "furnitureCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*furnitureCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="furnitureVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.furnitureVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "furnitureVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*furniture total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.furnitureCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.furnitureVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-6 */}
                  <div class="flex">
                    {/*lrsFee header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("LRSFee")}
                      </label>
                    </div>

                    {/*lrsFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetLrsFee.toString())}
                        className="w-full"
                      />
                    </div>

                    {/*lrsFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lrsFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lrsFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "lrsFeeCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lrsFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lrsFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lrsFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "lrsFeeVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lrsFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.lrsFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.lrsFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-7 */}
                  <div class="flex">
                    {/*valuation header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("valuation")}
                      </label>
                    </div>

                    {/*valuation budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetValuation.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*valuationCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="valuationCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.valuationCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "valuationCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*valuationVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="valuationVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.valuationVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "valuationVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*valuation total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.valuationCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.valuationVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-8 */}
                  <div class="flex">
                    {/*qsAndSurveyor header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("qsAndSurveyor")}
                      </label>
                    </div>

                    {/*qsAndSurveyor budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetQsAndSurveyor.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*qsAndSurveyorCost */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="qsAndSurveyorCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.qsAndSurveyorCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "qsAndSurveyorCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*qsAndSurveyorVat */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="qsAndSurveyorVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.qsAndSurveyorVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "qsAndSurveyorVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*qsAndSurveyor total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.qsAndSurveyorCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.qsAndSurveyorVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-9 */}
                  <div class="flex">
                    {/*lendersArrangementFee header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("lendersArrangementFee")}
                      </label>
                    </div>

                    {/*lendersArrangementFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetLendersArrangementFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*lendersArrangementFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lendersArrangementFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lendersArrangementFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "lendersArrangementFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lendersArrangementFeeVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lendersArrangementFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lendersArrangementFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "lendersArrangementFeeVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lendersArrangementFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.lendersArrangementFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.lendersArrangementFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-10 */}
                  <div class="flex">
                    {/*workCosts header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("workCosts")}
                      </label>
                    </div>

                    {/*workCosts budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetWorkCosts.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*workCostsCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="workCostsCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.workCostsCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "workCostsCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*workCostsVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="workCostsVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.workCostsVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "workCostsVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*workCosts total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.workCostsCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.workCostsVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-11*/}
                  <div class="flex">
                    {/*relocationCosts header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("relocationCosts")}
                      </label>
                    </div>

                    {/*relocationCosts budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetRelocationCosts.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*relocationCostsCost budgetBreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="relocationCostsCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.relocationCostsCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "relocationCostsCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*relocationCostsVat budgetBreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="relocationCostsVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.relocationCostsVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "relocationCostsVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*relocationCosts total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.relocationCostsCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.relocationCostsVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-12 */}
                  <div class="flex">
                    {/*relocationCostsVat header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("independentFinancialAdvisorFee")}
                      </label>
                    </div>

                    {/*independentFinancialAdvisorFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetIndependentFinancialAdvisorFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*independentFinancialAdvisorFeeCost budgetBreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="independentFinancialAdvisorFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown
                            .independentFinancialAdvisorFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "independentFinancialAdvisorFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*independentFinancialAdvisorFeeVat budgetBreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="independentFinancialAdvisorFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown
                            .independentFinancialAdvisorFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "independentFinancialAdvisorFeeVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*independentFinancialAdvisorFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.independentFinancialAdvisorFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.independentFinancialAdvisorFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-13 */}
                  <div class="flex">
                    {/*lendersLegalFee header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("lendersLegalFee")}
                      </label>
                    </div>

                    {/*lendersLegalFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetLendersLegalFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*lendersLegalFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lendersLegalFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lendersLegalFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "lendersLegalFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lendersLegalFeeVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="lendersLegalFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.lendersLegalFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "lendersLegalFeeVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*lendersLegalFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.lendersLegalFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.lendersLegalFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-14 */}
                  <div class="flex">
                    {/*fundsTransferFeeC header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("fundsTransferFee")}
                      </label>
                    </div>

                    {/*lendersLegalFee budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetFundsTransferFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*fundsTransferFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="fundsTransferFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.fundsTransferFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "fundsTransferFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*fundsTransferFeeVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="fundsTransferFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.fundsTransferFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "fundsTransferFeeVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*fundsTransferFee total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown
                                  ?.fundsTransferFeeCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.fundsTransferFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-15 */}
                  <div class="flex">
                    {" "}
                    {/*legals header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("legals")}
                      </label>
                    </div>
                    {/*legals budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetLegals.toString())}
                        className="w-full"
                      />
                    </div>
                    {/*legalsCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="legalsCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.legalsCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "legalsCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                    {/*legalsVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="legalsVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.legalsVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "legalsVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                    {/*legals total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.legalsCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.legalsVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-16 */}
                  <div class="flex">
                    {/*clientLegals header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("clientLegals")}
                      </label>
                    </div>

                    {/*clientlegals budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetClientLegals.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*clientLegalsCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="clientLegalsCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.clientLegalsCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "clientLegalsCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*clientLegalsVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="clientLegalsVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.clientLegalsVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "clientLegalsVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*clientLegals total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.clientLegalsCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.clientLegalsVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-17 */}
                  <div class="flex">
                    {/*projectManager header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("projectManager")}
                      </label>
                    </div>

                    {/*projectManager budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetProjectManager.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*projectManagerCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="projectManagerCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.projectManagerCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "projectManagerCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*projectManagerVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="projectManagerVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.projectManagerVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "projectManagerVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*projectManager total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.projectManagerCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.projectManagerVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-18 */}
                  <div class="flex">
                    {/*drainInspection header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("drainInspection")}
                      </label>
                    </div>

                    {/*drainInspection budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetDrainInspection.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*drainInspectionCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="drainInspectionCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.drainInspectionCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "drainInspectionCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*drainInspectionVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="drainInspectionVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.drainInspectionVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "drainInspectionVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*drainInspection total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.drainInspectionCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.drainInspectionVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-19*/}
                  <div class="flex">
                    {/*buildingControl header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("buildingControl")}
                      </label>
                    </div>

                    {/*buildingControl budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetBuildingControl.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*buildingControlCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="buildingControlCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.buildingControlCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "buildingControlCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*buildingControlVAT budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="buildingControlVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.buildingControlVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "buildingControlVat"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*buildingControl total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="buildingControlTotal"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.buildingControlCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.buildingControlVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-20 */}
                  <div class="flex">
                    {/*planningFee  header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("planningFee")}
                      </label>
                    </div>

                    {/*planningFeeCost budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(
                          remainingBudgetPlanningFee.toString()
                        )}
                        className="w-full"
                      />
                    </div>

                    {/*planningFeeCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="planningFeeCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.planningFeeCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(
                            e,
                            "planningFeeCost"
                          )
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*planningFeeVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="planningFeeVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.planningFeeVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "planningFeeVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*drainInspection total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="planningFeeTotal"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.planningFeeVat
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.planningFeeVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-21 */}
                  <div class="flex">
                    {/*other header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {this.state.budget.otherName}
                      </label>
                    </div>

                    {/*other budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetOther.toString())}
                        className="w-full"
                      />
                    </div>

                    {/*otherCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="otherCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.otherCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "otherCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*otherVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="otherVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.otherVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "otherVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*other total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="advocacyVat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.otherCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.otherVat
                              )
                            )
                          ).toString()
                        )}
                        placeholder={t("pleaseEnter")}
                        maxLength={20}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-22 */}
                  <div class="flex">
                    {/*vat header */}
                    <div class="flex-1 p-4">
                      <label
                        style={{
                          fontSize: "14px",
                          fontStyle: "Roboto",
                        }}
                      >
                        {t("vat")}
                      </label>
                    </div>

                    {/*vat budget */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        value={this.moneyMask(remainingBudgetVat.toString())}
                        className="w-full"
                      />
                    </div>

                    {/*vatCost budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="vatCost"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.vatCost
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "vatCost")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*vatVat budgetbreakdown */}
                    <div class="flex-1 p-4">
                      <InputText
                        onClick={this.selectInputText}
                        style={{ textAlign: "right" }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="vatVat"
                        value={this.moneyMask(
                          this.state.budgetBreakdown.vatVat
                        )}
                        placeholder={t("pleaseEnter")}
                        onChange={(e) =>
                          this.onInputChangeBudgetBreakdown(e, "vatVat")
                        }
                        maxLength={20}
                        className="w-full"
                      />
                    </div>

                    {/*vat total */}
                    <div class="flex-1 p-4">
                      <InputText
                        style={{
                          backgroundColor: "#cecccd",
                          textAlign: "right",
                        }}
                        readOnly={this.state.nonEditAccess}
                        disabled={this.state.disableDrawdownCell}
                        id="vat"
                        value={this.moneyMask(
                          (
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.vatCost
                              )
                            ) +
                            parseFloat(
                              this.removeAllSpecialCharacters(
                                this.state?.budgetBreakdown?.vatVat
                              )
                            )
                          ).toString()
                        )}
                        className="w-full"
                      />
                    </div>
                  </div>

                  {/*row-21 */}
                  <div class="flex" style={{ backgroundColor: "#D9D9D980" }}>
                    {/*totalBudget header */}
                    <div class="flex-1 p-4">
                      <div className="p-1 flex-fill">
                        <b>{t("totalBudget")}</b>
                      </div>
                    </div>

                    {/* totalBudget */}
                    <div class="flex-1 p-4">
                      <div className="d-flex ">
                        <div
                          className="p-1 flex-fill"
                          style={{ textAlign: "right" }}
                        >
                          <b>
                            {" "}
                            {this.moneyMask(remainingTotalBudget.toString())}
                          </b>
                        </div>
                      </div>
                    </div>

                    {/*costTotal BudgetBreakdown */}
                    <div class="flex-1 p-4">
                      <div
                        className="p-1 flex-fill"
                        style={{ textAlign: "right" }}
                      >
                        <b>
                          {this.moneyMask(
                            this.calculateBudgetBreakdownCostSummation().toString()
                          )}
                        </b>
                      </div>
                    </div>

                    {/*vatTotal BudgetBreakdown */}
                    <div class="flex-1 p-4">
                      <div
                        className="p-1 flex-fill"
                        style={{ textAlign: "right" }}
                      >
                        <b>
                          {this.moneyMask(
                            this.calculateBudgetBreakdownVATSummation().toString()
                          )}
                        </b>
                      </div>
                    </div>

                    {/*totalCost BudgetBreakdown */}
                    <div class="flex-1 p-4">
                      <div
                        className="p-1 flex-fill"
                        style={{ textAlign: "right" }}
                      >
                        <b>
                          {this.moneyMask(
                            this.calculateBudgetBreakdowntTotalSummation().toString()
                          )}
                        </b>
                      </div>
                    </div>
                  </div>

                  <Divider />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      className="flex justify-content-start"
                      id="dialogButtonDelete"
                    >
                      <Button
                        label={t("deleteProjectBtn")}
                        className="icon-red"
                        onClick={() =>
                          this.setState({
                            deleteBudgetBreakdownConfirmDialogVisible: true,
                          })
                        }
                      />
                    </div>

                    <div
                      className="flex justify-content-end"
                      id="dialogButtonCancel"
                    >
                      <div>
                        <Button
                          label={t("CANCEL")}
                          className="p-button-mb-gray"
                          onClick={() =>
                            this.setState({
                              updateDrawdownDialogVisible: false,
                            })
                          }
                        />
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div>
                        <Button
                          disabled={!this.state.saveButtonStatus}
                          label={t("SAVE")}
                          className="p-button-mb"
                          onClick={() => this.monthlyReportSave()}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
              </Dialog>
              {/*update cost claim  dialog */}
              <Dialog
                visible={this.state.updateCostClaimDialogVisible}
                style={{ width: "550px" }}
                header={t("costClaimAndPayment")}
                modal
                className="p-fluid"
                footer={updateCostClaimFooter}
                onHide={this.hideCostClaimDialog}
              >
                <Card style={{ height: "112px", background: "#CCEAEE" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div>
                          <label className="costClaimCardLabel">
                            {t(this?.state?.selectedRow?.month)}
                          </label>
                        </div>
                        <div>
                          <label className="costClaimCardLabel">
                            {this?.state?.selectedRow?.year}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div>
                          <label className="costClaimCardLabel">
                            {t("net")}
                          </label>{" "}
                        </div>
                        <div className="costClaimCardText">
                          {this.moneyMask(
                            this.calculateNetSummation(
                              this.state.selectedRow
                            ).toString()
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div>
                          <label className="costClaimCardLabel">
                            {t("vat")}
                          </label>
                        </div>
                        <div className="costClaimCardText">
                          {this.moneyMask(
                            this.calculateVATSummation(
                              this.state.selectedRow
                            ).toString()
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div>
                          <label className="costClaimCardLabel">
                            {t("total")}
                          </label>
                        </div>
                        <div className="costClaimCardText">
                          {this.moneyMask(
                            this.calculateTotalSummation(
                              this.state.selectedRow
                            ).toString()
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>

                <div className="p-float-label">
                  <Dropdown
                    id="idTypes"
                    disabled={this.state.nonEditAccess}
                    value={this?.state?.newCostClaimPayments?.claimStatus}
                    options={CostClaimPaymentOptions}
                    onChange={(e) => {
                      this.onInputChangeClaimCost(e, "claimStatus");
                    }}
                    optionLabel="name"
                    optionValue="code"
                    className="w-full"
                  />
                  <label htmlFor="status" className="platformColor">
                    {t("status")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    disabled={this.state.disableCostClaimCell}
                    id="amountClaimed"
                    value={this.state.newCostClaimPayments.amountClaimed}
                    onChange={(e) =>
                      this.onInputChangeClaimCost(e, "amountClaimed")
                    }
                    maxLength={20}
                    className="p-inputtext block mb-2"
                    keyfilter="num"
                  />
                  <label htmlFor="amountClaimed" className="platformColor">
                    {t("amountClaimed")} £ (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="totalToDate"
                    value={this.state.newCostClaimPayments.totaltoDate}
                    onChange={(e) =>
                      this.onInputChangeClaimCost(e, "totaltoDate")
                    }
                    maxLength={20}
                    className="p-inputtext block mb-2"
                    keyfilter="num"
                    disabled={this.state.disableCostClaimCell}
                  />
                  <label htmlFor="totalToDate" className="platformColor">
                    {t("totalToDate")} £ (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="notesMonthYear"
                    value={this.state.newCostClaimPayments.note}
                    onChange={(e) => this.onInputChangeClaimCost(e, "note")}
                    maxLength={500}
                    className="p-inputtext block mb-2"
                    disabled={this.state.disableCostClaimCell}
                  />
                  <label htmlFor="notesMonthYear" className="platformColor">
                    {t("notes")} (*)
                  </label>
                </div>
              </Dialog>
              <ConfirmDialog
                visible={this.state.deleteDocumentConfirmDialogVisible}
                onHide={() => {
                  this.setState({ deleteDocumentConfirmDialogVisible: false });
                }}
                message={t("confirmMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle"
                accept={this.acceptDeleteDocument}
                reject={this.rejectDeleteDocument}
              />
              <ConfirmDialog
                visible={this.state.deleteBudgetBreakdownConfirmDialogVisible}
                onHide={() => {
                  this.setState({
                    deleteBudgetBreakdownConfirmDialogVisible: false,
                  });
                }}
                message={t("confirmMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-red"
                acceptLabel={t("Delete")}
                rejectLabel={t("cancel")}
                accept={this.acceptDeleteBudgetBreakdown}
                reject={this.rejectDeleteDocument}
              />
              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />
              <WaitDialog loading={this.state.loading} lang={t} />
              <WaitDialogDocument
                loading={this.state.documentLoading}
                lang={t}
              />
              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(FinancialManagement));
