import React, { Component } from "react";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";

import { isUser, isViewerExpert } from "../common/token-check";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";

import SysService from "../services/sys.service";

import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import {
  rowClass,
  handleDownloadEQDatasheetDocument,
} from "../common/functions";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart } from "chart.js";

Chart.register(zoomPlugin);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title,
  Legend
);

class IndoorQE extends Component {
  emptyEQ = {
    eqRoomName: "",
    eqDeviceName: "",
    eqDeviceType: "",
    eqBrandNameModel: "",
    eqDeviceEUI: "",
    eqDatasheetURL: "",
  };

  sendButtonNewDialog = {
    timeInterval: "",
    reportTime: "",
  };

  generateButtonNewDialog = {
    emailAddress: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",

      pid: "",
      visibleCustomSidebar: false,
      nonEditAccess: true,
      activeIndex: 0,

      p91Documents: [],
      p91DocumentIndex: -1,
      p91SelectedDocument: null,

      eqs: [],
      newEQ: this.emptyEQ,
      addEQDialogVisible: false,
      addEQConfirmDialogVisible: false,

      updateEQDialogVisible: false,
      updateEQConfirmDialogVisible: false,

      eqDataVisible: false,
      selectedEquipment: null,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      s3Response: "",
      tbuFile: null,
      doc: "",

      saveButtonStatus: false,

      filters1: null,
      globalFilterValue1: "",

      generateDialogVisible: false,
      sendDialogVisible: false,

      reportTime: "",
      timeInterval: "",
      emailAddress: "",
    };

    this.sensorData = [];
    this.ts = "";
    this.co2 = "";
    this.lig = "";
    this.hum = "";
    this.tem = "";
    this.bat = "";
    this.gw = "";

    this.tsArr = [];
    this.co2Arr = [];
    this.ligArr = [];
    this.humArr = [];
    this.temArr = [];
  }

  /*JS_methods*/
  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeEQ = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newEQ = { ...this.state.newEQ };
    newEQ[`${name}`] = val;

    this.setState({ newEQ });
  };

  showEqData = (e, name) => {
    const val = (e.target && e.target.value) || "";

    this.setState({
      loading: true,
      selectedEquipment: val,
    });

    this.sensorData = [];
    this.ts = "";
    this.co2 = "";
    this.lig = "";
    this.hum = "";
    this.tem = "";
    this.bat = "";
    this.gw = "";

    SysService.getSensorData(val.eqDeviceEUI).then(
      (response) => {
        this.sensorData = JSON.parse(response.data.jsonVal);

        if (this.sensorData.length > 0) {
          this.ts = this.sensorData[this.sensorData.length - 1].ts;
          this.co2 = this.sensorData[this.sensorData.length - 1].co2;
          this.lig = this.sensorData[this.sensorData.length - 1].lig;
          this.hum = this.sensorData[this.sensorData.length - 1].hum;
          this.tem = this.sensorData[this.sensorData.length - 1].tem;
          this.bat = this.sensorData[this.sensorData.length - 1].bat;
          this.gw = this.sensorData[this.sensorData.length - 1].gw;

          for (let i = 0; i < this.sensorData.length; i++) {
            this.tsArr.push(this.sensorData[i].ts);
            this.ligArr.push(this.sensorData[i].lig);
            this.humArr.push(this.sensorData[i].hum);
            this.temArr.push(this.sensorData[i].tem);
            this.co2Arr.push(this.sensorData[i].co2);
          }
        }
        this.setState({
          loading: false,
          blockedPanel: false,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);
      }
    );

    this.setState({
      eqDataVisible: true,
    });
  };

  actionTemplate = (data, props) => {
    return (
      <div>
        {data.eqDatasheetURL !== "" && (
          <Button
            icon="pi pi-download"
            href
            tooltip="Download"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              handleDownloadEQDatasheetDocument(data, props);
            }}
          />
        )}

        {!isViewerExpert() && (
          <>
            <Button
              icon="pi pi-pencil"
              href
              tooltip="Update"
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.handleUpdateEQ(data, props);
              }}
            />
            <Button
              icon="pi pi-trash"
              tooltip="Delete"
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.handleDeleteEQ(data, props);
              }}
            />
          </>
        )}
      </div>
    );
  };

  handleDeleteEQ = (rowData) => {
    const index = this.state.eqs.indexOf(rowData);
    if (index > -1) {
      this.state.eqs.splice(index, 1);
    }

    this.setState({
      newEQ: this.emptyEQ,
      addEQDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  handleAddEQ = () => {
    this.setState({
      newEQ: this.emptyEQ,
      submitted: false,
      addEQDialogVisible: true,
    });
  };

  hideAddEQDialog = () => {
    this.setState({
      submitted: false,
      addEQDialogVisible: false,
    });
  };

  handleUpdateEQ = (rowData) => {
    const index = this.state.eqs.indexOf(rowData);

    this.setState({
      newEQ: rowData,
      submitted: false,
      updateEQDialogVisible: true,
      deviceIndex: index,
    });
  };

  hideUpdateEQDialog = () => {
    this.setState({
      submitted: false,
      updateEQDialogVisible: false,
    });
  };

  hideShowEQDialog = () => {
    this.setState({
      eqDataVisible: false,
    });
  };

  handleSendButton = () => {
    this.setState({
      sendButtonNewDialog: this.sendButtonNewDialog,
      submitted: false,
      sendDialogVisible: true,
    });
  };

  handleGenerateButton = () => {
    this.setState({
      generateButtonNewDialog: this.generateButtonNewDialog,
      submitted: false,
      generateDialogVisible: true,
    });
  };

  hideGenerateDialog = () => {
    this.setState({
      submitted: false,
      generateDialogVisible: false,
    });
  };

  hideSendDialog = () => {
    this.setState({
      submitted: false,
      sendDialogVisible: false,
    });
  };

  /*Equipment Add Dialog*/
  acceptAddEQ = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (
      this.state.newEQ.eqRoomName == null ||
      this.state.newEQ.eqRoomName === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheRoomName" />);

      return;
    }

    if (
      this.state.newEQ.eqDeviceName == null ||
      this.state.newEQ.eqDeviceName === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceName" />);

      return;
    } else if (
      this.state.newEQ.eqDeviceType == null ||
      this.state.newEQ.eqDeviceType === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceType" />);

      return;
    } else if (
      this.state.newEQ.eqBrandNameModel == null ||
      this.state.newEQ.eqBrandNameModel === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheBrandNameModel" />);

      return;
    } else if (
      this.state.newEQ.eqDeviceEUI == null ||
      this.state.newEQ.eqDeviceEUI === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceEUI" />);

      return;
    }

    if (fileX !== undefined) {
      const maxAllowedSize = 5 * 1024 * 1024;

      if (fileX.size > maxAllowedSize) {
        this.setState({ loading: false });

        toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

        return;
      }

      this.setState({
        documentLoading: true,
      });

      SysService.getS3URL(
        fileX.name,
        "P9" + this.state.activeIndex,
        this.state.pid
      ).then(
        (response) => {
          this.setState(
            {
              s3Response: response.data,
              tbuFile: fileX,
              saveButtonStatus: true,
            },
            () => {
              this.state.newEQ.eqDatasheetURL =
                this.state.s3Response.documentURL;
            }
          );

          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }

    this.setState({ saveButtonStatus: true, addEQDialogVisible: false }, () => {
      this.state.eqs.push(this.state.newEQ);
    });
  };

  rejectAddEQ = () => {};

  /*Equipment Update Dialog*/
  acceptUpdateEQ = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (
      this.state.newEQ.eqRoomName == null ||
      this.state.newEQ.eqRoomName === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheRoomName" />);

      return;
    }

    if (
      this.state.newEQ.eqDeviceName == null ||
      this.state.newEQ.eqDeviceName === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceName" />);

      return;
    } else if (
      this.state.newEQ.eqDeviceType == null ||
      this.state.newEQ.eqDeviceType === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceType" />);

      return;
    } else if (
      this.state.newEQ.eqBrandNameModel == null ||
      this.state.newEQ.eqBrandNameModel === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheBrandNameModel" />);

      return;
    } else if (
      this.state.newEQ.eqDeviceEUI == null ||
      this.state.newEQ.eqDeviceEUI === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceEUI" />);

      return;
    }

    if (fileX !== undefined) {
      const maxAllowedSize = 5 * 1024 * 1024;

      if (fileX.size > maxAllowedSize) {
        this.setState({ loading: false });

        toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

        return;
      }

      this.setState({
        documentLoading: true,
      });

      SysService.getS3URL(
        fileX.name,
        "P9" + this.state.activeIndex,
        this.state.pid
      ).then(
        (response) => {
          this.setState(
            {
              s3Response: response.data,
              tbuFile: fileX,
              saveButtonStatus: true,
            },
            () => {
              this.state.newEQ.eqDatasheetURL =
                this.state.s3Response.documentURL;
            }
          );

          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }

    this.setState(
      { saveButtonStatus: true, updateEQDialogVisible: false },
      () => {
        this.state.eqs[this.state.deviceIndex] = this.state.newEQ;
      }
    );
  };

  rejectUpdateEQ = () => {};

  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let temp91Documents = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P91") {
              temp91Documents.push(response.data.documentList[i]);
            }
          }
        }

        this.setState({
          project: response.data,
          eqs: response.data.equipmentList,
          p91Documents: temp91Documents,
          loading: false,
          blockedPanel: false,
          nonEditAccess:
            response.data.status === 2 && !isViewerExpert() ? false : true,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.initFilters1();
    window.scrollTo(0, 0);
  }

  /**/
  getS3URL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }

    this.setState({
      loading: true,
    });

    if (!isViewerExpert()) {
      SysService.getS3URL(
        fileNameX,
        this.state.doc.documentType,
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (!isViewerExpert()) {
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addEQDialogVisible: false,
        updateEQDialogVisible: false,
      });
    }
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.equipments = this.state.eqs;
        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P9" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
            });

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }

    window.scrollTo(0, 0);
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("equipments")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  };

  dropdownEqTemplate = (data) => {
    return (
      <>
        {data.eqRoomName}: {data.eqDeviceName} - {data.eqBrandNameModel} (
        {data.eqDeviceEUI})
      </>
    );
  };

  projectIDTemplate(data) {
    let arr = [];

    arr = Array.from(String(data), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }
    let arrRes = arrX.concat(arr);
    let str = "";
    str = str + arrRes.join("");
    return str;
  }

  /**/
  render() {
    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);

    {
      /*Chart options*/
    }
    const options = {
      responsive: true,
      stacked: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: false,
            },
            mode: "x",
          },
        },
      },
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
        },
      },
    };

    const options2 = {
      responsive: true,
      stacked: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: false,
            },
            mode: "x",
          },
        },
      },
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
        },
      },
    };

    const labels = this.tsArr;
    const data = {
      labels,
      datasets: [
        {
          label: "Humidity",
          data: this.humArr,
          borderColor: "rgba(0, 0, 255, 0.5)",
          borderCapStyle: "butt",
          backgroundColor: "rgba(0, 0, 255, 0.5)",
          yAxisID: "y",
        },
        {
          label: "Temperature",
          data: this.temArr,
          borderColor: "rgb(0, 255, 0)",
          borderCapStyle: "butt",
          backgroundColor: "rgba(0, 255, 0, 0.5)",
          yAxisID: "y",
        },
      ],
    };

    const data2 = {
      labels,
      datasets: [
        {
          label: "Light",
          data: this.ligArr,
          borderColor: "rgb(255, 0, 0)",
          borderCapStyle: "butt",
          backgroundColor: "rgba(255, 0, 0, 0.5)",
          yAxisID: "y",
        },
      ],
    };

    this.stepItems = [
      {
        label: t("equipmentRegistration"),
        command: (event) => {},
      },
      {
        label: t("visualizeMeasuredData"),
        command: (event) => {},
      },
    ];

    const addEQDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddEQDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.setState({ addEQConfirmDialogVisible: true })}
        />

        <ConfirmDialog
          visible={this.state.addEQConfirmDialogVisible}
          onHide={() => {
            this.setState({ addEQConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptAddEQ}
          reject={this.rejectAddEQ}
        />
      </React.Fragment>
    );

    const updateEQDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideUpdateEQDialog}
        />
        <Button
          label={t("update")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.setState({ updateEQConfirmDialogVisible: true })}
        />

        <ConfirmDialog
          visible={this.state.updateEQConfirmDialogVisible}
          onHide={() => {
            this.setState({ updateEQConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptUpdateEQ}
          reject={this.rejectUpdateEQ}
        />
      </React.Fragment>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <Card
                style={{ height: "3.8em", background: "rgb(230, 240, 247)" }}
              >
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      style={{ fontSize: "18px", fontStyle: "Manrope" }}
                      label={
                        this.state.project.projectName +
                        " (ID: " +
                        this.projectIDTemplate(this.state.project.id) +
                        ")"
                      }
                      className="mb-2 custom-chip-home"
                    />
                  </Link>
                </div>
              </Card>

              <br />

              <br />

              <div className={"col-12 "}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      style={{
                        color: "0094A8",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: "lighter",
                      }}
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />
                    <div>
                      <Chip
                        style={{ fontStyle: "Manrope", fontSize: "14px" }}
                        label={<b>{t("phase") + " 9: " + t("phase9Name")}</b>}
                        icon="pi pi-globe"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                      onSelect={(e) => {
                        if (!this.state.saveButtonStatus) {
                          this.setState({ activeIndex: e.index });
                        } else {
                          if (this.state.activeIndex !== e.index) {
                            toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
                          }
                        }
                      }}
                      readOnly={false}
                    />
                  </div>

                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >
                    {/*Phase9_Equipment_Registration*/}
                    <TabPanel header={t("equipmentRegistration")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p91Text")}</div>
                      </div>

                      <div className="grid">
                        {/*add Equipment Button*/}

                        <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddEq")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddEQ}
                            />
                          )}
                        </div>

                        {/*DOCUMENTS TABLE*/}
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.eqs}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noEquipmentFound")}
                              header={headerTable}
                              paginator
                              rows={10}
                              filters={this.state.filters1}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "eqRoomName",
                                "eqDeviceName",
                                "eqDeviceType",
                                "eqDeviceEUI",
                                "eqBrandNameModel",
                              ]}
                            >
                              <Column
                                field="eqRoomName"
                                header={t("roomName")}
                                sortable
                              ></Column>
                              <Column
                                field="eqDeviceName"
                                header={t("deviceName")}
                                sortable
                              ></Column>
                              <Column
                                field="eqDeviceType"
                                header={t("deviceType")}
                                sortable
                              ></Column>
                              <Column
                                field="eqDeviceEUI"
                                header={t("deviceEUI")}
                                sortable
                              ></Column>
                              <Column
                                field="eqBrandNameModel"
                                header={t("brandNameModel")}
                                sortable
                              ></Column>
                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "12em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase9_Visualize_Measured_Data_(IoT Devices)*/}
                    <TabPanel header={t("visualizeMeasuredData")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div className="root-preline">{t("p92Text")}</div>
                      </div>

                      <div className="grid">
                        {/*selectEquipment*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="selectEquipment"
                              className="platformColor"
                            >
                              {t("selectEquipment")} (*)
                            </label>
                          </div>
                          <div className="grid">
                            <div className="col-12 md:col-11 lg:col-11">
                              <Dropdown
                                id="selectEquipment"
                                value={this.state.selectedEquipment}
                                options={this.state.eqs}
                                onChange={(e) =>
                                  this.showEqData(e, "selectedDevice")
                                }
                                optionLabel={this.dropdownEqTemplate}
                                className="w-full"
                              />
                            </div>
                            <div className="col-12 md:col-1 lg:col-1">
                              <Button
                                disabled={this.state.selectedEquipment == null}
                                icon="pi pi-chart-bar"
                                href
                                tooltip="Show"
                                className="p-button-rounded p-button-text"
                                onClick={(e) => {
                                  this.setState({ eqDataVisible: true });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {/*selectTimeInterval}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="selectTimeInterval"
                              className="platformColor"
                            >
                              {t("selectTimeInterval")} (*)
                            </label>
                          </div>
                          <div>
                            <Dropdown
                              id="selectTimeInterval"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.selectTimeInterval}
                              options={currentValuation}
                              onChange={(e) =>
                                this.onInputChange(e, "selectTimeInterval")
                              }
                              optionLabel={t("selectTimeInterval")}
                              optionValue="code"
                              className="w-full"
                            />
                          </div>
                        </div>
                      */}
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <br />
                <div
                  class="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div class="btn-group" role="group" aria-label="First group">
                    <Button
                      style={{ fontSize: "14px", fontStyle: "Manrope" }}
                      icon="pi pi-chevron-circle-left"
                      disabled={this.state.activeIndex <= 0}
                      label={t("previous")}
                      className="p-button-sm p-button-mb"
                      onClick={() => this.stepPrev()}
                    />
                    &nbsp;
                    <Button
                      icon="pi pi-chevron-circle-right"
                      disabled={
                        this.state.activeIndex >= this.stepItems.length - 1
                      }
                      style={{ fontSize: "14px", fontStyle: "Manrope" }}
                      label={t("next")}
                      className="p-button-sm p-button-mb"
                      onClick={() => {
                        this.stepNext();
                      }}
                    />
                    &nbsp;
                    {!isViewerExpert() && (
                      <Button
                        disabled={!this.state.saveButtonStatus}
                        style={{ fontSize: "14px", fontStyle: "Manrope" }}
                        icon="pi pi-check-circle"
                        label={
                          this.state.activeIndex < this.stepItems.length - 1
                            ? t("saveNext")
                            : t("save")
                        }
                        className="p-button-sm p-button-danger"
                        onClick={() => this.stepSaveAndNext()}
                      />
                    )}
                  </div>
                </div>
              </div>
              <br />

              {/*Equipments Dialog */}
              <Dialog
                visible={this.state.addEQDialogVisible}
                style={{ width: "450px" }}
                header={t("equipment")}
                className="p-fluid"
                footer={addEQDialogFooter}
                onHide={this.hideAddEQDialog}
              >
                <div className="p-float-label">
                  <InputText
                    id="eqRoomName"
                    value={this.state.newEQ.eqRoomName}
                    onChange={(e) => this.onInputChangeEQ(e, "eqRoomName")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqRoomName">{t("roomName")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceName"
                    value={this.state.newEQ.eqDeviceName}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceName")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceName">{t("deviceName")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceType"
                    value={this.state.newEQ.eqDeviceType}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceType")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceType">{t("deviceType")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqBrandNameModel"
                    value={this.state.newEQ.eqBrandNameModel}
                    onChange={(e) =>
                      this.onInputChangeEQ(e, "eqBrandNameModel")
                    }
                    maxLength={100}
                    className="w-full"
                  />
                  <label htmlFor="eqBrandNameModel">
                    {t("brandNameModel")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceEUI"
                    value={this.state.newEQ.eqDeviceEUI}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceEUI")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceEUI">{t("deviceEUI")} (*)</label>
                </div>

                <div className="p-float-label">
                  <div className="mb-2">
                    <font color="black">{t("datasheet")}: </font>
                  </div>
                  <input
                    id="fupload"
                    type="file"
                    accept=".xlsx, .xls, .csv, .doc, .docx, .pdf, .jpg, .png, .jpeg, .gif|image/*"
                  ></input>
                </div>
              </Dialog>

              {/*Equipments Update Dialog */}
              <Dialog
                visible={this.state.updateEQDialogVisible}
                style={{ width: "450px" }}
                header={t("equipment")}
                className="p-fluid"
                footer={updateEQDialogFooter}
                onHide={this.hideUpdateEQDialog}
              >
                <div className="p-float-label">
                  <InputText
                    id="eqRoomName"
                    value={this.state.newEQ.eqRoomName}
                    onChange={(e) => this.onInputChangeEQ(e, "eqRoomName")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqRoomName">{t("roomName")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceName"
                    value={this.state.newEQ.eqDeviceName}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceName")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceName">{t("deviceName")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceType"
                    value={this.state.newEQ.eqDeviceType}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceType")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceType">{t("deviceType")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqBrandNameModel"
                    value={this.state.newEQ.eqBrandNameModel}
                    onChange={(e) =>
                      this.onInputChangeEQ(e, "eqBrandNameModel")
                    }
                    maxLength={100}
                    className="w-full"
                  />
                  <label htmlFor="eqBrandNameModel">
                    {t("brandNameModel")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceEUI"
                    value={this.state.newEQ.eqDeviceEUI}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceEUI")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceEUI">{t("deviceEUI")} (*)</label>
                </div>

                <div className="p-float-label">
                  <div className="mb-2">
                    <font color="black">{t("datasheet")} (*): </font>
                    {this.state.newEQ.eqDatasheetURL !== "" && (
                      <a href={this.state.newEQ.eqDatasheetURL} target="_blank">
                        {t("datasheet")}
                      </a>
                    )}
                  </div>
                  <input
                    id="fupload"
                    type="file"
                    accept=".xlsx, .xls, .csv, .doc, .docx, .pdf, .jpg, .png, .jpeg, .gif|image/*"
                  ></input>
                </div>
              </Dialog>

              <Dialog
                visible={this.state.eqDataVisible}
                style={{ width: "1000px" }}
                modal
                className="p-fluid"
                onHide={this.hideShowEQDialog}
              >
                {this.state.selectedEquipment !== null && (
                  <>
                    {this.ts !== "" && (
                      <>
                        <div className="mb-2" style={{ position: "right" }}>
                          <h5>
                            Last data received time : <small>{this.ts}</small>{" "}
                          </h5>
                        </div>
                        <br />

                        <div className="grid">
                          <div className="col-12 md:col-12 lg:col-4">
                            <div id="app">
                              <Card
                                className="card text-center"
                                header={"Temperature"}
                                style={{ height: "10%", fontSize: "16px" }}
                              >
                                <Card
                                  variant="top"
                                  src="holder.js/100px160"
                                  style={{
                                    background:
                                      "linear-gradient(279deg, rgba(17,210,237,1) 33%, rgba(148,230,233,1) 72%)",
                                  }}
                                  subTitle={
                                    <h3 style={{ color: "white" }}>
                                      {this.tem} <small>°C </small>
                                    </h3>
                                  }
                                ></Card>
                              </Card>
                            </div>
                          </div>

                          <div className="col-12 md:col-12 lg:col-4">
                            <div id="app">
                              <Card
                                className="card text-center"
                                header={"Humidity"}
                                style={{ height: "20%", fontSize: "16px" }}
                              >
                                <Card
                                  style={{
                                    background:
                                      "linear-gradient(279deg, rgba(17,210,237,1) 33%, rgba(148,230,233,1) 72%)",
                                  }}
                                  subTitle={
                                    <h3 style={{ color: "white" }}>
                                      {this.hum} <small>%H</small>
                                    </h3>
                                  }
                                ></Card>
                              </Card>
                            </div>
                          </div>

                          <div className="col-12 md:col-12 lg:col-4">
                            <div id="app">
                              <Card
                                className="card text-center"
                                header="Light"
                                style={{ height: "20%", fontSize: "16px" }}
                              >
                                <Card
                                  variant="top"
                                  src="holder.js/100px160"
                                  style={{
                                    background:
                                      "linear-gradient(279deg, rgba(17,210,237,1) 33%, rgba(148,230,233,1) 72%)",
                                  }}
                                  subTitle={
                                    <h3 style={{ color: "white" }}>
                                      {this.lig} <small>lux</small>
                                    </h3>
                                  }
                                ></Card>
                              </Card>
                            </div>
                          </div>
                        </div>

                        <br />
                        <br />

                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontStyle: "Manrope",
                            fontWeight: "bolder",
                            color: "GrayText",
                          }}
                        >
                          {this.state.selectedEquipment.eqRoomName +
                            ": " +
                            this.state.selectedEquipment.eqDeviceName +
                            " - " +
                            this.state.selectedEquipment.eqBrandNameModel +
                            " (" +
                            this.state.selectedEquipment.eqDeviceEUI +
                            ")"}
                        </div>

                        {/*Humidity && Temperature Graph*/}
                        <div className="mb-2">
                          <Line options={options} data={data} />
                        </div>
                        <hr
                          style={{ width: "100%", height: 5, color: "red" }}
                        />
                        {/*Light Graph*/}
                        <div className="mb-2">
                          <Line options={options2} data={data2} />
                        </div>
                      </>
                    )}
                    {this.ts === "" && (
                      <>
                        <div
                          style={{ paddingTop: "50px", paddingBottom: "80px" }}
                        >
                          {t("sensorDataNotFound")}
                        </div>
                      </>
                    )}
                  </>
                )}
              </Dialog>

              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />

              <WaitDialog loading={this.state.loading} lang={t} />
              <WaitDialogDocument
                loading={this.state.documentLoading}
                lang={t}
              />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(IndoorQE));
