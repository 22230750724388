import React, { Component } from "react";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";
import { InputMask } from "primereact/inputmask";
import { Galleria } from "primereact/galleria";
import { FileUpload } from "primereact/fileupload";

import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

import { isUser, isViewerExpert } from "../common/token-check";
import { handleDownloadDocument } from "../common/functions";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import WallInsulation from "../common/ccs_wallInsulation.js";
import FloorInsulation from "../common/css_floorInsulation.js";
import Ventilation from "../common/ccs_ventilation.js";
import Solar from "../common/ccs_solar.js";
import RoofInsulation from "../common/ccs_roofInsulation.js";
import ExternalDoorReplacement from "../common/ccs_externalDoorReplacemen.js";
import HeatingSystemUpgrade from "../common/ccs_heatingSystemUpgrade.js";

import surveyReportReceived from "../common/ccs_surveyReportReceived";
import photosUploaded from "../common/ccs_photosUploaded";
import agreeSpecification from "../common/ccs_agreeSpecification";
import workandCostPlan from "../common/ccs_workandCostPlan";
import workandCostPlanValuationtoUnderwriter from "../common/ccs_workandCostPlanValuationtoUnderwriter";
import YesNo from "../common/ccs_yesNo";
import SysService from "../services/sys.service";

import DocumentUpload from "../common/documentUpload.jsx";
import PdfViewer from "../common/pdfViewer.jsx";

import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

class FS extends Component {
  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: "",
    phase: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",

      pid: "",
      visibleCustomSidebar: false,
      nonEditAccess: true,
      activeIndex: 0,
      galleriaIndex: 0,

      p20Documents: [],
      p20DocumentIndex: -1,
      p20SelectedDocument: null,

      p21Documents: [],
      p21DocumentIndex: -1,
      p21SelectedDocument: null,

      p22Documents: [],
      p22DocumentIndex: -1,
      p22SelectedDocument: null,

      uploadedDocuments: [],

      loading: false,
      documentLoading: false,

      blockedPanel: false,

      s3Response: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      newDocument: this.emptyNewDocument,
      documents: [],

      saveButtonStatus: false,
      selectedDocument: null,
      isRequiredDocumentCompliance: true,
      addDocumentVisible: false,

      selectedRow: null,
      deleteDocumentConfirmDialogVisible: false,

      galleria1: React.createRef(null),
      imageList1: [],
      imageList2: [],
      imageList3: [],
      galleriaIndex3: 0,

      addDocumentConfirmDialogVisible: false,
      uploadDocumentCheckConfirmDialog: false,

      selectedImage2: null,
      selectedImages2: [],
      images2: [],

      pdfVisible: false,
      documentUrl: "",

      galleria2: React.createRef(null),

      galleria3: React.createRef(null),
      uploadedImages: [],
      galleriaIndex2: 0,
      uploadedImages2: [],
      activeIndex2: null,

      chosenImages: [],
      onValidationFailImages: [],
      newImages2: [],

      //showModal: false,
    };
    this.galleriaRef = React.createRef();
  }

  /*JS_methods*/

  onUpload = () => {
    this.setState({
      loading: true,
    });

    let chosenImages = [...this.state.chosenImages];
    const updatedImages = chosenImages.filter(
      (image) => image.title !== this.state.onValidationFailImages.name
    );

    this.setState((prevState) => ({
      imageList2: [...prevState.imageList2, ...updatedImages],
    }));

    this.setState({
      chosenImages: [],
      loading: false,
    });

    let documents = [...this.state.imageList2,...updatedImages];
    if (documents) {
      let localArray = [];

      for (let i = 0; i < documents.length; i++) {
        const doc = { ...this.emptyNewDocument };

        doc.documentName = documents[i].alt;
        doc.documentURL = documents[i].thumbnailImageSrc;
        doc.fileDescription = documents[i].alt;
        doc.fileName = documents[i].title;
        doc.phase = "P2" + this.state.activeIndex;

        localArray.push(doc);
      }

      this.setState({
        loading: false,
        p21Documents: localArray,
        saveButtonStatus: true,
        chosenImages: [],
      });
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
        selected: false,
        chosenImages: [],
      });
    }
  };

  onSelect = (event) => {
    const files = event.files;

    this.setState({
      loading: true,
    });

    //let newImages = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.startsWith("image/")) {
        // this.setState({
        //   loading: false,
        // });

        const tbuFile = files[i];

        SysService.getS3URL(
          files[i].name,
          "P2" + this.state.activeIndex,
          this.state.pid,
          0
        ).then(
          (response) => {
            const s3Response = response.data;

            SysService.putFile2S3URL(s3Response.uploadURL, tbuFile).then(
              (response) => {
                const imageObject = {
                  itemImageSrc: s3Response.documentURL,
                  thumbnailImageSrc: s3Response.documentURL,
                  alt: s3Response.documentName,
                  title: s3Response.fileName,
                };

                this.setState((prevState) => ({
                  chosenImages: [...prevState.chosenImages, imageObject],
                }));

                this.setState({
                  loading: false,
                });
              },
              (error) => {
                this.setState({
                  loading: false,
                });
                const errorMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.timeoutErrorMessage ||
                  error.toString();

                if (
                  error.response &&
                  (error.response.status === 401 ||
                    error.response.status === 403 ||
                    error.response.status === 404)
                ) {
                  switch (error.response.status) {
                    case 401:
                      window.location.href = "/401";
                      break;
                    case 403:
                      window.location.href = "/403";
                      break;
                    case 404:
                      window.location.href = "/404";
                      break;
                    default:
                      break;
                  }
                }
              }
            );
          },
          (error) => {
            this.setState({
              loading: false,
            });

            const errorMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString();

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }
    }
  };

  onValidationFail = (event) => {
    let validationFailFile = event;

    this.setState({
      onValidationFailImages: validationFailFile,
    });
  };

  //   this.setState({
  //     loading: true,
  //   });

  //   //e.persist();

  //   const selectedImages = [];

  //   for (let i = 0; i < files.length; i++) {
  //     if (files[i].type.startsWith("image/")) {
  //       this.setState({
  //         loading: false,
  //       });

  //       // const emptyFileObj = {
  //       //   objectURL: "",
  //       //   size: "",
  //       //   documentName: "",
  //       //   lastModified: "",
  //       // };

  //       const emptyImgObj = {
  //         itemImageSrc: "",
  //         thumbnailImageSrc: "",
  //         alt: "",
  //         title: "",
  //       };

  //       // const emptyImgObj = {
  //       //   original: "",
  //       //   thumbnail: "",
  //       //   alt: "",
  //       //   title: "",
  //       // };

  //       // emptyFileObj.objectURL = files[i].objectURL;
  //       // emptyFileObj.size = files[i].size;
  //       // emptyFileObj.documentName = files[i].documentName;
  //       // emptyFileObj.lastModified = files[i].lastModified;

  //       // selectedImages.push(emptyFileObj);

  //       emptyImgObj.itemImageSrc = files[i].objectURL;
  //       emptyImgObj.thumbnailImageSrc = files[i].objectURL;
  //       emptyImgObj.alt = files[i].documentName;
  //       emptyImgObj.title = files[i].documentName;

  //       // emptyImgObj.itemImageSrc = files[i].objectURL;
  //       // emptyImgObj.thumbnailImageSrc = files[i].objectURL;
  //       // emptyImgObj.alt = files[i].documentName;
  //       // emptyImgObj.title = files[i].documentName;

  //       selectedImages.push(emptyImgObj);

  //       // SysService.getS3URL(
  //       //   file.name,
  //       //   "P2" + this.state.activeIndex,
  //       //   this.state.pid,
  //       //   0
  //       // ).then(
  //       //   (response) => {
  //       //     const s3Response = response.data;
  //       //     const tbuFile = file;

  //       //     this.setState({
  //       //       loading: true,
  //       //     });

  //       //     SysService.putFile2S3URL(s3Response.uploadURL, tbuFile).then(
  //       //       (response) => {
  //       //         const newImgObj = {
  //       //           uploadURL: s3Response.uploadURL,
  //       //           fileName: s3Response.fileName,
  //       //           documentName: s3Response.documentName,
  //       //           documentURL: s3Response.documentURL,
  //       //         };

  //       //         selectedImages2.push(newImgObj);

  //       //         if (selectedImages2.length === e.target.files.length) {
  //       //           this.setState((prevState) => ({
  //       //             images2: [...prevState.images, ...selectedImages2],
  //       //             loading: false,
  //       //           }));
  //       //         }
  //       //         // this.setState({
  //       //         //   saveVersionButton: true,
  //       //         // });
  //       //       },
  //       //       (error) => {
  //       //         this.setState({
  //       //           loading: false,
  //       //           content:
  //       //             (error.response &&
  //       //               error.response.data &&
  //       //               error.response.data.message) ||
  //       //             error.message ||
  //       //             error.toString(),
  //       //         });
  //       //       }
  //       //     );
  //       //   },
  //       //   (error) => {
  //       //     this.setState({
  //       //       loading: false,
  //       //       content:
  //       //         (error.response &&
  //       //           error.response.data &&
  //       //           error.response.data.message) ||
  //       //         error.message ||
  //       //         error.toString(),
  //       //     });
  //       //   }
  //       // );
  //     }
  //     const uploadedImages2 = [
  //       ...this.state.uploadedImages2,
  //       ...selectedImages,
  //     ];
  //     this.setState({
  //       uploadedImages2,
  //     });
  //   }
  // };
  onClear = () => {
    this.setState({
      chosenImages: [],
    });
  };

  onRemove = (event) => {

    let removedFile = event.file;
    
    let chosenImage = [...this.state.chosenImages];

    const updatedImages = chosenImage.filter(
      (image) => image.title !== removedFile.name
    );
    // const updatedImages = chosenImage.filter(file => file !== removedFile);

    this.setState({
      chosenImages: updatedImages,
    });
  };

  itemTemplate = (item) => {
    return (
      <div className="custom-galleria-item">
        <img
          src={item?.itemImageSrc}
          alt={item?.alt}
          style={{ width: "100%", display: "block" }}
        />
        <div className="custom-content">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    );
  };

  itemTemplate3 = (item) => {
    return (
      <div className="custom-galleria-item">
        <img
          src={item?.itemImageSrc}
          alt={item?.alt}
          style={{ width: "100%", display: "block" }}
        />
        <div className="custom-content">
          <h4>{item?.title}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    );
  };

  thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: "block" }}
      />
    );
  };

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  thumbnailTemplate3 = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: "block", width: "20px" }}
      />
    );
  };

  itemTemplatePdf = () => {
    return (
      <div className="custom-galleria-item">
        <img
          src={this.state.documentUrl}
          style={{ width: "100%", display: "block" }}
        />
      </div>
    );
  };

  PDFViewer = (rowData) => {
    this.setState({
      pdfVisible: true,
      documentUrl: rowData.documentURL,
    });
  };

  actionTemplate = (data, props) => {
    const { t } = this.props;
    return (
      <div>
        <Button
          icon="pi pi-eye"
          tooltip={t("view")}
          className="p-button-rounded p-button-text"
          onClick={() => this.PDFViewer(data, props)}
        />
        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {!isViewerExpert() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.setState({
                selectedRow: data,
                deleteDocumentConfirmDialogVisible: true,
              });
            }}
          />
        )}
      </div>
    );
  };

  forceUpdateGalleria = () => {
    if (this.galleriaRef.current) {
      this.galleriaRef.current.forceUpdate();
    }
  };

  handleKeyDown = (event, name) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      let project = { ...this.state.project };
      project[`${name}`] = null;
      this.setState({
        project,
        saveButtonStatus: true,
      });
    }
  };

  handleDeleteDocument = (rowData) => {
    if (this.state.activeIndex === 0) {
      const index = this.state.p20Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p20Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 2) {
      const index = this.state.p22Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p22Documents.splice(index, 1);
      }
    }

    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  acceptDeleteDocument = () => {
    this.handleDeleteDocument(this.state.selectedRow);
  };

  rejectDeleteDocument = () => {};

  handleAddDocument = () => {
    this.setState({
      addDocumentVisible: true,
      documents: [],
    });
  };

  handleDocuments = () => {
    this.setState({
      loading: true,
    });

    let documents = [...this.state.documents];
    if (documents) {
      let localArray = [];

      for (let i = 0; i < documents.length; i++) {
        const doc = { ...this.emptyNewDocument };

        doc.documentDate = documents[i].documentDate;
        doc.documentName = documents[i].documentName;
        doc.documentURL = documents[i].documentURL;
        doc.fileDescription = documents[i].description;
        doc.fileName = documents[i].fileName;
        doc.phase = "P2" + this.state.activeIndex;

        localArray.push(doc);
      }

      if (this.state.activeIndex === 0) {
        let localP20Documents = [...this.state.p20Documents];
        localP20Documents = localP20Documents.concat(localArray);
        this.setState({
          loading: false,
          p20Documents: localP20Documents,
          addDocumentVisible: false,
          saveButtonStatus: true,
        });
      } else if (this.state.activeIndex === 2) {
        let localP22Documents = [...this.state.p22Documents];
        localP22Documents = localP22Documents.concat(localArray);
        this.setState({
          loading: false,
          p22Documents: localP22Documents,
          addDocumentVisible: false,
          saveButtonStatus: true,
        });
      }
    } else {
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      this.setState({
        loading: false,
      });
    }
  };

  emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  handleAcceptDocument = () => {
    this.setState({
      loading: true,
    });

    let documents = { ...this.state.documents };

    const _data = { ...this.state.newDocument, documents };

    if (
      _data.documentType === null ||
      _data.documentType === undefined ||
      _data.documentType === ""
    ) {
      this.setState({
        loading: false,
      });
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    if (
      _data.documents === null ||
      _data.documents === undefined ||
      _data.documents === "" ||
      _data.documents.length < 1
    ) {
      this.setState({
        loading: false,
      });
      toast.warning(<Trans i18nKey="warnMandatoryDocument" />);
      return;
    }

    this.setState({
      setAddDocumentVisible: false,
    });
  };

  hideDocumentDialog = () => {
    this.setState({
      addDocumentVisible: false,
      documents: [],
    });
  };

  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let temp20Documents = [];
        //let temp21Documents = [];
        let temp22Documents = [];
        let imageList = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P20") {
              temp20Documents.push(response.data.documentList[i]);
            }

            if (response.data.documentList[i].phase === "P22") {
              temp22Documents.push(response.data.documentList[i]);
            }

            // if (response.data.documentList[i].phase === "P21") {
            //   temp21Documents.push(response.data.documentList[i]);
            // }

            if (
              response.data.documentList[i].documentURL?.endsWith(".jpg") ||
              response.data.documentList[i]?.documentURL?.endsWith(".png") ||
              response.data.documentList[i]?.documentURL?.endsWith(".jpeg") ||
              response.data.documentList[i]?.documentURL?.endsWith(".JPG") ||
              response.data.documentList[i]?.documentURL?.endsWith(".PNG") ||
              response.data.documentList[i]?.documentURL?.endsWith(".JPEG")
            ) {
              let newObj = {};
              newObj.itemImageSrc = response.data.documentList[i].documentURL;
              newObj.thumbnailImageSrc =
                response.data.documentList[i].documentURL;
              newObj.alt = response.data.documentList[i].fileDescription;
              newObj.title = response.data.documentList[i].fileName;

              if (response.data.documentList[i].phase === "P20") {
                this.state.imageList1.push(newObj);
              }
              if (response.data.documentList[i].phase === "P22") {
                this.state.imageList3.push(newObj);
              }
              if (response.data.documentList[i].phase === "P21") {
                //this.state.imageList2.push(newObj);
                imageList.push(newObj);
              }
            }
          }
        }

        this.setState({
          project: response.data,
          p20Documents: temp20Documents,
          p22Documents: temp22Documents,
          imageList2: imageList,
          // p21Documents: temp21Documents,
          loading: false,
          blockedPanel: false,
          nonEditAccess:
            response.data.status === 2 && !isViewerExpert() ? false : true,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.initFilters1();
    window.scrollTo(0, 0);
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (!isViewerExpert()) {
        this.setState({
          loading: true,
        });

        this.state.project.documents = [];

        if (inx === 0) {
          this.state.project.documents = this.state.p20Documents;
        }
        if (inx === 1) {
          this.state.project.documents = this.state.p21Documents;
        }
        if (inx === 2) {
          this.state.project.documents = this.state.p22Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P2" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false,
              documents: [],
              imageList1: [],
              imageList2: [],
              imageList3: [],
            });
            this.componentDidMount();

            window.scrollTo(0, 0);
            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  };

  projectIDTemplate(data) {
    let arr = [];

    arr = Array.from(String(data), Number);

    let arrX = [];

    for (let i = 0; i < 5 - arr.length; i++) {
      arrX.push("0");
    }
    let arrRes = arrX.concat(arr);
    let str = "";
    str = str + arrRes.join("");
    return str;
  }

  nextPhase = () => {
    if (!this.state.saveButtonStatus) {
      this.props.history.push({
        pathname: "/mortgageProcess",
        state: this.state.project,
      });
    } else {
      toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
    }
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  setDocumentLoading = (documentLoading) => {
    this.setState({ documentLoading });
  };

  setDocuments = (data) => {
    this.setState({
      documents: data,
    });
  };

  setSaveButtonVisible = (data) => {
    this.setState({
      saveButtonStatus: data,
    });
  };

  setSelectedDocument = (data) => {
    this.setState({
      selectedDocument: data,
    });
  };

  acceptUploadDocumentCheck = () => {
    this.setState({ uploadDocumentCheckConfirmDialog: false });
  };

  uploadDocumentCheck = (e) => {
    if (e.target.value === "1" && this.state.p20Documents.length < 1) {
      this.setState({ uploadDocumentCheckConfirmDialog: true });
    } else {
      this.setState({ uploadDocumentCheckConfirmDialog: false });
    }
  };

  /**/
  render() {
    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);

    this.stepItems = [
      {
        label: t("Survey Notes and Documents"),
        command: (event) => {},
      },
      {
        label: t("Upload Photos"),
        command: (event) => {},
      },
      {
        label: t("Energy Efficiency Installations"),
        command: (event) => {},
      },
    ];

    const addDocumentFooter = () => (
      <>
        <div className="flex justify-content-end px-2">
          <Button
            style={{ backgroundColor: "#414140", width: "20%" }}
            label={t("CANCEL")}
            className="p-button-mb-gray"
            onClick={this.hideDocumentDialog}
          />

          <Button
            label={t("SAVE")}
            style={{ width: "20%" }}
            className="p-button-mb"
            disabled={[...this.state.documents].length > 0 ? false : true}
            onClick={this.handleDocuments}
          />
        </div>
      </>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <Card
                style={{ height: "3.8em", background: "rgb(230, 240, 247)" }}
              >
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip
                      style={{ fontSize: "18px", fontStyle: "Manrope" }}
                      label={
                        this.state.project.projectName +
                        " (ID: " +
                        this.projectIDTemplate(this.state.project.id) +
                        ")"
                      }
                      className="mb-2 custom-chip-home"
                    />
                  </Link>
                </div>
              </Card>

              <br />
              <br />

              <div className={"col-12 "}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      style={{
                        color: "0094A8",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                        fontWeight: "lighter",
                      }}
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />
                    <div>
                      <Chip
                        style={{ fontStyle: "Manrope", fontSize: "14px" }}
                        label={<b>{t("phase") + " 2: " + t("phase2Name")}</b>}
                        icon="pi pi-book"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                      onSelect={(e) => {
                        if (!this.state.saveButtonStatus) {
                          this.setState({ activeIndex: e.index });
                        } else {
                          if (this.state.activeIndex !== e.index) {
                            toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
                          }
                        }
                      }}
                      readOnly={false}
                    />
                  </div>

                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >
                    {/*Survey Notes and Documents*/}
                    <TabPanel header={t("Survey Notes and Documents")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {t("p21Text")}
                      </div>

                      <div className="grid">
                        {/*surveyBookedHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {" "}
                            {t("surveyBooked")}
                          </label>
                        </div>

                        {/*surveyBooked*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="surveyBooked"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.surveyBooked}
                              options={YesNo}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "surveyBooked")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "surveyBooked")
                              }
                            />
                          </div>
                        </div>

                        {/*surveyReportReceivedHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {" "}
                            {t("surveyReportReceived")}
                          </label>
                        </div>

                        {/*surveyReportReceived*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="surveyReportReceived"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.surveyReportReceived}
                              options={surveyReportReceived}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "surveyReportReceived")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "surveyReportReceived")
                              }
                            />
                          </div>
                        </div>

                        {/*constructionYearHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("constructionYear")}
                          </label>
                        </div>

                        {/*constructionYear*/}
                        <div className="col-12 md:col-6 lg:col-6 ">
                          <div>
                            <InputMask
                              id="constructionYear"
                              fontSize="14px"
                              mask="9999"
                              value={this.state.project.constructionYear}
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              placeholder="yyyy"
                              slotChar="yyyy"
                              onChange={(e) =>
                                this.onInputChange(e, "constructionYear")
                              }
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*photosUploadedHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("photosUploaded")}
                          </label>
                        </div>

                        {/*photosUploaded*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="photosUploaded"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.photosUploaded}
                              options={photosUploaded}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "photosUploaded");
                                this.uploadDocumentCheck(e);
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "photosUploaded")
                              }
                            />
                          </div>
                        </div>

                        {/*agreeSpecificationHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("agreeSpecification")}
                          </label>
                        </div>

                        {/*agreeSpecification*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="agreeSpecification"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.agreeSpecification}
                              options={agreeSpecification}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "agreeSpecification")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "agreeSpecification")
                              }
                            />
                          </div>
                        </div>

                        {/*workandCostPlanHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("workandCostPlan")}
                          </label>
                        </div>

                        {/*workandCostPlan*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="workandCostPlan"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.workandCostPlan}
                              options={workandCostPlan}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "workandCostPlan")
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "workandCostPlan")
                              }
                            />
                          </div>
                        </div>

                        {/*workandCostPlanValuationtoUnderwriterHeader*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("workandCostPlanValuationtoUnderwriter")}
                          </label>
                        </div>

                        {/*workandCostPlanValuationtoUnderwriter*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="workandCostPlanValuationtoUnderwriter"
                              style={{ fontSize: "14px" }}
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project
                                  .workandCostPlanValuationtoUnderwriter
                              }
                              options={workandCostPlanValuationtoUnderwriter}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(
                                  e,
                                  "workandCostPlanValuationtoUnderwriter"
                                )
                              }
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "workandCostPlanValuationtoUnderwriter"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*dividerLine*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <hr />
                        </div>

                        {/*addDocument*/}
                        <Button
                          style={{
                            color: "0094A8",
                            fontSize: "12px",
                            fontStyle: "Manrope",
                          }}
                          disabled={this.state.nonEditAccess}
                          label={t("btnAddDocument")}
                          className="p-button p-component p-button-outlined p-button-sm"
                          onClick={this.handleAddDocument}
                        />

                        {/* <div className="field col-12 mb-0">
                          {!isViewerExpert() && (
                            <Button
                              style={{
                                color: "0094A8",
                                fontSize: "12px",
                                fontStyle: "Manrope",
                              }}
                              disabled={this.state.nonEditAccess}
                              label={t("btnAddDocument")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddDocument}
                            />
                          )}
                        </div> */}
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              selectionMode="single"
                              value={this.state.p20Documents}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noDocumentFound")}
                              header={headerTable}
                              paginator
                              rows={10}
                              filters={this.state.filters1}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "fileName",
                                "fileDescription",
                                "documentDate",
                              ]}
                            >
                              <Column
                                field="fileName"
                                header={t("documentName")}
                                sortable
                              ></Column>
                              <Column
                                field="fileDescription"
                                header={t("fileDescription")}
                                sortable
                              ></Column>
                              <Column
                                field="documentDate"
                                header={t("date")}
                                sortable
                              ></Column>

                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "8em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>

                          {this.state.imageList1.length > 0 && (
                            <>
                              <br />
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>{t("uploadedPhotos")}</h5>
                                <br />

                                <Galleria
                                  ref={this.state.galleria1}
                                  value={this.state.imageList1}
                                  numVisible={3}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate}
                                  thumbnail={this.thumbnailTemplate}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{ maxWidth: "100%" }}
                                  thumbnailStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <div
                                  className="grid"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {this.state.imageList1 &&
                                    this.state.imageList1.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex: index,
                                              });
                                              this.state.galleria1.current.show();
                                            }}
                                          />
                                        );
                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </TabPanel>

                    {/*Upload Photos*/}
                    <TabPanel header={t("Survey Notes and Documents")}>
                      <div className="grid">
                        <div className="col-12 md:col-6 lg:col-12">
                          <FileUpload
                            name="demo[]"
                            url="https://primefaces.org/primereact/showcase/upload.php"
                            //onUpload={this.onUpload}
                            //onBeforeSend={this.onBeforeSend}

                            onBeforeUpload={this.onUpload}
                            onSelect={this.onSelect}
                            multiple
                            accept="image/*"
                            maxFileSize={1000000}
                            emptyTemplate={this.emptyTemplate}
                            onClear={this.onClear}
                            onRemove={this.onRemove}
                            onValidationFail={this.onValidationFail}
                          />
                        </div>

                        <div className="col-12 md:col-6 lg:col-12">
                          {this.state.imageList2.length > 0 && (
                            <>
                              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                <h5>Photos Gallery</h5>
                                <br />
                                <Galleria
                                  ref={this.state.galleria2}
                                  value={this.state.imageList2}
                                  numVisible={1}
                                  style={{ maxWidth: "850px" }}
                                  activeIndex={this.state.galleriaIndex2}
                                  onItemChange={(e) => {
                                    this.setState({
                                      galleriaIndex2: e.index,
                                    });
                                  }}
                                  circular
                                  fullScreen
                                  showItemNavigators
                                  showThumbnails={false}
                                  item={this.itemTemplate3}
                                  thumbnail={this.thumbnailTemplate3}
                                  customContent={
                                    <Button
                                      label="Show Details"
                                      icon="pi pi-external-link"
                                      onClick={() => {
                                        this.setState({
                                          visible: true,
                                        });
                                      }}
                                    />
                                  }
                                  itemStyle={{
                                    maxWidth: "100%",
                                    height: "auto",
                                  }}
                                  // thumbnailStyle={{
                                  //   width: "40px",
                                  //   height: "40px",
                                  // }}
                                />

                                <div
                                  className="grid"
                                  style={{
                                    maxWidth: "400px",
                                  }}
                                >
                                  {this.state.imageList2 &&
                                    this.state.imageList2.map(
                                      (image, index) => {
                                        let imgEl = (
                                          <img
                                            src={image.thumbnailImageSrc}
                                            alt={image.alt}
                                            style={{
                                              cursor: "pointer",
                                              width: "80px",
                                              height: "80px",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                galleriaIndex2: index,
                                              });
                                              this.state.galleria2.current.show();
                                            }}
                                          />
                                        );

                                        return (
                                          <div className="col-3" key={index}>
                                            {imgEl}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </TabPanel>

                    {/*Survey Notes and Documents*/}
                    <TabPanel header={t("Survey Notes and Documents")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {t("p22Text")}
                      </div>

                      <div className="grid">
                        {/*Wall Insulation Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("wallInsulation")}
                          </label>
                        </div>

                        {/*Wall Insulation*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="wallInsulation"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.wallInsulationP2}
                              options={WallInsulation}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "wallInsulationP2");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "wallInsulationP2")
                              }
                            />
                          </div>
                        </div>

                        {/*Floor Insulation Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("floorInsulation")}
                          </label>
                        </div>

                        {/*Floor Insulation */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.floorInsulationP2}
                              options={FloorInsulation}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "floorInsulationP2");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "floorInsulationP2")
                              }
                            />
                          </div>
                        </div>

                        {/*Roof Insulation Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("roofInsulation")}
                          </label>
                        </div>

                        {/*Roof Insulation*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.roofInsulationP2}
                              options={RoofInsulation}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "roofInsulationP2");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "roofInsulationP2")
                              }
                            />
                          </div>
                        </div>

                        {/*Windows Upgrade Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("windowsUpgrade")}
                          </label>
                        </div>

                        {/*Windows Upgrade */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.windowsUpgradeP2}
                              options={YesNo}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "windowsUpgradeP2");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "windowsUpgradeP2")
                              }
                            />
                          </div>
                        </div>

                        {/*External Door Replacement Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("externalDoorReplacement")}
                          </label>
                        </div>

                        {/*External Door Replacement*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={
                                this.state.project.externalDoorReplacementP2
                              }
                              options={ExternalDoorReplacement}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(
                                  e,
                                  "externalDoorReplacementP2"
                                );
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(
                                  e,
                                  "externalDoorReplacementP2"
                                )
                              }
                            />
                          </div>
                        </div>

                        {/*Heating System Upgrade Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("heatingSystemUpgrade")}
                          </label>
                        </div>

                        {/*Heating System Upgrade */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.heatingSystemUpgradeP2}
                              options={HeatingSystemUpgrade}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "heatingSystemUpgradeP2");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "heatingSystemUpgradeP2")
                              }
                            />
                          </div>
                        </div>

                        {/*Solar Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("solar")}
                          </label>
                        </div>

                        {/*Solar */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceCertificate"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.solarP2}
                              options={Solar}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "solarP2");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "solarP2")
                              }
                            />
                          </div>
                        </div>

                        {/* Ventilation Header */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <label
                            style={{ fontSize: "14px", fontStyle: "Roboto" }}
                          >
                            {t("ventilation")}
                          </label>
                        </div>

                        {/*Ventilation */}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div>
                            <Dropdown
                              id="energyPerformanceClass"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.ventilationP2}
                              options={Ventilation}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) => {
                                this.onInputChange(e, "ventilationP2");
                              }}
                              optionLabel="name"
                              optionValue="code"
                              className="w-full"
                              onKeyDown={(e) =>
                                this.handleKeyDown(e, "ventilationP2")
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 md:col-12 lg:col-12">
                        <hr />
                      </div>
                      <Button
                        style={{
                          color: "0094A8",
                          fontSize: "12px",
                          fontStyle: "Manrope",
                        }}
                        disabled={this.state.nonEditAccess}
                        label={t("btnAddDocument")}
                        className="p-button p-component p-button-outlined p-button-sm"
                        onClick={this.handleAddDocument}
                      />

                      <div className="col-12">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                          <DataTable
                            name="dt"
                            size="small"
                            showGridlines
                            selectionMode="single"
                            value={this.state.p22Documents}
                            dataKey="id"
                            stripedRows
                            responsiveLayout="scroll"
                            emptyMessage={t("noDocumentFound")}
                            header={headerTable}
                            paginator
                            rows={10}
                            filters={this.state.filters1}
                            filterDisplay="menu"
                            loading={this.state.loading}
                            globalFilterFields={[
                              "fileName",
                              "fileDescription",
                              "documentDate",
                            ]}
                          >
                            <Column
                              field="fileName"
                              header={t("documentName")}
                              sortable
                            ></Column>
                            <Column
                              field="fileDescription"
                              header={t("fileDescription")}
                              sortable
                            ></Column>
                            <Column
                              field="documentDate"
                              header={t("date")}
                              sortable
                            ></Column>

                            <Column
                              header={t("action")}
                              style={{ textAlign: "center", width: "8em" }}
                              body={this.actionTemplate}
                            />
                          </DataTable>
                        </div>

                        {this.state.imageList3.length > 0 && (
                          <>
                            <br />
                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                              <h5>{t("uploadedPhotos")}</h5>
                              <br />

                              <Galleria
                                ref={this.state.galleria3}
                                value={this.state.imageList3}
                                numVisible={3}
                                style={{ maxWidth: "850px" }}
                                activeIndex={this.state.galleriaIndex}
                                onItemChange={(e) => {
                                  this.setState({
                                    galleriaIndex3: e.index,
                                  });
                                }}
                                circular
                                fullScreen
                                showItemNavigators
                                showThumbnails={false}
                                item={this.itemTemplate}
                                thumbnail={this.thumbnailTemplate}
                                customContent={
                                  <Button
                                    label="Show Details"
                                    icon="pi pi-external-link"
                                    onClick={() => {
                                      this.setState({
                                        visible: true,
                                      });
                                    }}
                                  />
                                }
                                itemStyle={{ maxWidth: "100%" }}
                                thumbnailStyle={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />

                              <div
                                className="grid"
                                style={{ maxWidth: "400px" }}
                              >
                                {this.state.imageList3 &&
                                  this.state.imageList3.map((image, index) => {
                                    let imgEl = (
                                      <img
                                        src={image.thumbnailImageSrc}
                                        alt={image.alt}
                                        style={{
                                          cursor: "pointer",
                                          width: "80px",
                                          height: "80px",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            galleriaIndex3: index,
                                          });
                                          this.state.galleria3.current.show();
                                        }}
                                      />
                                    );
                                    return (
                                      <div className="col-3" key={index}>
                                        {imgEl}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <br />
                <div
                  class="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div class="btn-group" role="group" aria-label="First group">
                    {!isViewerExpert() && (
                      <Button
                        disabled={!this.state.saveButtonStatus}
                        style={{ fontSize: "14px", fontStyle: "Manrope" }}
                        icon="pi pi-check-circle"
                        label={
                          this.state.activeIndex < this.stepItems.length - 1
                            ? t("saveNext")
                            : t("save")
                        }
                        className="p-button-sm p-button-danger"
                        onClick={() => this.stepSaveAndNext()}
                      />
                    )}
                  </div>
                  <div class="btn-group">
                    <div class="input-group-prepend">
                      <Button
                        style={{
                          fontSize: "14px",
                          fontStyle: "Manrope",
                        }}
                        iconPos="right"
                        icon="pi pi-angle-double-right"
                        label={t("nextPhase")}
                        className="p-button-sm p-button-mb"
                        onClick={() => this.nextPhase()}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <Dialog
                visible={this.state.addDocumentVisible}
                style={{ width: "650px" }}
                header={t("addDocument")}
                modal
                className="p-fluid"
                footer={addDocumentFooter}
                onHide={this.hideDocumentDialog}
              >
                <DocumentUpload
                  loading={this.state.loading}
                  setLoading={this.setLoading}
                  documents={this.state.documents}
                  setDocuments={this.setDocuments}
                  selectedDocument={this.state.selectedDocument}
                  setSelectedDocument={this.setSelectedDocument}
                  setSaveButtonVisible={this.setSaveButtonVisible}
                  uploadType={"D"}
                  isMulti={true}
                  isRequired={this.state.isRequiredDocumentCompliance}
                  phase={"P2" + this.state.activeIndex}
                  pid={this.state.pid}
                />
              </Dialog>

              <Dialog
                visible={this.state.pdfVisible}
                style={{ width: "%100", height: "%100" }}
                modal
                className="p-fluid"
                onHide={() => this.setState({ pdfVisible: false })}
              >
                {this.state.documentUrl.endsWith(".png") ||
                this.state.documentUrl.endsWith(".jpeg") ||
                this.state.documentUrl.endsWith(".jpg") ? (
                  <Galleria
                    value={this.state.documentUrl}
                    numVisible={5}
                    circular
                    style={{ maxWidth: "640px", borderRadius: "6px" }}
                    showThumbnails={false}
                    item={this.itemTemplatePdf}
                  />
                ) : this.state.documentUrl.endsWith(".pdf") ? (
                  <PdfViewer pdfUrl={this.state.documentUrl} />
                ) : (
                  <div>No valid document format found</div>
                )}
              </Dialog>

              <ConfirmDialog
                visible={this.state.deleteDocumentConfirmDialogVisible}
                onHide={() => {
                  this.setState({ deleteDocumentConfirmDialogVisible: false });
                }}
                message={t("confirmMessage")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle"
                accept={this.acceptDeleteDocument}
                reject={this.rejectDeleteDocument}
              />

              <ConfirmDialog
                visible={this.state.uploadDocumentCheckConfirmDialog}
                onHide={() => {
                  this.setState({ uploadDocumentCheckConfirmDialog: false });
                }}
                message={t("uploadDocumentCheckText2")}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-orange"
                accept={this.acceptUploadDocumentCheck}
                reject={this.acceptUploadDocumentCheck}
                acceptLabel={t("okey")}
                rejectLabel={t("cancel")}
              />

              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />

              <WaitDialog loading={this.state.loading} lang={t} />
              <WaitDialogDocument
                loading={this.state.documentLoading}
                lang={t}
              />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(FS));
