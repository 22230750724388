import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import ReactHtmlParser from "react-html-parser";
import { withTranslation, Trans } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { format, parseISO } from "date-fns";
import SysService from "../services/sys.service";
import { toast } from "react-toastify";
import i18n from "i18next";
import Tasks from "../components/tasks";

const TaskInfo = ({
  t,
  startDate,
  dueDate,
  selectedMembers,
  selectedPriority,
  selectedProgress,
  selectedTask,
  notes,
  projectName,
  pid,
  notifyUpdate 
}) => {
  const [startDateInfo, setStartDateInfo] = useState([]);
  const [dueDateInfo, setDueDateInfo] = useState([]);
  const [selectedPriorityInfo, setSelectedPriorityInfo] = useState();
  const [selectedProgressInfo, setSelectedProgressInfo] = useState();
  const [notesInfo, setNotesInfo] = useState("");
  const [projectNameInfo, setProjectNameInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");

  useEffect(() => {
    setStartDateInfo(startDate);
    setDueDateInfo(dueDate);
    setSelectedPriorityInfo(selectedPriority);
    setSelectedProgressInfo(selectedProgress);
    setNotesInfo(notes);
    setProjectNameInfo(projectName);
  }, [
    startDate,
    dueDate,
    selectedMembers,
    selectedPriority,
    selectedProgress,
    selectedTask,
    notes,
    projectName,
  ]);

  const userTemplate = (user) => {
    return (
      <div className="flex justify-content-center align-items-center">
        <Avatar
          shape="circle"
          label={user.firstName[0] + user.lastName[0]}
          style={{
            backgroundColor: "#0094A8",
            color: "#ffffff",
            borderRadius: "5rem",
          }}
          size="large"
          title={user.firstName + " " + user.lastName}
        ></Avatar>
      </div>
    );
  };

  const priorityOptions = [
    { name: t("priorityLow"), code: 1 },
    { name: t("priorityMedium"), code: 2 },
    { name: t("priorityHigh"), code: 3 },
  ];

  const progressOptions = [
    { name: t("notStarted"), code: 1 },
    { name: t("inProgress"), code: 2 },
    { name: t("completed"), code: 3 },
  ];

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "600px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "0px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const acceptUpdateTask = () => {
    setLoading(true);
    if (
      startDateInfo === null ||
      startDateInfo === "" ||
      dueDateInfo === null ||
      dueDateInfo === ""
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    SysService.updateTask(
      selectedTask?.taskName,
      notesInfo,
      startDateInfo,
      dueDateInfo,
      selectedPriorityInfo,
      selectedProgressInfo,
      pid,
      selectedTask?.id,
      selectedMembers,
      selectedTask?.calendarId,
      selectedColor
    ).then(
      (response) => {
        setLoading(false);
        toast.success(i18n.t(response.data.message));
        notifyUpdate();
      },

      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    // const calendarApi = selectedDate.event;

    // SysService.updateCalendarEvent(
    //   selectedTask?.calendarId,
    //   selectedTask?.taskName,
    //   notesInfo,
    //   selectedColor,
    //   startDateInfo,
    //   dueDateInfo,
    //   pid,
    //   selectedTask?.id
    // ).then(
    //   (response) => {
    //     calendarApi.setExtendedProp("event_id", selectedTask?.id);
    //     calendarApi.setExtendedProp("description", notesInfo);
    //     calendarApi.setProp("title", selectedTask?.taskName);
    //     calendarApi.setProp("backgroundColor", selectedColor);
    //     calendarApi.setStart(startDateInfo);
    //     calendarApi.setEnd(dueDateInfo);
    //     setSelectedDate("");
    //   },
    //   (error) => {
    //     setError(
    //       (error.response &&
    //         error.response.data.cluster &&
    //         error.response.data.cluster.message) ||
    //         error.message ||
    //         error.timeoutErrorMessage ||
    //         error.toString()
    //     );
    //   }
    // );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <span className="platformColor mt-1 mb-3">{t("taskInfo")}</span>
          <div
            className="flex flex-column p-6 gap-2"
            style={{
              borderWidth: "3px",
              borderStyle: "solid",
              borderColor: "#0094A8",
              borderRadius: "6px",
              width: "550px",
              height: "550px",
              maxWidth: "100%",
              boxSizing: "border-box",
              overflowX: "auto",
            }}
          >
            <span className="platformColor">{t("assignedMembers")}</span>
            <br />
            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
              <div style={{ maxWidth: "100%", height: "100%" }}>
                <Carousel
                  value={selectedMembers}
                  numVisible={3}
                  numScroll={3}
                  responsiveOptions={responsiveOptions}
                  itemTemplate={userTemplate}
                  showIndicators={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                <div className="p-float-label-taskInfo w-full">
                  <div className="mb-4">
                    <h5 className="platformColor">{t("startDate")}</h5>
                    <Calendar
                      value={startDateInfo}
                      onChange={(e) => {
                        setStartDateInfo(e.value);
                      }}
                      dateFormat="dd/mm/yy"
                      className="p-inputtext-sm w-full"
                      showIcon={true}
                      id="startDate"
                      maxDate={dueDateInfo ? new Date(dueDateInfo) : null}
                      showButtonBar
                      onClearButtonClick={() => {
                        setStartDateInfo([]);
                      }}
                      appendTo="self"
                      autoZIndex={true}
                      showTime
                    />
                    {/* <InputText
                      style={{
                        backgroundColor: "#F4F4F4",
                      }}
                      readOnly={true}
                      value={formatDate(startDate)}
                      className="w-full"
                      onChange={(e) =>
                        setStartDateInfo({
                          ...newTaskContent,
                          taskName: e.target.value,
                        })
                      }
                    /> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                <div className="p-float-label-taskInfo w-full">
                  <div className="mb-4">
                    <h5 className="platformColor">{t("dueDate")}</h5>
                    <Calendar
                      value={dueDateInfo}
                      onChange={(e) => setDueDateInfo(e.value)}
                      dateFormat="dd/mm/yy"
                      className="p-inputtext-sm w-full"
                      id="dueDate"
                      minDate={startDateInfo ? new Date(startDateInfo) : null}
                      showTime
                      showIcon
                      showButtonBar
                      onClearButtonClick={() => {
                        setDueDateInfo(null);
                      }}
                      appendTo="self"
                      autoZIndex={true}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                <div className="p-float-label-taskInfo w-full">
                  <div className="mb-4">
                    <h5 className="platformColor">{t("priority")}</h5>

                    <Dropdown
                      value={selectedPriorityInfo}
                      options={priorityOptions}
                      onChange={(e) => setSelectedPriorityInfo(e.value)}
                      optionLabel="name"
                      optionValue="code"
                      className="p-inputtext-sm w-full"
                      id="priority"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                <div className="p-float-label-taskInfo w-full">
                  <div className="mb-4">
                    <h5 className="platformColor">{t("progress")}</h5>
                    <Dropdown
                      value={selectedProgressInfo}
                      options={progressOptions}
                      onChange={(e) => setSelectedProgressInfo(e.value)}
                      optionLabel="name"
                      optionValue="code"
                      className="p-inputtext-sm w-full"
                      id="progress"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
              <div className="p-float-label-taskInfo w-full">
                <div className="mb-4">
                  <h5 className="platformColor">{t("project")}</h5>
                  <InputText
                    style={{
                      backgroundColor: "#F4F4F4",
                    }}
                    readOnly={true}
                    value={projectName}
                    className="w-full"
                  />
                </div>
              </div>
            </div>

            <div className="flex align-items-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="p-float-label-taskInfo w-full">
                  <div className="mb-4">
                    <h5 className="platformColor">{t("notes")}</h5>
                    {/* <InputText
                      style={{
                        backgroundColor: "#F4F4F4",
                      }}
                      readOnly={true}
                      value={ReactHtmlParser(selectedTask?.notes)}
                      className="w-full"
                    /> */}

                    {/* <InputTextarea
                      style={{
                        backgroundColor: "#F4F4F4",
                      }}
                      fontSize="14px"
                      value={ReactHtmlParser(selectedTask?.notes)}
                      rows={3}
                      cols={20}
                      maxLength={1000}
                      className="w-full"
                    /> */}
                    <InputTextarea
                      id="worksRequired"
                      fontSize="14px"
                      value={notesInfo}
                      onChange={(e) => setNotesInfo(e.target.value)}
                      placeholder={t("pleaseEnter")}
                      rows={3}
                      cols={20}
                      maxLength={1000}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex align-items-center justify-content-end gap-3">
              <Button
                label={t("updateTask")}
                style={{
                  backgroundColor: "#0094A8",
                  color: "#fff",
                  width: "100%",
                }}
                onClick={acceptUpdateTask}
                id="buttonFontWeight"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(TaskInfo);
